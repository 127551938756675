// import './Mitwank_Dashboard.css';
import "../../App.css";
import { useEffect, useState } from "react";
import { Table } from "antd";
import "antd/dist/antd.css";
import React from "react";
import "../Mitwank/Sidebar.css";
import Loader from "../Loader";
import { Popover } from "antd";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
function ReceptionistAppointmentByDate() {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    pageSizeOptions: ["5", "10", "20", "50", "100"],
    showSizeChanger: true,
  });

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const [loaderon, setloaderon] = useState({});
  const [loaderonpay, setloaderonpay] = useState({});
  let navigate = useNavigate();
  const findBookingIndex = (id) => {
    const index = AppointmentData.findIndex(
      (booking) => booking.BookingId === id
    );
    return index;
  };

  function Pending_Payment(Paymentindex, BookingId) {
    var Url = window.API_PREFIX + "admin/Paybydoctor";
    var BookingIndex = findBookingIndex(BookingId);
    setloaderonpay({ ...loaderonpay, [Paymentindex]: true });
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: AppointmentData[BookingIndex]["BookingId"],
        PatientId: AppointmentData[BookingIndex]["PatientId"],
        loginAs: localStorage.getItem("LoginDetails"),
        receptionistId: localStorage.getItem("receptionistId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        setloaderonpay({ ...loaderonpay, [Paymentindex]: false });
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        } 
        else if (data["status"] === "1") {
          var tmp = AppointmentData;
          tmp[BookingIndex]["BookingStatus"] = 2;
          setAppointmentData([...tmp]);
        }
      });
  }

  function Delete_Appointment(Requestindex, BookingId) {
    var Url = window.API_PREFIX + "booking/delete_booking";
    // dispatch({
    //     type: 'StartLoad'
    // })
    var BookingIndex = findBookingIndex(BookingId);

    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: AppointmentData[BookingIndex]["BookingId"],
        loginAs: localStorage.getItem("LoginDetails"),
        receptionistId: localStorage.getItem("receptionistId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        // dispatch({
        //     type: 'StopLoad'
        // })
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        }
        else if (data["status"] === "1") {
          var tmp = AppointmentData;
          tmp.splice(BookingIndex, 1);
          setAppointmentData([...tmp]);
        }
      });
  }

  function RestoreRequest(Requestindex, BookingId) {
    var Url = window.API_PREFIX + "booking/restore_trash_booking";
    // dispatch({
    //     type: 'StartLoad'
    // })
    var BookingIndex = findBookingIndex(BookingId);

    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: AppointmentData[BookingIndex]["BookingId"],
        loginAs: localStorage.getItem("LoginDetails"),
        receptionistId: localStorage.getItem("receptionistId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        // dispatch({
        //     type: 'StopLoad'
        // })
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        }
        else if (data["status"] === "1") {
          var tmp = AppointmentData;
          tmp.splice(BookingIndex, 1);
          setAppointmentData([...tmp]);
        }
      });
  }

  function TrashDelete(Requestindex, BookingId) {
    var Url = window.API_PREFIX + "booking/trash_booking";
    var BookingIndex = findBookingIndex(BookingId);

    // dispatch({
    //     type: 'StartLoad'
    // })
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: AppointmentData[BookingIndex]["BookingId"],
        loginAs: localStorage.getItem("LoginDetails"),
        receptionistId: localStorage.getItem("receptionistId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        // dispatch({
        //     type: 'StopLoad'
        // })
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        }
        else if (data["status"] === "1") {
          var tmp = AppointmentData;
          tmp.splice(BookingIndex, 1);
          setAppointmentData([...tmp]);
        }
      });
  }

  function AcceptRequest(Requestindex, BookingId) {
    var Url = window.API_PREFIX + "booking/approve_booking";
    var BookingIndex = findBookingIndex(BookingId);
    setloaderon({ ...loaderon, [Requestindex]: true });
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: AppointmentData[BookingIndex]["BookingId"],
        clinicId: localStorage.getItem("clinicId"),
        loginAs: localStorage.getItem("LoginDetails"),
        receptionistId: localStorage.getItem("receptionistId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        setloaderon({ ...loaderon, [Requestindex]: false });
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        }
        else if (data["status"] === "1") {
          var tmp = AppointmentData;
          tmp[BookingIndex]["BookingStatus"] = 1;
          setAppointmentData([...tmp]);
        }
      });
  }

  function RejectRequest(Requestindex, BookingId) {
    var Url = window.API_PREFIX + "booking/reject_booking";
    setloaderon({ ...loaderon, [Requestindex]: true });
    var BookingIndex = findBookingIndex(BookingId);

    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: AppointmentData[BookingIndex]["BookingId"],
        clinicId: localStorage.getItem("clinicId"),
        loginAs: localStorage.getItem("LoginDetails"),
        receptionistId: localStorage.getItem("receptionistId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        setloaderon({ ...loaderon, [Requestindex]: false });
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        }
        else if (data["status"] === "1") {
          var tmp = AppointmentData;
          tmp[BookingIndex]["BookingStatus"] = -1;
          setAppointmentData([...tmp]);
        }
      });
  }

  // function AcceptRequest(Requestindex) {
  //     var tmp = AppointmentData
  //     tmp[Requestindex]['BookingStatus'] = 1
  //     setAppointmentData([...tmp])
  // }

  // function RejectRequest(Requestindex) {
  //     var tmp = AppointmentData
  //     tmp[Requestindex]['BookingStatus'] = -1
  //     setAppointmentData([...tmp])
  // }

  const columns = [
    {
      title: "Client Name",

      dataIndex: "PatientName",
      key: "name",
      //   render: (text) => <a>{text}</a>,
      render: (text, record) => (
        <>
          <div className="admin_dashboard_table_image_name_division">
            <img
              className="admin_dashboard_table_image"
              style={{ height: "2.5rem", width: "2.5rem" }}
              src={record["ProfilePic"]}
            />
            <div className="admin_dashboard_table_name_id_division">
              <div className="admin_dashboard_table_patient_name">{text}</div>
              <div className="admin_dashboard_table_patient_id">
                #SH{record["PatientId"]}
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Booking Date",
      dataIndex: "Date",
      key: "Bookingdate",
    },
    {
      title: "Appt Date",
      dataIndex: "Date",
      key: "date",
      render: (text, record) => (
        <>
          <div className="admin_dashboard_time_date_div">
            <div className="admin_dashboard_table_appt_date">{text}</div>
            <div className="admin_dashboard_table_appt_time">
              {record["Time"]}
            </div>
          </div>
        </>
      ),
    },
    // {
    //   title: 'Purpose',
    //   dataIndex: 'Purpose',
    //   key: 'purpose',
    //   render: (text, record) => <>
    //     <div className='admin_dashboard_table_appt_date'>{text}</div>

    //   </>
    // },
    // {
    //     title: 'Type',
    //     dataIndex: 'Patienthistory',
    //     key: 'type',
    //     render: (text, record) => <>
    //         <div className='admin_dashboard_table_appt_date'>{text ? 'Old Patient' : 'New Patient'}</div>
    //     </>
    // },
    {
      title: "Paid Amount",
      dataIndex: "Amount",
      key: "paidamount",
      //         render: (text,record) => <>
      //         <div className='admin_dashboard_table_appt_date'>{text}</div>

      // </>
    },
    {
      title: "Status",
      key: "tags",
      dataIndex: "BookingStatus",
      render: (text, record, index) => (
        <>
          {text === 0 ? (
            <div className="admin_dashboard_tabel_view_accept_cancel">
              {loaderon[index] ? (
                <Loader />
              ) : (
                <button
                  className="btn admin_dashboard_tabel_accept_btn"
                  onClick={() => {
                    AcceptRequest(index, record.BookingId);
                  }}
                >
                  Accept
                </button>
              )}

              {loaderon[index] ? (
                <Loader />
              ) : (
                <button
                  className="btn admin_dashboard_tabel_cancel_btn"
                  onClick={() => {
                    RejectRequest(index, record.BookingId);
                  }}
                >
                  Reject
                </button>
              )}
            </div>
          ) : text === 1 ? (
            <div className="admin_dashboard_tabel_view_accept_cancel">
              <div>
                <button
                  className="btn admin_dashboard_tabel_accept_btn"
                  disabled
                >
                  Accepted
                </button>
              </div>
            </div>
          ) : text === 2 ? (
            <div className="admin_dashboard_tabel_view_accept_cancel">
              <div>
                <button
                  className="btn admin_dashboard_tabel_accept_btn"
                  disabled
                >
                  Accepted
                </button>
              </div>
            </div>
          ) : (
            <div className="admin_dashboard_tabel_view_accept_cancel">
              <div>
                <button
                  className="btn admin_dashboard_tabel_cancel_btn"
                  disabled
                >
                  Rejected
                </button>
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Payment Status",
      key: "tags",
      dataIndex: "Ispaid",
      render: (text, record, index) => (
        <>
          <div className="admin_dashboard_tabel_view_accept_cancel">
            {record["BookingStatus"] === 1 ? (
              <div>
                {loaderonpay[index] ? (
                  <Loader />
                ) : (
                  <Popover
                    content={
                      <div className="d-flex justify-content-between">
                        <a onClick={pophide}>No</a>
                        <a
                          onClick={() => {
                            Pending_Payment(index, record.BookingId);
                            pophide();
                          }}
                        >
                          Yes
                        </a>
                      </div>
                    }
                    title="Are you want to sure ?"
                    trigger="click"
                    open={activePopoverIndex === index}
                    onOpenChange={(open) => handlepopOpenChange(index, open)}
                  >
                    <button className=" btn admin_dashboard_tabel_view_btn">
                      {" "}
                      Pending Payment
                    </button>
                  </Popover>
                )}
              </div>
            ) : record["BookingStatus"] === 2 ? (
              <div>
                <button
                  className="btn admin_dashboard_tabel_accept_btn"
                  disabled
                >
                  {/* <i class="fa-regular fa-eye me-1"></i> */}
                  Paid
                </button>
              </div>
            ) : record["BookingStatus"] === 0 ? (
              <div>
                <button className="btn admin_dashboard_tabel_view_btn" disabled>
                  {/* <i class="fa-regular fa-eye me-1"></i> */}
                  Pending Request
                </button>
              </div>
            ) : (
              <div>
                <button
                  className="btn admin_dashboard_tabel_cancel_btn"
                  disabled
                >
                  {/* <i class="fa-regular fa-eye me-1"></i> */}
                  N/A
                </button>
              </div>
            )}
            {/* <div><button className='btn admin_dashboard_tabel_cancel_btn'><i class="fa-regular fa-eye me-1"></i>Cancel</button></div> */}
          </div>
        </>
      ),
    },
  ];

  const [AppointmentData, setAppointmentData] = useState([]);
  const [NonSearchAppointmentData, setNonSearchAppointmentData] = useState([]);
  const [FilterDate, setFilterDate] = useState([]);
  const [AppointmentOption, setAppointmentOption] = useState([
    true,
    false,
    false,
  ]);

  useEffect(() => {
    var Url = window.API_PREFIX + "admin/All_booking";
    let clinic_id = localStorage.getItem("clinicId");
    let loginAs = localStorage.getItem("loginAs");
    let receptionistId = localStorage.getItem("receptionistId");
    let queryParams = [];
    if (clinic_id) queryParams.push(`clinicId=${clinic_id}`);
    if (loginAs) queryParams.push(`loginAs=${loginAs}`);
    if (receptionistId) queryParams.push(`receptionistId=${receptionistId}`);
    if (queryParams.length > 0) {
      Url += `?${queryParams.join("&")}`;
    }
    fetch(Url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
      // body: JSON.stringify({'Token':localStorage.getItem('DToken')}),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        }
        else if (data["status"] === "1") {
          setAppointmentData([...data["Booking"]]);
          setNonSearchAppointmentData([...data["Booking"]]);
        }
      });
  }, []);

  const [activePopoverIndex, setActivePopoverIndex] = useState(null);

  const handlepopOpenChange = (index, open) => {
    if (open) {
      setActivePopoverIndex(index);
    } else {
      setActivePopoverIndex(null);
    }
  };

  const pophide = () => {
    setActivePopoverIndex(null);
  };

  return (
    <>
      <div className="container-fuild mx-2">
        <div className="admin_dashboard_main_diviison">
          {/* <span className='admin_dashboard_patientappoinment_name'>Appoinment By Date</span> */}
          <div className="admin_dashboard_patientappoinment_date_division">
            <input
              type="date"
              className="admin_dashboard_patientappoinment_celender"
              id="birthday"
              name="birthday"
              onChange={(e) => {
                const date = new Date(e.target.value);
                const formattedDate = date.toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                });

                // Trim the month to the first three characters to ensure uniformity
                const parts = formattedDate.split(" ");
                parts[1] = parts[1].slice(0, 3); // Trim month to first 3 characters

                const correctedDate = parts.join(" ");

                console.log(correctedDate);
                setFilterDate(correctedDate);
              }}
            />
            <button
              className="btn admin_dashboard_patientappoinment_date_btn"
              onClick={() => {
                console.log("FilterDate", FilterDate);

                if (FilterDate === "" || FilterDate === "Invalid Date") {
                  setAppointmentData([...NonSearchAppointmentData]);
                } else {
                  setAppointmentData([
                    ...NonSearchAppointmentData.filter(
                      (item) => item.Date == FilterDate
                    ),
                  ]);
                  console.log("AppointmentData123", AppointmentData);
                }
              }}
            >
              Apply
            </button>
          </div>
        </div>
        <div className="admin_dashboard_patient_appointment_table_main_division">
          <div className="admin_dashboard_patient_appointment_table_division">
            <Table
              columns={columns}
              dataSource={AppointmentData}
              pagination={pagination}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ReceptionistAppointmentByDate;
