import React from 'react';
import './Invoice.css'

function Invoice() {
    return (
        <div>
            <header>
                <div className="d-flex justify-content-between">
                    <div>Contact No : (+91)-7383977771</div>
                    <div>
                        <div>GSTIN : 24FNUPS0046G1ZP</div>
                        <div>IE Code : FNUPS0046G</div>
                    </div>
                </div>
                <div className="text-center">
                    <img src="./headermainlogo.png" style={{ height: '100%', width: '40%' }} />
                </div>
                <div
                    style={{ fontSize: '15.5px', textAlign: 'center', borderBottom: '2px solid #e11f28', marginBottom: '10px' }}>
                    FF-9,SunRise Shops & Flats, Near Vrundavan Crossing, Waghodia Road,
                    Vadodara - 390025
                </div>
            </header>
            <section
                style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                <div
                    style={{ borderBottom: '2px solid black', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                    <h1 style={{ marginRight: '35px' }}>PROFORMA INVOICE</h1>

                    <div>
                        <div className="d-flex" style={{ borderBottom: '2ps solid black' }}>
                            <div
                                style={{ padding: '1px', borderLeft: '2px solid black', borderRight: '2px solid black', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ border: '1px solid black', height: '20px', width: '20px' }} ></div>
                            </div>
                            <div><b>Original for Recipient</b></div>
                        </div>

                        <div className="d-flex" style={{ borderBottom: '2px solid black' }}>
                            <div style={{ padding: '1px', borderLeft: '2px solid black', borderRight: '2px solid black', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ border: '1px solid black', height: '20px', width: '20px' }}></div>
                            </div>
                            <div><b>Duplicate</b></div>
                        </div>
                        <div className="d-flex">
                            <div style={{ padding: '1px', borderLeft: '2px solid black', borderRight: '2px solid black', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ border: '1px solid black', height: '20px', width: '20px' }}></div>
                            </div>
                            <div><b>Triplicate</b></div>
                        </div>
                    </div>
                </div>

                <div className="d-flex"
                    style={{ width: '100%', marginBottom: '25px', borderBottom: '1px solid black' }}>
                    <div style={{ width: '60%', borderRight: '1px solid black' }}>
                        <div style={{ padding: '10px', borderBottom: '1px solid black' }}></div>
                        <div style={{ marginLeft: '5px' }}>
                            <div>Name : PATEL PERIL</div>
                            <div>Address : 13 Silverdale Road, Stockport SK8 4QS</div>
                            <div>GSTIN : 24BEHPM2834L1ZC</div>
                            <div>State : Gujarat</div>
                            <div className='d-flex justify-content-center align-items-end' >
                                <div className='d-flex' style={{ borderLeft: '1px solid black', borderTop: '1px solid black', borderRight: '1px solid black', marginRight: '5px' }}>
                                    <div style={{ borderRight: '1px solid black', padding: '1px' }}>
                                        State Code:
                                    </div>
                                    <div className='pt-1'>24</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ width: '40%' }}>
                        <div style={{ padding: '10px', borderBottom: '1px solid black' }}></div>
                        <div style={{ marginLeft: '5px' }}>
                            <div>Invoice No. : 61</div>
                            <div>Invoice Date : 08/12/2023</div>
                            <div>State :Gujarat</div>
                            <div>&nbsp;&nbsp;</div>
                            <div className='d-flex justify-content-end'>
                                <div className='d-flex' style={{ borderLeft: '1px solid black', borderTop: '1px solid black', borderRight: '1px solid black', marginRight: '5px' }}>
                                    <div style={{ borderRight: '1px solid black', padding: '1px' }}>
                                        State Code:
                                    </div>
                                    <div style={{ padding: '1px' }}>24</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <table style={{ width: '100%', height: '27vh' }} className="main_table">
                    <thead>
                        <tr style={{ borderBottom: '1px solid black' }}>
                            <th style={{ borderRight: '1px solid black', textAlign: 'center' }}>
                                Sr.<br />
                                No.
                            </th>
                            <th style={{ borderRight: '1px solid black', textAlign: 'center' }}>
                                Name Of <br />Product/Service
                            </th>
                            <th style={{ borderRight: '1px solid black', textAlign: 'center' }}>
                                HSN<br />SAC
                            </th>
                            <th style={{ borderRight: '1px solid black', textAlign: 'center' }}>
                                Qty
                            </th>
                            <th style={{ borderRight: '1px solid black', textAlign: 'center' }}>
                                Amount
                            </th>
                            <th style={{ borderRight: '1px solid black', textAlign: 'center' }}>
                                Total
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-center">1</td>
                            <td className="text-start">Flutter App Development</td>
                            <td className="text-center">999294</td>
                            <td className="text-center">1</td>
                            <td className="text-center">₹ 4,694.92</td>
                            <td className="text-center">₹ 4,694.92</td>
                        </tr>

                        <tr style={{ borderTop: '1px solid black', height: '50px' }}>
                            <td className="text-center" style={{ border: 'none' }}></td>
                            <td className="text-start" style={{ border: 'none' }}></td>
                            <td className="text-center">Total</td>
                            <td className="text-center">1</td>
                            <td className="text-center">₹ 4,694.92</td>
                            <td className="text-center">₹ 4,694.92</td>
                        </tr>
                    </tbody>
                </table>

                <div className="d-flex">
                    <div style={{ width: '58.9%', borderRight: '1px solid black' }}>
                        <div style={{ padding: '10px 5px', borderBottom: '1px solid black' }}>
                            <div>Total invoice Amount In Words:</div>
                            <div>(Two Thousand point Zero Zero INR only)</div>
                        </div>

                        <div className="d-flex">
                            <div style={{ borderRight: '1px solid black' }}>
                                <div style={{ borderBottom: '1px solid black' }}>
                                    <div className="text-center"><b>:Bank Details:</b></div>
                                    <div className="d-flex p-2">
                                        <div>
                                            <div>Bank Name</div>
                                            <div>Bank A/C Number</div>
                                            <div>Bank Branch IFSC</div>
                                        </div>
                                        <div>
                                            <div>: Bank of Maharashtra</div>
                                            <div>: 60307655589</div>
                                            <div>: MAHB0000716</div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="text-center"><b>:Terms & Conditions:</b></div>
                                    <div style={{ height: '50px' }}></div>
                                    <div className="text-center">(*Subject to Vadodara jurisdion)</div>
                                </div>
                            </div>
                            <div></div>
                        </div>
                    </div>
                    <div style={{ width: '41.1%' }}>
                        <div className="d-flex justify-content-between">
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <td style={{ background: '#feed00' }}>Total Amount before Tax:</td>
                                    <td>₹1,694.92</td>
                                </tr>
                                <tr>
                                    <td>Add CGST 9%:</td>
                                    <td>₹152.54</td>
                                </tr>
                                <tr>
                                    <td>Add SGST 9%:</td>
                                    <td>₹152.54</td>
                                </tr>
                                <tr>
                                    <td>Add IGST 9%:</td>
                                    <td>₹0</td>
                                </tr>
                                <tr>
                                    <td style={{ background: '#feed00' }}>Tax Amount of GST :</td>
                                    <td>₹305.08</td>
                                </tr>
                                <tr>
                                    <td style={{ background: '#feed00' }}>Total Amount after Tax :</td>
                                    <td>₹2000.00</td>
                                </tr>
                            </table>
                        </div>
                        <div>
                            <div className="text-center">NYSHA SOLUTIONS</div>
                            <div style={{ height: '45px' }}></div>
                            <div className="text-center">Proprietor</div>
                            <div className="text-center">(Authorized Signatory)</div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="text-center" style={{background:'black', padding:'10px',marginTop:'5px'}}>
                <div style={{color:'white'}}>
                    Web : www.nyshasolutions.com | Email : contact@nyshasolutions.com
                </div>
            </footer>
        </div>
    )
}

export default Invoice