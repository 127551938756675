import React from 'react'

function Returnrefund() {
    return (<div className="container mt-5 mb-5" style={{ fontSize: '18px', textAlign: 'justify' }}>
        <p><strong>CANCELLATION AND REFUND POLICY</strong></p>

        <p>M/s. S&amp;H AESTHETIC CLINIC, having its office at FF/102, Pratap Tower, Makarpura Road, Vadodara, Gujarat, 390004, Gujarat, India (hereinafter referred to as &ldquo;<strong>Company</strong>&rdquo;); operates S&amp;H Aesthetic Clinic; and SH-Hydrafacial<strong>&nbsp;</strong>(hereinafter collectively referred to as<strong> "Clinics"); </strong>website https://shhydrafacial.in; https://shclinics.in and its mobile and tablet applications (hereinafter collectively referred to as "<strong>Platforms</strong>"), which inter alia facilitates (a) availing following services at the Clinics: skin consultation and treatment, hair consultation and treatment, Hydrafacial, laser treatment, peel treatments, medifacials and any other services that may be provided from time to time at the Clinics (collectively referred to as the "<strong>Services"</strong>) (b) Purchase of skin care products, cosmetic products, and such other products sold by the Company (hereinafter collectively referred to as<strong> "Products"</strong>) at the Clinics or through the Platforms, to the users of the Platforms ("<strong>Users</strong>").</p>

        <ol>
            <li><strong>Cancellation of Services: </strong></li>
            <ol style={{ listStyleType: 'lower-latin' }}>
                <li>Cancellation Charges for Services shall be INR 3,000/- (Rupees Three Thousand Only) per Service.</li>
                <li>In the event User desires to cancel any Service(s) for any unforeseen reason then the User has right to reschedule the Service once within 15 days from the date of cancellation. In the event User fails to reschedule the Service within 15 days then the Service Order will get lapsed, and the Company shall deduct the Cancellation Charges for Services and refund the balance amount to the source of User&rsquo;s payment within such reasonable time (subject to the policies of the User&rsquo;s bank in case of bank account/credit card refunds) from the date on which the Company initiates the refund. All refunds shall be subject to applicable charges as may be deducted by the User&rsquo;s bank.</li>
                <li>In the event User cancels any Service(s) prior to the commencement of the respective Service then the Company shall deduct the Cancellation Charges for Services and refund the balance amount to the source of User&rsquo;s payment within such reasonable time (subject to the policies of the User&rsquo;s bank in case of bank account/credit card refunds) from the date on which the Company initiates the refund. All refunds shall be subject to applicable charges as may be deducted by the User&rsquo;s bank.</li>
            </ol>
        </ol>

        <ol start="2">
            <li><strong>Cancellation of Products:</strong></li>
            <ol style={{ listStyleType: 'lower-latin' }}>
                <li>A User is permitted to fully or partially cancel orders only prior to the dispatch of Order from the Company&rsquo;s storage facility.</li>
                <li>Upon successfully placing an order on the Platforms and after the Company has successfully handed over the Product(s) to its Logistic Partner, the User will receive a unique tracking identity number, which will enable the User in tracking the status of delivery of the purchased Products.</li>
                <li>Prior to the dispatch of the purchased Products, should the User decide to cancel the purchase, the User can do so by referencing the unique order number received by the User and requesting the Company to process a cancellation.</li>
                <li>User can raise request for cancellation on the Platform. Upon the receipt of request for cancellation, Company shall immediately confirm to the User by sending an email and/or SMS with details of cancellation. User shall note that order will be considered as cancelled only upon the receipt of cancellation email and/or SMS by the User from the Company. In the event User has not received cancellation email and/or SMS from the Company, User shall again raise request for cancellation in accordance with procedure as mentioned herein above.</li>
                <li>In the event of cancellation, the Company shall initiate refunds within 15 (fifteen) business days from the date on which it received the request from the User. The refund will reflect in the User&rsquo;s source of payment within such reasonable time (subject to the policies of the User&rsquo;s bank in case of bank account/credit card refunds) from the date on which the Company initiates the refund. All refunds shall be subject to applicable charges as may be deducted by the User&rsquo;s bank.</li>
                <li>Please note that the Products sold by the Company are not eligible for return, refund or replacement.</li>
            </ol>
        </ol>

        <p>- Last Updated On <strong>13/09/2024</strong></p>


    </div>
    )
}

export default Returnrefund