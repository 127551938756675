import './ContactUs.css';
import '../../App.css';
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import toast, { Toaster } from 'react-hot-toast';

function ContactUs() {

    const recaptcha = useRef()
    let navigate = useNavigate();
    const [formData, setFormData] = useState({
        Name: '',
        Email: '',
        Contact: '',
        Description: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const captchaValue = recaptcha.current.getValue()
        if (!captchaValue) {
            toast.error('Please verify the reCAPTCHA!')
        } else {
            toast.success('Form submission successful!')
        }

        var Url = window.API_PREFIX + 'admin/contact_us'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(formData)
        }).then(resp => resp.json()).then(data => {

            if (data['status'] === "1") {
                toast.success('Admin will contact soon')
            }
            else {
                toast.error("Please fill require all fields")
            }
        }
        )
    };

    return (
        <>


            <div className='about_main_div'>
                <div className='container text-center col-md-5'>
                    <h1 className='contact_title'>Contact US</h1>
                    <div className='contact_para'>
                        <i>We are a young and creative company and we offer you fresh business ideas.</i>
                    </div>

                </div>

            </div>

            <div className='container contact_main_div'>
                <div className=' row'>
                    <div className='col-md-6 contact_sub_div'>
                        <span>CONTACT</span>
                        <h1>
                            How can we help your business to grow?
                        </h1>
                        <div>
                            <i>We bring the breathe of our experience and industry knowledge to help you succeed</i>
                        </div>
                    </div>
                    <form className='col-md-6 contact_tag_div' onSubmit={handleSubmit}>
                        <div className='contact_tag_main'>
                            <input type="text" class="form-control me-3  " id="exampleFormControlInput1" placeholder="Name" name="Name"
                                value={formData.Name}
                                onChange={handleChange}
                                required />
                            <input type="email" class="form-control  " id="exampleFormControlInput1" placeholder="E-mail id" name="Email"
                                value={formData.Email}
                                onChange={handleChange}
                                required />
                        </div>
                        <input type="text" pattern="\d*" inputmode="numeric" class="form-control mt-4" id="exampleFormControlInput1" placeholder="Phone Number" name="Contact"
                            value={formData.Contact}
                            onChange={handleChange}
                            required />
                        <textarea class="form-control mt-4" id="exampleFormControlTextarea1" rows="5" placeholder='For additional information' name="Description"
                            value={formData.Description}
                            onChange={handleChange}
                            required></textarea>
                        <ReCAPTCHA
                            className='mt-4'
                            sitekey="Your client site key"
                        // onChange={onChange}
                        />
                        <button id="submit" class="form-control mt-4 submit_btn" >SEND A MESSAGE</button>
                    </form>

                </div>
                <div className='row mt-4'>
                    <div className=' contact_us col-12 col-md-6'>
                        <h3><span className=' call_us'>Call Us</span></h3>
                        <h3><span className='contact_number'>+91 7984794957</span></h3>
                    </div>
                    <div className=' visit_us col-12 col-md-3'>
                        <h3><span>Visit Us</span></h3>
                        <h3>
                            <span className='visit_us'>
                                <strong>Working Days</strong><br />
                                <span className='color_us'>Monday - Friday</span>
                            </span>
                        </h3>
                    </div>
                    <div className=' follow_us col-12 col-md-3'>
                        <h4>
                            <span>Follow Us</span>
                        </h4>
                        <div className='social_icon'>
                            <a className='facebook_icon' href='##'>
                                <i class="fa-brands fa-facebook-f"></i>
                            </a>
                            <a className='facebook_icon' href='33'>
                                <svg style={{ height: '30px', width: '30px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#ffffff" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
                            </a>
                            <a className='facebook_icon' href='https://www.instagram.com/vinnyrituagarwal/'>
                                <i class="fa-brands fa-instagram"></i>
                            </a>
                            <a className='facebook_icon' href=''>
                                <i class="fa-brands fa-linkedin-in"></i>
                            </a>

                        </div>
                    </div>
                </div>

            </div>



        </>
    )
}

export default ContactUs;