// import "./MitwankLogin.css";
import '../../App.css';
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../StateProviders";
import mitwankloginimg from "../../media/login_img.png";
import { useEffect, useState } from "react";
// import "./Sidebar.css";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import toast, { Toaster } from 'react-hot-toast';

function Cliniclogin() {
  let navigate = useNavigate();
  const [{ CIsLogin }, dispatch] = useStateValue();
  const [email, setEmail] = useState();
  const [Password, setPassword] = useState();
  const [Inputtype, setInputtype] = useState("password");
  const [ButtonName, setButtonName] = useState(<i class="fa-solid fa-eye"></i>);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    if (CIsLogin) {
      navigate("/clinic/dashboard");
    }
  }, []);
  return (
    <div className="container">
      <div className="mitwank_login_main_division">
        <div className="mitwank_login_main_first_division">
          <div className="d-flex justify-content-center">
            <div className="mitwank_login_img_division">
              <img className="mitwank_login_img" src={mitwankloginimg} />
            </div>
          </div>
        </div>

        <div className="mitwank_login_main_second_division">
          <div className="mitwank_login_title_name">Clinic Login</div>

          <div class="mitwank_login_email_field_division form-floating mt-3">

            <TextField id="outlined-basic" label="Email" variant="outlined" className="w-100" onChange={(e) => {
              setEmail(e.target.value);
            }} />

          </div>



          <FormControl sx={{ width: '100%' }} variant="outlined" className="mt-3">
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  var Url = window.API_PREFIX + "admin/cliniclogin";
                  fetch(Url, {
                    method: "POST",
                    headers: {
                      "Content-type": "application/json",
                    },
                    body: JSON.stringify({
                      Email: email,
                      Password: e.target.value,
                    }),
                  })
                    .then((resp) => resp.json())
                    .then((data) => {
                      console.log(data);
                      if (data["status"] === "1") {
                        localStorage.setItem("DToken", data["Token"]);
                        localStorage.setItem('LoginDetails', 'Clinic')
                        localStorage.setItem("clinicId", data["clinic"]);
                        dispatch({ type: "CLogin" });
                        navigate("/clinic/dashboard");
                        window.location.reload();
                      } else {
                        toast.error(data["message"])
                        // alert(data["message"]);
                      }
                    });
                }
              }}
            />
          </FormControl>




          <div
            className="mitwank_login_forgot_password_division"
            onClick={() => {
              navigate("/clinicforgetpassword");
            }}
          >
            Forgot Password ?
          </div>
          <button
            className="mitwank_login_btn_login_division"




            onClick={() => {
              var Url = window.API_PREFIX + "admin/cliniclogin";
              fetch(Url, {
                method: "POST",
                headers: {
                  "Content-type": "application/json",
                },
                body: JSON.stringify({
                  Email: email,
                  Password: Password,
                }),
              })
                .then((resp) => resp.json())
                .then((data) => {
                  console.log(data);
                  if (data["status"] === "1") {
                    localStorage.setItem("DToken", data["Token"]);
                    localStorage.setItem('LoginDetails', 'Clinic')
                    localStorage.setItem("clinicId", data["clinic"]);
                    dispatch({ type: "CLogin" });
                    navigate("/clinic/dashboard");
                    window.location.reload();
                  } else {
                    toast.error(data["message"]);
                  }
                });
            }}
          >
            Login
          </button>

        </div>
      </div>
    </div>
  )
}

export default Cliniclogin