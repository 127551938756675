import patientdashboardimg from "../../media/image.jpg";
import { useNavigate } from "react-router-dom";
// import './MitwankSidebar.css';
// import '../Mitwank/Sidebar.css'
import "../../App.css";
import { useEffect, useState } from "react";
import { useStateValue } from "../StateProviders";
import toast from "react-hot-toast";
function ClinicSidebar() {
  const [{ Loading }, dispatch] = useStateValue();

  let navigate = useNavigate();
  const [DocData, setDocData] = useState({});
  useEffect(() => {
    var Url = window.API_PREFIX + "admin/clinicdetail";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        } else if (data["status"] === "1") {
          setDocData({ ...data["data"] });
        }
      });
  }, []);

  return (
    <div className="sidebar_main_division">
      <div class="User_detail_main_div mt-2 mb-2">
        <div class="profile_info">
          <div class="profile_info_img_div">
            <img src={DocData.clinicPhoto} />
          </div>
        </div>
        <h4>{DocData.clinicName}</h4>
        <div class="d-flex justify-content-center align-items-center">
          {/* <span style={{ textTransform: 'capitalize' }}>male</span>
          <i class="fa-solid fa-circle" style={{ Color: 'rgb(116, 58,135)', fontSize: '6px', margin: '0px 2px' }}></i> */}
          <span>{DocData.clinicMobile}</span>
        </div>
      </div>

      <div
        className={
          window.location.pathname.includes("/clinic/dashboard")
            ? "sidebar_dashboard_title_division_active"
            : "sidebar_dashboard_title_division"
        }
        onClick={() => {
          navigate("/clinic/dashboard");
        }}
      >
        <i class="fa-solid fa-shapes me-2"></i> Dashboard
      </div>

      <div
        className={
          window.location.pathname.includes("/clinic/clinicappointmentbydate")
            ? "sidebar_dashboard_title_division_active"
            : "sidebar_dashboard_title_division"
        }
        onClick={() => {
          navigate("/clinic/clinicappointmentbydate");
        }}
      >
        <i class="fa-solid fa-calendar-days me-2"></i> Appointment By Date
      </div>

      <div
        className={
          window.location.pathname.includes("/clinic/clinic-clients")
            ? "sidebar_dashboard_title_division_active"
            : "sidebar_dashboard_title_division"
        }
        onClick={() => {
          navigate("/clinic/clinic-clients");
        }}
      >
        <i class="fa-solid fa-user me-2"></i> My Clients
      </div>

      <div
        className={
          window.location.pathname.includes("/clinic/schedule-timing")
            ? "sidebar_dashboard_title_division_active"
            : "sidebar_dashboard_title_division"
        }
        onClick={() => {
          navigate("/clinic/schedule-timing");
        }}
      >
        <i class="fa-solid fa-calendar-day me-2"></i>Schedule Timing
      </div>

      <div
        className={
          window.location.pathname.includes("/clinic/clinic-add-receptionist")
            ? "sidebar_dashboard_title_division_active"
            : "sidebar_dashboard_title_division"
        }
        onClick={() => {
          navigate("/clinic/clinic-add-receptionist");
        }}
      >
        <i class="fa-solid fa-user-plus me-2"></i>Add Receptionist{" "}
      </div>

      <div
        className={
          window.location.pathname.includes("/clinic/profile-setting")
            ? "sidebar_dashboard_title_division_active"
            : "sidebar_dashboard_title_division"
        }
        onClick={() => {
          navigate("/clinic/profile-setting");
        }}
      >
        <i class="fa-solid fa-user-pen me-2"></i>Profile Settings{" "}
      </div>

      <div
        className={
          window.location.pathname.includes("/clinic/clinictransactions")
            ? "sidebar_dashboard_title_division_active"
            : "sidebar_dashboard_title_division"
        }
        onClick={() => {
          navigate("/clinic/clinictransactions");
        }}
      >
        <i class="fa-solid fa-indian-rupee-sign me-2"></i> Transactions{" "}
      </div>
      <div className={window.location.pathname.includes('/clinic/clinicoffers') ? 'sidebar_dashboard_title_division_active' : 'sidebar_dashboard_title_division'}
        onClick={() => {
          navigate('/clinic/clinicoffers')

        }}
      ><i class="fa-solid fa-percent me-2"></i> Offers </div>
      <div className={window.location.pathname.includes('/clinic/clinicevents') ? 'sidebar_dashboard_title_division_active' : 'sidebar_dashboard_title_division'}
        onClick={() => {
          navigate('/clinic/clinicevents')

        }}
      ><i class="fa-solid fa-calendar-days me-2"></i>Events </div>
      <div
        className={
          window.location.pathname.includes("/clinic/clinicchangepassword")
            ? "sidebar_dashboard_title_division_active"
            : "sidebar_dashboard_title_division"
        }
        onClick={() => {
          navigate("/clinic/clinicchangepassword");
        }}
      >
        <i class="fa-solid fa-key me-2"></i> Change Password{" "}
      </div>

      <div
        className={
          window.location.pathname.includes("/clinic/trash")
            ? "sidebar_dashboard_title_division_active"
            : "sidebar_dashboard_title_division"
        }
        onClick={() => {
          navigate("/clinic/trash");
        }}
      >
        <i class="fa-solid fa-trash me-2"></i> Trash
      </div>

      <div
        className="sidebar_dashboard_title_division"
        onClick={() => {
          localStorage.clear();
          dispatch({ type: "CLogout" });
          navigate("/clinic");
        }}
      >
        <i class="fa-solid fa-right-from-bracket me-2"></i> Logout{" "}
      </div>
    </div>
  );
}

export default ClinicSidebar;
