import patientdashboardimg from '../../media/image.jpg';
import '../../App.css';
import './ClientSidebar.css'
import '../Client/Sidebar.css'
import { useStateValue } from '../StateProviders';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import check_svg from '../../media/check_svg.svg'
import toast from "react-hot-toast";
export default function PatientSidebar() {
  let navigate = useNavigate()
  const [{ isLogin }, dispatch] = useStateValue()
  const [DocData, setDocData] = useState({});
  useEffect(() => {
    var Url = window.API_PREFIX + 'admin/Singledoctordetail'
    fetch(Url, {
      method: 'GET',

    }).then(resp => resp.json()).then(data => {
      // console.log("sv-->",data)
      if (data['status'] === "1") {

        setDocData({ ...data['data'] })

      }
    })
  }, [])


  useEffect(() => {
    var Url = window.API_PREFIX + "patient/view_profile";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/");
          window.location.reload();
        }
        else if (data["status"] === "1") {
          console.log(data);
          setUserData(data);
        }
      });
  }, []);

  const [userData, setUserData] = useState(null);
  const calculateAge = (birthdate) => {
    const birthDate = new Date(birthdate);
    const today = new Date();

    let years = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth();

    if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())) {
      years--;
      months += 12;
    }

    if (today.getDate() < birthDate.getDate()) {
      months--;
    }

    return `${years} years`;
  };



  return (
    <aside className='sidebar_main_division'>
      {userData ?
        <div className='User_detail_main_div mt-2 mb-2'>
          <div className='profile_info'>
            <div className='profile_info_img_div'>
              <img src={userData.Image}></img>
            </div>
          </div>
          <h4>{userData.Name}</h4>
          <div className='d-flex justify-content-center align-items-center'>
            <span style={{textTransform:'capitalize'}}>{userData.Gender}</span>
            <i class="fa-solid fa-circle" style={{ color: '#743A87', fontSize:'6px', margin:'0px 2px' }}></i>
            <span>{calculateAge(userData.Birthdate)}</span>
          </div>


        </div>
        : null
      }
      <div className={window.location.pathname.includes('/dashboard') ? 'sidebar_dashboard_title_division_active' : "sidebar_dashboard_title_division"}
        onClick={() => {

          navigate('/dashboard')
        }}
      ><i class="fa-solid fa-shapes me-2"></i> Dashboard</div>
      <div className={window.location.pathname.includes('/booking') ? 'sidebar_dashboard_title_division_active' : "sidebar_dashboard_title_division"}
        onClick={() => {

          navigate('/booking')
        }}
      ><i class="fa-solid fa-calendar-days me-2"></i> Book Appointment</div>

      <div className={window.location.pathname.includes('/profile-setting') ? 'sidebar_dashboard_title_division_active' : "sidebar_dashboard_title_division"}
        onClick={() => {

          navigate('/profile-setting')
        }}
      ><i class="fa-solid fa-user-pen me-2"></i> Profile Settings</div>
      <div className={window.location.pathname.includes('/change-password') ? 'sidebar_dashboard_title_division_active' : "sidebar_dashboard_title_division"}
        onClick={() => {

          navigate('/change-password')
        }}
      ><i class="fa-solid fa-key me-2" ></i> Change Password </div>
      <div className='sidebar_dashboard_title_division'
        onClick={() => {
          localStorage.clear()
          dispatch({
            type: "Logout"
          })
          navigate("")
        }}
      ><i class="fa-solid fa-right-from-bracket me-2"></i> Logout </div>

    </aside >
    // Asidetag-end
  )
}