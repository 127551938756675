import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom"
import Footer from "../Footer";
import Receptionistheader from './Receptionistheader';
import { useStateValue } from "../StateProviders";
import Receptionistlogin from "./Receptionistlogin";
import Topbar from "../Topbar";
import ReceptionistSidebar from "./ReceptionistSidebar";
import Breadcrumb from "../Breadcrumb";

export default function Receptionistlayout() {

    const [{ RIsLogin }, dispatch] = useStateValue();
    let navigate = useNavigate();

    useEffect(() => {
        if (RIsLogin) {
            // navigate("/mitwank/dashboard");
        }
    }, [RIsLogin]);


    // NOTE **********
    //     For Receptionist
    //     login = RLogin

    //     logout = RLogout
    // NOTE **********

    useEffect(() => {
        if (localStorage.getItem("DToken") !== null && localStorage.getItem("LoginDetails") === 'Receptionist') {
            dispatch({
                type: "RLogin",
            });
        } else {
            dispatch({
                type: "RLogout",
            });
        }
    }, []);
    return (<>
        <Topbar />
        <Receptionistheader />
        {RIsLogin ?
            <>
                <Breadcrumb />
                <div className="slidebar_and_component">
                    <ReceptionistSidebar />
                    <div className="outlet_main_div">
                        <Outlet />
                    </div>
                </div>
            </>
            : <Receptionistlogin />
        }
        <Footer />
    </>)
}