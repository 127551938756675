import React from 'react'
import './Notfound.css'
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
function Notfound() {

    let navigate = useNavigate();
    return (
        <div className="not-found">
            <h1 className='title'>404 - Page Not Found</h1>
            <p className='err_msg'>Sorry, the page you are looking for does not exist.</p>
            <p className='home_page' onClick={() => {
                                            navigate('/dashboard')
                                        }}>Back to dashboard</p>
        </div>
    )
}

export default Notfound