import "./ClientLogin.css";
import '../../App.css';
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../StateProviders";
import doccureloginimg from "../../media/login_img.png";
import { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import toast, { Toaster } from 'react-hot-toast';

function ClientLogin() {
  let navigate = useNavigate();

  const [{ IsLogin }, dispatch] = useStateValue();
  useEffect(() => {
    if (IsLogin) {
      navigate("/dashboard");
    }
  }, [IsLogin]);
  const [email, setEmail] = useState();
  const [Password, setPassword] = useState();
  const [isRequired, setIsRequired] = useState(false);

  const [Inputtype, setInputtype] = useState("password");
  const [ButtonName, setButtonName] = useState(<i class="fa-solid fa-eye"></i>);




  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const [isChecked, setIsChecked] = useState(true);
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleSubmit = () => {

    if (!email) {
      toast.error("Enter email")
    } else if (!Password) {
      toast.error("Enter password")
    } else if (!isRequired) {
      toast.error("Accept terms of use")
    } else {
      var Url = window.API_PREFIX + "patient/login_user";
      fetch(Url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          Email: email,
          Password: Password,
        }),
      })
        .then((resp) => resp.json())
        .then((data) => {
          // console.log(data);
          if (data["status"] === "1") {
            localStorage.setItem("DToken", data["Token"]);
            localStorage.setItem('LoginDetails', 'User')
            if (data["IsDetails"]) {
              dispatch({ type: "Login" });
              dispatch({ type: "Register" })

              navigate("/dashboard");
            }

            else {
              dispatch({ type: "Login" });

              navigate("/client-detail-register");
            }
            // window.location.reload();

          }
          else {
            toast.error(data["message"]);
            // alert(data["message"]);
          }

        });
    }
  }


  return (
    <div className="container">
      <div className="doccure_login_main_division">

        <div className="doccure_login_main_first_division">
          <img className="doccure_login_img" src={doccureloginimg} />
        </div>

        <div className="doccure_login_main_second_division">
          <span className="doccure_login_title_name">Login</span>
          <TextField id="outlined-basic" label="Email" variant="outlined" className="w-100 mt-2" onChange={(e) => {
            setEmail(e.target.value);
          }} />


          <FormControl sx={{ width: '100%' }} variant="outlined" className="mt-3">
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onKeyDown={(e) => {
                // if (e.key === "Enter") {
                //   var Url = window.API_PREFIX + "patient/login_user";
                //   fetch(Url, {
                //     method: "POST",
                //     headers: {
                //       "Content-type": "application/json",
                //     },
                //     body: JSON.stringify({
                //       Email: email,
                //       Password: Password,
                //     }),
                //   })
                //     .then((resp) => resp.json())
                //     .then((data) => {
                //       // console.log(data);
                //       if (data["status"] === "1") {
                //         localStorage.setItem("DToken", data["Token"]);
                //         localStorage.setItem('LoginDetails', 'User')
                //         if (data["IsDetails"]) {
                //           dispatch({ type: "Login" });
                //           dispatch({ type: "Register" })

                //           navigate("/dashboard");
                //         }

                //         else {
                //           dispatch({ type: "Login" });

                //           navigate("/client-detail-register");
                //         }
                //         // window.location.reload();

                //       }
                //       else {
                //         toast.error(data["message"]);
                //         // alert(data["message"]);
                //       }

                //     });
                // }
                if (e.key === "Enter") {
                  handleSubmit()
                }
              }}
            />
          </FormControl>



          <div className="text-end">

            <div
              className="doccure_login_forgot_password_division mt-2"
              onClick={() => {
                navigate("/forget-password");
              }}
            >
              Forgot Password ?
            </div>
          </div>
          <div class="m-3">
            <div class="form-check">
              <input type="checkbox" onChange={(e) => { setIsRequired(e.target.checked) }} checked={isRequired} class="form-check-input" id="agreeCheck" />
              <label class="form-check-label" for="agreeCheck">
                <span>I agree to the <a style={{ color: '#743a87' }} onClick={() => {
                  navigate("/terms-of-use")
                }}>Terms of use </a>, <a style={{ color: '#743a87' }} onClick={() => {
                  navigate("/privacy-policy")
                }}>privacy policy </a>
                  , <a style={{ color: '#743a87' }} onClick={() => {
                    navigate("/cancellation-and-refund-policy")
                  }}>Cancellation and refund policy.</a>
                </span>
              </label>
            </div>
          </div>
          <button
            className="doccure_login_btn_login_division mt-2"

            onClick={() => {
              handleSubmit()
            }}
            disabled={!isRequired}



          >
            Login
          </button>

          <div className="doccure_login_new_account_title_division mt-2">
            <span className="me-2">
              Don't have an account?
            </span>
            <span
              className="doccure_login_new_account_title_register"
              onClick={() => {
                navigate("/register");
              }}
            >
              Register here
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientLogin;
