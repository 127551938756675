import "./ClientDetailsRegister.css";
import "../../App.css";
import patientregisterimg from "../../media/login_img.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../StateProviders";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import toast from "react-hot-toast";

function PatientRegister() {
  let navigate = useNavigate();
  const [{ IsLogin, IsRegister }, dispatch] = useStateValue();

  const [options, setoptions] = useState([true, false, false, false]);
  const [name, setname] = useState("");
  const [Phnumber, setPhnumber] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [anniversaryDate, setAnniversaryDate] = useState("");
  const [spouseBirthDate, setSpouseBirthDate] = useState("");
  const [Img, setImg] = useState("");
  const [gender, setgender] = useState("");
  const [marriedStatus, setMarriedStatus] = useState("unmarried");
  const [ActiveMarriedStatus, setActiveMarriedStatus] = useState([
    false,
    false,
  ]);
  const [Weight, setWeight] = useState("");
  const [height, setheight] = useState("");
  const [profession, setprofession] = useState("");

  const [Address, setAddress] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [Country, setCountry] = useState("");
  const [OtherInfo, setOtherInfo] = useState("");
  const [file, setFile] = useState();
  const [Pincode, setPincode] = useState("");
  const [showImg, setshowImg] = useState();
  const [Activegender, setActivegender] = useState([false, false]);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePhoneNumberChange = (e) => {
    const newValue = e.target.value;
    // This regex allows only digits
    const numberOnlyRegex = /^[0-9]*$/;

    if (numberOnlyRegex.test(newValue)) {
      setPhnumber(newValue);
    }
    // If the input is not a number, we simply don't update the state,
    // effectively preventing non-numeric input
  };

  const handlePincodeChange = (e) => {
    const newValue = e.target.value;
    // This regex allows only digits
    const numberOnlyRegex = /^[0-9]*$/;

    if (numberOnlyRegex.test(newValue)) {
      setPincode(newValue);
    }
    // If the input is not a number, we simply don't update the state,
    // effectively preventing non-numeric input
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setBirthDate(selectedDate);
    validateAge(selectedDate);
  };

  // Calculate the maximum allowed date (18 years ago from today)
  const getMaxDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18);
    return today.toISOString().split("T")[0];
  };

  const validateAge = (selectedDate) => {
    const today = new Date();
    const birthDate = new Date(selectedDate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    if (age < 18) {
      toast.error("You must be at least 18 years old.");
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <div className="mt-5 mb-5">
        <div className="client_register_second_third_merge_main_division">
          <div className="client_register_second_main_division">
            <div
              className="client_register_registration_main_division"
              onClick={() => {
                if (options[0]) {
                  setoptions([true, false, false, false]);
                }
              }}
            >
              <div className="client_register_registration_number_division">
                <span
                  className={
                    options[0]
                      ? "client_register_registration_number_title_active"
                      : "client_register_registration_number_title"
                  }
                >
                  1
                </span>
              </div>
              <div className="client_register_registration_division mx-2">
                <div className="client_register_registration_title mb-1">
                  Registration
                </div>
                <div className="client_register_registration_sub_title">
                  Enter your Basic details for Registration
                </div>
              </div>
            </div>

            <div
              className="client_register_registration_main_division"
              onClick={() => {
                if (options[1]) setoptions([true, true, false, false]);
              }}
            >
              <div className="client_register_registration_number_division">
                <div
                  className={
                    options[1]
                      ? "client_register_registration_number_title_active"
                      : "client_register_registration_number_title"
                  }
                >
                  2
                </div>
              </div>
              <div className="client_register_registration_division mx-2">
                <div className="client_register_registration_title mb-1">
                  Profile Picture
                </div>
                <div className="client_register_registration_sub_title">
                  Upload your Profile Picture
                </div>
              </div>
            </div>

            <div
              className="client_register_registration_main_division"
              onClick={() => {
                if (options[2]) setoptions([true, true, true, false]);
              }}
            >
              <div className="client_register_registration_number_division">
                <div
                  className={
                    options[2]
                      ? "client_register_registration_number_title_active"
                      : "client_register_registration_number_title"
                  }
                >
                  3
                </div>
              </div>
              <div className="client_register_registration_division mx-2">
                <div className="client_register_registration_title mb-1">
                  Personal Details
                </div>
                <div className="client_register_registration_sub_title">
                  Enter your Personal Details
                </div>
              </div>
            </div>

            <div
              className="client_register_registration_main_division"
              onClick={() => {
                if (options[3]) {
                  setoptions([true, true, true, true]);
                }
              }}
            >
              <div className="client_register_registration_number_division">
                <div
                  className={
                    options[3]
                      ? "client_register_registration_number_title_active"
                      : "client_register_registration_number_title"
                  }
                >
                  4
                </div>
              </div>
              <div className="client_register_registration_division mx-2">
                <div className="client_register_registration_title mb-1">
                  Other Detail
                </div>
                <div className="client_register_registration_sub_title">
                  Provide More information
                </div>
              </div>
            </div>
          </div>

          {/* registration step 1 start */}
          {options[0] && !options[1] ? (
            <div className="client_register_third_main_division">
              {/* <div className="client_register_registration_details_main_division">
                                Whats your Number?
                                <span className="client_register_registration_star_icon">
                                    *
                                </span>
                            </div>
                            <div className="client_register_registration_details_sub_division">
                                We will only use it to advise you for any important changes.
                            </div> */}

              <TextField
                id="outlined-basic"
                label="Full Name*"
                variant="outlined"
                className="w-100 mt-2"
                value={name}
                onChange={(e) => {
                  const newValue = e.target.value;
                  const nonNumericRegex = /^[A-Za-z\s]*$/;
                  if (nonNumericRegex.test(newValue)) {
                    setname(newValue);
                  } else {
                    toast.error("Please enter alphabets only.");
                  }
                }}
              />

              <TextField
                id="outlined-basic"
                label="Mobile Number*"
                variant="outlined"
                className="w-100 mt-3"
                value={Phnumber}
                onChange={handlePhoneNumberChange}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              />

              {/* <TextField
                id="outlined-basic"
                label=" Mobile Number*"
                variant="outlined"
                className="w-100 mt-3"
                value={Phnumber}
                onChange={(e) => {
                  setPhnumber(e.target.value);
                }}
              /> */}

              <div className="client_register_date_feild_division">
                <lable>Enter Your Birthdate*</lable>
                <input
                  type="date"
                  class="client_register_email_field form-control"
                  id="inputdateofbirth"
                  value={birthDate}
                  onChange={handleDateChange}
                  max={getMaxDate()}
                />
              </div>

              <button
                className="client_register_continue_btn"
                onClick={() => {
                  if (Phnumber !== "" && name !== "" && birthDate !== "") {
                    if (Phnumber?.length < 10 || Phnumber?.length > 10) {
                      toast.error("Enter Valid Mobile Number");
                    } else {
                      setoptions([true, true, false, false]);
                    }
                  } else {
                    toast.error("Enter Name , Number , Date Of Birth");
                  }
                }}
              >
                Continue
              </button>
            </div>
          ) : null}

          {/* registration step 1 end */}

          {/* upload img step 2 start */}
          {options[1] && !options[2] ? (
            <div className="client_register_third_main_division">
              <div className="client_register_registration_details_main_division">
                Upload Profile Picture
              </div>
              <div className="client_register_registration_details_sub_division">
                Add a profile photo
              </div>

              <div className="client_register_upload_img_main_division">
                {showImg ? (
                  <img src={showImg} />
                ) : (
                  <div className="client_register_upload_img_division">
                    <i class="fa-solid fa-camera"></i>
                  </div>
                )}
              </div>

              <div className="client_register_upload_images_main_division">
                <button
                  type="button"
                  className="clientregister_upload_photo_button btn"
                >
                  <i className="fa fa-upload profilesettings_upload_icon"></i>{" "}
                  Upload Photo
                  <input
                    type="file"
                    accept="image/gif, image/jpeg, image/png"
                    onChange={(e) => {
                      setImg(e.target.files[0]);

                      setshowImg(URL.createObjectURL(e.target.files[0]));
                    }}
                  />
                </button>
              </div>

              <button
                className="client_register_continue_btn"
                onClick={() => {
                  if (Img) {
                    setoptions([true, true, true, false]);
                  }
                  if (!Img) {
                    toast.error("Please Upload Profile Picture");
                  }
                }}
              >
                Continue
              </button>
            </div>
          ) : null}

          {/* upload img step 2 end */}

          {/* personal details step 3 start */}

          {options[2] && !options[3] ? (
            <div className="client_register_third_main_division">
              <div className="client_register_registration_details_main_division">
                What are your personal details?
                <span className="client_register_registration_star_icon">
                  *
                </span>
              </div>
              <div className="client_register_registration_details_sub_division">
                Please provide your personal information
              </div>

              <div className="client_register_personal_gender_main_division">
                <button
                  className={
                    Activegender[0]
                      ? "client_register_personal_male_btn_active"
                      : "client_register_personal_male_btn"
                  }
                  onClick={() => {
                    setgender("male");
                    setActivegender([true, false]);
                  }}
                >
                  <i class="fa-solid fa-mars-stroke"></i> Male
                </button>
                <button
                  className={
                    Activegender[1]
                      ? "client_register_personal_female_btn_active"
                      : "client_register_personal_female_btn"
                  }
                  onClick={() => {
                    setgender("female");
                    setActivegender([false, true]);
                  }}
                >
                  <i class="fa-solid fa-mars-stroke-up"></i> Female
                </button>
              </div>

              <div className="client_register_personal_gender_main_division">
                <button
                  className={
                    ActiveMarriedStatus[0]
                      ? "client_register_personal_male_btn_active"
                      : "client_register_personal_male_btn"
                  }
                  onClick={() => {
                    setMarriedStatus("unmarried");
                    setActiveMarriedStatus([true, false]);
                  }}
                >
                  <i class="fa-solid fa-mars-stroke"></i> Un-Married
                </button>
                <button
                  className={
                    ActiveMarriedStatus[1]
                      ? "client_register_personal_female_btn_active"
                      : "client_register_personal_female_btn"
                  }
                  onClick={() => {
                    setMarriedStatus("married");
                    setActiveMarriedStatus([false, true]);
                  }}
                >
                  <i class="fa-solid fa-mars-stroke-up"></i> Married
                </button>
              </div>

              {marriedStatus === "married" && (
                <>
                  <div className="client_register_date_feild_division">
                    <lable>Enter Your Anniversary</lable>
                    <input
                      type="date"
                      class="client_register_email_field form-control"
                      id="inputdateofbirth"
                      value={anniversaryDate}
                      onChange={(e) => {
                        setAnniversaryDate(e.target.value);
                      }}
                    />
                  </div>

                  <div className="client_register_date_feild_division">
                    <lable>Enter Your Spouse Birthdate</lable>
                    <input
                      type="date"
                      class="client_register_email_field form-control"
                      id="inputdateofbirth"
                      value={spouseBirthDate}
                      onChange={(e) => {
                        setSpouseBirthDate(e.target.value);
                      }}
                    />
                  </div>
                </>
              )}

              <TextField
                id="outlined-basic"
                label="Profession*"
                variant="outlined"
                className="w-100 mt-3"
                value={profession}
                onChange={(e) => {
                  const newValue1 = e.target.value;
                  const nonNumericRegex = /^[A-Za-z\s]*$/;
                  if (nonNumericRegex.test(newValue1)) {
                    setprofession(newValue1);
                  } else {
                    toast.error("Please enter alphabets only.");
                  }
                }}
              />

              <button
                className="client_register_continue_btn"
                onClick={() => {
                  if (gender === "") {
                    toast.error("Please Select Gender");
                  } else if (profession !== "") {
                    setoptions([true, true, true, true]);
                  } else {
                    toast.error("Enter Profession");
                  }
                }}
              >
                Continue
              </button>
            </div>
          ) : null}

          {/* personal details step 3 end */}

          {/* other details step 4 start */}

          {options[3] ? (
            <div className="client_register_third_main_division">
              <div className="client_register_registration_details_main_division">
                Other Details
                <span className="client_register_registration_star_icon">
                  *
                </span>
              </div>
              <div className="client_register_registration_details_sub_division">
                Please fill the details below
              </div>

              <TextField
                id="outlined-basic"
                label="Address"
                variant="outlined"
                className="w-100 mt-3"
                value={Address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />

              <div className=" client_register_gluecose_div ">
                <TextField
                  id="outlined-basic"
                  label="City"
                  variant="outlined"
                  className="w-100 mt-3 me-1"
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="State"
                  variant="outlined"
                  className="w-100 mt-3"
                  onChange={(e) => {
                    setState(e.target.value);
                  }}
                />
              </div>
              <div className=" client_register_gluecose_div ">
                <TextField
                  id="outlined-basic"
                  label="Country"
                  variant="outlined"
                  className="w-100 mt-3 me-1"
                  onChange={(e) => {
                    setCountry(e.target.value);
                  }}
                />

                <TextField
                  id="outlined-basic"
                  label="Pincode"
                  variant="outlined"
                  className="w-100 mt-3"
                  value={Pincode}
                  onChange={handlePincodeChange}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                />
              </div>

              <button
                className="client_register_continue_btn"
                onClick={() => {
                  if (Phnumber?.length < 10 || Phnumber?.length > 10) {
                    toast.error("Enter Valid Mobile Number");
                  } else if (
                    Pincode !== "" &&
                    (Pincode?.length < 6 || Pincode?.length > 6)
                  ) {
                    toast.error("Enter Valid Pincode");
                  } else {
                    if (options[3]) {
                      if (Address !== "") {
                        var Url =
                          window.API_PREFIX + "patient/add_registration_data";

                        var uploadData = new FormData();
                        uploadData.append(
                          "Token",
                          localStorage.getItem("DToken")
                        );
                        uploadData.append("Name", name);
                        uploadData.append("Pic", Img);
                        uploadData.append("Gender", gender);
                        uploadData.append("marriedStatus", marriedStatus);
                        uploadData.append("Weight", Weight);
                        uploadData.append("Height", height);
                        uploadData.append("profession", profession);
                        uploadData.append("Birthdate", birthDate);
                        if (anniversaryDate !== "") {
                          uploadData.append("anniversaryDate", anniversaryDate);
                        }
                        if (spouseBirthDate !== "") {
                          uploadData.append("spouseBirthDate", spouseBirthDate);
                        }
                        uploadData.append("Address", Address);
                        uploadData.append("City", City);
                        uploadData.append("State", State);
                        uploadData.append("Country", Country);
                        uploadData.append("Pincode", Pincode);
                        uploadData.append("OtherInformation", OtherInfo);
                        uploadData.append("Mobile", Phnumber);

                        fetch(Url, {
                          method: "POST",
                          // headers: {
                          //     'Content-type': 'application/json',
                          // },
                          body: uploadData,
                        })
                          .then((resp) => resp.json())
                          .then((data) => {
                            console.log(data);
                            if (data["status"] === "1") {
                              toast.success(
                                "Your Details Successfully Registered"
                              );
                              dispatch({ type: "Register" });
                              dispatch({ type: "Login" });
                              navigate("/dashboard");
                            }
                          });
                      } else {
                        toast.error("Enter Address");
                      }
                    }
                  }
                }}
              >
                Continue
              </button>
            </div>
          ) : null}

          {/* other details step 4 end */}
        </div>
      </div>
    </>
  );
}

export default PatientRegister;
