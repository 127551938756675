import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

function Breadcrumb() {

    const path = window.location.pathname.split('/').filter(Boolean);
    const displayPath = path.map((whichpath) =>
        whichpath === 'receptionistappointmentbydate' ? 'Appoinment by date' : whichpath &&
            whichpath === 'schedule-timing' ? 'schedule timings' : whichpath &&
                whichpath === 'receptionistchangepassword' ? 'change password' : whichpath &&
                    whichpath === 'booking' ? 'Book Appoinment' : whichpath &&
                        whichpath === 'profile-setting' ? 'Profile Setting' : whichpath &&
                            whichpath === 'change-password' ? 'Change Password' : whichpath &&
                                whichpath === 'clinicappointmentbydate' ? 'Appoinment by date' : whichpath &&
                                    whichpath === 'clinic-clients' ? 'My Clients' : whichpath &&
                                        whichpath === 'clientprofile' ? '' : whichpath &&
                                            whichpath === 'clinic-add-receptionist' ? 'Add receptionist' : whichpath &&
                                                whichpath === 'clinicchangepassword' ? 'change password' : whichpath &&
                                                    whichpath === 'mitwankappointmentbydate' ? 'Appoinment by date' : whichpath &&
                                                        whichpath === 'my-clients' ? 'My Clients' : whichpath &&
                                                            whichpath === 'myclinic' ? 'My Clinics' : whichpath &&
                                                                whichpath === 'add-package' ? 'Add Package' : whichpath &&
                                                                    whichpath === 'mitwankchangepassword' ? 'change password' : whichpath &&
                                                                        whichpath === 'mitwanktrasactions' ? 'Transactions' : whichpath &&
                                                                            whichpath === 'mitwankoffers' ? 'Offers' : whichpath &&
                                                                                whichpath === 'mitwankevents' ? 'Events' : whichpath &&
                                                                                    whichpath === 'clientmessages' ? 'Messages' : whichpath &&
                                                                                        whichpath === 'clinictransactions' ? 'Transactions' : whichpath &&
                                                                                            whichpath === 'clinicoffers' ? 'Clinic Offer' : whichpath &&
                                                                                                whichpath === 'clinicevents' ? 'Clinic Event' : whichpath &&
                                                                                                    whichpath === 'receptionistprofilesettings' ? 'Profile Setting' : whichpath &&
                                                                                                        whichpath === 'receptionistoffers' ? 'Receptionist Offer' : whichpath &&
                                                                                                            whichpath === 'receptionistevents' ? 'Receptionist Event' : whichpath



    );


    return (
        <nav className='admin_dashboard_breadcrumb_main_division' >
            <div className='d-flex justify-content-between'>
                <div>
                    <div className='admin_dashboard_breadcrumb_division'>
                        <p className='admin_dashboard_breadcrumb_title'>home</p>
                        <p className='admin_dashboard_breadcrumb_title1'>/</p>
                        <p className='admin_dashboard_breadcrumb_title2'>{displayPath[0]}</p>
                    </div>
                    <div>
                        <h1 className='admin_dashboard_breadcrumb_title_main'> {displayPath[1] ? displayPath[1] : displayPath[0]}</h1>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Breadcrumb