import React from 'react'


function Termsandconditions() {
  return (
    <>
      <div className="container mt-5 mb-5 " style={{ fontSize: '18px', textAlign: 'justify' }}>


        <p><strong>TERMS OF USE</strong></p>

        <p>These Terms of Use and policies about Privacy, Fees, Payments, Promotions, Delivery, Cancellation and Refund together shall form the entire &ldquo;<strong>Terms of Use</strong>&rdquo;.</p>

        <ol style={{ listStyleType: 'upper-alpha' }}>

          <li><strong>PREAMBLE</strong></li>
          <ol>
            <li>M/s. S&amp;H AESTHETIC CLINIC, having its office at FF/102, Pratap Tower, Makarpura Road, Vadodara, Gujarat, 390004, Gujarat, India; S&amp;H Aesthetic Clinic; and SH-Hydrafacial <strong>(</strong>hereinafter collectively referred to as<strong> "Clinic")</strong>.</li>
          </ol>
          <ol start="2">
            <li>These Terms of Use, read together with the (a)&nbsp;Privacy Policy; (b)&nbsp;Fee and Payment Policy; (c)&nbsp;Delivery Policy; (d)&nbsp;Cancellation and Refund Policy&nbsp;constitute a legal and binding agreement between you and</li>
          </ol>
          <ol start="3">
            <li>The Terms of Use, inter alia, provides the terms that govern your access to use (a) Clinic's website https://shhydrafacial.in; https://shclinics.in, and its mobile and tablet applications (hereinafter collectively referred to as<strong> "Platforms"</strong>); (b) availing following services at the Clinic: skin consultation and treatment, hair consultation and treatment, Hydrafacial, laser treatment, peel treatments, hair consultation and treatment, medifacials and any other services that may be provided from time to time at the Clinic (collectively referred to as the "<strong>Services"</strong>) (c) Purchase of skin care products, cosmetic products, and such other products sold by the Clinic (hereinafter collectively referred to as<strong> "Products"</strong>) at the Clinic or through the Platforms.</li>
          </ol>
          <ol start="4">
            <li>You hereby agree and understand that this Terms of Use forms a binding contract between Clinic and anyone who accesses, browses, or purchases the Products and/or avail the Services in any manner (hereinafter referred to as "<strong>User</strong>") and accordingly, you hereby agree to be bound by the terms contained in Terms of Use. If you do not agree to the Terms of Use, you are advised not to avail the Services and/or purchase Products. The terms contained in Terms of Use shall be accepted without modification. The procurement of the Services and/or purchase of Products would constitute acceptance of the Terms of Use.</li>
          </ol>
          <ol start="5">
            <li>The provisions of Indian laws that govern the Terms of Use are, including, but not limited to: (a) the Indian Contract Act, 1872; (b) the Information Technology Act, 2000; (c) the rules, regulations, guidelines and clarifications framed thereunder, including the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011&nbsp;<strong>(SPI Rules)</strong>and; (d) the Information Technology (Intermediaries Guidelines) Rules, 2011&nbsp;<strong>("IG Rules")</strong>.</li>
          </ol>
          <ol start="6">
            <li><strong>GST Information: </strong></li>
          </ol>

          <table>
            <tbody style={{ border: '1px solid black' }}>
              <tr style={{ border: '1px solid black' }}>
                <td style={{ border: '1px solid black' }} width="108">
                  <p><strong>Name</strong></p>
                </td>
                <td style={{ border: '1px solid black' }} width="90">
                  <p><strong>State</strong></p>
                </td>
                <td style={{ border: '1px solid black' }} width="168">
                  <p><strong>GST Number</strong></p>
                </td>
                <td style={{ border: '1px solid black' }} width="199">
                  <p><strong>Address</strong></p>
                </td>
              </tr>
              <tr style={{ border: '1px solid black' }}>
                <td style={{ border: '1px solid black' }} width="108">
                  <p>S&amp;H AESTHETIC CLINIC</p>
                </td>
                <td style={{ border: '1px solid black' }} width="90">
                  <p>GUJARAT</p>
                </td>
                <td style={{ border: '1px solid black' }} width="168">
                  <p>24ADEPC8664R1Z0</p>
                </td>
                <td style={{ border: '1px solid black' }} width="199">
                  <p>FF/102, Pratap Tower, Makarpura Road, Vadodara, Gujarat, 390004, Gujarat, India</p>
                </td>
              </tr>
            </tbody>
          </table>

          <li className='mt-4'><strong>INTERPRETATION</strong></li>
          <ol>
            <li>Headings, subheadings, titles, subtitles to clauses, sub-clauses and paragraphs are for information only and shall not form part of the operative provisions of the Terms of Use and shall be ignored in construing the same.</li>
          </ol>
          <ol start="2">
            <li>Words denoting the singular shall include the plural, and words denoting any gender shall include all genders.</li>
          </ol>
          <ol start="3">
            <li>The words "include" and "including" are to be construed without limitation.</li>
          </ol>


          <li className='mt-4'><strong>TERMS OF USE APPLICABLE TO USER</strong></li>
          <ol>
            <li>User must be 18 years of age or older to register, visit the Platform, Clinic or avail the Services in any manner. By registering, visiting or availing the Services, you hereby represent and warrant to Clinic that you are 18 years of age or older and that you have the right, authority and capacity to use the Services, and agree to abide by the Terms of Use. Suppose a User is below 18 years of age. In that case, it is assumed that he/she is using/browsing the Platforms; availing the Services under the supervision of his/her parent or legal guardian and that such User's parent or legal guardian has read and agrees to the terms of this Terms of Use, including terms of availing the Service and purchase of Products, on behalf of the minor User. Should Clinic be made aware that a User is under the age of 18 and is using/browsing the Platforms without the supervision of their parent or legal guardian, Clinic reserves the right to deactivate such User's account without notice.</li>
          </ol>
          <ol start="2">
            <li>The contents of Platform, information, text, graphics, images, logos, button icons, software code, interface, design and the collection, arrangement and assembly of the content on the Platforms or any of the other Services or any Products are the property of Clinic, its Proprietor, associates, affiliates, suppliers and vendors, as the case may be ("Clinic Content"), and are protected under copyright, trademark and other applicable laws. You shall not modify the Clinic Content or reproduce, display, publicly perform, distribute, reverse engineer or otherwise use the Clinic Content in any way for any public or commercial purpose or for personal gain.</li>
          </ol>
          <ol start="3">
            <li>Clinic authorises you to view and access the Clinic Content solely to avail Service(s), identify Products, carry out purchases of Products and process cancellation, refunds, in accordance with Cancellation and Refund Policy, if any. Clinic, therefore, grants you limited, revocable permission to access and use the Services. This permission does not include permission for (a) carrying out any resale of the Products; (b) commercial use of the Clinic Content; (c) collection and use of product listings, description, and/or prices for any purpose; and (d) any derivative use of the Platforms or&nbsp;Clinic Content.</li>
          </ol>
          <ol start="4">
            <li>To assist the User in identifying the Services and Products of their choice, Clinic provides visual representations on the Platforms, including graphics, illustrations, photographs, images, videos, charts, screenshots, infographics and other visual aids. While reasonable efforts are made to provide accurate visual representations, Clinic disclaims any guarantee or warranty of the exactness of such visual representation or description, with the actual Product delivered to the User. The appearance of the Product when delivered may vary for various reasons.</li>
          </ol>
          <ol start="5">
            <li>Users may register themselves on the Platforms. Registration on the Platforms is one-time, and you are required to remember your username and password and keep the same confidential. In the event you have misplaced your username and password details, you can retrieve and change the same using the "forgot username/password" option on the Platforms. For the purposes of identifying a User, Clinic may, from time to time, collect certain personally identifiable information such as your first name and last name, email address, mobile phone number, postal address, other contact information, demographic profile, etc.</li>
          </ol>
          <ol start="6">
            <li>The User shall assume all risks, liabilities, and consequences if their account has been accessed illegally or without authorisation through means such as hacking and if through such unauthorised access, a purchase of Products has been made through the Platforms. It is specifically clarified that payments of money towards any Products purchased through the Platforms by unauthorised or illegal use of the User's account shall entirely be borne by the User.</li>
          </ol>
          <ol start="7">
            <li>The display of Products for purchase on the Platforms is merely an invitation to receive an offer. An order placed by a User to purchase a Product constitutes an offer. Clinic reserves the right to accept or reject your offer. All orders placed by Users on the Platforms are subject to the availability of such Product, Clinic's acceptance of the User's offer and the User's continued adherence to the Terms of Use.</li>
          </ol>
          <ol start="8">
            <li>You agree to maintain and promptly update all your data and keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current, incomplete, or if Clinic has reasonable grounds to suspect that the information provided by you is untrue, inaccurate, not current, incomplete, or not in accordance with the Terms of Use, Clinic reserves the right to indefinitely suspend, terminate or block your access to the Platforms, and refuse to provide you with access to the Platforms in future.</li>
          </ol>
          <ol start="9">
            <li>All rights and liabilities of Clinic with respect to any Services and Products to be provided by it shall be restricted to the scope of the Terms of Use. In addition to the Terms of Use, you shall also ensure that you comply with the Terms of Use of the third parties, whose links are contained/embedded in the Services and Products. It is hereby clarified that Clinic shall not be held liable for any transaction/agreement between you and any such third parties.</li>
          </ol>
          <ol start="10">
            <li>You understand that on your registration as a User or your availing any Services or purchase of Products on the Platforms and/or at the Clinic, you may receive text messages (including through internet-based mobile messaging) and/or emails from Clinic on your registered mobile number and/or email address. These messages and/or emails could relate inter alia to your registration, Clinic's acceptance or rejection of your offer to avail any Service and/or purchase a Product, payment information, Product dispatch information, information pertaining to other activities you carry out on the Platforms and information pertaining to the promotions that are undertaken by Clinic (or third parties in connection with the Platforms) from time to time. It is specifically clarified that a text message and/or an email confirming the receipt of your order is not an acceptance from Clinic that the Product will be delivered. Clinic's acceptance of your offer to purchase shall occur and conclude only when Clinic sends booking confirmation - text message and/or email confirming such booking. Clinic, at all times, reserves the right to limit the number of items in or cancel an order before dispatch.</li>
          </ol>
          <ol start="11">
            <li>Any communication from Clinic shall be sent only to your registered mobile number and/or email address or such other contact number or email address that you may designate for any particular transaction. You shall be solely responsible for updating your registered mobile number and/or email address on the Platforms if there is a change. Further, Clinic may also send you notifications and reminders with respect to scheduled Service(s); deliveries of the purchased Products. While Clinic shall make every endeavour to share prompt reminders and notifications relating to the Services and delivery of purchased Products with you, Clinic shall not be held liable for any failure to send such notifications or reminders to you.</li>
          </ol>
          <ol start="12">
            <li>Clinic may, at any time and without having to service any prior notice to you: (a) upgrade, update, change, modify, or improve the Services or a part of the Services in a manner it may deem fit, and (b) change the contents of the Terms of Use in substance, or as to procedure or otherwise; in each case which will be applicable to all Users. You hereby agree that this is in the fairness of things given the nature of the business and its operations, and you will abide by them. As such, you must keep yourself updated at all times and review the Terms of Use from time to time. Clinic may also alter or remove any content from the Platforms without notice.</li>
          </ol>
          <ol start="13">
            <li>While Clinic shall make reasonable endeavours to maintain high-security standards and shall provide the Services by using reasonable efforts, Clinic shall not be liable for any interruption that may be caused to your access or use of the Services.</li>
          </ol>
          <ol start="14">
            <li>Access to and registration on the Platforms is free of cost. Although unlikely, Clinic may modify the Fee, Payment and Promotions Policy to include a fee on access and browsing of the Platforms or for the use of any new service introduced by Clinic without serving prior notice on the Users.</li>
          </ol>
          <ol start="15">

            <li>The Services included on or otherwise made available to the Users at the Clinic and / or through the Platforms are provided on an "as is" and "as available" basis without any representations or warranties, express or implied, except if otherwise specified in writing. Clinic does not covenant or warrant that:</li>
            <ol style={{ listStyleType: 'lower-latin' }}>
              <li>the Services will be made available at all times;</li>
              <li>the Clinic Content available on the Platforms is complete, true, accurate or non-misleading; and</li>
              <li>the Products are of specified merchantability, merchantable quality and fit for use for a particular purpose.</li>

            </ol>

          </ol>
          <p>The User hereby affirms that the information provided by them is true, correct and complete to the best of their knowledge and belief. The User agrees and understands that they will be solely responsible in the event of any inaccuracy or deviation therefrom at a later stage.</p>


          <li><strong>USER</strong> <strong>COVENANTS AND OBLIGATIONS</strong></li>
          <ol >
            <li>As mandated under the provisions of Regulation 3(2) of the IG Rules, Clinic hereby informs you that you are prohibited from hosting, displaying, uploading, modifying, publishing, transmitting, updating or sharing any information that:</li>
            <ol style={{ listStyleType: 'lower-latin' }}>
              <li>belongs to another person and to which you do not have any right;</li>
              <li>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating to or encouraging money laundering or gambling, or otherwise harmful in any manner whatsoever;</li>
              <li>harms minors in any way;</li>
              <li>infringes any patent, trademark, copyright or other proprietary rights;</li>
              <li>violates any law for the time being in force;</li>
              <li>deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</li>
              <li>impersonates or defames another person; or</li>
              <li>contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource.</li>
              <li>Threatens India's unity, integrity, defence, security or sovereignty, friendly relations with foreign states, or public order or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting any other nation.</li>
            </ol>
          </ol>
          <ol start="2">
            <li>You are also prohibited from:</li>
            <ol style={{ listStyleType: 'lower-latin' }}>
              <li>violating or attempting to violate the integrity or security of the Platforms or the Clinic Content;</li>
              <li>transmitting any information on or through the Platforms that is disruptive or competitive to the provision of Services by Clinic;</li>
              <li>intentionally submitting on the Platforms false or inaccurate information;</li>
              <li>using any engine, software, tool, agent or other mechanisms (such as spiders, robots, avatars, worms, time bombs, Easter eggs, cancelbots, intelligent agents, etc.) to navigate or search the Platforms;</li>
              <li>attempting to decipher, decompile, disassemble or reverse engineer any part of the Platforms; or</li>
              <li>copying or duplicating in any manner any of the Clinic Content.</li>
            </ol>
          </ol>
          <ol start="3">
            <li>You are also obligated to:</li>
            <ol style={{ listStyleType: 'lower-latin' }}>
              <li>refrain from acquiring any ownership rights by downloading the Clinic Content;</li>
              <li>read the Terms of Use and agree to accept the Terms of Use set out therein;</li>
              <li>refrain from copying or modifying the Clinic Content available on the Platforms or at the Clinic for any purpose;</li>
              <li>comply with all applicable laws in connection with availing Services and your use of the Platforms;</li>
              <li>not refuse the delivery of purchased Products except when damages and deficiencies can be identified upfront at the time of delivery; and</li>
              <li>use the Products for personal, non-commercial use.</li>
            </ol>
          </ol>
          <ol start="4">
            <li>You hereby authorise Clinic to declare and provide declarations to any Governmental authority on request on your behalf, including that the Products ordered by you are for personal, non-commercial use.</li>
          </ol>
          <ol start="5">
            <li>Clinic may disclose or transfer information provided by you to its affiliates in India and other countries, and you hereby consent to such transfer. In terms of the SPI Rules, Clinic can transfer sensitive personal data or information to any other body corporate or a person that ensures the same level of data protection that is adhered to by Clinic as provided for under the SPI Rules, only if such transfer is necessary for the performance of the lawful contract between Clinic or any person on its behalf and the User or where you have consented to such data transfer.</li>
          </ol>


          <li className='mt-4'><strong>THIRD-PARTY INFORMATION</strong></li>
          <ol>
            <li>All information about third parties as available on the Platforms (collectively referred to as "Third Party Information") is provided solely for your reference. Clinic is not endorsing the Third Party Information and is not responsible for any errors and representation, nor is it associated with it, and you shall access the Third Party Information at your own risk.</li>
          </ol>
          <ol start="2">
            <li>Further, it is up to you to take sufficient precautions to ensure that whatever links you select, whether from the Platforms, or other Services, is free of such items such as, but not limited to, viruses, worms, Trojan horses, defects and other items of a destructive nature.</li>
          </ol>


          <li className='mt-4'><strong>INTELLECTUAL</strong> <strong>PROPERTY RIGHTS</strong></li>
          <ol>
            <li>All the intellectual property used at the Clinic and on the Platforms by Clinic, including the Clinic Content, shall remain the property of Clinic, its associates, affiliates, suppliers, vendors, or of any third party hosting such intellectual property on the Platforms. Except as provided in Terms of Use, the materials may not be modified, copied, reproduced, distributed, republished, downloaded, displayed, sold, compiled, posted or transmitted in any form by any means, including but not limited to electronic, mechanical, photocopying, recording or other means, without the prior express written permission of Clinic or any third party hosting such material on the Platforms, as the case may be.</li>
            <li>If you believe that your intellectual property rights have been used in a way that raises concerns of infringement, please write to us at&nbsp;<a>shreyans@shhydrafacial.in</a> and let us know of your problems.</li>
          </ol>

          <li className='mt-4'><strong>UNLAWFUL OR PROHIBITED USE: </strong> </li>
          <ol>
            You warrant to Clinic that you will comply with all applicable laws, statutes, ordinances and regulations regarding the use of the Services, purchasing Products and any other related activities. You further warrant that you will not use the Platforms in any way prohibited by these Terms of Use or under any applicable law.
          </ol>


          <li className='mt-4'><strong>LIABILITY:</strong></li>
          <ol>
            <li>You acknowledge and undertake that you are accessing the Services and purchasing the Products at your own risk. You are using prudent judgment before booking appointment for any Services; placing an order for a Product or availing of any Services through the Platforms. Clinic shall, at no point, be held liable or responsible for any representations or warranties in relation to the Services and Products. Refund of the price paid for (a) the Services booked; and/or (b) the Product(s) ordered shall be governed by the Cancellation and Refund Policy.</li>
          </ol>
          <ol start="2">
            <li>Clinic does not provide or make any representation, warranty or guarantee, express or implied about the Platforms, Products or the Services, and all implied warranties under law or contract are to the maximum extent possible hereby disclaimed.</li>
          </ol>
          <ol start="3">
            <li>The maximum aggregate liability of Clinic, in respect of all Services provided and all transactions undertaken by the User by using the Services, shall be limited to a maximum of INR 1,000 (Indian Rupees One Thousand Only).</li>
          </ol>



          <li className='mt-4'><strong>INDEMNITY: </strong></li>
          <ol>
            You hereby agree to indemnify and hold harmless Clinic, its affiliates, officers, directors, employees, consultants, licensors, agents and representatives from any and all claims, losses, damages, and/or costs (including reasonable attorney fees and costs) arising from (i) your access to or use of the Services and/or Products, (ii) violation of the Terms of Use, (iii) infringement, or infringement by any other user of your account with Clinic, (iv) action or omission on behalf of Clinic's third party affiliates, manufacturers, vendors, suppliers and logistic partners in providing services; and (v) infringement of any intellectual property or other rights of any person or entity. In addition to your foregoing obligations, you agree to provide Clinic with reasonable assistance, at your expense, in defending any such claim, loss, liability, damage or cost. Clinic shall notify you promptly of such claim, loss, liability, or demand.
          </ol>



          <li className='mt-4'><strong>GRIEVANCE OFFICER: </strong></li>
          <ol>
            In accordance with the provisions of the SPI Rules, any grievances which you may have with respect to the information shared by you with Clinic hereunder and its treatment may be directed by you to the grievance officer of Clinic at <a>shreyans@shhydrafacial.in</a> or at the below mentioned coordinates:
          </ol>
          <span><strong>Name:</strong>&nbsp; Shreyans Karia<br /> <strong>Email Address:&nbsp;</strong><a>shreyans@shhydrafacial.in</a></span><br />
          <span><strong>Postal Address:</strong>&nbsp;FF/102, Pratap Tower, Makarpura Road, Vadodara, Gujarat, 390004, Gujarat, India</span> <br />
          <span><strong>Designation:</strong>&nbsp;Grievance Officer</span>


          <li className='mt-4'><strong>SEVERABILITY:</strong></li>
          <ol>
            If any provision of the Terms of Use is determined to be invalid or unenforceable in whole or in part, such invalidity or unenforceability shall attach only to such provision, and the remaining part of such provision and all other provisions of the Terms of Use shall continue to be in full force and effect.
          </ol>



          <li className='mt-4'><strong>TERM AND TERMINATION:</strong></li>
          <ol>
            <li>The Terms of Use will remain in full force and effect while you use any Service and/or Products in any form or capacity.</li>
            <li>Clinic reserves the right to terminate its Services provided to you in the event of a breach of any terms contained in Terms of Use, misrepresentation of information, any unlawful activity or if Clinic is unable to verify or authenticate any information you submit to it.</li>
            <li>The User may terminate the Terms of Use at any time, provided that the User discontinues any further use of the Platforms or Services.</li>
            <li>It is specifically clarified that any termination of the Terms of Use by a User shall not cancel the User's obligation to pay for Services availed, Products purchased on the Platforms or at the Clinic or any other obligation which has accrued or is unfulfilled and relates to the period before termination.</li>
            <li>Any provision of the Terms of Use that imposes an obligation or creates a right that by its nature will be valid after termination or expiration of the Terms of Use shall survive the termination or expiration of the Terms of Use.</li>
          </ol>


          <li className='mt-4'><strong>DISPUTE RESOLUTION AND GOVERNING LAW: </strong></li>
          <ol>
            The Terms of Use and any contractual obligation between Clinic and you under the Terms of Use shall be governed by the laws of India, subject to the exclusive jurisdiction of the courts at Vadodara, Gujarat.
          </ol>



          <li className='mt-4'><strong>WAIVER: </strong></li>
          <ol>
            No provision of, right, power or privilege under this Terms of Use shall be deemed to have been waived by any act, delay, omission or acquiescence on the part of Clinic, its directors or employees, but shall be waived only by an instrument in writing signed by an authorised representative of Clinic.
          </ol>


          <li className='mt-4'><strong>ADDRESS OF SERVICE:</strong></li>

          <span><strong>Postal Address: &nbsp;</strong>FF/102, Pratap Tower, Makarpura Road, Vadodara, Gujarat, 390004, Gujarat, India</span><br></br>
          <span><strong>Email Address: </strong><a>shreyans@shhydrafacial.in</a></span>
        </ol>

        <p>- Last Updated On <strong>13/09/2024</strong></p>
      </div >
    </>
  )
}

export default Termsandconditions




