import React, { useState, useEffect } from 'react'
import './Newsletter.css'
import MitwankSidebar from "./MitwankSidebar";
import { Table } from 'antd';
import "../../App.css"

const columns = [
    {
        title: 'Sr No',
        render(text, record, index) {
            return {
                children: <div style={{ fontWeight: '600', textTransform: 'capitalize' }}>{index + 1}</div>
            }
        }
    },
    {
        title: 'E-mail Id',
        dataIndex: 'Email',
        render(text) {
            return {
                props: {
                    style: { fontSize: "16px", textAlign: "center", color: '#483a15' }
                },
                children: <div style={{ fontWeight: '600' }}  >{text}</div>
            }
        }
    },
    {
        title: 'Subscribed',
        dataIndex: 'subscribetonewsletter',
        render(text) {
            return {
                children: <div style={{ fontWeight: '600', textTransform: 'capitalize', textAlign: 'center' }}  >Yes</div>
            }
        }
    },
    {
        title: 'Date',
        dataIndex: 'Date',
        render(text) {
            // Parse the text into a Date object
            const dateObj = new Date(text);

            // Format the date as DD-MM-YYYY
            const formattedDate = [
                dateObj.getDate().toString().padStart(2, '0'),
                (dateObj.getMonth() + 1).toString().padStart(2, '0'),
                dateObj.getFullYear()
            ].join('-');

            // Format the time in AM/PM format
            const formattedTime = dateObj.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true,
            });

            return {
                children: (
                    <div style={{ fontWeight: '600', textTransform: 'capitalize', textAlign: 'center' }}>
                        {formattedDate} <br></br> {formattedTime}
                    </div>
                )
            };
        }
    },
]

function Newsletter() {


    const convertToCSV = (data) => {
        const csvRows = [];

        // Get headers
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(','));

        // Loop over the rows
        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        }

        return csvRows.join('\n');
    };

    const downloadCSV = () => {
        const csvData = convertToCSV(contactData);
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'data.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };


    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5,
        pageSizeOptions: ['5', '10', '20', '50', '100'],
        showSizeChanger: true,
    });
    const [contactData, setcontactData] = useState([])

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };
    useEffect(() => {

        var Url = window.API_PREFIX + 'admin/all_newsletter_request?Token=' + localStorage.getItem("DToken")
        fetch(Url, {
            method: 'Get',

        }).then(resp => resp.json()).then(data => {

            if (data['status'] === "1") { setcontactData([...data['all_contacts']]) }

        }
        )
    }, [])

    return (
        <>

            <div className="container-fuild mx-2 mt-2">
                <div className='mitwank_newsletter_main_diviison'>
                    <div className='col d-flex justify-content-end align-items-center'>
                        <button type="button" class="btn export_button mb-3 me-4" onClick={downloadCSV} >Export</button>
                    </div>
                </div>
                <div className='mitwank_newsletter_table_main_division'>
                    <div className='mitwank_newsletter_table_division'>
                        <Table columns={columns} dataSource={contactData} pagination={pagination}
                            onChange={handleTableChange} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Newsletter