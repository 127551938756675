import React from "react";
import "../../App.css";
import "./ClientProfilesettings.css";
import profilepic from "../../media/image.jpg";
import ClientSidebar from "./ClientSidebar";
import "../Client/Sidebar.css";
import Patientsidebarresponsive from "./Clientsidebarresponsive";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
function ClientProfilesettings() {
  let navigate = useNavigate();
  const [name, setname] = useState("");
  const [Email, setEmail] = useState("");
  const [Phnumber, setPhnumber] = useState("");
  const [Img, setImg] = useState("");
  const [gender, setgender] = useState("");
  const [Weight, setWeight] = useState("");
  const [height, setheight] = useState("");
  const [profession, setprofession] = useState("");
  const [marriedStatus, setMarriedStatus] = useState("");
  const [anniversaryDate, setAnniversaryDate] = useState("");
  const [spuoseBirthDate, setSpouseBirthDate] = useState("");
  const [age, setage] = useState("");
  const [Address, setAddress] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [Country, setCountry] = useState("");
  const [OtherInfo, setOtherInfo] = useState("");
  const [Pincode, setPincode] = useState("");
  const [showImg, setshowImg] = useState();
  const [Birthdate, setBirthdate] = useState();
  const [open, setopen] = useState([true, false]);

  const [allergiesToFoodMedication, setAllergiesToFoodMedication] =
    useState("");
  const [skinSencitivity, setSkinSencitivity] = useState(false);
  const [tedencyKeloidFormation, setTedencyKeloidFormation] = useState(false);
  const [pregnancyStatus, setPregnancyStatus] = useState("");
  const [menstrualStatus, setMenstrualStatus] = useState("");
  const [smokingStatus, setSmokingStatus] = useState("");
  const [previousSurgeryDesc, setPreviousSurgeryDesc] = useState("");
  const [skinCareRoutine, setSkinCareRoutine] = useState("");
  const [retinolStatus, setRetinolStatus] = useState("");
  const [cosmeticTreatmentDesc, setCosmeticTreatmentDesc] = useState("");
  const [treatmentType, setTreatmentType] = useState("");
  const [treatmentDate, setTreatmentDate] = useState("");
  const [otherProblemDesc, setOtherProblemDesc] = useState("");

  useEffect(() => {
    var Url = window.API_PREFIX + "patient/view_profile";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);

        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/");
          window.location.reload();
        } else if (data["status"] === "1") {
          console.log(data);

          const birthDate = dayjs(data["Birthdate"]);
          const today = dayjs();

          setname(data["Name"]);
          setPhnumber(data["Mobile"]);
          setImg(data["Image"]);
          setgender(data["Gender"]);
          setWeight(data["Weight"]);
          setheight(data["Height"]);
          setprofession(data["Profession"]);
          setage(today.diff(birthDate, "year"));

          setAddress(data["Address"]);
          setCity(data["City"]);
          setState(data["State"]);
          setCountry(data["Country"]);
          setOtherInfo(data["OtherInformation"]);
          setEmail(data["Email"]);
          setshowImg(data["Image"]);
          // setFile(data['Name'])
          setPincode(data["Pincode"]);
          setBirthdate(data["Birthdate"]);
          setAllergiesToFoodMedication(data["allergy_food_medications"]);
          setSkinSencitivity(data["skinSencitive"]);
          setTedencyKeloidFormation(data["tedencyKeloidFormation"]);
          setPregnancyStatus(data["pregnancyStatus"]);
          setMenstrualStatus(data["menstrualStatus"]);
          setSmokingStatus(data["smokingStatus"]);
          setPreviousSurgeryDesc(data["previousSurgeryDescription"]);
          setSkinCareRoutine(data["skinCareRoutine"]);
          setRetinolStatus(data["retinolStatus"]);
          setCosmeticTreatmentDesc(data["cosmeticTreatmentDescription"]);
          setTreatmentType(data["treatmentType"]);
          setTreatmentDate(data["treatmentDate"]);
          setOtherProblemDesc(data["anyProblemDescription"]);
          setMarriedStatus(data["marriedStatus"]);
          setSpouseBirthDate(data["spouseBirthDate"]);
          setAnniversaryDate(data["anniversaryDate"]);
        }
      });
  }, []);
  return (
    <>
      <main className="container-fuild mx-2">
        <div class="client_bashboard_tab_division">
          <div
            className={
              open[0]
                ? "col client_bashboard_tabs_title_name_division_active"
                : "col client_bashboard_tabs_title_name_division"
            }
            onClick={() => {
              setopen([true, false]);
            }}
          >
            <div class="client_bashboard_tabs_title_name">Profile</div>
          </div>
          <div
            className={
              open[1]
                ? "col client_bashboard_tabs_title_name_division_active"
                : "col client_bashboard_tabs_title_name_division"
            }
            onClick={() => {
              setopen([false, true]);
            }}
          >
            <div class="client_bashboard_tabs_title_name">
              {" "}
              Other Information
            </div>
          </div>
        </div>
        {open[0] ? (
          <div className="Profilesettings_main_diviison">
            <div className="profilesettings_Upload_image">
              <div className="profilesettings_profile_image">
                <img
                  className="profilesettings_profile_image_sizes"
                  src={showImg}
                />
              </div>
              <div className="profilesettings_upload_button_main">
                <button
                  type="button"
                  className="profilesettings_upload_photo_button btn"
                >
                  <i className="fa fa-upload profilesettings_upload_icon"></i>{" "}
                  Upload Photo
                  <input
                    type="file"
                    accept="image/gif, image/jpeg, image/png"
                    onChange={(e) => {
                      setImg(e.target.files[0]);

                      setshowImg(URL.createObjectURL(e.target.files[0]));
                    }}
                  />
                </button>
                <br />
                <div className="profilesetting_upload_p">
                  <p>Allowed JPG, GIF or PNG. Max size of 20MB</p>
                </div>
              </div>
            </div>
            <div className="profilesetting_input_form">
              <div className="row g-3 profilesetting_form">
                <div className="col-md-6 profilesettings_first_name">
                  <label
                    for="inputfirstaname"
                    className="form-label profilesettings_label"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control profilesettings_input"
                    placeholder="Enter Your Name"
                    id="inputfirstname"
                    disabled
                    value={name}
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                  />
                </div>
                <div className="col-md-6 profilesettings_mobile">
                  <label
                    for="inputmobile"
                    className="form-label profilesettings_label"
                  >
                    Mobile Number
                  </label>
                  <input
                    type="textfield"
                    className="form-control profilesettings_input"
                    id="inputmobile"
                    placeholder="Enter Your Mobile Number"
                    disabled
                    value={Phnumber}
                    onChange={(e) => {
                      setPhnumber(e.target.value);
                    }}
                  />
                </div>

                <div className="col-md-6 profilesettings_email">
                  <label
                    for="inputemail"
                    className="form-label profilesettings_label"
                  >
                    Email ID
                  </label>
                  <input
                    type="email"
                    className="form-control profilesettings_input"
                    placeholder="Enter Your Email Address"
                    id="inputemail"
                    disabled
                    value={Email}
                  />
                </div>
                <div className="col-md-6 profilesettings_Date_of_birth">
                  <label
                    for="inputdateofbirth"
                    className="form-label profilesettings_label"
                  >
                    Date Of Birth
                  </label>
                  <input
                    type="date"
                    className="form-control profilesettings_input"
                    disabled
                    id="inputdateofbirth"
                    value={Birthdate}
                  />
                </div>
                <div className="col-md-3">
                  <label
                    for="inputage"
                    className="form-label profilesettings_label"
                  >
                    Age
                  </label>
                  <input
                    type="text"
                    className="form-control profilesettings_input"
                    disabled
                    id="inputage"
                    value={age}
                    onChange={(e) => {
                      setage(e.target.value);
                    }}
                  />
                </div>
                <div className="col-md-3">
                  <label
                    for="inputage"
                    className="form-label profilesettings_label"
                  >
                    Gender
                  </label>
                  <input
                    type="text"
                    className="form-control profilesettings_input text-capitalize"
                    disabled
                    id="inputage"
                    value={gender}
                    // onChange={(e) => {
                    //   setGender(e.target.value);
                    // }}
                  />
                </div>
                <div className="col-md-3">
                  <label
                    for="inputage"
                    className="form-label profilesettings_label"
                  >
                    Proffesion
                  </label>
                  <input
                    type="text"
                    className="form-control profilesettings_input text-capitalize"
                    disabled
                    id="inputage"
                    value={profession}
                    onChange={(e) => {
                      setprofession(e.target.value);
                    }}
                  />
                </div>
                <div className="col-md-3">
                  <label
                    for="inputage"
                    className="form-label profilesettings_label"
                  >
                    Maritial Status
                  </label>
                  <input
                    type="text"
                    className="form-control profilesettings_input  text-capitalize"
                    disabled
                    id="inputage"
                    value={marriedStatus}
                    onChange={(e) => {
                      setMarriedStatus(e.target.value);
                    }}
                  />
                </div>
                {marriedStatus === "married" && (
                  <>
                    <div className="col-md-6">
                      <label
                        for="inputage"
                        className="form-label profilesettings_label"
                      >
                        Anniversary Date
                      </label>
                      <input
                        type="date"
                        className="form-control profilesettings_input"
                        id="inputage"
                        value={anniversaryDate}
                        onChange={(e) => {
                          setAnniversaryDate(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <label
                        for="inputage"
                        className="form-label profilesettings_label"
                      >
                        Spouse Birth Date
                      </label>
                      <input
                        type="date"
                        className="form-control profilesettings_input"
                        id="inputage"
                        value={spuoseBirthDate}
                        onChange={(e) => {
                          setSpouseBirthDate(e.target.value);
                        }}
                      />
                    </div>
                  </>
                )}

                <div className="col-12 profilesettings_address">
                  <label
                    for="inputAddress"
                    className="form-label profilesettings_label"
                  >
                    Address
                  </label>
                  <textarea
                    className="form-control profilesettings_address_textarea"
                    placeholder="Enter Your Address"
                    id="floatingTextarea"
                    value={Address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  ></textarea>
                </div>
                <div className="col-md-6 profilesettings_city">
                  <label
                    for="inputCity"
                    className="form-label profilesettings_label"
                  >
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control profilesettings_input"
                    placeholder="Enter City"
                    id="inputCity"
                    value={City}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                  />
                </div>
                <div className="col-md-6 profilesettings_state">
                  <label
                    for="inputstate"
                    className="form-label profilesettings_state_label"
                  >
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control profilesettings_input"
                    placeholder="Enter State"
                    id="inputstate"
                    value={State}
                    onChange={(e) => {
                      setState(e.target.value);
                    }}
                  />
                </div>
                <div className="col-md-6 profilesettings_zip_code">
                  <label
                    for="inputzipcode"
                    className="form-label profilesettings_label"
                  >
                    Zip Code
                  </label>
                  <input
                    type="text"
                    className="form-control profilesettings_input"
                    placeholder="Enter Zip Code"
                    id="inputzipcode"
                    value={Pincode}
                    onChange={(e) => {
                      setPincode(e.target.value);
                    }}
                  />
                </div>
                <div className="col-md-6 profilesettings_country">
                  <label
                    for="inputcountry"
                    className="form-label profilesettings_label"
                  >
                    Country
                  </label>
                  <input
                    type="text"
                    className="form-control profilesettings_input"
                    placeholder="Enter Country"
                    id="inputcountry"
                    value={Country}
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                  />
                </div>
                <div className="col-12 profilesettings_other_information">
                  <label
                    for="inputotherinformation"
                    className="form-label profilesettings_label"
                  >
                    Other Information
                  </label>
                  <textarea
                    className="form-control profilesettings_address_textarea"
                    id="floatingTextarea"
                    placeholder="Other Information"
                    value={OtherInfo}
                    onChange={(e) => {
                      setOtherInfo(e.target.value);
                    }}
                  ></textarea>
                </div>
                <div className="col-12 profilesettings_savechanges_button_main">
                  <button
                    className="btn profilesettings_savechanges_button"
                    onClick={() => {
                      var Url =
                        window.API_PREFIX + "patient/add_registration_data";

                      var uploadData = new FormData();
                      uploadData.append(
                        "Token",
                        localStorage.getItem("DToken")
                      );
                      uploadData.append("Name", name);
                      uploadData.append("Pic", Img);
                      uploadData.append("Gender", gender);
                      uploadData.append("Weight", Weight);
                      uploadData.append("Height", height);
                      uploadData.append("Birthdate", Birthdate);
                      uploadData.append("profession", profession);
                      uploadData.append("Address", Address);
                      uploadData.append("City", City);
                      uploadData.append("State", State);
                      uploadData.append("Country", Country);
                      uploadData.append("Pincode", Pincode);
                      uploadData.append("OtherInformation", OtherInfo);
                      uploadData.append("Mobile", Phnumber);
                      uploadData.append("marriedStatus", marriedStatus);
                      if (anniversaryDate !== "") {
                        uploadData.append("anniversaryDate", anniversaryDate);
                      }
                      if (spuoseBirthDate !== "") {
                        uploadData.append("spouseBirthDate", spuoseBirthDate);
                      }

                      fetch(Url, {
                        method: "POST",
                        // headers: {
                        //     'Content-type': 'application/json',
                        // },
                        body: uploadData,
                      })
                        .then((resp) => resp.json())
                        .then((data) => {
                          console.log(data);
                          if (data["status"] === "Block") {
                            toast.error(data["message"]);
                            localStorage.clear();
                            navigate("/");
                            window.location.reload();
                          } else if (data["status"] === "1") {
                            toast.success(
                              "Profile details change successfully"
                            );

                            if (data["FileUpdated"]) {
                              window.location.reload();
                            }
                          }
                        });
                    }}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {open[1] ? (
          <div className="Profilesettings_main_diviison">
            <form className="medical-form">
              {/* Allergies Section */}
              <div className="form-section">
                <h2 className="form-heading">Allergies:</h2>
                <div className="form-group">
                  <label className="form-label">
                    Do you have any allergies to food medications?
                  </label>
                  <input
                    className="form-input"
                    type="text"
                    name="allergies"
                    value={allergiesToFoodMedication}
                    onChange={(e) => {
                      setAllergiesToFoodMedication(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">
                    Is your skin very sensitive?
                  </label>
                  <div className="form-radio-group">
                    <input
                      className="form-radio"
                      type="radio"
                      id="skinSensitiveYes"
                      name="skinSensitive"
                      checked={skinSencitivity === "yes"}
                      onChange={(e) => {
                        setSkinSencitivity("yes");
                      }}
                    />
                    <label
                      className="form-radio-label"
                      htmlFor="skinSensitiveYes"
                    >
                      Yes
                    </label>

                    <input
                      className="form-radio"
                      type="radio"
                      id="skinSensitiveNo"
                      name="skinSensitive"
                      checked={skinSencitivity === "no"}
                      onChange={(e) => {
                        setSkinSencitivity("no");
                      }}
                    />
                    <label
                      className="form-radio-label"
                      htmlFor="skinSensitiveNo"
                    >
                      No
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">
                    Do you have a tendency to keloid formation?
                  </label>
                  <div className="form-radio-group">
                    <input
                      className="form-radio"
                      type="radio"
                      id="keloidYes"
                      name="keloid"
                      checked={tedencyKeloidFormation === "yes"}
                      onChange={(e) => {
                        setTedencyKeloidFormation("yes");
                      }}
                    />
                    <label className="form-radio-label" htmlFor="keloidYes">
                      Yes
                    </label>

                    <input
                      className="form-radio"
                      type="radio"
                      id="keloidNo"
                      name="keloid"
                      checked={tedencyKeloidFormation === "no"}
                      onChange={(e) => {
                        setTedencyKeloidFormation("no");
                      }}
                    />
                    <label className="form-radio-label" htmlFor="keloidNo">
                      No
                    </label>
                  </div>
                </div>
              </div>

              {gender === "female" && (
                <>
                  {/* Pregnancy Section */}
                  <div className="form-section">
                    <h2 className="form-heading">Pregnancy:</h2>
                    <div className="form-group">
                      <label className="form-label">
                        Are you currently pregnant?
                      </label>
                      <div className="form-radio-group">
                        <input
                          className="form-radio"
                          type="radio"
                          id="pregnancyYes"
                          name="pregnancy"
                          checked={pregnancyStatus === "yes"}
                          onChange={(e) => {
                            setPregnancyStatus("yes");
                          }}
                        />
                        <label
                          className="form-radio-label"
                          htmlFor="pregnancyYes"
                        >
                          Yes
                        </label>

                        <input
                          className="form-radio"
                          type="radio"
                          id="pregnancyNo"
                          name="pregnancy"
                          checked={pregnancyStatus === "no"}
                          onChange={(e) => {
                            setPregnancyStatus("no");
                          }}
                        />
                        <label
                          className="form-radio-label"
                          htmlFor="pregnancyNo"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* Menstrual History Section */}
                  <div className="form-section">
                    <h2 className="form-heading">Menstrual history:</h2>
                    <div className="form-group">
                      <label className="form-label">
                        Is your menstrual history Regular/Irregular?
                      </label>
                      <div className="form-radio-group">
                        <input
                          className="form-radio"
                          type="radio"
                          id="menstrualRegular"
                          name="menstrual"
                          checked={menstrualStatus === "yes"}
                          onChange={(e) => {
                            setMenstrualStatus("yes");
                          }}
                        />
                        <label
                          className="form-radio-label"
                          htmlFor="menstrualRegular"
                        >
                          Regular
                        </label>

                        <input
                          className="form-radio"
                          type="radio"
                          id="menstrualIrregular"
                          name="menstrual"
                          checked={menstrualStatus === "no"}
                          onChange={(e) => {
                            setMenstrualStatus("no");
                          }}
                        />
                        <label
                          className="form-radio-label"
                          htmlFor="menstrualIrregular"
                        >
                          Irregular
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* Smoking Section */}
              <div className="form-section">
                <h2 className="form-heading">Smoking:</h2>
                <div className="form-group">
                  <label className="form-label">Do you smoke?</label>
                  <div className="form-radio-group">
                    <input
                      className="form-radio"
                      type="radio"
                      id="smokeYes"
                      name="smoke"
                      checked={smokingStatus === "yes"}
                      onChange={(e) => {
                        setSmokingStatus("yes");
                      }}
                    />
                    <label className="form-radio-label" htmlFor="smokeYes">
                      Yes
                    </label>

                    <input
                      className="form-radio"
                      type="radio"
                      id="smokeNo"
                      name="smoke"
                      checked={smokingStatus === "no"}
                      onChange={(e) => {
                        setSmokingStatus("no");
                      }}
                    />
                    <label className="form-radio-label" htmlFor="smokeNo">
                      No
                    </label>
                  </div>
                </div>
              </div>

              {/* Previous Surgeries Section */}
              <div className="form-section">
                <h2 className="form-heading">
                  Have you had any previous surgeries?
                </h2>
                <div className="form-group">
                  <label className="form-label">Please describe:</label>
                  <textarea
                    className="form-textarea"
                    name="surgeries"
                    value={previousSurgeryDesc}
                    onChange={(e) => {
                      setPreviousSurgeryDesc(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>

              {/* Current Skin Care Routine Section */}
              <div className="form-section">
                <h2 className="form-heading">Current skin care routine:</h2>
                <div className="form-group">
                  <label className="form-label">
                    Describe your routine (cleansers, moisturizers, sunscreen,
                    etc.):
                  </label>
                  <textarea
                    className="form-textarea"
                    name="skinRoutine"
                    value={skinCareRoutine}
                    onChange={(e) => {
                      setSkinCareRoutine(e.target.value);
                    }}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label className="form-label">Use of Retinol:</label>
                  <div className="form-radio-group">
                    <input
                      className="form-radio"
                      type="radio"
                      id="retinolYes"
                      name="retinolUse"
                      checked={retinolStatus === "yes"}
                      onChange={(e) => {
                        setRetinolStatus("yes");
                      }}
                    />
                    <label className="form-radio-label" htmlFor="retinolYes">
                      Yes
                    </label>

                    <input
                      className="form-radio"
                      type="radio"
                      id="retinolNo"
                      name="retinolUse"
                      checked={retinolStatus === "no"}
                      onChange={(e) => {
                        setRetinolStatus("no");
                      }}
                    />
                    <label className="form-radio-label" htmlFor="retinolNo">
                      No
                    </label>
                  </div>
                </div>
              </div>

              {/* Cosmetic Treatment History Section */}
              <div className="form-section">
                <h2 className="form-heading">Cosmetic treatment history:</h2>
                <div className="form-group">
                  <label className="form-label">
                    Previous laser or any treatment?
                  </label>
                  <textarea
                    className="form-textarea"
                    name="cosmeticTreatment"
                    value={cosmeticTreatmentDesc}
                    onChange={(e) => {
                      setCosmeticTreatmentDesc(e.target.value);
                    }}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label className="form-label">Type:</label>
                  <input
                    className="form-input"
                    type="text"
                    name="treatmentType"
                    value={treatmentType}
                    onChange={(e) => {
                      setTreatmentType(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Date:</label>
                  <input
                    className="form-date"
                    type="date"
                    name="treatmentDate"
                    value={treatmentDate}
                    onChange={(e) => {
                      setTreatmentDate(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Any problems?</label>
                  <textarea
                    className="form-textarea"
                    name="treatmentProblems"
                    value={otherProblemDesc}
                    onChange={(e) => {
                      setOtherProblemDesc(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
              <div className="col-12 profilesettings_savechanges_button_main">
                <button
                  // type="submit"
                  className="btn profilesettings_savechanges_button"
                  onClick={() => {
                    var Url =
                      window.API_PREFIX + "patient/add_patient_other_info";

                    var uploadData = new FormData();
                    uploadData.append("Token", localStorage.getItem("DToken"));
                    uploadData.append(
                      "allergy_food_medications",
                      allergiesToFoodMedication
                    );
                    uploadData.append("skinSencitive", skinSencitivity);
                    uploadData.append(
                      "tedencyKeloidFormation",
                      tedencyKeloidFormation
                    );
                    uploadData.append("pregnancyStatus", pregnancyStatus);
                    uploadData.append("menstrualStatus", menstrualStatus);
                    uploadData.append("smokingStatus", smokingStatus);
                    uploadData.append(
                      "previousSurgeryDescription",
                      previousSurgeryDesc
                    );
                    uploadData.append("skinCareRoutine", skinCareRoutine);
                    uploadData.append("retinolStatus", retinolStatus);
                    uploadData.append(
                      "cosmeticTreatmentDescription",
                      cosmeticTreatmentDesc
                    );
                    uploadData.append("treatmentType", treatmentType);
                    uploadData.append("treatmentDate", treatmentDate);
                    uploadData.append(
                      "anyProblemDescription",
                      otherProblemDesc
                    );
                    fetch(Url, {
                      method: "POST",
                      // headers: {
                      //     'Content-type': 'application/json',
                      // },
                      body: uploadData,
                    })
                      .then((resp) => resp.json())
                      .then((data) => {
                        console.log(data);
                        if (data["status"] === "Block") {
                          toast.error(data["message"]);
                          localStorage.clear();
                          navigate("/");
                          window.location.reload();
                        }
                        else if (data["status"] === "1") {
                          toast.success("Profile detail change successfully");
                          if (data["FileUpdated"]) {
                            window.location.reload();
                          }
                        }
                      });
                  }}
                >
                  Save Other Information
                </button>
              </div>
            </form>
          </div>
        ) : null}
      </main>
    </>
  );
}

export default ClientProfilesettings;
