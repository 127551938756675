import React from 'react'

function Shippingpolicy() {
    return (
        <div className="container mt-5 mb-5 " style={{ fontSize: '18px', textAlign: 'justify' }}>
            <h2 className='mt-3'><strong>Shipping policy</strong></h2>
            <p>There are no physical products, so no shipping needed.</p>
            <p>- Last Updated On <strong>13/09/2024</strong></p>
        </div>
    )
}

export default Shippingpolicy