import React from "react";
import { useEffect, useState } from "react";
import { Table } from "antd";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import ClinicSidebar from "./ClinicSidebar";
import toast, { Toaster } from "react-hot-toast";
function Clinictrash() {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    pageSizeOptions: ["5", "10", "20", "50", "100"],
    showSizeChanger: true,
  });
  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  let navigate = useNavigate();
  const [DashboardOption, setDashboardOption] = useState([
    true,
    false,
    false,
    false,
  ]);
  const [docAppointmentData, setdocAppointmentData] = useState([]);
  const [docPrescribeData, setdocPrescribeData] = useState([]);
  const [docMedicalData, setdocMedicalData] = useState([]);
  const [docBillingData, setdocBillingData] = useState([]);

  useEffect(() => {
    getappointment();
  }, []);

  function Delete_Appointment(Requestindex, BookingId) {
    var Url = window.API_PREFIX + "booking/delete_booking";
    const index = docAppointmentData.findIndex(
      (Booking) => Booking.BookingId === BookingId
    );
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docAppointmentData[index]["BookingId"],
        loginAs: localStorage.getItem("LoginDetails"),
        clinicId: localStorage.getItem("clinicId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        // dispatch({
        //     type: 'StopLoad'
        // })
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } else if (data["status"] === "1") {
          getappointment();
        }
      });
  }

  function RestoreRequest(Requestindex, BookingId) {
    var Url = window.API_PREFIX + "booking/restore_trash_booking";
    const index = docAppointmentData.findIndex(
      (Booking) => Booking.BookingId === BookingId
    );
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docAppointmentData[index]["BookingId"],
        loginAs: localStorage.getItem("LoginDetails"),
        clinicId: localStorage.getItem("clinicId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        }
        else if (data["status"] === "1") {
          getappointment();
        }
      });
  }

  function getmedicalrecord() {
    var Url = window.API_PREFIX + "admin/view_medicalrecords_trash";
    let clinic_id = localStorage.getItem("clinicId");
    if (clinic_id) {
      Url += `?clinicId=${clinic_id}`;
    }
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        loginAs: localStorage.getItem("LoginDetails"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        }
        else if (data["status"] === "1") {
          setdocMedicalData([...data["records"]]);
        }
      });
  }

  function restore_medicalrecord(Requestindex, id) {
    var Url = window.API_PREFIX + "admin/restore_delete_files";
    const index = docMedicalData.findIndex((records) => records.id === id);
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docMedicalData[index]["id"],
        loginAs: localStorage.getItem("LoginDetails"),
        clinicId: localStorage.getItem("clinicId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        }
        else if (data["status"] === "1") {
          getmedicalrecord();
        }
      });
  }

  function delete_medicalrecord(Requestindex, id) {
    var Url = window.API_PREFIX + "admin/delete_files";
    const index = docMedicalData.findIndex((records) => records.id === id);
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docMedicalData[index]["id"],
        loginAs: localStorage.getItem("LoginDetails"),
        clinicId: localStorage.getItem("clinicId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        }
        else if (data["status"] === "1") {
          getmedicalrecord();
        }
      });
  }

  function getappointment() {
    var Url = window.API_PREFIX + "admin/all_trash_bookings";
    let clinic_id = localStorage.getItem("clinicId");
    let loginAs = localStorage.getItem("LoginDetails");
    let queryParams = [];
    if (clinic_id) queryParams.push(`clinicId=${clinic_id}`);
    if (loginAs) queryParams.push(`loginAs=${loginAs}`);
    if (queryParams.length > 0) {
      Url += `?${queryParams.join("&")}`;
    }
    fetch(Url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
      // body: JSON.stringify({'Token':localStorage.getItem('DToken')}),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        }
        else if (data["status"] === "1") {
          setdocAppointmentData([...data["Booking"]]);
        }
      });
  }

  function getprescription() {
    var Url = window.API_PREFIX + "admin/view_prescription_trash";
    let clinic_id = localStorage.getItem("clinicId");
    let loginAs = localStorage.getItem("LoginDetails");
    let queryParams = [];
    if (clinic_id) queryParams.push(`clinicId=${clinic_id}`);
    if (loginAs) queryParams.push(`loginAs=${loginAs}`);
    if (queryParams.length > 0) {
      Url += `?${queryParams.join("&")}`;
    }

    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } 
        else if (data["status"] === "1") {
          setdocPrescribeData([...data["data"]]);
        }
      });
  }

  function restore_prescription(Requestindex, id) {
    var Url = window.API_PREFIX + "admin/restore_delete_framework";
    const index = docPrescribeData.findIndex((data) => data.id === id);
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docPrescribeData[index]["id"],
        loginAs: localStorage.getItem("LoginDetails"),
        clinicId: localStorage.getItem("clinicId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        }
        else if (data["status"] === "1") {
          getprescription();
        }
      });
  }

  function delete_prescription(Requestindex, id) {
    var Url = window.API_PREFIX + "admin/delete_framework";
    const index = docPrescribeData.findIndex((data) => data.id === id);
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docPrescribeData[index]["id"],
        loginAs: localStorage.getItem("LoginDetails"),
        clinicId: localStorage.getItem("clinicId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        }
        else if (data["status"] === "1") {
          getprescription();
        }
      });
  }

  function getbilling() {
    var Url = window.API_PREFIX + "admin/billingpatientwise_trash";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        loginAs: localStorage.getItem("LoginDetails"),
        clinicId: localStorage.getItem("clinicId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        }
        else if (data["status"] === "1") {
          setdocBillingData([...data["Bill"]]);
        }
      });
  }

  function restore_billing(Requestindex, id) {
    var Url = window.API_PREFIX + "admin/restore_delete_invoice";
    const index = docBillingData.findIndex((Bill) => Bill.id === id);
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docBillingData[index]["id"],
        loginAs: localStorage.getItem("LoginDetails"),
        clinicId: localStorage.getItem("clinicId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        }
        else if (data["status"] === "1") {
          getbilling();
        }
      });
  }

  function delete_billing(Requestindex, id) {
    var Url = window.API_PREFIX + "admin/delete_invoice";
    const index = docBillingData.findIndex((Bill) => Bill.id === id);
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docBillingData[index]["id"],
        loginAs: localStorage.getItem("LoginDetails"),
        clinicId: localStorage.getItem("clinicId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } 
        else if (data["status"] === "1") {
          getbilling();
        }
      });
  }

  const Appoinmenttrash = [
    {
      title: "Client Name",

      dataIndex: "PatientName",
      key: "name",
      render: (text, record) => (
        <>
          <div className="mitwank_dashboard_table_image_name_division">
            <img
              className="mitwank_dashboard_table_image"
              src={record["ProfilePic"]}
              style={{ height: "2.5rem", width: "2.5rem" }}
            />
            <div className="mitwank_dashboard_table_name_id_division">
              <div className="mitwank_dashboard_table_patient_name">{text}</div>
              <div className="mitwank_dashboard_table_patient_id">
                #SH{record["PatientId"]}
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Appt Date",
      dataIndex: "Date",
      key: "date",
      render: (text, record) => (
        <>
          <div className="mitwank_dashboard_time_date_div">
            <div className="mitwank_dashboard_table_appt_date">{text}</div>
            <div className="mitwank_dashboard_table_appt_time">
              {record["Time"]}
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Receptionist Name",
      dataIndex: "Purpose",
      key: "purpose",
      render: (text, record) => (
        <>
          <div className="mitwank_dashboard_table_appt_date">{text}</div>
        </>
      ),
    },
    {
      title: " Amount",
      dataIndex: "Amount",
      key: "paidamount",
    },
    {
      title: "Option",
      key: "tags",
      dataIndex: "BookingStatus",
      render: (text, record, index) => (
        <>
          <div>
            <button
              className="btn mitwank_dashboard_tabel_cancel_btn"
              onClick={() => {
                // var INDEX = currentPage * entriesPerPage + index

                RestoreRequest(index, record.BookingId);
              }}
            >
              Restore
            </button>
          </div>
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (text, record, index) => (
        <>
          <i
            class="fa-solid fa-trash-can"
            style={{ color: "#e11f28" }}
            onClick={() => {
              // var INDEX = currentPage * entriesPerPage + index

              Delete_Appointment(index, record.BookingId);
            }}
          ></i>
        </>
      ),
    },
  ];

  const Frameworktrash = [
    {
      title: "Client Name",
      dataIndex: "PatientName",
      key: "name",
      render: (text, record) => (
        <>
          <div className="mitwank_dashboard_table_image_name_division">
            <img
              className="mitwank_dashboard_table_image"
              src={record["ProfilePic"]}
              style={{ height: "2.5rem", width: "2.5rem" }}
            />
            <div className="mitwank_dashboard_table_name_id_division">
              <div className="mitwank_dashboard_table_patient_name">{text}</div>
              <div className="mitwank_dashboard_table_patient_id">
                #SH{record["PatientId"]}
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Title Name",
      dataIndex: "Name",
      key: "name",
      render: (text, record, index) => (
        <div
          // onClick={() => {
          //     navigate("/mitwank/addprescription", {
          //         state: {
          //             PID: PID,
          //             ADDType: false,
          //             Title: record.Title,
          //             Date: record.Date

          //         }
          //     })
          // }}

          onClick={() => {}}
        >
          {text}
        </div>
      ),
    },

    {
      title: "Date",
      dataIndex: "Date",
      key: "date",
      render: (text) => {
        const formattedDate = format(new Date(text), "dd-MM-yyyy");
        return (
          <>
            <div className="client_dashboard_table_appdate_date">
              {formattedDate}
            </div>
          </>
        );
      },
    },

    {
      title: "Attachment",
      dataIndex: "Attachment",
      key: "Attachment",
      render: (text) => (
        <>
          {/* <button className='patient_dashboard_table_print_btn me-2'><i class="fa-solid fa-print"></i> Print</button> */}
          <a href={text} target="_blank">
            {" "}
            <button className="patient_dashboard_table_view_btn">
              <i class="fa-regular fa-eye"></i> View
            </button>
          </a>
        </>
      ),
    },
    {
      title: "Option",
      key: "tags",
      dataIndex: "BookingStatus",
      render: (text, record, index) => (
        <>
          <div>
            <button
              className="btn patient_dashboard_table_view_btn"
              onClick={() => {
                // var INDEX = currentPage * entriesPerPage + index

                restore_prescription(index, record.id);
              }}
            >
              Restore
            </button>
          </div>
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (text, record, index) => (
        <>
          <i
            class="fa-solid fa-trash-can"
            style={{ color: "#e11f28" }}
            onClick={() => {
              // var INDEX = currentPage * entriesPerPage + index

              delete_prescription(index, record.id);
            }}
          ></i>
        </>
      ),
    },
  ];

  const Filestrash = [
    {
      title: "Client Name",

      dataIndex: "PatientName",
      key: "name",
      render: (text, record) => (
        <>
          <div className="mitwank_dashboard_table_image_name_division">
            <img
              className="mitwank_dashboard_table_image"
              src={record["ProfilePic"]}
              style={{ height: "2.5rem", width: "2.5rem" }}
            />
            <div className="mitwank_dashboard_table_name_id_division">
              <div className="mitwank_dashboard_table_patient_name">{text}</div>
              <div className="mitwank_dashboard_table_patient_id">
                #SH{record["PatientId"]}
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Record ID",
      dataIndex: "id",
      key: "ID",
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
      render: (text) => {
        const formattedDate = format(new Date(text), "dd-MM-yyyy");
        return (
          <>
            <div className="client_dashboard_table_appdate_date">
              {formattedDate}
            </div>
          </>
        );
      },
    },
    {
      title: "Title Name",
      dataIndex: "TitleName",
      key: "TitleName",
    },
    {
      title: "Remarks",
      dataIndex: "Symptoms",
      key: "Description",
    },
    {
      title: "Attachment",
      dataIndex: "Attachment",
      key: "Attachment",
      render: (text) => (
        <>
          {/* <button className='patient_dashboard_table_print_btn me-2'><i class="fa-solid fa-print"></i> Print</button> */}
          <a href={text} target="_blank">
            {" "}
            <button className="patient_dashboard_table_view_btn">
              <i class="fa-regular fa-eye"></i> View
            </button>
          </a>
        </>
      ),
    },
    {
      title: "Created",
      dataIndex: "CreateBy",
      key: "Created",
    },
    {
      title: "Option",
      key: "tags",
      dataIndex: "BookingStatus",
      render: (text, record, index) => (
        <>
          <div>
            <button
              className="btn mitwank_dashboard_tabel_cancel_btn"
              onClick={() => {
                // var INDEX = currentPage * entriesPerPage + index

                restore_medicalrecord(index, record.id);
              }}
            >
              Restore
            </button>
          </div>
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (text, record, index) => (
        <>
          <i
            class="fa-solid fa-trash-can"
            style={{ color: "#e11f28" }}
            onClick={() => {
              // var INDEX = currentPage * entriesPerPage + index

              delete_medicalrecord(index, record.id);
            }}
          ></i>
        </>
      ),
    },
  ];

  const Billingtrash = [
    {
      title: "Client Name",

      dataIndex: "PatientName",
      key: "name",
      render: (text, record) => (
        <>
          <div className="mitwank_dashboard_table_image_name_division">
            <img
              className="mitwank_dashboard_table_image"
              src={record["ProfilePic"]}
              style={{ height: "2.5rem", width: "2.5rem" }}
            />
            <div className="mitwank_dashboard_table_name_id_division">
              <div className="mitwank_dashboard_table_patient_name">{text}</div>
              <div className="mitwank_dashboard_table_patient_id">
                #SH{record["PatientId"]}
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Invoice No",
      dataIndex: "Invoice",
      key: "InvoiceNo",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
    {
      title: "Paid On Date",
      dataIndex: "Paidon",
      key: "PaidOn",
      render: (text) => {
        const formattedDate = format(new Date(text), "dd-MM-yyyy");
        return (
          <>
            <div className="client_dashboard_table_appdate_date">
              {formattedDate}
            </div>
          </>
        );
      },
    },
    {
      title: "Show",
      key: "Attachment",
      dataIndex: "Attachment",
      render: (text) => {
        return (
          <>
            <button className="client_dashboard_table_print_btn me-2">
              <i class="fa-solid fa-print"></i> Print
            </button>
            <a href={text} target="_blank">
              {" "}
              <button className="client_dashboard_table_view_btn">
                <i class="fa-regular fa-eye"></i> View
              </button>
            </a>
          </>
        );
      },
    },
    {
      title: "Option",
      key: "tags",
      dataIndex: "BookingStatus",
      render: (text, record, index) => (
        <>
          <div>
            <button
              className="btn mitwank_dashboard_tabel_cancel_btn"
              onClick={() => {
                // var INDEX = currentPage * entriesPerPage + index

                restore_billing(index, record.id);
              }}
            >
              Restore
            </button>
          </div>
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (text, record, index) => (
        <>
          <i
            class="fa-solid fa-trash-can"
            style={{ color: "#e11f28" }}
            onClick={() => {
              // var INDEX = currentPage * entriesPerPage + index

              delete_billing(index, record.id);
            }}
          ></i>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="container-fuild mx-2">
        <div className="component_division_mitwank_patient_profile">
          <div className="mitwank_patient_profile_main_division">
            <div class="client_bashboard_tab_division">
              <div
                className={
                  DashboardOption[0]
                    ? "col client_bashboard_tabs_title_name_division_active"
                    : "col client_bashboard_tabs_title_name_division"
                }
                onClick={() => {
                  getappointment();
                  setDashboardOption([true, false, false, false]);
                }}
              >
                <div class="client_bashboard_tabs_title_name">Appointments</div>
              </div>

              <div
                className={
                  DashboardOption[1]
                    ? "col client_bashboard_tabs_title_name_division_active"
                    : "col client_bashboard_tabs_title_name_division"
                }
                onClick={() => {
                  getprescription();
                  setDashboardOption([false, true, false, false]);
                }}
              >
                <div class="client_bashboard_tabs_title_name">
                  Offers & Events
                </div>
              </div>
              <div
                className={
                  DashboardOption[2]
                    ? "col client_bashboard_tabs_title_name_division_active"
                    : "col client_bashboard_tabs_title_name_division"
                }
                onClick={() => {
                  getmedicalrecord();
                  setDashboardOption([false, false, true, false]);
                }}
              >
                <div class="client_bashboard_tabs_title_name">Files</div>
              </div>
              <div
                className={
                  DashboardOption[3]
                    ? "col client_bashboard_tabs_title_name_division_active"
                    : "col client_bashboard_tabs_title_name_division"
                }
                onClick={() => {
                  getbilling();
                  setDashboardOption([false, false, false, true]);
                }}
              >
                <div class="client_bashboard_tabs_title_name">Billing</div>
              </div>
            </div>

            {DashboardOption[0] ? (
              <div className="mitwank_patient_profile_table_division">
                <Table
                  columns={Appoinmenttrash}
                  dataSource={docAppointmentData}
                  pagination={pagination}
                  onChange={handleTableChange}
                />
              </div>
            ) : DashboardOption[1] ? (
              <>
                <div className="mitwank_patient_profile_table_division">
                  <Table
                    columns={Frameworktrash}
                    dataSource={docPrescribeData}
                    pagination={pagination}
                    onChange={handleTableChange}
                  />
                </div>
              </>
            ) : DashboardOption[2] ? (
              <>
                <div className="mitwank_patient_profile_table_division">
                  <Table
                    columns={Filestrash}
                    dataSource={docMedicalData}
                    pagination={pagination}
                    onChange={handleTableChange}
                  />
                </div>
              </>
            ) : DashboardOption[3] ? (
              <>
                <div className="mitwank_patient_profile_table_division">
                  <Table
                    columns={Billingtrash}
                    dataSource={docBillingData}
                    pagination={pagination}
                    onChange={handleTableChange}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default Clinictrash;
