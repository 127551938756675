import { useNavigate } from "react-router-dom";
import "../../App.css";
import { useEffect, useState } from "react";
import { useStateValue } from "../StateProviders";
import toast, { Toaster } from "react-hot-toast";
function ReceptionistSidebar() {
  const [dispatch] = useStateValue();

  let navigate = useNavigate();
  const [DocData, setDocData] = useState({});
  useEffect(() => {
    var Url = window.API_PREFIX + "admin/receptionistdetail";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        } else if (data["status"] === "1") {
          setDocData({ ...data["data"] });
        }
      });
  }, []);

  return (
    <div className="sidebar_main_division">
      <div className="User_detail_main_div mt-2 mb-2">
        <div className="profile_info">
          <div className="profile_info_img_div">
            <img alt="Receptionist_Image" src={DocData.ProfilePic}></img>
          </div>
        </div>
        <h4>{DocData.Name}</h4>
        <span>{DocData.Email}</span>
      </div>
      <div
        className={
          window.location.pathname.includes("/receptionist/dashboard")
            ? "sidebar_dashboard_title_division_active"
            : "sidebar_dashboard_title_division"
        }
        onClick={() => {
          navigate("/receptionist/dashboard");
        }}
      >
        <i class="fa-solid fa-shapes me-2"></i> Dashboard
      </div>

      <div
        className={
          window.location.pathname.includes(
            "/receptionist/receptionistappointmentbydate"
          )
            ? "sidebar_dashboard_title_division_active"
            : "sidebar_dashboard_title_division"
        }
        onClick={() => {
          navigate("/receptionist/receptionistappointmentbydate");
        }}
      >
        <i class="fa-solid fa-calendar-days me-2"></i> Appointment By Date
      </div>

      <div
        className={
          window.location.pathname.includes("/receptionist/schedule-timing")
            ? "sidebar_dashboard_title_division_active"
            : "sidebar_dashboard_title_division"
        }
        onClick={() => {
          navigate("/receptionist/schedule-timing");
        }}
      >
        <i class="fa-solid fa-calendar-day me-2"></i>Schedule Timing
      </div>

      <div
        className={
          window.location.pathname.includes(
            "/receptionist/receptionistprofilesettings"
          )
            ? "sidebar_dashboard_title_division_active"
            : "sidebar_dashboard_title_division"
        }
        onClick={() => {
          navigate("/receptionist/receptionistprofilesettings");
        }}
      >
        <i class="fa-solid fa-user-pen me-2"></i> Profile Settings
      </div>

      <div
        className={
          window.location.pathname.includes(
            "/receptionist/receptionistchangepassword"
          )
            ? "sidebar_dashboard_title_division_active"
            : "sidebar_dashboard_title_division"
        }
        onClick={() => {
          navigate("/receptionist/receptionistchangepassword");
        }}
      >
        <i class="fa-solid fa-key me-2"></i> Change Password{" "}
      </div>
      <div className={window.location.pathname.includes('/receptionist/receptionistoffers')
        ? 'sidebar_dashboard_title_division_active'
        : 'sidebar_dashboard_title_division'
      }
        onClick={() => {
          navigate('/receptionist/receptionistoffers')

        }}
      ><i class="fa-solid fa-percent me-2"></i> Offers </div>
      <div className={window.location.pathname.includes('/receptionist/receptionistevents') ? 'sidebar_dashboard_title_division_active' : 'sidebar_dashboard_title_division'}
        onClick={() => {
          navigate('/receptionist/receptionistevents')

        }}
      ><i class="fa-solid fa-calendar-days me-2"></i>Events </div>
      <div
        className="sidebar_dashboard_title_division"
        onClick={() => {
          localStorage.clear();
          navigate("/");
          dispatch({ type: "RLogout" });
        }}
      >
        <i class="fa-solid fa-right-from-bracket me-2"></i> Logout{" "}
      </div>
    </div>
  );
}

export default ReceptionistSidebar;
