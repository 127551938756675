import React from 'react';
import googleplay from '../media/about/googleplay.png'
import appstore from '../media/about/appstore.png';

function Downloadapp() {
    return (
        <div className='d-flex justify-content-center align-items-center' style={{height:'100vh'}}>
            <div className='col'>


                <a  href="https://play.google.com/store/apps/details?id=com.kodenauts.shhydrafacial.android">
                    <img className='col col-12' src={googleplay}></img>
                </a>
                <a  href="https://apps.apple.com/in/app/sh-hydrafacial/id6683282817">
                    <img className='col col-12' src={appstore} ></img>
                </a>
            </div>



        </div>
    )
}

export default Downloadapp