import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom"
import Footer from "../Footer";
import Clinicheader from '../Clinic/Clinicheader';
import { useStateValue } from "../StateProviders";
import Cliniclogin from "./Cliniclogin";
import Topbar from "../Topbar";
import Breadcrumb from "../Breadcrumb";
import ClinicSidebar from "./ClinicSidebar";

export default function Cliniclayout() {

    const [{ CIsLogin }, dispatch] = useStateValue();
    let navigate = useNavigate();

    useEffect(() => {
        if (CIsLogin) {
            // navigate("/mitwank/dashboard");
        }
    }, [CIsLogin]);





    useEffect(() => {
        if (localStorage.getItem("CToken") !== null && localStorage.getItem("LoginDetails") === 'Clinic') {
            dispatch({
                type: "CLogin",
            });
        } else {
            dispatch({
                type: "CLogout",
            });
        }
    }, []);
    return (<>
        <Topbar />
        <Clinicheader />
        {CIsLogin ?
            <>
                <Breadcrumb />
                <div className="slidebar_and_component">
                    <ClinicSidebar />
                    <div className="outlet_main_div">
                        <Outlet />
                    </div>
                </div>
            </>

            : <Cliniclogin />}
        <Footer />
    </>)
}