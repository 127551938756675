import React from "react";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
function Receptionistprofilesetting() {
  const [name, setname] = useState("");
  const [Email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [Img, setImg] = useState("");
  const [showImg, setshowImg] = useState();
  let navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [number, setNumber] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [gender, setgender] = useState("");
  const [Activegender, setActivegender] = useState([false, false]);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  useEffect(() => {
    var Url = window.API_PREFIX + "admin/viewreceptionist";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        receptionistId: localStorage.getItem("receptionistId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        } else if (data["status"] === "1") {
          setshowImg(data["receptionistDetail"]["receptionistProfilePic"]);
          setImg(data["receptionistDetail"]["receptionistProfilePic"]);
          setname(data["receptionistDetail"]["receptionistName"]);
          setEmail(data["receptionistDetail"]["receptionistEmail"]);
          setNumber(data["receptionistDetail"]["receptionistNumber"]);
          setBirthDate(data["receptionistDetail"]["receptionistBirthDate"]);
          if (data["receptionistDetail"]["receptionistGender"] === "male") {
            setgender("male");
            setActivegender([true, false]);
          } else if (
            data["receptionistDetail"]["receptionistGender"] === "female"
          ) {
            setgender("female");
            setActivegender([false, true]);
          }
        }
      });
  }, []);

  const handleDateChangedate = (e) => {
    const selectedDate = e.target.value;
    setBirthDate(selectedDate);
    validateAge(selectedDate);
  };

  const validateAge = (selectedDate) => {
    const today = new Date();
    const birthDate = new Date(selectedDate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    if (age < 18) {
      toast.error("You must be at least 18 years old.");
      return false;
    } else {
      return true;
    }
  };

  const getMaxDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18);
    return today.toISOString().split("T")[0];
  };

  return (
    <main className="container-fuild mx-2">
      <div>
        <div className="Profilesettings_main_diviison">
          <div className="profilesettings_Upload_image">
            <div className="profilesettings_profile_image">
              <img
                className="profilesettings_profile_image_sizes"
                src={showImg}
              />
            </div>
            <div className="profilesettings_upload_button_main">
              <button
                type="button"
                className="profilesettings_upload_photo_button btn"
              >
                <i className="fa fa-upload profilesettings_upload_icon"></i>{" "}
                Upload Photo
                <input
                  type="file"
                  accept="image/gif, image/jpeg, image/png"
                  onChange={(e) => {
                    setImg(e.target.files[0]);

                    setshowImg(URL.createObjectURL(e.target.files[0]));
                  }}
                />
              </button>
              <br />
              <div className="profilesetting_upload_p">
                <p>Allowed JPG, GIF or PNG. Max size of 20MB</p>
              </div>
            </div>
          </div>
          <div className="profilesetting_input_form">
            <div className="row g-3 profilesetting_form">
              <div className="col-md-6 profilesettings_first_name">
                <label
                  for="inputfirstaname"
                  className="form-label profilesettings_label"
                >
                  Receptionist Name
                </label>
                <input
                  type="text"
                  className="form-control profilesettings_input"
                  placeholder="Enter Your Name"
                  id="inputfirstname"
                  value={name}
                  onChange={(e) => {
                    setname(e.target.value);
                  }}
                />
              </div>

              <div className="col-md-6 profilesettings_email">
                <label
                  for="inputemail"
                  className="form-label profilesettings_label"
                >
                  Email ID
                </label>
                <input
                  type="email"
                  className="form-control profilesettings_input"
                  placeholder="Enter Your Email Address"
                  id="inputemail"
                  value={Email}
                  disabled
                />
              </div>
              <div className="col-md-6 profilesettings_email">
                <label
                  for="inputemail"
                  className="form-label profilesettings_label"
                >
                  Number
                </label>
                <input
                  type="number"
                  className="form-control profilesettings_input"
                  placeholder="Enter Your number"
                  id="number"
                  value={number}
                />
              </div>
              <div className="col-md-6 profilesettings_email">
                <label className="form-label profilesettings_label">
                  Enter your birthdate
                </label>
                <input
                  label="dob"
                  type="date"
                  class="profilesettings_input form-control"
                  id="inputdateofbirth"
                  value={birthDate}
                  onChange={handleDateChangedate}
                  max={getMaxDate()}
                />
              </div>
              <div
                className="client_register_personal_gender_main_division"
                style={{ height: "40px" }}
              >
                <button
                  className={
                    Activegender[0]
                      ? "client_register_personal_male_btn_active"
                      : "client_register_personal_male_btn"
                  }
                  onClick={() => {
                    setgender("male");
                    setActivegender([true, false]);
                  }}
                >
                  <i class="fa-solid fa-mars-stroke"></i> Male
                </button>
                <button
                  className={
                    Activegender[1]
                      ? "client_register_personal_female_btn_active"
                      : "client_register_personal_female_btn"
                  }
                  onClick={() => {
                    setgender("female");
                    setActivegender([false, true]);
                  }}
                >
                  <i class="fa-solid fa-mars-stroke-up"></i> Female
                </button>
              </div>
              <div className="col-12 profilesettings_savechanges_button_main">
                <button
                  className="btn profilesettings_savechanges_button"
                  onClick={() => {
                    var Url = window.API_PREFIX + "admin/editreceptionist";

                    var uploadData = new FormData();
                    uploadData.append("Token", localStorage.getItem("DToken"));
                    uploadData.append(
                      "id",
                      localStorage.getItem("receptionistId")
                    );
                    uploadData.append("receptionistProfilePic", Img);
                    uploadData.append("receptionistname", name);
                    uploadData.append("receptionistNumber", number);
                    uploadData.append("receptionistBirthDate", birthDate);
                    uploadData.append(
                      "receptionistId",
                      localStorage.getItem("receptionistId")
                    );
                    uploadData.append(
                      "loginAs",
                      localStorage.getItem("LoginDetails")
                    );
                    uploadData.append("receptionistGender", gender);
                    fetch(Url, {
                      method: "POST",
                      // headers: {
                      //     'Content-type': 'application/json',
                      // },
                      body: uploadData,
                    })
                      .then((resp) => resp.json())
                      .then((data) => {
                        console.log(data);
                        if (data["status"] === "Block") {
                          toast.error(data["message"]);
                          localStorage.clear();
                          navigate("/Receptionist");
                          window.location.reload();
                        } else if (data["status"] === "1") {
                          toast.success("Profile detail change successfully");
                          if (data["FileUpdated"]) {
                            window.location.reload();
                          }
                        }
                      });
                  }}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Receptionistprofilesetting;
