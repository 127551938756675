// import './Schedule_Timings.css';
import "../../App.css";
import { useState } from "react";
import { useStateValue } from "../StateProviders";
import toast from "react-hot-toast";
import ClinicSidebar from "./ClinicSidebar";
// import './Sidebar.css'
import { useNavigate } from "react-router-dom";
function Schedule_Timings() {
  const WeekDay = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [Weekdayactive, setWeekdayActive] = useState(null);
  const [{ IsLogin }, dispatch] = useStateValue();
  const [TimeSlot, setTimeSlot] = useState([]);
  const [TimeSlotactive, setTimeSlotActive] = useState([]);
  const [SaveText, setSaveText] = useState(false);
  let navigate = useNavigate();
  return (
    <>

      <div className="container-fuild mx-2">
        <div className="schedule_timings_main_diviison">
          <div className="schedule_timings_diviison">
            <div className="schedule_timings_week_division">
              {WeekDay.map((eachDay, DayIndex) => {
                return (
                  <div
                    onClick={() => {
                      setSaveText(false);
                      var Url =
                        window.API_PREFIX +
                        "timing/showslot_advance_doctor";
                      fetch(Url, {
                        method: "POST",
                        headers: {
                          "Content-type": "application/json",
                        },
                        body: JSON.stringify({
                          Token: localStorage.getItem("DToken"),
                          Tag: DayIndex,
                          ClinicId: localStorage.getItem("clinicId"),
                          loginAs: localStorage.getItem("LoginDetails"),
                        }),
                      })
                        .then((resp) => resp.json())
                        .then((data) => {
                          console.log(data);
                          if (data["status"] === "Block") {
                            toast.error(data["message"]);
                            localStorage.clear();
                            navigate("/Clinic");
                            window.location.reload();
                          }
                          else if (data["status"] === "1") {
                            setTimeSlot([...data["TotalSlot"]]);
                            setTimeSlotActive([...data["ActiveSlot"]]);
                          }
                        });
                      setWeekdayActive(eachDay);
                    }}
                    className={
                      Weekdayactive === eachDay
                        ? "schedule_timings_week_name_active"
                        : "schedule_timings_week_name"
                    }
                  >
                    {eachDay}
                  </div>
                );
              })}
            </div>
            <div className="schedule_timings_time_slot_division">
              <div className="schedule_timings_time_slote_title">
                time slots
              </div>
            </div>

            <div className="schedule_timings_time_slot_table_division">
              {TimeSlot.map((eachTimeSlot, TimeSlotindex) => {
                return (
                  <div
                    className={
                      TimeSlotactive[TimeSlotindex]
                        ? "schedule_timings_time_slots_active"
                        : "schedule_timings_time_slots"
                    }
                    onClick={() => {
                      setSaveText(false);

                      var tmpFlag = TimeSlotactive;
                      tmpFlag[TimeSlotindex] = !tmpFlag[TimeSlotindex];
                      setTimeSlotActive([...tmpFlag]);
                    }}
                  >
                    {eachTimeSlot}
                  </div>
                );
              })}
            </div>
            <div className="schedule_timings_save_slot_button_division">
              <button
                type="button"
                class="btn schedule_timings_save_slot_button"
                onClick={() => {
                  var Url = window.API_PREFIX + "timing/update_slot";
                  fetch(Url, {
                    method: "POST",
                    headers: {
                      "Content-type": "application/json",
                    },
                    body: JSON.stringify({
                      Token: localStorage.getItem("DToken"),
                      DayTag: WeekDay.indexOf(Weekdayactive),
                      SlotActive: TimeSlotactive,
                      clinicId: localStorage.getItem("clinicId"),
                      loginAs: localStorage.getItem("LoginDetails"),
                    }),
                  })
                    .then((resp) => resp.json())
                    .then((data) => {
                      console.log(data);
                      if (data["status"] === "Block") {
                        toast.error(data["message"]);
                        localStorage.clear();
                        navigate("/Clinic");
                        window.location.reload();
                      }
                      else if (data["status"] === "1") {
                        toast.success("Saved Successfully");
                        setSaveText(true);
                      }
                    });
                }}
                disabled={SaveText}
              >
                {SaveText ? "Saved" : "Save Slot"}
              </button>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-3">
            <div>
              <i class="fa-solid fa-square me-2 available_1"></i>
              Availble
            </div>
            <div className="ms-3">
              <i class="fa-solid fa-square me-2 available_2"></i>
              Not Availble
            </div>
          </div>
        </div>
      </div>
      {/* <Toaster /> */}
    </>
  );
}

export default Schedule_Timings;
