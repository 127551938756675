import "./MitwankclientProfile.css";
import "../../App.css";
import "antd/dist/antd.css";
import { Table } from "antd";
import React, { useState, useEffect, useRef } from "react";
import Popup from "reactjs-popup";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "../Mitwank/Sidebar.css";
import { Popover } from "antd";
import { format } from "date-fns";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { SyncLoader } from "react-spinners";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../Loader";
import { ClipLoader } from "react-spinners";
import TextField from "@mui/material/TextField";
import image from "../../media/event6.jpg";

function Clinicsprofile() {
  const [FilterDate, setFilterDate] = useState([]);
  const [eventcategory, seteventCategory] = useState("");
  const [eventplace, seteventPlace] = useState("");
  const [eventtitle, setevntTitle] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [eventFees, seteventFees] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [loaderon, setloaderon] = useState(false);
  const [loaderonpay, setloaderonpay] = useState(false);
  const [frameworkData, setFrameworkData] = useState({
    bookingId: "",
    patientId: "",
    clinicId: "",
  });
  const [fileData, setFileData] = useState({
    bookingId: "",
    patientId: "",
    clinicId: "",
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    pageSizeOptions: ["5", "10", "20", "50", "100"],
    showSizeChanger: true,
  });
  const [OTPbuttonFlag, setOTPbuttonFlag] = useState(false);
  const [systemOTP, setsystemOTP] = useState(false);
  const [buttonFlag, setbuttonFlag] = useState(false);

  const [addprescribtion1, setaddprescribtion1] = useState(false);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = (page) => {
    setCurrentPage(page - 1);
    console.log("Current page:", page);
  };

  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const handleEntriesPerPageChange = (current, size) => {
    setEntriesPerPage(size);
    console.log("Entries per page:", size);
  };

  const addmedicalrecordpopup = {
    borderRadius: "10px",
    backgroundColor: "white",
    // boxshadow: "1 1 20px rgba(255, 255, 255, 0.4)",
    borderradius: "5px",
    padding: "0px",
    width: "100%",
    height: "max-content",
    margin: "60px 450px",
  };
  const addprescribtionpopup = {
    borderRadius: "10px",
    backgroundColor: "white",
    // boxshadow: "1 1 20px rgba(255, 255, 255, 0.4)",
    borderradius: "5px",
    padding: "0px",
    width: "100%",
    height: "max-content",
    margin: "60px 450px",
  };
  const addprescribtionpopup1 = {
    borderRadius: "10px",
    backgroundColor: "white",
    // boxshadow: "1 1 20px rgba(255, 255, 255, 0.4)",
    padding: "0px",
    width: "100%",
    height: "max-content",
    margin: "60px 450px",
  };
  const editpricepopup = {
    borderRadius: "10px",
    backgroundColor: "white",
    // boxshadow: "1 1 20px rgba(255, 255, 255, 0.4)",
    borderradius: "5px",
    padding: "0px",
    width: "100%",
    height: "max-content",
    margin: "60px 450px",
  };

  const params = useParams();
  let navigate = useNavigate();
  const { PID } = params;
  console.log("PID: ", PID);
  const { state } = useLocation();

  const [addMedicalRecord, setAddMedicalRecord] = useState(false);
  const [addInvoice, setaddInvoice] = useState(false);
  const [editpricePop, seteditpricePop] = useState(false);

  const [docAppointmentData, setdocAppointmentData] = useState([]);
  const [docPrescribeData, setdocPrescribeData] = useState([]);
  const [docReceptionistData, setdocReceptionistData] = useState([]);
  const [docMedicalData, setdocMedicalData] = useState([]);
  const [docBillingData, setdocBillingData] = useState([]);
  const [editPriceIndex, seteditPriceIndex] = useState("");
  const [eacheditprice, seteacheditprice] = useState("");

  const [TitleName, setTitleName] = useState();
  const [isRequiredTitleName, setIsRequiredTitleName] = useState(true);
  const [Symptoms, setSymptoms] = useState();
  const [isRequiredSymptoms, setIsRequiredSymptoms] = useState(true);
  const [RecordDate, setRecordDate] = useState("");
  const [MedicalRecord, setMedicalRecord] = useState();
  const [isRequiredMedicalRecord, setIsRequiredMedicalRecord] = useState(true);

  const [addprescribtion, setaddprescribtion] = useState(false);
  const [addprescribtion2, setaddprescribtion2] = useState(false);
  const [addreceptionist, setaddreceptionist] = useState(false);
  const [PrescName, setPrescName] = useState("");
  const [isRequiredPrescName, setIsRequiredPrescName] = useState(true);
  const [PrescRecordDate, setPrescRecordDate] = useState("");
  const [PrescRecord, setPrescRecord] = useState();
  const [isRequiredPrescRecord, setIsRequiredPrescRecord] = useState(true);

  const [Amount, setAmount] = useState();
  const [isRequiredAmount, setIsRequiredAmount] = useState(true);
  const [InvoiceDate, setInvoiceDate] = useState();
  const [isRequiredInvoiceDate, setIsRequiredInvoiceDate] = useState(true);
  const [InvoiceRecord, setInvoiceRecord] = useState();
  const [isRequiredInvoiceRecord, setIsRequiredInvoiceRecord] = useState(true);
  const [DashboardOption, setDashboardOption] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [timers, setTimers] = useState({});
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const [isModalOpenoffer, setIsModalOpenoffer] = useState(false);

  const [isReadMore, setIsReadMore] = useState({ id: "", open: false });

  const [eventList, setEventList] = useState([]);
  const [offerList, setOfferList] = useState([]);

  useEffect(() => {
    const timerIntervals = {};

    eventList.forEach((event, index) => {
      const updateTimer = () => {
        const targetDate = new Date(`${event.Date} ${event.time}`).getTime();
        const currentTime = new Date().getTime();
        const difference = targetDate - currentTime;

        if (difference > 0) {
          setTimers((prev) => ({
            ...prev,
            [index]: {
              days: Math.floor(difference / (1000 * 60 * 60 * 24)),
              hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
              minutes: Math.floor((difference / 1000 / 60) % 60),
              seconds: Math.floor((difference / 1000) % 60),
            },
          }));
        } else {
          clearInterval(timerIntervals[index]);
          setTimers((prev) => ({
            ...prev,
            [index]: "Time is over",
          }));
        }
      };
      timerIntervals[index] = setInterval(updateTimer, 1000);
      updateTimer();
    });

    return () => {
      Object.values(timerIntervals).forEach(clearInterval);
    };
  }, [eventList]);

  useEffect(() => {
    console.log(state);
    getappointment();
  }, []);

  function getappointment() {
    var Url = window.API_PREFIX + "admin/clinicwiseappointment";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        clinicId: PID,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          const APdata = [...data["appointment"]];
          const dataWithAPSr = APdata.map((item, index) => ({
            ...item,
            srNo: APdata.length - index,
          }));
          console.log("P");
          setdocAppointmentData(dataWithAPSr);
        }
      });
  }

  function TrashDelete(Requestindex, BookingId) {
    var Url = window.API_PREFIX + "booking/trash_booking";
    const index = docAppointmentData.findIndex(
      (appointment) => appointment.BookingId === BookingId
    );
    console.log(docAppointmentData[index]);
    // dispatch({
    //     type: 'StartLoad'
    // })
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docAppointmentData[index]["BookingId"],
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        // dispatch({
        //     type: 'StopLoad'
        // })
        if (data["status"] === "1") {
          getappointment();
        }
      });
  }

  function delete_medicalrecord(Requestindex, id) {
    var Url = window.API_PREFIX + "admin/trash_delete_files";
    const index = docMedicalData.findIndex((records) => records.id === id);

    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docMedicalData[index]["id"],
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          getmedicalrecord();
        }
      });
  }

  function delete_prescription(Requestindex, id) {
    var Url = window.API_PREFIX + "admin/trash_delete_framework";
    const index = docPrescribeData.findIndex((data) => data.id === id);
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docPrescribeData[index]["id"],
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          getprescription();
        }
      });
  }

  function delete_billing(Requestindex, id) {
    var Url = window.API_PREFIX + "admin/trash_delete_invoice";
    const index = docBillingData.findIndex((Bill) => Bill.id === id);
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docBillingData[index]["id"],
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          getbilling();
        }
      });
  }

  function delete_receptionist(Requestindex, id) {
    var Url = window.API_PREFIX + "admin/trashdeletereceptionist";
    const index = docReceptionistData.findIndex((records) => records.id === id);

    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docReceptionistData[index]["id"],
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          getReceptionist();
        }
      });
  }

  function addprescription() {
    var Url = window.API_PREFIX + "admin/add_prescription";
    setloaderon(true);
    var uploadData = new FormData();
    uploadData.append("Token", localStorage.getItem("DToken"));
    uploadData.append("Name", PrescName);
    uploadData.append("PrescRecordDate", PrescRecordDate);
    uploadData.append("Attachment", PrescRecord);
    uploadData.append("clinicId", PID);
    uploadData.append("PatientId", frameworkData?.patientId);
    uploadData.append("bookingId", frameworkData?.bookingId);

    fetch(Url, {
      method: "POST",
      // headers: {
      //     'Content-type': 'application/json',
      // },
      body: uploadData,
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setloaderon(false);
          getprescription();
          toast.success("Offers & Events Added");
          setaddprescribtion(false);
        }
      });
  }

  function getmedicalrecord() {
    var Url = window.API_PREFIX + "admin/clinicwisemedicalrecords";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        clinicId: PID,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setdocMedicalData([...data["records"]]);
        }
      });
  }

  function getEventList(clinicId) {
    var Url = window.API_PREFIX + "admin/allevents";
    if (clinicId) {
      Url += `?clinicId=${clinicId}`;
    }
    fetch(Url, {
      method: "GET",
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setEventList([...data["Events"]]);
        }
      });
  }

  function getOfferList(clinicId) {
    var Url = window.API_PREFIX + "admin/alloffers";
    if (clinicId) {
      Url += `?clinicId=${clinicId}`;
    }
    fetch(Url, {
      method: "GET",
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setOfferList([...data["Offers"]]);
        }
      });
  }

  function getReceptionist() {
    var Url = window.API_PREFIX + "admin/receptionistlist";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        clinicId: PID,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          const receptionistData = [...data["receptionistList"]];
          const dataWithSrNo = receptionistData.map((item, index) => ({
            ...item,
            srNo: receptionistData.length - index,
          }));
          setdocReceptionistData(dataWithSrNo);
        }
      });
  }

  function getprescription() {
    var Url = window.API_PREFIX + "admin/clinicwiseprescription";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        clinicId: PID,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          const PrescribeData = [...data["data"]];
          const dataWithSrNo = PrescribeData.map((item, index) => ({
            ...item,
            srNo: PrescribeData.length - index,
          }));
          setdocPrescribeData(dataWithSrNo);
        }
      });
  }

  function getbilling() {
    var Url = window.API_PREFIX + "admin/clinicwisebillings";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        clinicId: PID,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setdocBillingData([...data["Bill"]]);
        }
      });
  }

  function Pending_Payment(Paymentindex, BookingId) {
    var Url = window.API_PREFIX + "admin/Paybydoctor";
    const index = docAppointmentData.findIndex(
      (appointment) => appointment.BookingId === BookingId
    );
    setloaderonpay({ ...loaderonpay, [Paymentindex]: true });
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docAppointmentData[index]["BookingId"],
        PatientId: PID,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setloaderonpay({ ...loaderonpay, [Paymentindex]: false });
          var tmp = docAppointmentData;
          tmp[index]["Status"] = 2;
          setdocAppointmentData([...tmp]);
        }
      });
  }

  function AcceptRequest(Requestindex, BookingId) {
    var Url = window.API_PREFIX + "booking/approve_booking";
    const index = docAppointmentData.findIndex(
      (appointment) => appointment.BookingId === BookingId
    );
    setloaderon({ ...loaderon, [Requestindex]: true });
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docAppointmentData[index]["BookingId"],
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setloaderon({ ...loaderon, [Requestindex]: false });
          var tmp = docAppointmentData;
          tmp[index]["Status"] = 1;
          setdocAppointmentData([...tmp]);
        }
      });
  }

  function RejectRequest(Requestindex, BookingId) {
    var Url = window.API_PREFIX + "booking/reject_booking";
    const index = docAppointmentData.findIndex(
      (appointment) => appointment.BookingId === BookingId
    );
    setloaderon({ ...loaderon, [Requestindex]: true });
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docAppointmentData[index]["BookingId"],
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setloaderon({ ...loaderon, [Requestindex]: false });
          var tmp = docAppointmentData;
          tmp[index]["Status"] = -1;
          setdocAppointmentData([...tmp]);
        }
      });
  }

  const doctor_patient_profile_columns = [
    {
      title: "Sr. No",
      dataIndex: "srNo",
      render(text, record, index) {
        return {
          props: {
            style: { fontSize: "16px", textAlign: "center", color: "#483a15" },
          },
          children: <div style={{ fontWeight: "600" }}>{record.srNo}</div>,
        };
      },
    },
    {
      title: "Booking Date",
      dataIndex: "BookingDate",
      key: "address",
      render: (text) => {
        const formattedDate = format(new Date(text), "dd-MM-yyyy");
        return (
          <>
            <div className="client_dashboard_table_appdate_date">
              {formattedDate}
            </div>
          </>
        );
      },
    },
    {
      title: "Appt Date",
      dataIndex: "Date",
      key: "age",
      render: (text, record) => {
        const formattedDate = format(new Date(text), "dd-MM-yyyy");
        return (
          <>
            <div className="mitwank_patient_profile_table_datetime_div">
              <div className="mitwank_patient_profile_table_appdate_date">
                {formattedDate}
              </div>
              <div className="mitwank_patient_profile_table_appdate_time">
                {record["Slot"]}
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "Patient",
      key: "patient",
      render: (text, record, index) => (
        <div>{record?.patientDetails?.patientName}</div>
      ),
    },

    // {
    //   title: "Purpose",
    //   dataIndex: "Purpose",
    //   key: "Purpose",
    // },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "address",
    },

    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (text, record, index) => (
        <>
          {text === 0 ? (
            <>
              <div className="d-flex">
                {loaderon[index] ? (
                  <Loader />
                ) : (
                  <button
                    className="btn mitwank_patient_profile_table_confirm_btn"
                    onClick={() => {
                      // var INDEX = currentPage * entriesPerPage + index
                      AcceptRequest(index, record.BookingId);
                    }}
                  >
                    Accept
                  </button>
                )}

                {loaderon[index] ? (
                  <Loader />
                ) : (
                  <button
                    className="btn patient_dashboard_table_reject_btn"
                    onClick={() => {
                      // var INDEX = currentPage * entriesPerPage + index
                      RejectRequest(index, record.BookingId);
                    }}
                  >
                    Reject
                  </button>
                )}
              </div>
            </>
          ) : text === 1 || text === 2 ? (
            <button
              className="btn patient_dashboard_table_confirm_btn"
              disabled
            >
              Accepted
            </button>
          ) : text === -1 ? (
            <button className="btn patient_dashboard_table_reject_btn" disabled>
              Rejected
            </button>
          ) : null}
        </>
      ),
    },
    {
      title: "Payment",
      key: "tags",
      dataIndex: "tags",
      render: (text, record, index) => (
        <>
          {record["Status"] === 1 ? (
            <>
              {loaderonpay[index] ? (
                <Loader />
              ) : (
                <button
                  className="mitwank_patient_profile_table_edit_btn me-2"
                  onClick={() => {
                    // var INDEX = currentPage * entriesPerPage + index
                    const INDEX = docAppointmentData.findIndex(
                      (appointment) =>
                        appointment.BookingId === record.BookingId
                    );
                    seteditpricePop(true);
                    seteditPriceIndex(INDEX);
                    seteacheditprice(record["Amount"]);
                  }}
                >
                  <i class="fa-solid fa-pen-to-square"></i> Edit Price
                </button>
              )}

              {loaderonpay[index] ? (
                <Loader />
              ) : (
                <Popover
                  content={
                    <div className="d-flex justify-content-between">
                      <a onClick={pophide}>No</a>
                      <a
                        onClick={() => {
                          Pending_Payment(index, record.BookingId);
                          pophide();
                        }}
                      >
                        Yes
                      </a>
                    </div>
                  }
                  title="Are you want to sure ?"
                  trigger="click"
                  open={activePopoverIndex === index}
                  onOpenChange={(open) => handlepopOpenChange(index, open)}
                >
                  <button className=" btn admin_dashboard_tabel_view_btn">
                    {" "}
                    Pending Payment
                  </button>
                </Popover>
              )}
            </>
          ) : record["Status"] === 2 ? (
            <button
              className="btn patient_dashboard_table_confirm_btn"
              disabled
            >
              Paid
            </button>
          ) : record["Status"] === 0 ? (
            <>
              <button
                className="mitwank_patient_profile_table_edit_btn"
                onClick={() => {
                  // var INDEX = currentPage * entriesPerPage + index
                  seteditpricePop(true);
                  const INDEX = docAppointmentData.findIndex(
                    (appointment) => appointment.BookingId === record.BookingId
                  );
                  seteditPriceIndex(INDEX);
                  seteacheditprice(record["Amount"]);
                }}
              >
                <i class="fa-solid fa-pen-to-square"></i> Edit Price
              </button>
              <button
                className="btn mitwank_patient_profile_table_Pending_btn mt-1"
                disabled
              >
                Pending Request
              </button>
            </>
          ) : record["Status"] === -1 ? (
            <button className="btn patient_dashboard_table_reject_btn" disabled>
              N/A
            </button>
          ) : null}
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (text, record, index) => (
        <div>
          {/* {record?.Status === 2 && (
            <i
              class="fa-solid fa-upload"
              style={{ color: "#743A87" }}
              onClick={() => {
                setaddprescribtion(true);
                setFrameworkData({
                  bookingId: record?.BookingId,
                  patientId: record?.patientDetails?.patientId,
                  clinicId: record?.ClinicDetails?.ClinicId,
                });
              }}
            ></i>
          )} */}
          {/* {record?.Status === 2 && (
            <i
              class="fa-solid fa-folder mx-2"
              style={{ color: "#743A87" }}
              onClick={() => {
                setAddMedicalRecord(true);
                setFileData({
                  bookingId: record?.BookingId,
                  patientId: record?.patientDetails?.patientId,
                  clinicId: record?.ClinicDetails?.ClinicId,
                });
              }}
            ></i>
          )} */}

          <i
            class="fa-solid fa-trash-can mx-2"
            style={{ color: "#e11f28" }}
            onClick={() => {
              TrashDelete(index, record.BookingId);
            }}
          ></i>
        </div>
      ),
    },
  ];

  const Prescribecolumns = [
    {
      title: "Sr. No",
      dataIndex: "srNo",
      render(text, record, index) {
        return {
          props: {
            style: { fontSize: "16px", textAlign: "center", color: "#483a15" },
          },
          children: <div style={{ fontWeight: "600" }}>{record.srNo}</div>,
        };
      },
    },
    {
      title: "Clinic",
      key: "nameClinic",
      render: (text, record, index) => (
        <div>{record?.ClinicDetails?.ClinicName}</div>
      ),
    },
    {
      title: "Patient",
      key: "patient",
      render: (text, record, index) => (
        <div>{record?.patientDetails?.patientName}</div>
      ),
    },
    // {
    //   title: "Appt Date",
    //   key: "nameClinic",
    //   render: (text, record, index) => (
    //     <div>{record?.BookingDetails?.bookingApptDate}</div>
    //   ),
    // },
    {
      title: " Name",
      dataIndex: "Name",
      key: "name",
      render: (text, record, index) => <div>{text}</div>,
    },

    {
      title: "Date",
      dataIndex: "Date",
      key: "date",
      render: (text) => {
        const formattedDate = format(new Date(text), "dd-MM-yyyy");
        return (
          <>
            <div className="client_dashboard_table_appdate_date">
              {formattedDate}
            </div>
          </>
        );
      },
    },

    {
      title: "Attachment",
      dataIndex: "Attachment",
      key: "Attachment",
      render: (text) => (
        <>
          <a href={text} target="_blank">
            {" "}
            <button className="patient_dashboard_table_view_btn">
              <i class="fa-regular fa-eye"></i> View
            </button>
          </a>
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (text, record, index) => (
        <>
          <i
            class="fa-solid fa-trash-can"
            style={{ color: "#e11f28" }}
            onClick={() => {
              delete_prescription(index, record.id);
            }}
          ></i>
        </>
      ),
    },
  ];

  const Medicalcolumn = [
    {
      title: "Record ID",
      dataIndex: "id",
      key: "ID",
    },
    {
      title: "Clinic",
      key: "nameClinic",
      render: (text, record, index) => (
        <div>{record?.ClinicDetails?.ClinicName}</div>
      ),
    },
    {
      title: "Patient",
      key: "patient",
      render: (text, record, index) => (
        <div>{record?.patientDetails?.patientName}</div>
      ),
    },
    // {
    //   title: "Appt Date",
    //   key: "nameClinic",
    //   render: (text, record, index) => (
    //     <div>{record?.BookingDetails?.bookingApptDate}</div>
    //   ),
    // },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
      render: (text) => {
        const formattedDate = format(new Date(text), "dd-MM-yyyy");
        return (
          <>
            <div className="client_dashboard_table_appdate_date">
              {formattedDate}
            </div>
          </>
        );
      },
    },
    {
      title: "Title Name",
      dataIndex: "TitleName",
      key: "TitleName",
    },
    {
      title: "Remarks",
      dataIndex: "Symptoms",
      key: "Description",
    },
    {
      title: "Attachment",
      dataIndex: "Attachment",
      key: "Attachment",
      render: (text) => (
        <>
          <a href={text} target="_blank">
            {" "}
            <button className="patient_dashboard_table_view_btn">
              <i class="fa-regular fa-eye"></i> View
            </button>
          </a>
        </>
      ),
    },
    {
      title: "Created",
      dataIndex: "CreateBy",
      key: "Created",
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (text, record, index) => (
        <>
          <i
            class="fa-solid fa-trash-can"
            style={{ color: "#e11f28" }}
            onClick={() => {
              delete_medicalrecord(index, record.id);
            }}
          ></i>
        </>
      ),
    },
  ];

  const BillingColumn = [
    {
      title: "Invoice No",
      dataIndex: "Invoice",
      key: "InvoiceNo",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
    {
      title: "Paid On Date",
      dataIndex: "Paidon",
      key: "PaidOn",
      render: (text) => {
        const formattedDate = format(new Date(text), "dd-MM-yyyy");
        return (
          <>
            <div className="client_dashboard_table_appdate_date">
              {formattedDate}
            </div>
          </>
        );
      },
    },
    {
      title: "Attachment",
      key: "Attachment",
      dataIndex: "Attachment",
      render: (text) => {
        return (
          <>
            <button className="client_dashboard_table_print_btn me-2">
              <i class="fa-solid fa-print"></i> Print
            </button>
            <a href={text} target="_blank">
              {" "}
              <button className="client_dashboard_table_view_btn">
                <i class="fa-regular fa-eye"></i> View
              </button>
            </a>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (text, record, index) => (
        <>
          <i
            class="fa-solid fa-trash-can"
            style={{ color: "#e11f28" }}
            onClick={() => {
              delete_billing(index, record.id);
            }}
          ></i>
        </>
      ),
    },
  ];

  const receptionistcolumn = [
    {
      title: "Sr No",
      dataIndex: "srNo",
      render(text, record, index) {
        return {
          props: {
            style: { fontSize: "16px", textAlign: "center", color: "#483a15" },
          },
          children: <div style={{ fontWeight: "600" }}>{record.srNo}</div>,
        };
      },
    },
    {
      title: "Receptionist Name",
      dataIndex: "Name",
      key: "name",
      render: (text, record, index) => <div>{record?.receptionistName}</div>,
    },
    {
      title: "Receptionist Email",
      dataIndex: "Name",
      key: "name",
      render: (text, record, index) => <div>{record?.receptionistEmail}</div>,
    },
    {
      title: "Action",
      dataIndex: "Actions",
      key: "Actions",
      render: (text, record, index) => (
        <div className="d-flex justify-content-center align-items-center">
          <i
            class="fa-solid fa-pen me-2"
            style={{ color: "green" }}
            onClick={() => {
              setIsModalOpen2(true);
              setSelectedId(record?.id);
              setrecename(record?.receptionistName);
              setmail(record?.receptionistEmail);
              setpassword(record?.receptionistPassword);
              setImg(record?.receptionistProfilePic);
              setshowImg(record?.receptionistProfilePic);
              setnumber(record?.receptionistNumber);
              setBirthDate(record?.receptionistBirthDate);
              if (record?.receptionistGender === "male") {
                setgender("male");
                setActivegender([true, false]);
              } else if (record?.receptionistGender === "female") {
                setgender("female");
                setActivegender([false, true]);
              }
            }}
          ></i>
          <i
            class="fa-solid fa-trash-can me-2"
            style={{ color: "#e11f28" }}
            onClick={() => {
              delete_receptionist(index, record.id);
            }}
          ></i>

          <div class="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              checked={record.IsBlocked === 1}
              onChange={(e) => handleToggleUser(record.id, e.target.checked)}
            />
          </div>
        </div>
      ),
    },
  ];

  const [recename, setrecename] = useState();
  const [mail, setmail] = useState("");
  const [number, setnumber] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [selectedId, setSelectedId] = useState();
  const handleOutsideClick2 = (event) => {
    if (event.target.id === "myModal") {
      closeModal2();
    }
  };

  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const handleToggleUser = async (id, isActive) => {
    console.log("isActiveRec: ", isActive);
    console.log("idRec: ", id);
    if (isActive === true) {
      await blockReceptionist(id);
    } else {
      await unblockReceptionist(id);
    }
  };

  const handleDateChangedate = (e) => {
    const selectedDate = e.target.value;
    setBirthDate(selectedDate);
    validateAge(selectedDate);
  };

  const getMaxDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18);
    return today.toISOString().split("T")[0];
  };

  const validateAge = (selectedDate) => {
    const today = new Date();
    const birthDate = new Date(selectedDate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    if (age < 18) {
      toast.error("You must be at least 18 years old.");
      return false;
    } else {
      return true;
    }
  };

  // Blocking API call
  const blockReceptionist = async (id) => {
    var Url = window.API_PREFIX + "admin/blockreceptionist";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Id: id,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        // dispatch({
        //     type: 'StopLoad'
        // })
        if (data["status"] === "1") {
          toast.success("Receptionist is Block Successfully");
          getReceptionist();
        }
      });
  };

  // Unblocking API call
  const unblockReceptionist = async (id) => {
    var Url = window.API_PREFIX + "admin/unblockreceptionist";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Id: id,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        // dispatch({
        //     type: 'StopLoad'
        // })
        if (data["status"] === "1") {
          toast.success("Receptionist is Un Block Successfully");
          getReceptionist();
        }
      });
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const [clinicname, setclinicname] = useState("");
  const [Email, setEmail] = useState("");
  const [Phnumber, setPhnumber] = useState("");
  const [Img, setImg] = useState("");
  const [Timing, setTiming] = useState("");
  const [Address, setAddress] = useState("");
  const [OtherInfo, setOtherInfo] = useState("");
  const [showImg, setshowImg] = useState();
  const [password, setpassword] = useState("");
  const [profile, setprofile] = useState("");
  const [receptionistname, setreceptionistname] = useState("");
  const [receptionistmail, setreceptionistmail] = useState("");
  const [receptionistpassword, setreceptionistpassword] = useState("");
  const [gender, setgender] = useState("");
  const [receptionistNumber, setreceptionistNumber] = useState("");
  const [receptionistBirthdate, setreceptionistBirthdate] = useState("");
  const [Activegender, setActivegender] = useState([false, false]);

  const checkSubmitBtnDisable = () => {
    if (!isRequiredPrescName && !isRequiredPrescRecord) {
      return false;
    } else {
      return true;
    }
  };

  const submitbtndisable = () => {
    if (
      !isRequiredTitleName &&
      !isRequiredMedicalRecord &&
      !isRequiredSymptoms
    ) {
      return false;
    } else {
      return true;
    }
  };

  const invoicesubmitbtndisable = () => {
    if (
      !isRequiredAmount &&
      !isRequiredInvoiceDate &&
      !isRequiredInvoiceRecord
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    var Url = window.API_PREFIX + "admin/viewclinicprofile";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        clinicId: PID,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setshowImg(data["clinicProfilePic"]);
          setImg(data["clinicProfilePic"]);
          setclinicname(data["clinicName"]);
          setAddress(data["clinicAddress"]);
          setTiming(data["clinicTiming"]);
          setpassword(data["clinicPassword"]);
          setPhnumber(data["clinicMobile"]);
          setEmail(data["clinicEmail"]);
          setOtherInfo(data["clinicDescription"]);
        }
      });
  }, []);

  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  useEffect(() => {
    const Url = window.API_PREFIX + "admin/allclinics";
    fetch(Url, {
      method: "GET",
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setBranches([...data["allClinicList"]]);
        }
      });
  }, []);

  const handleSelectChange = (event) => {
    setSelectedBranch(event.target.value);
  };

  const [activePopoverIndex, setActivePopoverIndex] = useState(null);

  const handlepopOpenChange = (index, open) => {
    if (open) {
      setActivePopoverIndex(index);
    } else {
      setActivePopoverIndex(null);
    }
  };

  const pophide = () => {
    setActivePopoverIndex(null);
  };

  const [searchQuery, setSearchQuery] = useState("");

  function addEvent() {
    var Url = window.API_PREFIX + "admin/addevents";
    var uploadData = new FormData();
    uploadData.append("Token", localStorage.getItem("DToken"));
    uploadData.append("clinicId", PID);
    uploadData.append("title", eventtitle);
    uploadData.append("description", eventDescription);
    uploadData.append("time", eventTime);
    uploadData.append("fees", eventFees);
    uploadData.append("Attachment", PrescRecord);
    uploadData.append("Date", FilterDate);

    fetch(Url, {
      method: "POST",
      body: uploadData,
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setaddprescribtion2(false);
          getEventList(PID);
          toast.success("Added Successfully");
        }
      });
  }

  const modalRef = useRef(null);
  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setIsModalOpen1(null);
    }
  };

  useEffect(() => {
    if (isModalOpen1 !== null) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isModalOpen1]);

  const [otpsuccess, setotpsuccess] = useState(false);

  return (
    <>
      <div className="container-fuild mx-2">
        <div className="component_division_mitwank_patient_profile">
          <div
            onClick={() => {
              navigate("/mitwank/myclinic");
            }}
          >
            <button className="btn client_back_button">
              <i class="fa-solid fa-arrow-left back_icon"></i>Back
            </button>
          </div>

          <div className="mitwank_patient_profile_main_division">
            <div class="client_bashboard_tab_division">
              <div
                className={
                  DashboardOption[0]
                    ? "col client_bashboard_tabs_title_name_division_active"
                    : "col client_bashboard_tabs_title_name_division"
                }
                onClick={() => {
                  setDashboardOption([
                    true,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                  ]);
                }}
              >
                <div class="client_bashboard_tabs_title_name">
                  {" "}
                  Clinic Profile
                </div>
              </div>

              <div
                className={
                  DashboardOption[1]
                    ? "col client_bashboard_tabs_title_name_division_active"
                    : "col client_bashboard_tabs_title_name_division"
                }
                onClick={() => {
                  getappointment();
                  setDashboardOption([
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                    false,
                  ]);
                }}
              >
                <div class="client_bashboard_tabs_title_name">Appointments</div>
              </div>

              <div
                className={
                  DashboardOption[2]
                    ? "col client_bashboard_tabs_title_name_division_active"
                    : "col client_bashboard_tabs_title_name_division"
                }
                onClick={() => {
                  getOfferList(PID);
                  setDashboardOption([
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                    false,
                  ]);
                }}
              >
                <div class="client_bashboard_tabs_title_name">Offers</div>
              </div>
              <div
                className={
                  DashboardOption[3]
                    ? "col client_bashboard_tabs_title_name_division_active"
                    : "col client_bashboard_tabs_title_name_division"
                }
                onClick={() => {
                  getmedicalrecord();
                  setDashboardOption([
                    false,
                    false,
                    false,
                    true,
                    false,
                    false,
                    false,
                  ]);
                }}
              >
                <div class="client_bashboard_tabs_title_name">
                  Client's Share
                </div>
              </div>
              <div
                className={
                  DashboardOption[4]
                    ? "col client_bashboard_tabs_title_name_division_active"
                    : "col client_bashboard_tabs_title_name_division"
                }
                onClick={() => {
                  getbilling();
                  setDashboardOption([
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                    false,
                  ]);
                }}
              >
                <div class="client_bashboard_tabs_title_name">Billing</div>
              </div>
              <div
                className={
                  DashboardOption[5]
                    ? "col client_bashboard_tabs_title_name_division_active"
                    : "col client_bashboard_tabs_title_name_division"
                }
                onClick={() => {
                  getReceptionist();
                  setDashboardOption([
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                  ]);
                }}
              >
                <div class="client_bashboard_tabs_title_name">Receptionist</div>
              </div>

              <div
                className={
                  DashboardOption[6]
                    ? "col client_bashboard_tabs_title_name_division_active"
                    : "col client_bashboard_tabs_title_name_division"
                }
                onClick={() => {
                  getEventList(PID);
                  setDashboardOption([
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                  ]);
                }}
              >
                <div class="client_bashboard_tabs_title_name">
                  HF events by VDo
                </div>
              </div>
            </div>

            {DashboardOption[0] ? (
              <div>
                <div className="Profilesettings_main_diviison">
                  <div className="profilesettings_Upload_image">
                    <div className="profilesettings_profile_image">
                      <img
                        className="profilesettings_profile_image_sizes"
                        src={showImg}
                      />
                    </div>
                    <div className="profilesettings_upload_button_main">
                      <button
                        type="button"
                        className="profilesettings_upload_photo_button btn"
                      >
                        <i className="fa fa-upload profilesettings_upload_icon"></i>{" "}
                        Upload Photo
                        <input
                          type="file"
                          accept="image/gif, image/jpeg, image/png"
                          onChange={(e) => {
                            setImg(e.target.files[0]);

                            setshowImg(URL.createObjectURL(e.target.files[0]));
                          }}
                        />
                      </button>
                      <br />
                      {/* ... */}
                      <div className="profilesetting_upload_p">
                        <p>Allowed JPG, GIF or PNG. Max size of 20MB</p>
                      </div>
                      {/* .. */}
                    </div>
                  </div>
                  <div className="profilesetting_input_form">
                    <div className="row g-3 profilesetting_form">
                      <div className="col-md-6 profilesettings_first_name">
                        <label
                          for="inputfirstaname"
                          className="form-label profilesettings_label"
                        >
                          Clinic Name
                        </label>
                        <input
                          type="text"
                          className="form-control profilesettings_input"
                          placeholder="Clinic Name"
                          id="inputfirstname"
                          value={clinicname}
                          onChange={(e) => {
                            setclinicname(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-md-6 profilesettings_mobile">
                        <label
                          for="inputmobile"
                          className="form-label profilesettings_label"
                        >
                          Mobile Number
                        </label>
                        <input
                          type="textfield"
                          className="form-control profilesettings_input"
                          id="inputmobile"
                          placeholder="Mobile Number"
                          value={Phnumber}
                          onChange={(e) => {
                            setPhnumber(e.target.value);
                          }}
                        />
                      </div>

                      <div className="col-md-6 profilesettings_email">
                        <label
                          for="inputemail"
                          className="form-label profilesettings_label"
                        >
                          Email ID
                        </label>
                        <input
                          type="email"
                          className="form-control profilesettings_input"
                          placeholder="Email Address"
                          id="inputemail"
                          disabled
                          value={Email}
                        />
                      </div>

                      <div className="col-md-6 profilesettings_email">
                        <label
                          for="inputemail"
                          className="form-label profilesettings_label"
                        >
                          Timing
                        </label>
                        <input
                          type="email"
                          className="form-control profilesettings_input"
                          placeholder="Timing"
                          id="inputemail"
                          value={Timing}
                          onChange={(e) => {
                            setTiming(e.target.value);
                          }}
                        />
                      </div>

                      <div className="col-md-6">
                        <label className="form-label profilesettings_label">
                          Password
                        </label>
                        <FormControl
                          style={{ width: "100%" }}
                          variant="outlined"
                          className="mt-2"
                        >
                          {password === "" && (
                            <InputLabel htmlFor="outlined-adornment-password">
                              Password
                            </InputLabel>
                          )}
                          <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                            value={password}
                            onChange={(e) => {
                              setpassword(e.target.value);
                            }}
                          />
                        </FormControl>
                      </div>

                      <div className="col-12 profilesettings_address">
                        <label
                          for="inputAddress"
                          className="form-label profilesettings_label"
                        >
                          Address
                        </label>
                        <textarea
                          className="form-control profilesettings_address_textarea"
                          placeholder="Address"
                          id="floatingTextarea"
                          value={Address}
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                        ></textarea>
                      </div>

                      <div className="col-12 profilesettings_other_information">
                        <label
                          for="inputotherinformation"
                          className="form-label profilesettings_label"
                        >
                          Other Information
                        </label>
                        <textarea
                          className="form-control profilesettings_address_textarea"
                          id="floatingTextarea"
                          placeholder="Other Information"
                          value={OtherInfo}
                          onChange={(e) => {
                            setOtherInfo(e.target.value);
                          }}
                        ></textarea>
                      </div>
                      <div className="col-12 profilesettings_savechanges_button_main">
                        <button
                          // type="submit"
                          className="btn profilesettings_savechanges_button"
                          onClick={() => {
                            var Url = window.API_PREFIX + "admin/editclinic";

                            var uploadData = new FormData();
                            uploadData.append(
                              "Token",
                              localStorage.getItem("DToken")
                            );
                            uploadData.append("clinicId", PID);
                            uploadData.append("clinicProfilePic", Img);
                            uploadData.append("clinicName", clinicname);
                            uploadData.append("clinicAddress", Address);
                            uploadData.append("clinicTiming", Timing);
                            uploadData.append("clinicPassword", password);
                            uploadData.append("clinicMobile", Phnumber);
                            uploadData.append("clinicDescription", OtherInfo);
                            fetch(Url, {
                              method: "POST",
                              // headers: {
                              //     'Content-type': 'application/json',
                              // },
                              body: uploadData,
                            })
                              .then((resp) => resp.json())
                              .then((data) => {
                                console.log(data);
                                if (data["status"] === "1") {
                                  toast.success(
                                    "Profile detail change successfully"
                                  );
                                  if (data["FileUpdated"]) {
                                    window.location.reload();
                                  }
                                }
                              });
                          }}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : DashboardOption[1] ? (
              <div className="mitwank_patient_profile_table_division">
                <Table
                  columns={doctor_patient_profile_columns}
                  dataSource={docAppointmentData}
                  pagination={pagination}
                  onChange={handleTableChange}
                />
              </div>
            ) : DashboardOption[2] ? (
              <>
                {" "}
                <div
                  className="mitwank_patient_profile_add_prescription_division"
                  onClick={() => {
                    // navigate("/mitwank/addprescription", {
                    //     state: {
                    //         PID: PID,
                    //         ADDType: true

                    //     }
                    // })
                    setaddprescribtion1(true);
                  }}
                >
                  <button className="mitwank_patient_profile_add_prescription_btn">
                    + Add Offers
                  </button>
                </div>
                <div className="row mt-4 container ">
                  {offerList?.map((item, i) => (
                    <div
                      className="col col-12 col-sm-4 col-md-4 gx-2 gy-2"
                      key={i}
                    >
                      <div
                        key={i}
                        style={{
                          borderRadius: "10px",
                          overflow: "hidden",
                          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                        }}
                      >
                        <div
                          style={{
                            backgroundImage: `url(${image})`,
                            backgroundSize: "cover",
                            height: "200px",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "10px",
                              display: "flex",
                              gap: "5px",
                            }}
                          ></div>
                        </div>
                        <div style={{ padding: "20px" }}>
                          <div className="d-flex align-items-center justify-content-between">
                            <h3
                              style={{
                                margin: "0 0 10px 0",
                                fontSize: "18px",
                                textAlign: "start",
                              }}
                            >
                              {item?.title}
                            </h3>
                            <h3
                              style={{
                                margin: "0 0 10px 0",
                                fontSize: "18px",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setIsModalOpen1(i);
                                setSelectedRow(item);
                              }}
                            >
                              {item?.ClinicDetails?.ClinicName}
                            </h3>
                          </div>

                          {isModalOpen1 === i && (
                            <div
                              style={{
                                position: "fixed",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                ref={modalRef}
                                style={{
                                  backgroundColor: "#fff",
                                  padding: "20px",
                                  borderRadius: "10px",
                                  width: "300px",
                                  textAlign: "center",
                                  position: "relative",
                                }}
                              >
                                <div
                                  className="d-flex align-items-center justify-content-center me-2 "
                                  style={{ color: "black" }}
                                >
                                  <img
                                    className="image_div"
                                    style={{ justifyItems: "center" }}
                                    src={
                                      selectedRow?.ClinicDetails
                                        ?.clinicProfilePic
                                    }
                                  ></img>
                                </div>
                                <div
                                  className="new_card_client_name"
                                  style={{ color: "black", fontSize: "20px" }}
                                >
                                  {selectedRow?.ClinicDetails?.ClinicName}
                                </div>
                                <div
                                  className="other_info mb-2"
                                  style={{ color: "black" }}
                                >
                                  <div>
                                    <i className="fa-solid fa-phone me-2"></i>
                                    {selectedRow?.ClinicDetails?.ClinicContact}
                                  </div>
                                  <div>
                                    <i className="fa-solid fa-location-dot me-2"></i>
                                    {selectedRow?.ClinicDetails?.ClinicAddress}
                                  </div>
                                  <div>
                                    <i class="fa-solid fa-at me-2"></i>
                                    {selectedRow?.ClinicDetails?.ClinicEmail}
                                  </div>
                                </div>
                                {/* Close button inside modal */}
                                <button
                                  onClick={() => setIsModalOpen1(null)}
                                  style={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    background: "none",
                                    color: "black",
                                    border: "none",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                >
                                  &times;
                                </button>
                              </div>
                            </div>
                          )}
                          <p style={{ color: "#A0A0A0" }}>{item?.Date}</p>
                          <div
                            style={{
                              margin: "0 0 10px 0",
                              fontSize: "14px",
                              color: "black",
                              textAlign: "start",
                              whiteSpace:
                                isReadMore.open && isReadMore.id === i
                                  ? "normal"
                                  : "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {isReadMore.open && isReadMore.id === i
                              ? item?.description
                              : item?.description}
                          </div>
                          <div
                            onClick={() => {
                              setIsReadMore({
                                id: i,
                                open:
                                  i === isReadMore.id ? !isReadMore.open : true,
                              });
                            }}
                            style={{
                              cursor: "pointer",
                              color: "blue",
                              textDecoration: "underline",
                              textAlign: "start",
                              margin: "0 0 10px 0",
                            }}
                          >
                            {isReadMore.open && isReadMore.id === i
                              ? "Show Less"
                              : "Read More"}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : DashboardOption[3] ? (
              <>
                {/* <div className="mitwank_patient_profile_add_prescription_division">
                    <button
                      onClick={() => {
                        setAddMedicalRecord(true);
                        console.log("Popup open");
                      }}
                      className="mitwank_patient_profile_add_prescription_btn btn"
                    >
                      + Add Files
                    </button>

                  </div> */}

                <div className="mitwank_patient_profile_table_division">
                  <Table
                    columns={Medicalcolumn}
                    dataSource={docMedicalData}
                    pagination={pagination}
                    onChange={handleTableChange}
                  />
                </div>
              </>
            ) : DashboardOption[4] ? (
              <>
                {/* <div className="mitwank_patient_profile_add_prescription_division">
                    <button
                      onClick={() => {
                        setaddInvoice(true);
                        console.log("Popup open");
                      }}
                      className="mitwank_patient_profile_add_prescription_btn btn"
                    >
                      + Add Invoice
                    </button>
                  </div> */}

                <div className="mitwank_patient_profile_table_division">
                  <Table
                    columns={BillingColumn}
                    dataSource={docBillingData}
                    pagination={pagination}
                    onChange={handleTableChange}
                  />
                </div>
              </>
            ) : DashboardOption[5] ? (
              <>
                <div
                  className="mitwank_patient_profile_add_prescription_division"
                  onClick={() => {
                    setaddreceptionist(true);
                  }}
                >
                  <button className="mitwank_patient_profile_add_prescription_btn">
                    + Add Receptionist
                  </button>
                </div>

                <div className="mitwank_patient_profile_table_division">
                  <Table
                    columns={receptionistcolumn}
                    dataSource={docReceptionistData}
                    pagination={pagination}
                    onChange={handleTableChange}
                  />
                </div>
              </>
            ) : DashboardOption[6] ? (
              <>
                {" "}
                <div class=" mt-2 d-flex align-items-center justify-content-end">
                  <div className="search-field ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Events"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <span className="search-icon">
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </span>
                  </div>
                  <button
                    class="mitwank_patient_profile_add_prescription_btn ms-3"
                    onClick={() => {
                      setaddprescribtion2(true);
                    }}
                  >
                    + Add Events
                  </button>
                </div>
                <div>
                  <div className="row mt-4 container  ">
                    {eventList?.map((item, i) => (
                      <div
                        className="col col-12 col-sm-6 col-md-6 col-lg-4 gx-2 gy-2"
                        key={i}
                      >
                        <div
                          style={{
                            filter:
                              timers[i] === "Time is over"
                                ? "grayscale(100%)"
                                : "grayscale(0%)",
                            borderRadius: "10px",
                            overflow: "hidden",
                            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                          }}
                          key={i}
                        >
                          <div
                            style={{
                              backgroundImage: `url(${image})`,
                              backgroundSize: "cover",
                              height: "200px",
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: "10px",
                                left: "10px",
                                display: "flex",
                                gap: "5px",
                              }}
                            >
                              <span
                                style={{
                                  backgroundColor: "#4361EE",
                                  color: "white",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  fontSize: "12px",
                                }}
                              >
                                EVENT
                              </span>
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                bottom: "10px",
                                left: "10px",
                                backgroundColor: "rgba(0,0,0,0.7)",
                                color: "white",
                                padding: "5px 10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                              }}
                            >
                              ₹ {item.fees}
                            </div>
                          </div>
                          <div style={{ padding: "20px", color: "white" }}>
                            <div className="d-flex justify-content-between align-item-center">
                              <h3
                                style={{
                                  margin: "0 0 10px 0",
                                  fontSize: "18px",
                                  textAlign: "start",
                                }}
                              >
                                {item.title}
                              </h3>
                              <h3
                                style={{
                                  margin: "0 0 10px 0",
                                  fontSize: "18px",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setIsModalOpen1(i);
                                  setSelectedRow(item);
                                }}
                              >
                                {item?.ClinicDetails?.ClinicName}
                              </h3>
                            </div>
                            {isModalOpen1 === i && (
                              <div
                                style={{
                                  position: "fixed",
                                  top: "0",
                                  left: "0",
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  ref={modalRef}
                                  style={{
                                    backgroundColor: "#fff",
                                    padding: "20px",
                                    borderRadius: "10px",
                                    width: "300px",
                                    textAlign: "center",
                                    position: "relative",
                                  }}
                                >
                                  <div
                                    className="d-flex align-items-center justify-content-center me-2 "
                                    style={{ color: "black" }}
                                  >
                                    <img
                                      className="image_div"
                                      style={{ justifyItems: "center" }}
                                      src={
                                        selectedRow?.ClinicDetails
                                          ?.clinicProfilePic
                                      }
                                    ></img>
                                  </div>
                                  <div
                                    className="new_card_client_name"
                                    style={{
                                      color: "black",
                                      fontSize: "20px",
                                    }}
                                  >
                                    {selectedRow?.ClinicDetails?.ClinicName}
                                  </div>
                                  <div
                                    className="other_info mb-2"
                                    style={{ color: "black" }}
                                  >
                                    <div>
                                      <i className="fa-solid fa-phone me-2"></i>
                                      {
                                        selectedRow?.ClinicDetails
                                          ?.ClinicContact
                                      }
                                    </div>
                                    <div>
                                      <i className="fa-solid fa-location-dot me-2"></i>
                                      {
                                        selectedRow?.ClinicDetails
                                          ?.ClinicAddress
                                      }
                                    </div>
                                    <div>
                                      <i class="fa-solid fa-at me-2"></i>
                                      {selectedRow?.ClinicDetails?.ClinicEmail}
                                    </div>
                                  </div>
                                  {/* Close button inside modal */}
                                  <button
                                    onClick={() => setIsModalOpen1(null)}
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      right: "10px",
                                      background: "none",
                                      color: "black",
                                      border: "none",
                                      fontSize: "20px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    &times;
                                  </button>
                                </div>
                              </div>
                            )}
                            <p style={{ color: "#A0A0A0" }}>{item?.Date}</p>
                            <div
                              style={{
                                margin: "0 0 10px 0",
                                fontSize: "14px",
                                color: "black",
                                textAlign: "start",
                                whiteSpace:
                                  isReadMore.open && isReadMore.id === i
                                    ? "normal"
                                    : "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {isReadMore.open && isReadMore.id === i
                                ? item?.description
                                : item?.description}
                            </div>
                            <div
                              onClick={() => {
                                setIsReadMore({
                                  id: i,
                                  open:
                                    i === isReadMore.id
                                      ? !isReadMore.open
                                      : true,
                                });
                              }}
                              style={{
                                cursor: "pointer",
                                color: "blue",
                                textDecoration: "underline",
                                textAlign: "start",
                                margin: "0 0 10px 0",
                              }}
                            >
                              {isReadMore.open && isReadMore.id === i
                                ? "Show Less"
                                : "Read More"}
                            </div>

                            <div
                              style={{
                                textAlign: "start",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <div
                                  style={{
                                    textAlign: "center",
                                    gap: "3px",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: "18px",
                                      color: "#333",
                                    }}
                                  >
                                    {timers[i]?.days || 0}
                                  </div>
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      color: "#555",
                                    }}
                                  >
                                    Days
                                  </span>
                                </div>
                                <span
                                  style={{
                                    fontSize: "18px",
                                    color: "#333",
                                    margin: "0 3px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  :
                                </span>
                                <div
                                  style={{
                                    textAlign: "center",
                                    gap: "3px",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: "18px",
                                      color: "#333",
                                      margin: 0,
                                    }}
                                  >
                                    {timers[i]?.hours || 0}
                                  </div>
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      color: "#555",
                                    }}
                                  >
                                    Hours
                                  </span>
                                </div>
                                <span
                                  style={{
                                    fontSize: "18px",
                                    color: "#333",
                                    margin: "0 3px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  :
                                </span>
                                <div
                                  style={{
                                    textAlign: "center",
                                    gap: "3px",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: "18px",
                                      color: "#333",
                                      margin: 0,
                                    }}
                                  >
                                    {timers[i]?.minutes || 0}
                                  </div>
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      color: "#555",
                                    }}
                                  >
                                    Minutes
                                  </span>
                                </div>
                                <span
                                  style={{
                                    fontSize: "18px",
                                    color: "#333",
                                    margin: "0 3px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  :
                                </span>
                                <div
                                  style={{
                                    textAlign: "center",
                                    gap: "3px",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "18px",
                                      color: "#333",
                                      margin: 0,
                                    }}
                                  >
                                    {timers[i]?.seconds || 0}
                                  </p>
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      color: "#555",
                                    }}
                                  >
                                    Seconds
                                  </span>
                                </div>
                              </div>

                              {/* <button
                                className="mt-2"
                                style={{
                                  backgroundColor: "transparent",
                                  border: "2px solid #4361EE",
                                  color: "#4361EE",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  fontSize: "14px",
                                }}
                              >
                                ATTEND →
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : null}

            {/* Add Files Popup Start */}
            <Popup
              contentStyle={addmedicalrecordpopup}
              modal
              // closeOnDocumentClick={false}
              open={addMedicalRecord}
              onClose={() => {
                setAddMedicalRecord(false);
                setloaderon(false);
                setFileData({
                  bookingId: "",
                  patientId: "",
                  clinicId: "",
                });
              }}
            >
              <div className="Add_medical_records_popup_div">
                <div className="add_medical_records_popuop_header">
                  <h5 className="add_medical_records_records_title">
                    Add Files
                  </h5>
                  <i
                    onClick={() => {
                      setAddMedicalRecord(false);
                      setFileData({
                        bookingId: "",
                        patientId: "",
                        clinicId: "",
                      });
                    }}
                    class=" add_medical_records_popuop_colse_button fa-solid fa-xmark"
                  ></i>
                </div>
                <div className="add_medical_records_popup_body">
                  <div className="popup_body_div_two">
                    <select
                      className="form-select w-25 mb-3"
                      aria-label="Select Clinic"
                      value={selectedBranch}
                      onChange={handleSelectChange}
                    >
                      <option value="" disabled>
                        Select Clinic
                      </option>
                      {branches.map((branch) => (
                        <option key={branch.id} value={branch.id}>
                          {branch.name}
                        </option>
                      ))}
                    </select>

                    <div className="popup_body_name_patient_section row">
                      <div className="col-12 col-md-6">
                        <p className="popup_body_name_title d-flex">
                          Title Name
                          <div className="required_feild ms-2">
                            (* required){" "}
                          </div>
                        </p>
                        <input
                          type="text"
                          name="description"
                          class=" popup_input_tags form-control"
                          placeholder="Enter Title Name"
                          onChange={(e) => {
                            e.target.value !== ""
                              ? setIsRequiredTitleName(false)
                              : setIsRequiredTitleName(true);
                            setTitleName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <br></br>
                    <div>
                      <p className="popup_body_upload_title d-flex">
                        Upload
                        <div className="required_feild ms-2">(* required) </div>
                      </p>
                      <div class="  input-group">
                        <input
                          class=" form-control"
                          id="inputGroupFile02"
                          style={{ cursor: "pointer" }}
                          type="file"
                          name="myImage"
                          // onChange={(event) => {
                          //     event.target.files.length > 0 ? setIsRequiredMedicalRecord(false) : setIsRequiredMedicalRecord(true)
                          //     setMedicalRecord(event.target.files[0]);

                          // }}
                          onChange={(event) => {
                            const file = event.target.files[0];
                            if (file) {
                              if (file.size < 20 * 1024 * 1024) {
                                setIsRequiredMedicalRecord(false);
                                setMedicalRecord(file);
                              } else {
                                toast.error("File size must be less than 20MB");
                                event.target.value = null;
                                setIsRequiredMedicalRecord(true);
                              }
                            } else {
                              setIsRequiredMedicalRecord(true);
                            }
                          }}
                        />
                        <label class="input-group-text" for="inputGroupFile02">
                          Upload
                        </label>
                      </div>
                    </div>
                    <br></br>
                    <div>
                      <p className="popup_body_symptoms_title d-flex">
                        Remarks
                        <div className="required_feild ms-2">(* required) </div>
                      </p>
                      <input
                        type="text"
                        name="description"
                        class=" popup_input_tags form-control"
                        placeholder=""
                        onChange={(e) => {
                          e.target.value !== ""
                            ? setIsRequiredSymptoms(false)
                            : setIsRequiredSymptoms(true);
                          setSymptoms(e.target.value);
                        }}
                      />
                    </div>
                    <br></br>
                    <br></br>
                    <div className="text-center">
                      {loaderon ? (
                        <ClipLoader />
                      ) : (
                        <button
                          type="button"
                          className="popup_submit_button btn"
                          onClick={() => {
                            var Url =
                              window.API_PREFIX + "admin/add_medicalrecords";
                            setloaderon(true);
                            var uploadData = new FormData();
                            uploadData.append(
                              "Token",
                              localStorage.getItem("DToken")
                            );
                            uploadData.append("TitleName", TitleName);
                            uploadData.append("Symptoms", Symptoms);
                            uploadData.append("RecordDate", RecordDate);
                            uploadData.append("Attachment", MedicalRecord);
                            uploadData.append("clinicId", PID);
                            uploadData.append("CreatedBy", "Clinic");
                            uploadData.append("PatientId", fileData?.patientId);
                            uploadData.append("bookingId", fileData?.bookingId);

                            fetch(Url, {
                              method: "POST",
                              // headers: {
                              //     'Content-type': 'application/json',
                              // },
                              body: uploadData,
                            })
                              .then((resp) => resp.json())
                              .then((data) => {
                                console.log(data);
                                if (data["status"] === "1") {
                                  setloaderon(false);
                                  setAddMedicalRecord(false);
                                  setFileData({
                                    bookingId: "",
                                    patientId: "",
                                    clinicId: "",
                                  });
                                  getmedicalrecord();
                                  toast.success("File Added Successfully");
                                }
                              });
                          }}
                          disabled={submitbtndisable()}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Popup>
            {/* Add Files Popup End */}
            {/* {Add offers popup start} */}
            <Popup
              contentStyle={addprescribtionpopup1}
              modal
              open={addprescribtion1}
              // closeOnDocumentClick={false}
              onClose={() => {
                setaddprescribtion1(false);
                setFrameworkData({
                  bookingId: "",
                  patientId: "",
                  clinicId: "",
                });
              }}
            >
              <div className="Add_medical_records_popup_div">
                <div className="add_medical_records_popuop_header">
                  <h5 className="add_medical_records_records_title">
                    Add Offers
                  </h5>
                  <i
                    onClick={() => {
                      setaddprescribtion1(false);
                      setFrameworkData({
                        bookingId: "",
                        patientId: "",
                        clinicId: "",
                      });
                    }}
                    class=" add_medical_records_popuop_colse_button fa-solid fa-xmark"
                  ></i>
                </div>
                <div className="add_medical_records_popup_body">
                  <div className="popup_body_div_two">
                    <div className="popup_body_name_patient_section row">
                      <div className="mt-2 w-50">
                        <p className="popup_body_upload_title d-flex">
                          Upload
                          <div className="required_feild ms-2">
                            (* required){" "}
                          </div>
                        </p>
                        <div class="  input-group">
                          <input
                            class=" form-control"
                            id="inputGroupFile02"
                            style={{ cursor: "pointer" }}
                            type="file"
                            // onChange={(event) => {
                            //     event.target.files.length > 0 ? setIsRequiredPrescRecord(false) : setIsRequiredPrescRecord(true)
                            //     setPrescRecord(event.target.files[0]);
                            // }}
                            onChange={(event) => {
                              const file = event.target.files[0];

                              if (file) {
                                if (file.size < 20 * 1024 * 1024) {
                                  setIsRequiredPrescRecord(false);
                                  setPrescRecord(file);
                                } else {
                                  toast.error(
                                    "File size must be less than 20MB"
                                  );
                                  event.target.value = null;
                                  setIsRequiredPrescRecord(true);
                                }
                              } else {
                                setIsRequiredPrescRecord(true);
                              }
                            }}
                          />
                          <label
                            class="input-group-text"
                            for="inputGroupFile02"
                          >
                            Upload
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center mt-3">
                      {loaderon ? (
                        <ClipLoader />
                      ) : (
                        <button
                          type="button"
                          className="popup_submit_button btn"
                          onClick={() => {
                            addprescription();
                            setFrameworkData({
                              bookingId: "",
                              patientId: "",
                              clinicId: "",
                            });
                          }}
                          disabled={!PrescRecord}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Popup>
            {/* {Add offers popup end} */}

            {/* {Add Events popup start} */}

            <Popup
              contentStyle={addprescribtionpopup}
              modal
              open={addprescribtion2}
              // closeOnDocumentClick={false}
              onClose={() => {
                setaddprescribtion2(false);
                setTitleName("");
                setEventDescription("");
                setEventTime("");
                seteventFees("");
              }}
            >
              <div className="Add_medical_records_popup_div">
                <div className="add_medical_records_popuop_header">
                  <h5 className="add_medical_records_records_title">
                    Add Events
                  </h5>
                  <i
                    onClick={() => {
                      setaddprescribtion2(false);
                    }}
                    class=" add_medical_records_popuop_colse_button fa-solid fa-xmark"
                  ></i>
                </div>
                <div className="add_medical_records_popup_body">
                  <div className="popup_body_div_two">
                    <div className="popup_body_name_patient_section row">
                      {/* <div className="col-12 col-md-6">
                        <p className="popup_body_name_title d-flex">
                          Clinic
                          <div className="required_feild ms-2">
                            (* required){" "}
                          </div>
                        </p>

                        <select
                          className="form-select mb-3"
                          aria-label="Select Clinic"
                          value={selectedBranch}
                        >
                          <option value="" disabled>
                            Select Clinic
                          </option>
                          {branches.map((branch) => (
                            <option key={branch.id} value={branch.id}>
                              {branch.clinicName}
                            </option>
                          ))}
                        </select>
                      </div> */}
                      <div className="col-12 col-md-6">
                        <p className="popup_body_name_title d-flex">
                          Title Name
                          <div className="required_feild ms-2">
                            (* required){" "}
                          </div>
                        </p>
                        <input
                          type="text"
                          name="description"
                          class=" popup_input_tags form-control"
                          placeholder="Enter Title Name"
                          value={eventtitle}
                          onChange={(e) => {
                            setevntTitle(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-12 col-md-3 mt-2">
                        <p className="popup_body_name_title d-flex">
                          Enter Event Date
                          <div className="required_feild ms-2">
                            (* required){" "}
                          </div>
                        </p>
                        <input
                          label="dob"
                          type="date"
                          class="popup_input_tags form-control"
                          id="inputdateofbirth"
                          onChange={(e) => {
                            const date = new Date(e.target.value);
                            const formattedDate = date.toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              }
                            );
                            console.log(formattedDate);
                            setFilterDate(formattedDate);
                          }}
                        />
                      </div>
                      <div className="col-12 col-md-3 mt-2">
                        <p className="popup_body_name_title d-flex">
                          Event Fees
                          <div className="required_feild ms-2">
                            (* required){" "}
                          </div>
                        </p>
                        <input
                          label="text"
                          type="text"
                          class="popup_input_tags form-control"
                          id="inputdateofbirth"
                          value={eventFees}
                          onChange={(e) => {
                            seteventFees(e.target.value);
                          }}
                        />
                      </div>

                      <div className="col-12 col-md-3 mt-2">
                        <p className="popup_body_name_title d-flex">
                          Time
                          <div className="required_feild ms-2">
                            (* required){" "}
                          </div>
                        </p>
                        <input
                          type="time"
                          name="time"
                          class=" popup_input_tags form-control"
                          placeholder="Enter time"
                          value={eventTime}
                          onChange={(e) => {
                            setEventTime(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-12 col-md-6 mt-2">
                        <p className="popup_body_name_title d-flex">
                          Description
                          <div className="required_feild ms-2">
                            (* required){" "}
                          </div>
                        </p>
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="2"
                          value={eventDescription}
                          onChange={(e) => {
                            setEventDescription(e.target.value);
                          }}
                        />
                        {/* <input
                          type="text"
                          name="description"
                          class=" popup_input_tags form-control"
                          placeholder="Enter Title Name"
                          value={eventcategory}
                          onChange={(e) => {
                            seteventCategory(e.target.value)
                          }}
                        /> */}
                      </div>
                      <div className="mt-2 w-50">
                        <p className="popup_body_upload_title d-flex">
                          Upload
                          <div className="required_feild ms-2">
                            (* required){" "}
                          </div>
                        </p>
                        <div class="  input-group">
                          <input
                            class=" form-control"
                            id="inputGroupFile02"
                            style={{ cursor: "pointer" }}
                            type="file"
                            // onChange={(event) => {
                            //     event.target.files.length > 0 ? setIsRequiredPrescRecord(false) : setIsRequiredPrescRecord(true)
                            //     setPrescRecord(event.target.files[0]);
                            // }}
                            onChange={(event) => {
                              const file = event.target.files[0];

                              if (file) {
                                if (file.size < 20 * 1024 * 1024) {
                                  setIsRequiredPrescRecord(false);
                                  setPrescRecord(file);
                                } else {
                                  toast.error(
                                    "File size must be less than 20MB"
                                  );
                                  event.target.value = null;
                                  setIsRequiredPrescRecord(true);
                                }
                              } else {
                                setIsRequiredPrescRecord(true);
                              }
                            }}
                          />
                          <label
                            class="input-group-text"
                            for="inputGroupFile02"
                          >
                            Upload
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center mt-3">
                      {loaderon ? (
                        <ClipLoader />
                      ) : (
                        <button
                          type="button"
                          className="popup_submit_button btn"
                          onClick={() => {
                            addEvent();
                            setaddprescribtion2(false);
                          }}
                          // disabled={
                          //   !eventplace ||
                          //   !eventtitle ||
                          //   !eventTime ||
                          //   !eventcategory ||
                          //   !FilterDate ||
                          //   !PrescRecord
                          // }
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Popup>

            {/* {Add Events popup end} */}

            {/* {Add Receptionist Popup Start} */}
            <Popup
              contentStyle={addmedicalrecordpopup}
              modal
              // closeOnDocumentClick={false}
              open={addreceptionist}
              onClose={() => {
                setaddreceptionist(false);
                setloaderon(false);
              }}
            >
              <div className="Add_medical_records_popup_div">
                <div className="add_medical_records_popuop_header">
                  <h5 className="add_medical_records_records_title">
                    Add Receptionist
                  </h5>
                  <i
                    onClick={() => {
                      setaddreceptionist(false);
                    }}
                    class=" add_medical_records_popuop_colse_button fa-solid fa-xmark"
                  ></i>
                </div>
                <div className="add_medical_records_popup_body">
                  <div>
                    <div>
                      <label for="formFile" class="form-label">
                        Profile Picture
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        accept="image/gif, image/jpeg, image/png"
                        id="formFile"
                        onChange={(e) => {
                          setprofile(e.target.files[0]);
                        }}
                        required
                      />
                    </div>
                    <TextField
                      id="outlined-basic"
                      label="Receptionist Name"
                      variant="outlined"
                      size="small"
                      className="w-100 mt-3"
                      onChange={(e) => {
                        setreceptionistname(e.target.value);
                        const newValue = e.target.value;
                        const nonNumericRegex = /^[A-Za-z\s]*$/;
                        if (nonNumericRegex.test(newValue)) {
                          setreceptionistname(newValue);
                        } else {
                          toast.error("Please enter alphabets only.");
                        }
                      }}
                      value={receptionistname}
                      required
                    />

                    <TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      size="small"
                      className="w-100 mt-3"
                      onChange={(e) => {
                        setreceptionistmail(e.target.value);
                        setOTPbuttonFlag(false);
                      }}
                      value={receptionistmail}
                      required
                    />
                    <button
                      className={
                        receptionistmail.length !== 0 && !OTPbuttonFlag
                          ? "client_register_btn_login_division_active"
                          : "client_register_btn_login_division"
                      }
                      disabled={
                        !(receptionistmail.length !== 0 && !OTPbuttonFlag)
                      }
                      onClick={() => {
                        if (receptionistmail.length !== 0) {
                          var Url =
                            window.API_PREFIX + "admin/receptionistgenerateotp";
                          setloaderon(true);
                          fetch(Url, {
                            method: "POST",
                            headers: {
                              "Content-type": "application/json",
                            },
                            body: JSON.stringify({
                              Email: receptionistmail,
                            }),
                          })
                            .then((resp) => resp.json())
                            .then((data) => {
                              console.log(data);
                              if (data["status"] === "1") {
                                setotpsuccess(true);
                                setloaderon(false);
                                setOTPbuttonFlag(true);
                              } else if (data["status"] === "0") {
                                toast.error("Email already register");
                                setloaderon(false);
                              }
                            });
                        }
                      }}
                    >
                      {loaderon ? <SyncLoader color="white" /> : "Generate OTP"}
                    </button>

                    {otpsuccess ? (
                      <TextField
                        id="outlined-basic"
                        label="Otp"
                        size="small"
                        variant="outlined"
                        className="w-100 mt-3"
                        onChange={(e) => {
                          if (e.target.value.length !== 0) {
                            var Url =
                              window.API_PREFIX + "admin/receptionistcheckotp";
                            fetch(Url, {
                              method: "POST",
                              headers: {
                                "Content-type": "application/json",
                              },
                              body: JSON.stringify({
                                Email: receptionistmail,
                                OTP: e.target.value,
                              }),
                            })
                              .then((resp) => resp.json())
                              .then((data) => {
                                console.log(data);
                                if (data["status"] === "1") {
                                  setbuttonFlag(true);
                                  setsystemOTP(true);
                                } else if (data["status"] === "-1") {
                                  toast.error("Email not found");
                                  setbuttonFlag(false);
                                }
                              });
                          }
                        }}
                      />
                    ) : null}

                    {/* <TextField
                      id="outlined-basic"
                      label="Number"
                      variant="outlined"
                      size="small"
                      type="number"
                      className="w-100 mt-3"
                      required
                    /> */}

                    {/* <div className="client_register_date_feild_division">
                      <lable>Enter Your Birthdate*</lable>
                      <input
                        label="dob"
                        type="date"
                        class="client_register_email_field form-control h-50"
                        id="inputdateofbirth"
                      />
                    </div> */}

                    <div
                      className="client_register_personal_gender_main_division"
                      style={{ height: "40px" }}
                    >
                      <button
                        className={
                          Activegender[0]
                            ? "client_register_personal_male_btn_active"
                            : "client_register_personal_male_btn"
                        }
                        onClick={() => {
                          setgender("male");
                          setActivegender([true, false]);
                        }}
                      >
                        <i class="fa-solid fa-mars-stroke"></i> Male
                      </button>
                      <button
                        className={
                          Activegender[1]
                            ? "client_register_personal_female_btn_active"
                            : "client_register_personal_female_btn"
                        }
                        onClick={() => {
                          setgender("female");
                          setActivegender([false, true]);
                        }}
                      >
                        <i class="fa-solid fa-mars-stroke-up"></i> Female
                      </button>
                    </div>

                    <FormControl
                      sx={{ width: "100%" }}
                      variant="outlined"
                      className="mt-3"
                    >
                      <InputLabel htmlFor="outlined-adornment-password">
                        Create Password
                      </InputLabel>
                      <OutlinedInput
                        size="small"
                        id="outlined-adornment-password"
                        type={showPassword2 ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword2}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword2 ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                        onChange={(e) => {
                          setreceptionistpassword(e.target.value);
                        }}
                        value={receptionistpassword}
                        required
                      />
                    </FormControl>
                    <div className="d-flex gap-3">
                      <div className="col-6">
                        <TextField
                          id="outlined-basic"
                          label="Number"
                          variant="outlined"
                          size="small"
                          type="number"
                          className="w-100 mt-3"
                          value={number}
                          onChange={(e) => {
                            setnumber(e.target.value);
                          }}
                          required
                        />
                      </div>

                      <div className="client_register_date_feild_division col-6">
                        <lable>Enter Your Birthdate*</lable>
                        <input
                          label="dob"
                          type="date"
                          class="client_register_email_field form-control h-50"
                          id="inputdateofbirth"
                          value={birthDate}
                          onChange={handleDateChangedate}
                          max={getMaxDate()}
                        />
                      </div>
                    </div>

                    <div class="d-flex justify-content-center mt-3">
                      <button
                        type="button"
                        class="popup_submit_button btn"
                        disabled={!buttonFlag}
                        onClick={() => {
                          if (receptionistname === "") {
                            toast.error("Please Enter Receptionist Name");
                          } else if (receptionistmail === "") {
                            toast.error("Please Enter Receptionist Email");
                          } else if (!profile) {
                            toast.error("Please Upload Profile Picture");
                          } else if (receptionistpassword === "") {
                            toast.error("Please Enter Receptionist Password");
                          } else if (gender == "") {
                            toast.error("please select gender");
                          } else if (number == "") {
                            toast.error("please Enter Number");
                          } else if (birthDate == "") {
                            toast.error("please Select Birth Date");
                          } else {
                            var Url =
                              window.API_PREFIX + "admin/addreceptionist";
                            var uploadData = new FormData();
                            // uploadData.append("Token", localStorage.getItem("DToken"));
                            uploadData.append("clinicId", PID);
                            uploadData.append(
                              "receptionistname",
                              receptionistname
                            );
                            uploadData.append(
                              "receptionistProfilePic",
                              profile
                            );
                            uploadData.append(
                              "receptionistmail",
                              receptionistmail
                            );
                            uploadData.append(
                              "receptionistpassword",
                              receptionistpassword
                            );
                            uploadData.append("addedBy", "Admin");
                            uploadData.append("receptionistNumber", number);
                            uploadData.append(
                              "receptionistBirthDate",
                              birthDate
                            );
                            uploadData.append("receptionistGender", gender);

                            fetch(Url, {
                              method: "POST",
                              body: uploadData,
                            })
                              .then((resp) => resp.json())
                              .then((data) => {
                                console.log(data);
                                if (data["status"] === "1") {
                                  setaddreceptionist(false);
                                  getReceptionist();
                                  toast.success("Added Successfully");
                                }
                              });
                          }
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Popup>
            {/* {Add Receptionist Popup End} */}

            {/* Add Frameworkd Popup Strat */}
            <Popup
              contentStyle={addprescribtionpopup}
              modal
              // closeOnDocumentClick={false}
              open={addprescribtion}
              onClose={() => {
                setaddprescribtion(false);
              }}
            >
              <div className="Add_medical_records_popup_div">
                <div className="add_medical_records_popuop_header">
                  <h5 className="add_medical_records_records_title">
                    Add Offers & Events
                  </h5>
                  <i
                    onClick={() => {
                      setaddprescribtion(false);
                    }}
                    class=" add_medical_records_popuop_colse_button fa-solid fa-xmark"
                  ></i>
                </div>
                <div className="add_medical_records_popup_body">
                  <div className="popup_body_div_two">
                    {/* <select
                        className="form-select w-25 mb-3"
                        aria-label="Select Clinic"
                        value={selectedBranch}
                        onChange={handleSelectChange}
                      >
                        <option value="" disabled>
                          Select Clinic
                        </option>
                        {branches.map((branch) => (
                          <option key={branch.id} value={branch.id}>
                            {branch.name}
                          </option>
                        ))}
                      </select> */}

                    <div className="popup_body_name_patient_section row">
                      <div className="col-12 col-md-6">
                        <p className="popup_body_name_title d-flex">
                          Name
                          <div className="required_feild ms-2">
                            (* required){" "}
                          </div>
                        </p>
                        <input
                          type="text"
                          name="description"
                          class=" popup_input_tags form-control"
                          placeholder="Enter Title Name"
                          onChange={(e) => {
                            e.target.value !== ""
                              ? setIsRequiredPrescName(false)
                              : setIsRequiredPrescName(true);
                            setPrescName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <br></br>

                    <div>
                      <p className="popup_body_upload_title d-flex">
                        Upload
                        <div className="required_feild ms-2">(* required) </div>
                      </p>
                      <div class="  input-group">
                        <input
                          class=" form-control"
                          id="inputGroupFile02"
                          style={{ cursor: "pointer" }}
                          type="file"
                          // onChange={(event) => {
                          //     event.target.files.length > 0 ? setIsRequiredPrescRecord(false) : setIsRequiredPrescRecord(true)
                          //     setPrescRecord(event.target.files[0]);
                          // }}
                          onChange={(event) => {
                            const file = event.target.files[0];

                            if (file) {
                              if (file.size < 20 * 1024 * 1024) {
                                setIsRequiredPrescRecord(false);
                                setPrescRecord(file);
                              } else {
                                toast.error("File size must be less than 20MB");
                                event.target.value = null;
                                setIsRequiredPrescRecord(true);
                              }
                            } else {
                              setIsRequiredPrescRecord(true);
                            }
                          }}
                        />
                        <label class="input-group-text" for="inputGroupFile02">
                          Upload
                        </label>
                      </div>
                    </div>
                    <br></br>
                    <div className="d-flex justify-content-center">
                      {loaderon ? (
                        <ClipLoader />
                      ) : (
                        <button
                          type="button"
                          className="popup_submit_button btn"
                          onClick={() => {
                            addprescription();
                          }}
                          disabled={checkSubmitBtnDisable()}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Popup>
            {/* Add Frameworkd Popup End */}

            {/* Add Invoice Popup Start */}
            <Popup
              contentStyle={addInvoice}
              modal
              // closeOnDocumentClick={false}
              open={addInvoice}
              onClose={() => {
                setaddInvoice(false);
              }}
            >
              <div className="Add_medical_records_popup_div">
                <div className="add_medical_records_popuop_header">
                  <h5 className="add_medical_records_records_title">
                    Add Invoice
                  </h5>
                  <i
                    onClick={() => {
                      setaddInvoice(false);
                    }}
                    class=" add_medical_records_popuop_colse_button fa-solid fa-xmark"
                  ></i>
                </div>
                <div className="add_medical_records_popup_body">
                  <div className="popup_body_div_two">
                    <select
                      className="form-select w-25 mb-3"
                      aria-label="Select Clinic"
                      value={selectedBranch}
                      onChange={handleSelectChange}
                    >
                      <option value="" disabled>
                        Select Clinic
                      </option>
                      {branches.map((branch) => (
                        <option key={branch.id} value={branch.id}>
                          {branch.name}
                        </option>
                      ))}
                    </select>

                    <div className="popup_body_name_patient_section row">
                      <div className="col-12 col-md-6">
                        <p className="popup_body_name_title d-flex">
                          Amount
                          <div className="required_feild ms-2">
                            (* required){" "}
                          </div>
                        </p>
                        <input
                          type="number"
                          name="amount"
                          class=" popup_input_tags form-control"
                          placeholder="Amount"
                          onChange={(e) => {
                            e.target.value !== ""
                              ? setIsRequiredAmount(false)
                              : setIsRequiredAmount(true);
                            setAmount(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <p className="popup_body_name_title d-flex">
                          Invoice Date
                          <div className="required_feild ms-2">
                            (* required){" "}
                          </div>
                        </p>
                        <input
                          type="date"
                          name="date"
                          class=" popup_input_tags form-control"
                          placeholder="Invoice-date"
                          onChange={(e) => {
                            e.target.value !== ""
                              ? setIsRequiredInvoiceDate(false)
                              : setIsRequiredInvoiceDate(true);
                            setInvoiceDate(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <br></br>

                    <div>
                      <p className="popup_body_upload_title d-flex">
                        Upload
                        <div className="required_feild ms-2">(* required) </div>
                      </p>
                      <div class="  input-group">
                        <input
                          class=" form-control"
                          id="inputGroupFile02"
                          style={{ cursor: "pointer" }}
                          type="file"
                          name="myImage"
                          // onChange={(event) => {
                          //     event.target.files.length > 0 ? setIsRequiredInvoiceRecord(false) : setIsRequiredInvoiceRecord(true)
                          //     setInvoiceRecord(event.target.files[0]);

                          // }}
                          onChange={(event) => {
                            const file = event.target.files[0];
                            if (file) {
                              if (file.size < 20 * 1024 * 1024) {
                                setIsRequiredInvoiceRecord(false);
                                setInvoiceRecord(file);
                              } else {
                                toast.error("File size must be less than 20MB");

                                event.target.value = null;
                                setIsRequiredInvoiceRecord(true);
                              }
                            } else {
                              setIsRequiredInvoiceRecord(true);
                            }
                          }}
                        />
                        <label class="input-group-text" for="inputGroupFile02">
                          Upload
                        </label>
                      </div>
                    </div>
                    <br></br>
                    <div className="text-center">
                      {loaderon ? (
                        <ClipLoader />
                      ) : (
                        <button
                          type="button"
                          className="popup_submit_button btn"
                          onClick={() => {
                            var Url = window.API_PREFIX + "admin/AddInvoice";
                            setloaderon(true);
                            var uploadData = new FormData();
                            uploadData.append(
                              "Token",
                              localStorage.getItem("DToken")
                            );
                            uploadData.append("Amount", Amount);
                            uploadData.append("InvoiceDate", InvoiceDate);
                            uploadData.append("Attachment", InvoiceRecord);
                            uploadData.append("PatientId", PID);

                            fetch(Url, {
                              method: "POST",
                              body: uploadData,
                            })
                              .then((resp) => resp.json())
                              .then((data) => {
                                console.log(data);
                                if (data["status"] === "1") {
                                  setloaderon(false);
                                  getbilling();
                                  toast.success("Invoice Added");
                                  setaddInvoice(false);
                                }
                              });
                          }}
                          disabled={invoicesubmitbtndisable()}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Popup>
            {/* Add Invoice Popup End */}

            {/* Edit Price Popup Start */}
            <Popup
              contentStyle={editpricepopup}
              modal
              // closeOnDocumentClick={false}
              open={editpricePop}
              onClose={() => {
                seteditpricePop(false);
              }}
            >
              <div className="Add_medical_records_popup_div">
                <div className="add_medical_records_popuop_header">
                  <h5 className="add_medical_records_records_title">
                    Edit Price
                  </h5>
                  <i
                    onClick={() => {
                      seteditpricePop(false);
                    }}
                    class=" add_medical_records_popuop_colse_button fa-solid fa-xmark"
                  ></i>
                </div>
                <div className="add_medical_records_popup_body">
                  <div className="popup_body_div_two">
                    <div className="popup_body_name_patient_section row">
                      <div className="col-12 col-md-6">
                        <p className="popup_body_name_title">Enter new price</p>
                        <input
                          type="text"
                          name="description"
                          class=" popup_input_tags form-control"
                          placeholder="Enter Price Name"
                          value={eacheditprice}
                          onChange={(e) => {
                            seteacheditprice(e.target.value);
                          }}
                        ></input>
                      </div>
                    </div>

                    <div className=" mt-2">
                      {loaderon ? (
                        <ClipLoader />
                      ) : (
                        <button
                          type="button"
                          className="popup_submit_button btn"
                          onClick={() => {
                            var Url =
                              window.API_PREFIX + "admin/changebookingprice";
                            setloaderon(true);
                            fetch(Url, {
                              method: "POST",
                              headers: {
                                "Content-type": "application/json",
                              },
                              body: JSON.stringify({
                                Token: localStorage.getItem("DToken"),
                                Amount: eacheditprice,
                                Id: docAppointmentData[editPriceIndex][
                                  "BookingId"
                                ],
                                PID: PID,
                              }),
                            })
                              .then((resp) => resp.json())
                              .then((data) => {
                                console.log(data);
                                if (data["status"] === "1") {
                                  setloaderon(false);
                                  toast.success("Price Updated Successfully");
                                  setdocAppointmentData([
                                    ...data["appointment"],
                                  ]);
                                  seteditpricePop(false);
                                }
                              });
                          }}
                        >
                          Confirm
                          <i class="fa-regular fa-circle-check ms-2"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Popup>
            {/* Edit Price Popup End */}
          </div>
        </div>

        {isModalOpen2 && (
          <div id="myModal" className="modal" onClick={handleOutsideClick2}>
            <div className="modal-content">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Edit Receptionist</h5>
                <i
                  onClick={closeModal2}
                  class="fa-solid fa-xmark fa-x close"
                ></i>
              </div>
              <div className="profilesettings_Upload_image">
                <div className="profilesettings_profile_image">
                  <img
                    className="profilesettings_profile_image_sizes"
                    src={showImg}
                  />
                </div>
                <div className="profilesettings_upload_button_main">
                  <button
                    type="button"
                    className="profilesettings_upload_photo_button btn"
                  >
                    <i className="fa fa-upload profilesettings_upload_icon"></i>{" "}
                    Upload Photo
                    <input
                      type="file"
                      accept="image/gif, image/jpeg, image/png"
                      onChange={(e) => {
                        setImg(e.target.files[0]);
                        setshowImg(URL.createObjectURL(e.target.files[0]));
                      }}
                    />
                  </button>
                  <br />
                  <div className="profilesetting_upload_p">
                    <p>Allowed JPG, GIF or PNG. Max size of 20MB</p>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <TextField
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  size="small"
                  className="w-100 mt-3"
                  value={recename}
                  onChange={(e) => {
                    setrecename(e.target.value);
                  }}
                  required
                />

                <TextField
                  id="outlined-basic"
                  label="Mail"
                  variant="outlined"
                  size="small"
                  className="w-100 mt-3"
                  value={mail}
                  onChange={(e) => {
                    setmail(e.target.value);
                  }}
                  required
                  disabled
                />

                <TextField
                  id="outlined-basic"
                  label="Number"
                  variant="outlined"
                  size="small"
                  type="number"
                  className="w-100 mt-3"
                  value={number}
                  onChange={(e) => {
                    setnumber(e.target.value);
                  }}
                  required
                />

                <div className="client_register_date_feild_division">
                  <lable>Enter Your Birthdate*</lable>
                  <input
                    label="dob"
                    type="date"
                    class="client_register_email_field form-control h-50"
                    id="inputdateofbirth"
                    value={birthDate}
                    onChange={handleDateChangedate}
                    max={getMaxDate()}
                  />
                </div>

                <div
                  className="client_register_personal_gender_main_division"
                  style={{ height: "40px" }}
                >
                  <button
                    className={
                      Activegender[0]
                        ? "client_register_personal_male_btn_active"
                        : "client_register_personal_male_btn"
                    }
                    onClick={() => {
                      setgender("male");
                      setActivegender([true, false]);
                    }}
                  >
                    <i class="fa-solid fa-mars-stroke"></i> Male
                  </button>
                  <button
                    className={
                      Activegender[1]
                        ? "client_register_personal_female_btn_active"
                        : "client_register_personal_female_btn"
                    }
                    onClick={() => {
                      setgender("female");
                      setActivegender([false, true]);
                    }}
                  >
                    <i class="fa-solid fa-mars-stroke-up"></i> Female
                  </button>
                </div>

                {/* <div className="client_register_date_feild_division">
                  <lable>Edit Your Birthdate*</lable>
                  <input
                    label="dob"
                    type="date"
                    class="client_register_email_field form-control h-50"
                    id="inputdateofbirth"
                  />
                </div> */}

                {/* <TextField
                  id="outlined-basic"
                  label="Number"
                  variant="outlined"
                  size="small"
                  type="number"
                  className="w-100 mt-3"
                  onChange={(e) => {
                    setnumber(e.target.value);
                  }}
                  required
                /> */}

                <FormControl
                  sx={{ width: "100%" }}
                  variant="outlined"
                  className="mt-3"
                >
                  {/* {password === "" && ( */}
                  <InputLabel htmlFor="outlined-adornment-password">
                    Creat Password
                  </InputLabel>
                  {/* )} */}
                  <OutlinedInput
                    label="password"
                    size="small"
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={password}
                    onChange={(e) => {
                      setpassword(e.target.value);
                    }}
                    required
                  />
                </FormControl>

                <div class="d-flex justify-content-center mt-3">
                  <button
                    type="button"
                    class="popup_submit_button btn"
                    onClick={() => {
                      var Url = window.API_PREFIX + "admin/editreceptionist";
                      var uploadData = new FormData();
                      uploadData.append("id", selectedId);
                      uploadData.append("receptionistname", recename);
                      uploadData.append("receptionistProfilePic", Img);
                      uploadData.append("receptionistpassword", password);
                      uploadData.append("receptionistNumber", number);
                      uploadData.append("receptionistBirthDate", birthDate);
                      uploadData.append("receptionistGender", gender);
                      fetch(Url, {
                        method: "POST",
                        body: uploadData,
                      })
                        .then((resp) => resp.json())
                        .then((data) => {
                          console.log(data);
                          if (data["status"] === "1") {
                            closeModal2();
                            setSelectedId("");
                            getReceptionist();
                            toast.success("Added Successfully");
                          }
                        });
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <Toaster />
      </div>
    </>
  );
}

export default Clinicsprofile;
