import "./NewclientRegister.css";
import "../../App.css";
import doccureloginimg from "../../media/login_img.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useStateValue } from "../StateProviders";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { SyncLoader } from "react-spinners";
import toast, { ToastBar, Toaster } from "react-hot-toast";

function ClientRegister() {
  let navigate = useNavigate();
  const [{ IsLogin }, dispatch] = useStateValue();
  const [userName, setuserName] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [userOTP, setuserOTP] = useState("-1");
  const [systemOTP, setsystemOTP] = useState(false);
  const [buttonFlag, setbuttonFlag] = useState(false);
  const [OTPbuttonFlag, setOTPbuttonFlag] = useState(false);
  const [Inputtype, setInputtype] = useState("password");
  const [ButtonName, setButtonName] = useState(<i class="fa-solid fa-eye"></i>);
  const [isRequired, setIsRequired] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [loaderon, setloaderon] = useState(false);

  return (
    <div className="container">
      <div className="client_register_main_division mt-5 mb-5">
        <div className="client_register_main_first_division">
          <div className="d-flex justify-content-center">
            <div className="client_register_img_division">
              <img className="client_register_img" src={doccureloginimg} />
            </div>
          </div>
        </div>

        <div className="client_register_main_second_division">
          <div className="client_register_title_name"> Register</div>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            className="w-100 mt-2"
            onChange={(e) => {
              setEmail(e.target.value);
              setOTPbuttonFlag(false);
            }}
          />

          <button
            className={
              Email.length !== 0 && !OTPbuttonFlag
                ? "client_register_btn_login_division_active"
                : "client_register_btn_login_division"
            }
            disabled={!(Email.length !== 0 && !OTPbuttonFlag)}
            onClick={() => {
              if (Email.length !== 0) {
                var Url = window.API_PREFIX + "patient/generate_otp";
                setloaderon(true);
                fetch(Url, {
                  method: "POST",
                  headers: {
                    "Content-type": "application/json",
                  },
                  body: JSON.stringify({
                    Email: Email,
                  }),
                })
                  .then((resp) => resp.json())
                  .then((data) => {
                    console.log(data);
                    if (data["status"] === "1") {
                      setloaderon(false);
                      setOTPbuttonFlag(true);
                    } else if (data["status"] === "0") {
                      toast.error("Email already register");
                      setloaderon(false);
                    }
                  });
              }
            }}
          >
            {loaderon ? <SyncLoader color="white" /> : "Generate OTP"}
          </button>

          <TextField
            id="outlined-basic"
            label="Otp"
            variant="outlined"
            className="w-100 mt-3"
            onChange={(e) => {
              if (e.target.value.length !== 0) {
                var Url = window.API_PREFIX + "patient/checkOTP";
                fetch(Url, {
                  method: "POST",
                  headers: {
                    "Content-type": "application/json",
                  },
                  body: JSON.stringify({
                    Email: Email,

                    OTP: e.target.value,
                  }),
                })
                  .then((resp) => resp.json())
                  .then((data) => {
                    console.log(data);
                    if (data["status"] === "1") {
                      setbuttonFlag(true);
                      setsystemOTP(true);
                    } else if (data["status"] === "-1") {
                      toast.error("Email not found");
                      setbuttonFlag(false);
                    }
                  });
              }
            }}
          />

          <FormControl
            sx={{ width: "100%" }}
            variant="outlined"
            className="mt-3"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Creat Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </FormControl>

          <div
            className="client_register_forgot_password_division"
            onClick={() => {
              navigate("/login");
            }}
          >
            Already have an account ?
          </div>
          <div class="m-3">
            <div class="form-check">
              <input type="checkbox" onChange={(e) => { setIsRequired(e.target.checked) }} checked={isRequired} class="form-check-input" id="agreeCheck" />
              <label class="form-check-label" for="agreeCheck">
                <span>I agree to the <a style={{ color: '#743a87' }} onClick={() => {
                  navigate("/terms-of-use")
                }}>Terms of use </a>, <a style={{ color: '#743a87' }} onClick={() => {
                  navigate("/privacy-policy")
                }}>privacy policy </a>
                  , <a style={{ color: '#743a87' }} onClick={() => {
                    navigate("/cancellation-and-refund-policy")
                  }}>Cancellation and refund policy.</a>
                </span>
              </label>
            </div>
          </div>

          <button
            className={
              buttonFlag && isRequired
                ? "client_register_btn_login_division_active"
                : "client_register_btn_login_division"
            }
            disabled={!buttonFlag}
            onClick={() => {
              if (
                Email.length !== 0 &&
                Password.length !== 0 &&
                systemOTP &&
                isRequired === true

              ) {
                var Url = window.API_PREFIX + "patient/register_user";
                fetch(Url, {
                  method: "POST",
                  headers: {
                    "Content-type": "application/json",
                  },
                  body: JSON.stringify({
                    UserName: userName,
                    Email: Email,
                    Password: Password,
                  }),
                })
                  .then((resp) => resp.json())
                  .then((data) => {
                    console.log(data);
                    if (data["status"] === "1") {
                      localStorage.setItem("DToken", data["Token"]);

                      dispatch({ type: "Login" });

                      navigate("/client-detail-register");
                    } else {
                      toast.success(data["message"]);
                    }
                  });
              }
            }}
          >
            Signup
          </button>
        </div>
      </div>
    </div >
  );
}
export default ClientRegister;
