import { useEffect, useState, useRef } from 'react';
import React from 'react';
import { useStateValue } from './StateProviders';
import './Header.css';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import snh_logo from '../media/SH-Hydrafacial.png';
import { Close } from '@mui/icons-material';


function Header() {

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    const [profile, setProfile] = useState(true)
    const [{ IsLogin, IsRegister }, dispatch] = useStateValue();
    const [userdata, setuserdata] = useState({});
    const [activeMenu, setActiveMenu] = useState([false, false, false, false])

    let navigate = useNavigate();
    useEffect(() => {

        var Url = window.API_PREFIX + 'patient/check_user'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {

                dispatch({ type: 'Login' })
                setGroupName("User_" + data["ID"] + "/")


                setuserdata({ ...data })
            }
            else {
                // navigate('/login')
            }
        })
        // }
    }, [IsLogin])

    useEffect(() => {
        console.log(">>>Header  Register")
        // dispatch({ type: 'Register' })

        // if (!IsLogin) {
        var Url = window.API_PREFIX + 'patient/check_user'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    Token: localStorage.getItem('DToken'),
                }
            ),
        }).then(resp => resp.json()).then(data => {
            console.log(data)
            if (data['status'] === "1") {

                if (!data['IsRegister']) {
                    dispatch({ type: 'NotRegister' })


                }
                else {
                    dispatch({ type: 'Register' })
                }



                setuserdata({ ...data })
            }
            else {
                // navigate('/login')
            }

            console.log(IsLogin)
            console.log(IsRegister)
        })
        // }
    }, [IsRegister])



    const [isFullScrVisible, setFullScrVisible] = useState(false);
    const fullScrStyle = {
        left: isFullScrVisible ? 0 : "",
    };

    const handleMenuClick2 = () => {
        setFullScrVisible(!isFullScrVisible);
    };

    const [isOpenn, setOpenn] = useState(false);


    const [options, setoptions] = useState([true, false]);


    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(timerId);
    }, []);

    const formatTime = (date) => {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        const strTime = `${hours}:${minutes}:${seconds} ${ampm}`;
        return strTime;
    };


    //     ######################### Notification Section ###########################

    const [anchorEl, setAnchorEl] = useState(null);


    // const [AllMsg,setAllMsg] = useState([])
    const [GroupName, setGroupName] = useState('')
    // const [unreadcount,setunreadcount] = useState("")


    const [chatSocket, setChatSocket] = useState(null);
    const [webSocketInitialized, setWebSocketInitialized] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [totalUnseenNotification, setTotalUnseenNotification] = useState(0);
    // console.log('notification', notifications)
    function getNotifications(showAlert) {
        var Url = window.API_PREFIX + 'admin/viewnotification'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    "Token": localStorage.getItem("DToken"),

                }
            ),
        }).then(resp => resp.json()).then(data => {
            if (data['status'] === "1") {


                setNotifications(data['notification'])


                const unseenNotification = data['notification'].filter(item => item.IsSeen === false).length;
                if (unseenNotification > 0) {
                    const message = data['notification'][0]['Message'];

                    if (showAlert == true && message) { 
                        // alert(data['notification'][0]['Message'])
                     }

                    dispatch({ type: "NewNotification" })
                }
                setTotalUnseenNotification(unseenNotification)
            }
        })
    }

    useEffect(() => {
        getNotifications(false)
    }, [anchorEl])

    useEffect(() => {

        if (!webSocketInitialized && GroupName !== "") {

            const socket = new WebSocket(
                window.WEBSOC + 'ws/UserNotify/' + GroupName
            );
            socket.onopen = () => {
                console.log("WebSocket connection opened");
                setWebSocketInitialized(true);

            };
            socket.onmessage = function (e) {
                let received_data_as_object = e['data'];
                // console.log(received_data_as_object)

                if (received_data_as_object.split("_")[0] === "Reload") {
                    getNotifications(true)
                }
                //   const data = JSON.parse(e.data);
                // setChatLog((prevChatLog) => prevChatLog + e.data + '\n');
            };

            socket.onclose = function (e) {
                console.error('Chat socket closed unexpectedly');
                setWebSocketInitialized(false);

            };
            setChatSocket(socket);
            // return () => {
            //     socket.close();
            // };

        }
    }, [GroupName])

    function handleSeen(notification) {
        var Url = window.API_PREFIX + 'admin/get_seen'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    "Token": localStorage.getItem("DToken"),
                    "id": notification.id.toString()
                }
            ),
        }).then(resp => resp.json()).then(data => {
            if (data['status'] === "1") {
                getNotifications(false);
                if (notification.Type === "Order") {
                    setAnchorEl(null);
                    navigate(`/administration/OrderDetail/${notification.Id_All_Type}`);
                }
            }
        })
    }

    function handleDelete(id) {
        var Url = window.API_PREFIX + 'admin/get_delete'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    "Token": localStorage.getItem("DToken"),
                    "Id": id.toString()
                }
            ),
        }).then(resp => resp.json()).then(data => {
            if (data['status'] === "1") {

                getNotifications(false);
            }
        })
    }

    function handleRead() {
        var Url = window.API_PREFIX + 'admin/get_allseen'
        fetch(Url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(
                {
                    "Token": localStorage.getItem("DToken"),
                }
            ),
        }).then(resp => resp.json()).then(data => {
            if (data['status'] === "1") {

                getNotifications(false);
            }
        })
    }


    function getTimeDifference(timestamp) {
        timestamp = timestamp.replace(/\.$/, '');
        const currentTime = new Date(); // Current date and time
        const previousTime = new Date(timestamp); // Replace '-' with '/' for broader browser compatibility

        const timeDifference = currentTime - previousTime;

        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30); // Approximation, not precise
        const years = Math.floor(months / 12); // Approximation, not precise

        if (seconds < 60) {
            return seconds + ' seconds ago';
        } else if (minutes < 60) {
            return minutes + ' minutes ago';
        } else if (hours < 24) {
            return hours + ' hours ago';
        } else if (days < 30) {
            return days + ' days ago';
        } else if (months < 12) {
            return months + ' months ago';
        } else {
            return years + ' years ago';
        }
    }




    const notificationstyle = (isSeen) => {
        return {
            backgroundColor: isSeen ? 'white' : '#d7d5d5',
            marginTop: "10px",
            borderRadius: "10px"
        }
    }







    const [bar, setbar] = useState(false)

    const barstyle = {
        right: bar ? 0 : ""
    }

    const handleMenuClick = () => {
        setbar(!bar);
    };


    const notificationSidebarRef = useRef(null);


    useEffect(() => {
        const handleBodyClick = (event) => {
            if (notificationSidebarRef.current && !notificationSidebarRef.current.contains(event.target)) {
                setbar(false); // Close the sidebar when clicking outside
            }
        };

        window.addEventListener('click', handleBodyClick);

        return () => {
            window.removeEventListener('click', handleBodyClick);
        };
    }, [notificationSidebarRef]);

    const handleBellClick = (event) => {
        event.stopPropagation(); // Stop the event from reaching the window click listener
        handleMenuClick(); // Toggle the notification sidebar
    };






    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef1 = useRef(null);

    // Toggle dropdown when notification icon is clicked
    const toggleDropdown = (event) => {
        event.stopPropagation();
        setIsOpen(!isOpen);
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);



    //     ######################### Notification Section   End ###########################








    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setProfile(true); // Close the profile if clicked outside
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);





    return (

        // Header-start
        <header className="container-fuild ">

            {/* <div className="" id="full-scr" style={fullScrStyle}>
                <div className='d-flex justify-content-end align-items-center' style={{ borderBottom: '1px solid grey' }}>
                    <div onClick={handleMenuClick2}>
                        <Hamburger
                            toggled={isOpenn}
                            toggle={setOpenn}
                            size={30}
                            color='black'
                        />
                    </div>
                </div>
                <div class="sidebar_division">
                    <div class="sidebar_img_profile_main_division">
                        <div class="sidebar_img_profile_division">
                            <img className=' header_profile_image' src={userdata['Img']}></img>
                        </div>
                    </div>
                    <div class="sidebar_profile_person_name">{userdata['Name']}</div>
                    <div class="sidebar_location_division">
                        Patient id: {userdata['displayId']}
                    </div>
                </div>

            </div> */}





            <div className=" header_main_division">
                {/* <div className="header_logo_main_division">
                    <img className="header_logo_division" src={snh_logo} alt="123" onClick={() => { navigate('/dashboard') }} />
                </div> */}

                {/* <div className='btn_bar' onClick={handleMenuClick2}>
                    <Hamburger
                        toggled={isOpenn}
                        toggle={setOpenn}
                        size={30}
                        color='black'
                    />
                </div> */}
                <div className="header_logo_main_division">
                    <img className="header_logo_division" src={snh_logo} alt="123" onClick={() => { navigate('/dashboard') }} />
                </div>


                <div className='header_center_main_division'>
                    <div className='d-flex align-items-center '>
                        <i class="fa-regular fa-clock clock_icon"></i>
                        <div className='live_clock'>{formatTime(time)}</div>
                    </div>

                </div>




                {IsLogin ? <>
                    <div className='header_user_hey' ref={dropdownRef}>
                        <div className=' d-flex  align-items-center justify-content-center header_profile_main_divisoin  ms-2' onClick={(() => { setProfile(!profile) }
                        )}>
                            <div className='header_profile_image_division'>
                                <img className=' header_profile_image' src={userdata['Img']}></img>
                            </div>
                            <div className='header_profile_header_name'>Hi,{userdata['Name']}</div>
                            <div><i class={profile ? "fa-solid fa-chevron-down ms-2" : "fa-solid fa-chevron-up header_profile_arrow_up_icon ms-2"}></i></div>
                            {!profile ? <div className='header_profile_division'>
                                <div className='header_profile_division_header'>
                                    <div className='header_profile_header_profile_image_division'><img className='header_profile_header_profile_image' src={userdata['Img']}></img></div>
                                    <div >
                                        <p className='header_profile_header_name'>{userdata['Name']}</p>
                                        <p className='header_profile_header_postname'>Client:{userdata['displayId'].replace('PT', 'SH')}</p>
                                    </div>
                                </div>
                                <div className='header_profile_list'
                                    onClick={() => {
                                        navigate('/dashboard')
                                    }}
                                ><i class="fa-solid fa-table-columns me-2"></i>  Dashboard</div>
                                <div className='header_profile_list'
                                    onClick={() => {
                                        navigate('/profile-setting')
                                    }}
                                ><i class="fa-solid fa-gears me-2"></i> Profile Settings</div>
                                <div className='header_profile_list'
                                    onClick={() => {
                                        navigate('/change-password')
                                    }}
                                ><i class="fa-solid fa-lock me-2"></i> Change Password</div>
                                <div className='header_profile_list'
                                    onClick={() => {
                                        localStorage.clear()
                                        dispatch({
                                            type: "Logout"
                                        })
                                        dispatch({ type: 'NotRegister' })

                                        navigate("")
                                    }}
                                ><i class="fa-solid fa-right-from-bracket me-2"></i> Log Out</div>
                            </div> : null} </div>
                    </div></> : null}



                {IsRegister && IsLogin ?
                    <div className="header_contact_profile_main_division">

                        <div className="notification_container">

                            {/* <div className="notification_icon"
                                onClick={toggleDropdown}
                            >
                                <i class="fa-solid fa-bell fa-xl " style={{ color: "grey" }}></i>
                            </div> */}

                            <IconButton

                                aria-label="more"
                                id="long-button"
                                aria-haspopup="true"
                                onClick={toggleDropdown}
                            >

                                <Badge badgeContent={totalUnseenNotification} color="primary">
                                    <i class="fa-solid fa-bell "></i>
                                </Badge>
                            </IconButton>


                            {isOpen && (
                                <div className="notifications_dropdown" ref={dropdownRef1}>
                                    <div className='d-flex justify-content-between'>         <h3 className='notification_heading'>Notifications</h3>
                                        <h3 className='notification_heading' style={{ color: '#BE5A9F', textDecoration: 'underline' }}>Clear All</h3>
                                    </div>


                                    {/* <div className="notification">
                                        <img src="profile1.png" alt="Profile" className="profile_pic" />
                                        <div className="notification_info">
                                            <p><strong>Travis Tremble</strong></p>
                                            <p>Sent a amount of <strong>$210</strong> for his Appointment</p>
                                            <p className="doctor">Dr. Ruby Perin</p>
                                        </div>
                                        <div className="time">18:30 PM</div>
                                    </div> */}

                                    {notifications.map((notification) => {
                                        const parseMessage = (message) => {
                                            const parts = message.split(" at ");
                                            const username = parts[0]
                                                .replace("Appointment request for ", "")
                                                .trim();
                                            const dateTimePart = parts[1]
                                                ? parts[1].split(" is ")[0]
                                                : "";
                                            const [date, timeRange] = dateTimePart.split(" ");
                                            return { username, date, timeRange };
                                        };

                                        const { username, date, timeRange } = parseMessage(
                                            notification.Message
                                        );

                                        if (notification.Type == "PendingAppointment")
                                            return (
                                                <div className="notification">
                                                    <div className="notification_info">
                                                        {/* <p><strong>{username}</strong></p> */}
                                                        <p>{notification.Message}</p>
                                                        <p className="doctor">
                                                            {getTimeDifference(notification.Datetime)}
                                                        </p>
                                                    </div>
                                                </div>
                                            );

                                        else if (notification.Type == "Appointment") {
                                            return (
                                                <div className="notification">
                                                    <div className="notification_info">
                                                        <p>{notification.Message}</p>
                                                        <p className="doctor">
                                                            {getTimeDifference(notification.Datetime)}
                                                        </p>
                                                    </div>
                                                </div>
                                            );

                                        } else if (notification.Type == "Registration") {
                                            return (
                                                <div className="notification">
                                                    <img
                                                        src={notification.img}
                                                        alt="Profile"
                                                        className="profile_pic"
                                                    />
                                                    <div className="notification_info">
                                                        <p>{notification.Message}</p>
                                                        <p className="doctor">
                                                            {getTimeDifference(notification.Datetime)}
                                                        </p>
                                                    </div>
                                                </div>
                                            );

                                        } else if (notification.Type == "FeePaid") {
                                            return (
                                                <div className="notification">
                                                    <div className="notification_info">
                                                        <p>{notification.Message}</p>
                                                        <p className="doctor">
                                                            {getTimeDifference(notification.Datetime)}
                                                        </p>
                                                    </div>
                                                </div>
                                            );

                                        } else if (notification.Type == "MedicalRecords") {
                                            return (
                                                // <div
                                                //   style={{
                                                //     display: "flex",
                                                //     alignItems: "center",
                                                //     borderBottom: "1px solid #d5d5d5",
                                                //     padding: "5px",
                                                //   }}
                                                // >
                                                //   <MenuItem
                                                //     style={notificationstyle(notification.IsSeen)}
                                                //   >
                                                //     <div
                                                //       style={{ whiteSpace: "pre-wrap" }}
                                                //       onClick={() => handleSeen(notification)}
                                                //     >
                                                //       <div>
                                                //         <img
                                                //           className="doctor_header_notification_patient_image"
                                                //           onClick={() => {
                                                //             navigate(
                                                //               "/mitwank/clientprofile/" +
                                                //                 notification.User
                                                //             );
                                                //           }}
                                                //           src={notification.img}
                                                //         ></img>
                                                //       </div>
                                                //       <div className="text_notification">
                                                //         {notification.Title}
                                                //       </div>
                                                //       <div className="text_notification">
                                                //         {notification.Message}
                                                //       </div>
                                                //       <div className="text_notification">
                                                //         {getTimeDifference(notification.Datetime)}
                                                //       </div>
                                                //     </div>
                                                //     <IconButton
                                                //       aria-label="delete"
                                                //       size="small"
                                                //       onClick={() => handleDelete(notification.id)}
                                                //     >
                                                //       <Close fontSize="small" />
                                                //     </IconButton>
                                                //   </MenuItem>
                                                // </div>

                                                <div className="notification">
                                                    <div className="notification_info">
                                                        <p>{notification.Message}</p>
                                                        <p className="doctor">
                                                            {getTimeDifference(notification.Datetime)}
                                                        </p>
                                                    </div>
                                                </div>
                                            );

                                        } else if (notification.Type == "Receptionist") {
                                            return (
                                                <div className="notification">
                                                    <div className="notification_info">
                                                        <p>{notification.Message}</p>
                                                        <p className="doctor">
                                                            {getTimeDifference(notification.Datetime)}
                                                        </p>
                                                    </div>
                                                </div>
                                            );

                                        } else if (notification.Type == "Invoice") {
                                            return (
                                                <div className="notification">
                                                    <div className="notification_info">
                                                        <p>{notification.Message}</p>
                                                        <p className="doctor">
                                                            {getTimeDifference(notification.Datetime)}
                                                        </p>
                                                    </div>
                                                </div>
                                            );

                                        } else if (notification.Type == "Offer&Event") {
                                            return (
                                                <div className="notification">
                                                    <div className="notification_info">
                                                        <p>{notification.Message}</p>
                                                        <p className="doctor">
                                                            {getTimeDifference(notification.Datetime)}
                                                        </p>
                                                    </div>
                                                </div>
                                            );

                                        } else if (notification.Type == "DeleteRequest") {
                                            return (
                                                <div className="notification">
                                                    <div className="notification_info">
                                                        <p>{notification.Message}</p>
                                                        <p className="doctor">
                                                            {getTimeDifference(notification.Datetime)}
                                                        </p>
                                                    </div>
                                                </div>
                                            );


                                        }

                                        else {
                                            <div>
                                            </div>
                                        }
                                    })}
                                </div>
                            )}
                        </div>


                        <div>
                            {/* <IconButton

                                aria-label="more"
                                id="long-button"
                                aria-haspopup="true"
                            onClick={handleBellClick}
                            >

                                <Badge badgeContent={totalUnseenNotification} color="primary">
                                    <i class="fa-solid fa-bell "></i>
                                </Badge>
                            </IconButton> */}


                        </div>
                        {/* <div style={{ marginLeft: '20px' }}>
                            <div className='notification_sidebar' style={barstyle} ref={notificationSidebarRef} >
                                <div className='notificitaion_title_div'>
                                    <div className='doctor_header_notification_title'>Notifications</div>
                                    <div onClick={handleRead} style={{ borderBottom: '1px solid grey', color: '#483a15' }}>Clear All</div>
                                    <div className='icon_div' onClick={() => {
                                        setbar(false)
                                    }}>
                                        <i class="fa-solid fa-xmark" style={{ color: 'black' }} onClick={() => {
                                            setbar(false)
                                        }}></i>
                                    </div>
                                </div>
                                <div className='notification_main_div' style={{ marginTop: '50px', overflowY: 'scroll', backgroundColor: 'white' }}>
                                    {notifications.map((notification) => {

                                        return (
                                            <div style={{ display: "flex", alignItems: "center", borderBottom: '1px solid #d5d5d5' }}>
                                                <MenuItem style={notificationstyle(notification.IsSeen)}>
                                                    <div style={{ whiteSpace: "pre-wrap" }} onClick={() => handleSeen(notification)}>
                                                        <div className='text_notification'>{notification.Title}</div>
                                                        <div className='text_notification'>{notification.Message}</div>
                                                        <div className='text_notification'>{getTimeDifference(notification.Datetime)}</div>
                                                    </div>
                                                    <IconButton aria-label="delete" size="small" onClick={() => handleDelete(notification.id)}>
                                                        <Close fontSize="small" />
                                                    </IconButton>
                                                </MenuItem>
                                            </div>
                                        )


                                    })}
                                </div>
                            </div>
                        </div> */}

                    </div>

                    :

                    <div className='header_login_signup_btn_division'>
                        <button className='btn header_login_signup_btn'
                            onClick={() => {
                                navigate('/register')
                                localStorage.clear()
                                dispatch({ type: "Logout" })
                                dispatch({ type: 'NotRegister' })

                            }}
                        >Register / Signup</button>
                    </div>
                }
            </div>
        </header>

    )
}
export default Header;