import { useEffect, useState, usestate } from 'react';
import '../../App.css';
import './AddPrescription.css';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import '../Mitwank/Sidebar.css'
import React from 'react';
import { useStateValue } from '../StateProviders';

import { useNavigate, useLocation } from 'react-router-dom';


function AddPrescription() {

    const [{ Loading }, dispatch] = useStateValue();
    let navigate = useNavigate();
    const { state } = useLocation()
    const today = new Date();
    const currentDate = today.toDateString()
    const [PresData, setPresData] = useState([])
    const [Title, setTitle] = useState([])

    useEffect(() => {
        console.log(state)

        if (state["ADDType"]) {

            setPresData([])

        }

        else if (!state["ADDType"]) {
            dispatch({
                type: 'StartLoad'
            })
            var Url = window.API_PREFIX + 'admin/view_prescription'
            fetch(Url, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(
                    {
                        Token: localStorage.getItem('DToken'),
                        PatientId: state['PID'],
                        Title: state['Title'],
                        Date: state['Date'],




                    }
                ),
            }).then(resp => resp.json()).then(data => {
                console.log(data)
                dispatch({
                    type: 'StopLoad'
                })
                if (data['status'] === "1") {
                    setTitle(state['Title'])
                    setPresData([...data['prescriptiob']])
                }
            })

        }
    }, [])
    const columns = [
        {
            title: 'Sr No',

            dataIndex: 'name',
            key: 'name',

            render: (text, record, index) => <>
                <div className='add_prescription_table_image_name_division'>
                    <label class="add_prescription_checkbox_name form-check-label" for="exampleCheck1">{index + 1}</label>

                </div>
            </>

        },
        {
            title: 'Medicine Name',

            dataIndex: 'MedicineName',
            key: 'name',

            render: (text, record, index) => <>
                <div className='add_prescription_table_image_name_division'>

                    <input type="text" class=" add_prescription_name_division form-control" placeholder='Medicine Name' value={text}
                        onChange={(e) => {
                            var tmp = PresData
                            tmp[index]['MedicineName'] = e.target.value
                            setPresData(tmp)
                        }}
                    ></input>

                </div>
            </>

        },
        {
            title: 'Quantity',
            dataIndex: 'Quantity',
            key: 'Quantity',
            render: (text, record, index) => <>
                <div className='add_prescription_quantity_main_division'>

                    <input type='number' class='add_prescription_quantity_division form-control ' placeholder='0' value={text}
                        onChange={(e) => {
                            var tmp = PresData
                            tmp[index]['Quantity'] = e.target.value
                            setPresData(tmp)
                        }}
                    ></input>
                </div>
            </>
        },
        {
            title: 'Day',
            dataIndex: 'Days',
            key: 'Day',
            render: (text, record, index) => <>
                <div className='add_prescription_day_main_division'>
                    <input type='number' class='add_prescription_day_division form-control' placeholder='days' value={record.Days}
                        onChange={(e) => {
                            var tmp = PresData
                            tmp[index]['Days'] = e.target.value
                            setPresData(tmp)
                        }}
                    ></input>
                </div>
            </>

        },

        {
            title: 'Time',
            dataIndex: 'Time',
            key: 'Time',
            render: (text, record, index) => <>

                <div class="add_prescription_cheackbox_division form-check">
                    <div className='add_prescription_cheackbox_sub_division col'>
                        <input type="checkbox" class="add_prescription_cheacbox_button from-check-input" id="exampleCheck1" checked={record.Morning}
                            onClick={() => {
                                var tmp = PresData
                                tmp[index]['Morning'] = !tmp[index]['Morning']
                                setPresData([...tmp])
                            }}
                        ></input>
                        <label class="add_prescription_checkbox_name form-check-label" for="exampleCheck1">Morning</label>
                    </div>

                    <div className='add_prescription_cheackbox_sub_division col'>
                        <input type="checkbox" class="add_prescription_cheacbox_button from-check-input" id="exampleCheck1" checked={record.Afternoon}
                            onClick={() => {
                                var tmp = PresData
                                tmp[index]['Afternoon'] = !tmp[index]['Afternoon']
                                setPresData([...tmp])
                            }}
                        ></input>
                        <label class="add_prescription_checkbox_name form-check-label" for="exampleCheck1">Afternoon</label>

                    </div>
                    <div className='add_prescription_cheackbox_sub_division1 col '>
                        <input type="checkbox" class="add_prescription_cheacbox_button from-check-input" id="exampleCheck1" checked={record.Evening}
                            onClick={() => {
                                var tmp = PresData
                                tmp[index]['Evening'] = !tmp[index]['Evening']
                                setPresData([...tmp])
                            }}></input>
                        <label class="add_prescription_checkbox_name form-check-label" for="exampleCheck1">Evening</label>
                    </div>

                    <div className='add_prescription_cheackbox_sub_division col ' >
                        <input type="checkbox" class="add_prescription_cheacbox_button from-check-input" id="exampleCheck1" checked={record.Night}
                            onClick={() => {
                                var tmp = PresData
                                tmp[index]['Night'] = !tmp[index]['Night']
                                setPresData([...tmp])
                            }}
                        ></input>
                        <label class="add_prescription_checkbox_name form-check-label" for="exampleCheck1">Night</label>
                    </div>
                    <div className='add_prescription_delate_icon_division'
                        onClick={() => {
                            var tmp = PresData
                            tmp.splice(index, 1)
                            setPresData([...tmp])
                        }}
                    >
                        <i class="add_prescription_delate_icon fa-regular fa-trash-can"></i>
                    </div>

                </div>
            </>
        },
        {

            title: '',
            key: 'tags',
            dataIndex: 'tags',
            render: (_, { tags }) => (
                <>
                    <div></div>
                </>
            ),
        },
    ];
    const data = [
        {
            key: '1',
            patientname: 'John Brown',
            date: 32,
            purpose: 'General',
            type: 'New Patient',
            paidamount: 2000,
            tags: ['nice', 'developer'],
        },
        {
            key: '1',
            patientname: 'John Brown',
            date: 32,
            purpose: 'General',
            type: 'New Patient',
            paidamount: 2000,
            tags: ['nice', 'developer'],
        },
        {
            key: '1',
            patientname: 'John Brown',
            date: 32,
            purpose: 'General',
            type: 'New Patient',
            paidamount: 2000,
            tags: ['nice', 'developer'],
        },

    ];

    return (

        <>
            {/* breadcrumbs-start */}
            <div className='add_prescription_main_division'>
                <div className='add_prescription_division'>
                    <p className='add_prescription_title'>home</p>
                    <p className='add_prescription_title1'>/</p>
                    <p className='add_prescription_title2'>{state["ADDType"] ? "Add" : "Edit"} prescription</p>
                </div>
                <div>
                    <h3 className='add_prescription_title_main'>{state["ADDType"] ? "Add" : "Edit"}  prescription</h3>
                </div>
            </div>
            {/* breadcrumbs-end */}

            <div className="container-fuild">
                <div className='slidebar_and_component'>
                    <div className='sidebar_main_division'>
                        <div className='sidebar_division'></div>
                    </div>
                    <div className='component_division'>
                        <div className='add_prescription_diviison'>
                            <div className='add_prescription_main_diviison'>

                                <div className='add_prescription_edit_division'>
                                    <div className='add_prescription_edit_Name'>{state["ADDType"] ? "Add" : "Edit"}  Prescription</div>
                                </div>
                                <div class='add_prescription_second_main_division'>
                                    <div className='add_prescription_main_coach_info'>
                                        <div className='add_prescription_coach_info'>
                                            <div className='add_prescription_coach_name'>Dr.Eric Buckles</div>
                                            <div className='add_prescription_coach_job'>Dentist</div>
                                            <div className='add_prescription_coach_city'>Newyork,United States</div>

                                            <div className='add_prescription_day_main_division'>
                                                <input type='text' class='add_prescription_day_division form-control' placeholder='Title' value={Title} disabled={!state["ADDType"]}
                                                    onChange={(e) => {
                                                        setTitle(e.target.value)
                                                    }}
                                                ></input>
                                            </div>
                                        </div>

                                        <div className='add_prescription_date_division'>
                                            <div>
                                                <div className='add_prescription_date'>{state["ADDType"] ? "" : ""}</div>
                                                <div className='add_prescription_id_code
                                                '>{state["ADDType"] ? currentDate : ""} </div>
                                                <div>
                                                    <div className='add_prescription_Add_more'
                                                        onClick={() => {
                                                            var tmp = PresData
                                                            tmp.push(
                                                                {
                                                                    // MedicineName:"",
                                                                    // Quantity:0,
                                                                    // Days:0,
                                                                    Morning: false,
                                                                    Afternoon: false,
                                                                    Evening: false,
                                                                    Night: false,
                                                                })
                                                            setPresData([...tmp])
                                                        }}

                                                    ><i class="coach_patient_profile_plue_icon fa-solid fa-circle-plus"></i> Add more</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className='add_prescription_patient_appointment_table_division'>
                                        <Table columns={columns} dataSource={PresData} />
                                    </div>

                                    {/* <div className='add_prescription_click_here_main_division'>
                                        <div className='add_prescription_click_bar'>
                                            Click here to sign
                                        </div>

                                    </div>
                                    <div className='add_prescription_docter_name_dr_main_division'>
                                        <div className='add_prescription_docter_name_dr_division'>
                                            <div>
                                                <div className='add_prescription_docter_dr'>( Dr. Eric Buckles )</div>
                                                <div className='add_prescription_signature_division'>Signature</div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className='add_prescription_button_main_division'>
                                        <div className='add_prescription_save_button_division'>
                                            <button type="button" class="add_prescription_save_button "
                                                onClick={() => {

                                                    if (state['ADDType']) {
                                                        console.log(PresData)
                                                        dispatch({
                                                            type: 'StartLoad'
                                                        })
                                                        var Url = window.API_PREFIX + 'admin/add_prescription'
                                                        fetch(Url, {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-type': 'application/json',
                                                            },
                                                            body: JSON.stringify(
                                                                {
                                                                    Token: localStorage.getItem('DToken'),
                                                                    PatientId: state.PID,
                                                                    Data: PresData,
                                                                    Title: Title


                                                                }
                                                            ),
                                                        }).then(resp => resp.json()).then(data => {
                                                            console.log(data)
                                                            dispatch({
                                                                type: 'StopLoad'
                                                            })
                                                            if (data['status'] === "1") {
                                                                navigate("/mitwank/patientprofile/" + state.PID)
                                                            }
                                                        })
                                                    }
                                                    else {
                                                        var Url = window.API_PREFIX + 'admin/edit_prescription'
                                                        dispatch({
                                                            type: 'StartLoad'
                                                        })
                                                        fetch(Url, {
                                                            method: 'POST',
                                                            headers: {
                                                                'Content-type': 'application/json',
                                                            },
                                                            body: JSON.stringify(
                                                                {
                                                                    Token: localStorage.getItem('DToken'),
                                                                    PatientId: state.PID,
                                                                    Data: PresData,
                                                                    Title: Title


                                                                }
                                                            ),
                                                        }).then(resp => resp.json()).then(data => {
                                                            console.log(data)
                                                            dispatch({
                                                                type: 'StopLoad'
                                                            })
                                                            if (data['status'] === "1") {
                                                                navigate("/mitwank/patientprofile/" + state.PID)
                                                            }
                                                        })
                                                    }

                                                }}
                                            >Save</button>
                                        </div>
                                        <div className='add_prescription_clear_button_divison'>
                                            <button type="button" class="add_prescription_clear_button">Clear</button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>


                    </div>

                </div>

            </div>
        </>
    );

};

export default AddPrescription;