import React from 'react'
import './Topbar.css'

function Topbar() {
  return (
   <div className='top_main_div '>
    <div className='kodenauts'>
    Welcome to S&H Aesthetic Clinic
    </div>
   </div>
  )
}

export default Topbar
