import './AboutUs.css';
import '../../App.css';
import { useNavigate } from 'react-router-dom';
import logophoto from '../../media/about/Layer_1_coupon.png';
import logo2photo from '../../media/about/Layer_1_money.png';
import logo3photo from '../../media/about/card.png';
import logo4photo from '../../media/about/Layer_1_payment.png';
import missioniamge from '../../media/about/viiny.png';
import award from '../../media/about/award.jpeg';
import awardtwo from '../../media/about/awardtwo.jpeg';

function AboutUs() {
    let navigate = useNavigate();

    return (
        <>

            <div className='main_div_about_page ' >

                <div className='about_header_div'>
                    <div className='about_div'>
                        <h1 style={{ color: 'white' }}>About Us</h1>
                    </div>
                    <div className='about_heading_div'>
                        <span> We are a young and creative company and<br></br> we offer you fresh business ideas.</span>

                    </div>
                </div>

            </div >

            <div className='expertise_main_div container'>



                <div className='expertise_div'>
                    <div style={{ color: '#9e9f7f', fontSize: '20px' }}>OUR EXPERTISE</div>
                    <div className='future_div'>
                        <h3 style={{ color: '#313C4C' }}>Behavioural Training </h3>
                        <h3 style={{ color: '#313C4C' }}>Sales Training </h3>
                        <h3 style={{ color: '#313C4C' }}>Personal Coaching </h3>

                    </div>
                    <div className='heading_expertise_div'>
                        <span style={{ color: '#8E836E' }}>We bring the breathe of our experience <br></br>and  knowledge to help you succeed</span>
                    </div>
                </div>


                {/* <div className='card_1_div'>
                    <div className='developing_div'>
                        <div className='card_div' style={{ marginRight: '20px', marginBottom: '20px' }} >
                            <img src={logophoto} className='logo_image'></img>
                            <div className='content_div'>
                                <h3>Developing Best Financing <br></br>Options</h3>
                            </div>
                        </div>

                        <div className='card_div'>
                            <img src={logo2photo} className='logo_image'></img>
                            <div className='content_div'>
                                <h3>Financing Modeling and <br></br>Analytics</h3>
                            </div>
                        </div>
                    </div>

                    <div className='developing_div' >
                        <div className='card_div' style={{ marginLeft: '20px', marginBottom: '20px' }} >

                            <img src={logo3photo} className='logo_image'></img>

                            <div className='content_div'>
                                <h3>Improving Your
                                    <br></br>Business  Planning</h3>
                            </div>
                        </div>

                        <div className='card_div'>

                            <img src={logo4photo} className='logo_image'></img>

                            <div className='content_div'>
                                <h3>Delivering New  <br></br>Financing Solutions</h3>
                            </div>
                        </div>

                    </div>
                </div> */}
            </div>

            <div className='container mt-5 mb-5'>
                <h3 className='mt-3'>
                    1) I believe that every individual has the power to achieve greatness, and I'm here to support you along the way. Let's work together to identify your strengths, address your weaknesses, and create a roadmap for success. With dedication and hard work, I'm confident that you'll be able to reach your true potential.
                </h3>
                <h3 className='mt-3'>
                    2) Coaching entrepreneurs to create a practical business plan by analysing their goals, resources, market trends, and competition is crucial. A coach's role is to provide honest feedback that helps entrepreneurs achieve their goals.
                </h3>
                <h3 className='mt-3'>
                    3) By fostering a culture of empowerment and motivation, we can help our employees reach their full potential and achieve greater levels of productivity and efficiency. By boosting team morale and encouraging personal growth, we create an environment that is conducive to success and allows everyone to thrive.
                </h3>
                <h3 className='mt-3'>
                    4) Assisting the team in achieving exceptional sales growth by utilizing the optimal strategies, expertise, and effective closing tactics.
                </h3>
            </div>

            < div className=" third_part_mian_div container" >
                <div class="some_facts row " style={{ display: 'flex' }}>
                    <div class=" col-md-4  col-12 part_1_div">
                        <div className='service'>
                            01
                        </div>
                        <div className='content_part'>
                            <p>Discover the power of personal<br></br> coaching and training with us</p>
                        </div>

                    </div>
                    <div className='col-md-4 col-12 part_1_div'>
                        <div className='service'>
                            02
                        </div>
                        <div className='content_part'>
                            <p>We offer top notch group inbound training,designed <br></br>to help you and your team achieve your goals.</p>
                        </div>
                    </div>
                    <div className='col-md-4  col-12 part_1_div'>
                        <div className='service'>
                            03
                        </div>
                        <div className='content_part'>
                            <p>Take your skills to the next level with our outbounds <br></br>training-featuring exciting retreats and activities.</p>
                        </div>

                    </div>
                </div>
            </div >

            <div className='container'>
                <div style={{ color: '#9e9f7f', fontSize: '20px' }}>ABOUT</div>

                <div className='mission_main_div row '>
                    <div className='future_div col-12 col-md-7'>
                        <h3 style={{ color: '#313C4C' }}>Vinny Ritu Agarwal is a seasoned professional with over 5 years of
                            experience in behavioral training. Her expertise lies in working with a
                            diverse range of industries, where she has honed her skills and
                            developed a deep understanding of human behavior. Vinny's
                            passion for helping others grow and succeed is evident in her work,
                            where she combines her knowledge and experience to create
                            impactful training programs. Her dedication to her field makes her a
                            valuable asset to any organization looking to enhance their
                            employees' performance and overall well-being.
                        </h3>
                        <h3 className='introduction_div'>
                            She is a truly inspirational figure who started her
                            journey at such a young age and worked hard to become
                            one of the finest coaches in behavioural training for
                            sales, efficiency, and business aspects. It's a testament
                            to their dedication and passion for their work.
                        </h3>
                        <div className='about_list'>
                            - Youngest business coach.
                        </div>
                        <div className='about_list'>
                            - Featured in ANI, Business Standard, yourstory, the print, and many other platforms.
                        </div>
                        <div className='about_list'>
                            - Women entrepreneur of the year 2022.
                        </div>
                        <div className='about_list'>
                            - Best Skill Trainer 2023 by Kiran Bedi.
                        </div>

                    </div>

                    <div className='mission_photo col-12 col-md-5' >
                        <img src={missioniamge} className='mission_image'></img>
                    </div>


                </div>

            </div>



            <div className='tag_contact_background'>
                <div className='tag_contact_div '>
                    <h1 className='tag_line'>Don't wait any longer to invest in your Personal & Professional Development !!</h1>
                    <button type="button" className="btn contact_btn  " onClick={(() => (
                        navigate('/contact')
                    ))}>Conatct Us</button>

                </div>
            </div>

            <div className='container'>
                <div className='mission_vision_main_div row'>
                    <div className='vinny_mission col-md-5 col-12 col-lg-5'>
                        <h1>MISSION</h1>
                        <div className='empower_content'>
                            <p>"Empowering individuals and organizations to thrive by transforming work culture,enhancing human
                                efficiency,and fostering personal fulfillment.Our mission is to expand our services across India  and the U.S
                                by 2028,touching lives  creating positive change through coaching and support"</p>
                        </div>
                    </div>


                    <div className='vinny_mission col-md-5 col-12 col-lg-5'>
                        <h1>VISION</h1>
                        <div className='empower_content'>
                            <p>"Our vision is to be the leading provider of behavioral coaching,empowering indivuals worldwide to unlock their goals,and lead filfilling lives. through innoavative coaching methodologies and a commitment to excellence,we ail to inspire lasting change
                                and create a positive impact on indiviuals,organization and communities"</p>
                        </div>
                    </div>


                </div>

            </div>

            <div className='container'>
                <h1>Awards</h1>
                <div className='row'>
                    <img className='col-12 col-md-6' src={award}></img>
                    <img className='col-12 col-md-6' src={awardtwo}></img>
                </div>

            </div>





        </>
    )
}


export default AboutUs