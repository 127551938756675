import React, { useState, useEffect } from 'react'
import './Newsletter.css'
import MitwankSidebar from "./MitwankSidebar";
import { Table } from 'antd';
import "../../App.css"

function Transaction() {
    const [transactionList, setTransactionList] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5,
        pageSizeOptions: ['5', '10', '20', '50', '100'],
        showSizeChanger: true,
    });

    useEffect(() => {
        const Url = window.API_PREFIX + "admin/allbillings";
        fetch(Url, {
            method: "GET",
        })
            .then((resp) => resp.json())
            .then((data) => {
                console.log(data);
                if (data["status"] === "1") {
                    setTransactionList([...data["Bill"]]);
                }
            });
    }, []);

    const receptionistcolumn = [
        {
            title: 'Sr No',
            dataIndex: 'srNo',
            render(text, record, index) {
                return {
                    props: {
                        style: { fontSize: "16px", textAlign: "center", color: '#483a15' }
                    },
                    children: <div style={{ fontWeight: '600' }}>{record?.id}</div>
                }
            }
        },
        {
            title: 'Invoice No',
            dataIndex: 'Invoiceno',
            render: (text, record, index) => (
                <div>
                    {record?.Invoice}
                </div>
            ),
        },
        {
            title: 'Clinic',
            dataIndex: 'Ptaient Id',
            render: (text, record, index) => (
                <div>
                    {record?.ClinicDetails?.ClinicName}
                </div>
            ),
        },
        {
            title: 'Patient Name',
            dataIndex: 'Name',
            key: 'name',
            render: (text, record, index) => (
                <div>
                    {record?.PatientDetails?.patientName}
                </div>
            ),
        },
        {
            title: 'Total Amount',
            dataIndex: 'totalamount',
            render: (text, record, index) => (
                <div>
                    {record?.Amount}
                </div>
            ),
        },
        {
            title: 'Paid On',
            dataIndex: 'status',
            render: (text, record, index) => (
                <div>
                    {record?.Paidon}
                </div>
            ),
        },
        // {
        //     title: 'Action',
        //     dataIndex: 'Actions',
        //     key: 'Actions',
        //     render: (text, record, index) => (

        //         <>
        //             <i class="fa-solid fa-trash-can" style={{ color: '#e11f28' }}
             
        //             ></i>
        //         </>
        //     )
        // }
    ];
    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };


    const downloadCSV = () => {
        const csvData = convertToCSV(transactionList);
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'data.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const convertToCSV = (data) => {
        const csvRows = [];

        // Get headers
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(','));

        // Loop over the rows
        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        }

        return csvRows.join('\n');
    };

    return (
        <>

            <div className="container-fuild mx-2 mt-2">
                <div className='mitwank_newsletter_main_diviison'>
                    <div className='col d-flex justify-content-end align-items-center'>
                        <button type="button" class="btn export_button mb-3 me-4" onClick={downloadCSV} >Export</button>
                    </div>
                </div>
                <div className='mitwank_newsletter_table_main_division'>
                    <div className='mitwank_newsletter_table_division'>
                        <Table columns={receptionistcolumn} dataSource={transactionList} pagination={pagination}
                            onChange={handleTableChange} />
                    </div>
                </div>
            </div >
        </>
    )
}

export default Transaction