import React from "react";
import "./MitwankChangePassword.css";
import { useState, useEffect } from "react";
import "../Mitwank/Sidebar.css";
import MitwankSidebar from "./MitwankSidebar";
import toast from "react-hot-toast";
import TextField from "@mui/material/TextField";

function Addpackage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeUpdateModal = () => {
    setIsEditing(false);
  };

  const handleOutsideClick = (event) => {
    if (event.target.id === "myModal") {
      closeModal();
    }
  };
  const [packageList, setPackageList] = useState([]);
  const [packagename, setpackagename] = useState("");
  const [discription, setdiscription] = useState("");
  const [price, setprice] = useState("");
  const [duration, setduration] = useState("");
  const [isRequiredpackagename, setIsprequiredpackagename] = useState(true);
  const [isRequireddiscription, setIsRequireddiscription] = useState(true);
  const [isRequiredprice, setIsRequiredprice] = useState(true);
  const [isRequiredduration, setIsRequiredduration] = useState(true);

  const [selectedData, setSelectedData] = useState({
    id: "",
    packageName: "",
    packageDescription: "",
    packagePrice: "",
    packageDuration: "",
  });

  useEffect(() => {
    const Url = window.API_PREFIX + "patient/packagelist";
    fetch(Url, {
      method: "GET",
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setPackageList([...data["packageList"]]);
        }
      });
  }, []);


  const checkSubmitBtnDisable = () => {
    if (!isRequiredpackagename && !isRequireddiscription && !isRequiredprice && !isRequiredduration) {
      return false
    } else {
      return true
    }
  }

  function getPackages() {
    var Url = window.API_PREFIX + "patient/packagelist";
    fetch(Url, {
      method: "GET",
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setPackageList([...data["packageList"]]);
        }
      });
  }

  function deletePackage(packageId) {
    var Url = window.API_PREFIX + "admin/trashpackage";
    var uploadData = new FormData();
    uploadData.append("Id", packageId);
    fetch(Url, {
      method: "POST",
      body: uploadData,
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          getPackages();
        }
      });
  }

  return (
    <>
      <div className="container-fuild mx-2">
        <div class="text-end mt-2">
          <button
            class="mitwank_patient_profile_add_prescription_btn"
            onClick={openModal}
          >
            + Add Package
          </button>

          {isModalOpen && (
            <div id="myModal" className="modal" onClick={handleOutsideClick}>
              <div className="modal-content">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Add Package</h5>
                  <i
                    onClick={closeModal}
                    class="fa-solid fa-xmark fa-x close"
                  ></i>
                </div>
                <div>
                  <TextField
                    id="outlined-basic"
                    label="Package Name"
                    variant="outlined"
                    size="small"
                    className="w-100 mt-3"
                    onChange={(e) => {
                      e.target.value !== "" ? setIsprequiredpackagename(false) : setIsprequiredpackagename(true)
                      setpackagename(e.target.value)
                    }}
                    required
                  />

                  <textarea
                    class="form-control mt-3"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    placeholder="Add Discription"
                    onChange={(e) => {
                      e.target.value !== "" ? setIsRequireddiscription(false) : setIsRequireddiscription(true)
                      setdiscription(e.target.value)
                    }}
                  ></textarea>

                  <TextField
                    type="number"
                    id="outlined-basic"
                    label="Package Price"
                    variant="outlined"
                    size="small"
                    className="w-100 mt-3"
                    onChange={(e) => {
                      e.target.value !== "" ? setIsRequiredprice(false) : setIsRequiredprice(true)
                      setprice(e.target.value)
                    }}
                    required
                  />

                  <TextField
                    id="outlined-basic"
                    label="Package Duration"
                    variant="outlined"
                    size="small"
                    className="w-100 mt-3"
                    onChange={(e) => {
                      e.target.value !== "" ? setIsRequiredduration(false) : setIsRequiredduration(true)
                      setduration(e.target.value)
                    }}
                    required
                  />

                  <div class="d-flex justify-content-center mt-3">
                    <button
                      type="button"
                      class="popup_submit_button btn"
                      disabled={checkSubmitBtnDisable()}
                      onClick={() => {
                        var Url = window.API_PREFIX + "patient/addnewpackage";
                        var uploadData = new FormData();
                        uploadData.append(
                          "Token",
                          localStorage.getItem("DToken")
                        );
                        uploadData.append("packageName", packagename);
                        uploadData.append("packageDescription", discription);
                        uploadData.append("packagePrice", price);
                        uploadData.append("packageDuration", duration);
                        fetch(Url, {
                          method: "POST",
                          body: uploadData,
                        })
                          .then((resp) => resp.json())
                          .then((data) => {
                            console.log(data);
                            if (data["status"] === "1") {
                              closeModal();
                              getPackages();
                              toast.success("Added Successfully");
                            }
                          });
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {isEditing && (
          <div id="myModal" className="modal" onClick={handleOutsideClick}>
            <div className="modal-content">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Edit Package</h5>
                <i
                  onClick={closeUpdateModal}
                  class="fa-solid fa-xmark fa-x close"
                ></i>
              </div>
              <div>
                <TextField
                  id="outlined-basic"
                  label="Package Name"
                  variant="outlined"
                  size="small"
                  className="w-100 mt-3"
                  value={selectedData?.packageName}
                  onChange={(e) => {
                    setSelectedData((prevData) => ({
                      ...prevData,
                      packageName: e.target.value,
                    }));
                  }}
                  required
                />

                <textarea
                  class="form-control mt-3"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder="Add Discription"
                  value={selectedData?.packageDescription}
                  onChange={(e) => {
                    setSelectedData((prevData) => ({
                      ...prevData,
                      packageDescription: e.target.value,
                    }));
                  }}

                  
                ></textarea>

                <TextField
                  type="number"
                  id="outlined-basic"
                  label="Package Price"
                  variant="outlined"
                  size="small"
                  className="w-100 mt-3"
                  value={selectedData?.packagePrice}
                  onChange={(e) => {
                    setSelectedData((prevData) => ({
                      ...prevData,
                      packagePrice: e.target.value,
                    }));
                  }}
                  required
                />

                <TextField
                  id="outlined-basic"
                  label="Package Duration"
                  variant="outlined"
                  size="small"
                  className="w-100 mt-3"
                  value={selectedData?.packageDuration}
                  onChange={(e) => {
                    setSelectedData((prevData) => ({
                      ...prevData,
                      packageDuration: e.target.value,
                    }));
                  }}
                  required
                />

                <div class="d-flex justify-content-center mt-3">
                  <button
                    type="button"
                    class="popup_submit_button btn"
                    onClick={() => {
                      var Url = window.API_PREFIX + "patient/editpackage";
                      var uploadData = new FormData();
                      uploadData.append(
                        "Token",
                        localStorage.getItem("DToken")
                      );
                      uploadData.append("id", selectedData?.id);
                      uploadData.append(
                        "packageName",
                        selectedData?.packageName
                      );
                      uploadData.append(
                        "packageDescription",
                        selectedData?.packageDescription
                      );
                      uploadData.append(
                        "packagePrice",
                        selectedData?.packagePrice
                      );
                      uploadData.append(
                        "packageDuration",
                        selectedData?.packageDuration
                      );
                      fetch(Url, {
                        method: "POST",
                        body: uploadData,
                      })
                        .then((resp) => resp.json())
                        .then((data) => {
                          console.log(data);
                          if (data["status"] === "1") {
                            closeUpdateModal();
                            getPackages();
                            setSelectedData({
                              id: "",
                              packageName: "",
                              packageDescription: "",
                              packagePrice: "",
                              packageDuration: "",
                            });

                            toast.success("Edited Successfully");
                          }
                        });
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          {packageList.map((item, id) => {
            return (
              <div
                className=" col col-12 col-md-4"
                style={{ padding: "10px" }}
                key={id}
              >
                <div
                  style={{
                    padding: "10px",
                    border: "1px solid #E5E7EB",
                    borderRadius: "25px",
                    cursor: "pointer",
                    textAlign: "start",
                    backgroundColor: "#fff",
                  }}
                >
                  <h4>{item?.packageName}</h4>
                  <span>{item.packageDescription}</span>
                  <h3 className="mt-2">{item.packagePrice}</h3>
                  <h4 className="mt-2 mb-3">{item.packageDuration}</h4>
                  <div className="d-flex">
                    <div className="small_icon me-2">
                      <i
                        class="fa-solid fa-pen"
                        style={{ color: "green" }}
                        onClick={() => {
                          setIsEditing(true);
                          setSelectedData({
                            id: item?.id,
                            packageName: item?.packageName,
                            packageDescription: item?.packageDescription,
                            packagePrice: item?.packagePrice,
                            packageDuration: item?.packageDuration,
                          });
                        }}
                      ></i>
                    </div>
                    <div className="small_icon">
                      <i
                        class="fa-solid fa-trash"
                        style={{ color: "red" }}
                        onClick={() => {
                          deletePackage(item?.id);
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Addpackage;
