import "./ClientDashboard.css";
import "../../App.css";
import "antd/dist/antd.css";
import { Table } from "antd";
import "../Client/Sidebar.css";
import React from "react";
import { useEffect, useState, useRef } from "react";
import Popup from "reactjs-popup";
import "swiper/css";
import "swiper/css/pagination";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import toast from "react-hot-toast";
import { ClipLoader } from "react-spinners";
import image from "../../media/event6.jpg";
import { Popover } from "antd";

function ClientDashboard() {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    pageSizeOptions: ["5", "10", "20", "50", "100"],
    showSizeChanger: true,
  });

  const [GroupName, setGroupName] = useState("");
  const [chatSocket, setChatSocket] = useState(null);
  const [webSocketInitialized, setWebSocketInitialized] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [timers, setTimers] = useState({});
  const [offerList, setOfferList] = useState([]);
  const [isReadMore, setIsReadMore] = useState({ id: "", open: false });
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  // Function to close the modal
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };

  function getOfferList() {
    var Url = window.API_PREFIX + "admin/alloffers";
    fetch(Url, {
      method: "GET",
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setOfferList([...data["Offers"]]);
        }
      });
  }

  useEffect(() => {
    if (!webSocketInitialized && GroupName !== "") {
      const socket = new WebSocket(
        window.WEBSOC + "ws/ClientDashboard/" + GroupName
      );
      socket.onopen = () => {
        console.log("WebSocket connection opened");
        setWebSocketInitialized(true);
      };
      socket.onmessage = function (e) {
        let received_data_as_object = e["data"];
        if (received_data_as_object.split("_")[0] === "Reload") {
          get_appointment();
        }
      };

      socket.onclose = function (e) {
        console.error("Chat socket closed unexpectedly");
        setWebSocketInitialized(false);
      };
      setChatSocket(socket);
    }
  }, [GroupName]);

  useEffect(() => {
    const timerIntervals = {};

    eventList.forEach((event, index) => {
      const updateTimer = () => {
        const targetDate = new Date(`${event.Date} ${event.time}`).getTime();
        const currentTime = new Date().getTime();
        const difference = targetDate - currentTime;

        if (difference > 0) {
          setTimers((prev) => ({
            ...prev,
            [index]: {
              days: Math.floor(difference / (1000 * 60 * 60 * 24)),
              hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
              minutes: Math.floor((difference / 1000 / 60) % 60),
              seconds: Math.floor((difference / 1000) % 60),
            },
          }));
        } else {
          clearInterval(timerIntervals[index]);
          setTimers((prev) => ({
            ...prev,
            [index]: "Time is over",
          }));
        }
      };
      timerIntervals[index] = setInterval(updateTimer, 1000);
      updateTimer();
    });

    return () => {
      Object.values(timerIntervals).forEach(clearInterval);
    };
  }, [eventList]);

  function getEventList() {
    var Url = window.API_PREFIX + "patient/patientevents";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/");
          window.location.reload();
        } else if (data["status"] === "1") {
          setEventList([...data["Events"]]);
        }
      });
  }

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  function getprescription() {
    var Url = window.API_PREFIX + "patient/view_prescription";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/");
          window.location.reload();
        } else if (data["status"] === "1") {
          setuserPrescribeData([...data["data"]]);
        }
      });
  }

  function getmedicalrecord() {
    var Url = window.API_PREFIX + "patient/view_medicalrecords";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/");
          window.location.reload();
        } else if (data["status"] === "1") {
          setuserMedicalData([...data["records"]]);
        }
      });
  }

  function getbilling() {
    var Url = window.API_PREFIX + "patient/billingpatientwise";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/");
          window.location.reload();
        } else if (data["status"] === "1") {
          setuserBillingData([...data["Bill"]]);
        }
      });
  }
  const addmedicalrecordpopup = {
    borderRadius: "10px",
    backgroundColor: "white",
    borderradius: "5px",
    padding: "0px",
    width: "100%",
    height: "max-content",
    margin: "20px 450px",
  };
  const [userAppointmentData, setuserAppointmentData] = useState([]);
  const [userPrescribeData, setuserPrescribeData] = useState([]);
  const [userMedicalData, setuserMedicalData] = useState([]);
  const [addMedicalRecord, setAddMedicalRecord] = useState(false);
  const [TitleName, setTitleName] = useState();
  const [isRequiredTitleName, setIsRequiredTitleName] = useState(true);
  const [Symptoms, setSymptoms] = useState();
  const [isRequiredSymptoms, setIsRequiredSymptoms] = useState(true);
  const [RecordDate, setRecordDate] = useState("");
  const [MedicalRecord, setMedicalRecord] = useState();
  const [isRequiredMedicalRecord, setIsRequiredMedicalRecord] = useState(true);

  const [userBillingData, setuserBillingData] = useState([]);
  const [userData, setuserData] = useState({});
  const [branches, setBranches] = useState([]);

  const [selectedBranch, setSelectedBranch] = useState("");
  // console.log("selectedBranch: ", selectedBranch);

  const handleSelectChange = (event) => {
    setSelectedBranch(event.target.value);
  };

  useEffect(() => {
    const Url = window.API_PREFIX + "patient/patientclinics";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/");
          window.location.reload();
        } else if (data["status"] === "1") {
          setBranches([...data["allclinics"]]);
        }
      });
  }, []);

  function get_appointment() {
    var Url = window.API_PREFIX + "patient/userwiseappointment";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/");
          window.location.reload();
        } else if (data["status"] === "1") {
          setuserAppointmentData([...data["appointment"]]);
        }
      });
  }

  useEffect(() => {
    get_appointment();
    var Url = window.API_PREFIX + "patient/view_profile";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/");
          window.location.reload();
        } else if (data["status"] === "1") {
          setGroupName("User_" + data["id"] + "/");
          setuserData({ ...data });
        }
      });
  }, []);

  function delete_medicalrecord(Requestindex, id) {
    var Url = window.API_PREFIX + "admin/trash_delete_files";
    const index = userMedicalData.findIndex((records) => records.id === id);
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: userMedicalData[index]["id"],
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          getmedicalrecord();
        }
      });
  }

  const [DashboardOption, setDashboardOption] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
  ]);

  const handleTextareaChange = (e) => {
    e.target.value !== ""
      ? setIsRequiredTitleName(false)
      : setIsRequiredTitleName(true);
    setTitleName(e.target.value);
  };

  const checkSubmitBtnDisable = () => {
    if (
      !isRequiredTitleName &&
      !isRequiredMedicalRecord &&
      !isRequiredSymptoms
    ) {
      return false;
    } else {
      return true;
    }
  };

  let navigate = useNavigate();

  const [loaderon, setloaderon] = useState(false);

  const Prescribecolumns = [
    {
      title: "Clinic",
      key: "nameClinic",
      render: (text, record, index) => (
        <div>{record?.ClinicDetails?.ClinicName}</div>
      ),
    },
    // {
    //   title: "Appt Date",
    //   key: "nameClinic",
    //   render: (text, record, index) => (
    //     <div>{record?.BookingDetails?.bookingApptDate}</div>
    //   ),
    // },
    {
      title: " Name",
      dataIndex: "Name",
      key: "name",
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "date",
      render: (text) => {
        const formattedDate = format(new Date(text), "dd-MM-yyyy");
        return (
          <>
            <div className="client_dashboard_table_appdate_date">
              {formattedDate}
            </div>
          </>
        );
      },
    },

    {
      title: "Attachment",
      dataIndex: "Attachment",
      key: "Attachment",
      render: (text) => (
        <>
          <a href={text} target="_blank">
            {" "}
            <button className="client_dashboard_table_view_btn">
              <i class="fa-regular fa-eye"></i> View
            </button>
          </a>
        </>
      ),
    },
  ];

  const BillingColumn = [
    {
      title: "Invoice No",
      dataIndex: "Invoice",
      key: "InvoiceNo",
    },
    {
      title: "Clinic",
      key: "nameClinic",
      render: (text, record, index) => (
        <div>{record?.ClinicDetails?.ClinicName}</div>
      ),
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
    {
      title: "Paid On",
      dataIndex: "Paidon",
      key: "PaidOn",
      render: (text) => {
        const formattedDate = format(new Date(text), "dd-MM-yyyy");
        return (
          <>
            <div className="client_dashboard_table_appdate_date">
              {formattedDate}
            </div>
          </>
        );
      },
    },
    {
      title: "Actions",
      key: "Attachment",
      dataIndex: "Attachment",
      render: (text) => {
        console.log("Clicked", text);
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            <button className="client_dashboard_table_print_btn me-2">
              <i class="fa-solid fa-print"></i> Print
            </button>
            <a href={text} target="_blank">
              {" "}
              <button className="client_dashboard_table_view_btn">
                <i class="fa-regular fa-eye"></i> View
              </button>
            </a>
          </div>
        );
      },
    },
  ];

  const Medicalcolumn = [
    {
      title: "Record ID",
      dataIndex: "id",
      key: "ID",
    },
    {
      title: "Clinic",
      key: "nameClinic",
      render: (text, record, index) => (
        <div>{record?.ClinicDetails?.ClinicName}</div>
      ),
    },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
      render: (text) => {
        const formattedDate = format(new Date(text), "dd-MM-yyyy");
        return (
          <>
            <div className="client_dashboard_table_appdate_date">
              {formattedDate}
            </div>
          </>
        );
      },
    },
    {
      title: "Title Name",
      dataIndex: "TitleName",
      key: "TitleName",
    },
    {
      title: "Remarks",
      dataIndex: "Symptoms",
      key: "Description",
    },
    {
      title: "Attachment",
      dataIndex: "Attachment",
      key: "Attachment",
      render: (text) => (
        <>
          <a href={text} target="_blank">
            {" "}
            <button className="client_dashboard_table_view_btn">
              <i class="fa-regular fa-eye"></i> View
            </button>
          </a>
        </>
      ),
    },
    {
      title: "Uploaded By",
      dataIndex: "CreateBy",
      key: "Created",
      render: (text) => (
        <>
          <div>
            {text === "User"
              ? "By client"
              : text || text === "Admin"
              ? "Clinic"
              : text}
          </div>
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (text, record, index) => (
        <>
          <i
            class="fa-solid fa-trash-can"
            style={{ color: "#e11f28" }}
            onClick={() => {
              delete_medicalrecord(index, record.id);
            }}
          ></i>
        </>
      ),
    },
  ];

  const Appointmentcolumns = [
    {
      title: "Clinic Name",
      dataIndex: "",
      render(record) {
        // console.log("record: ", record);
        return {
          children: <div>{record?.ClinicDetails?.ClinicName}</div>,
        };
      },
    },
    {
      title: "Appt Date",
      dataIndex: "Date",
      key: "age",
      render: (text, record) => {
        const formattedDate = format(new Date(text), "dd-MM-yyyy");
        return (
          <>
            <div className="client_dashboard_table_appdate_date">
              {formattedDate}
            </div>
            <div className="client_dashboard_table_appdate_time">
              {record["Slot"]}
            </div>
          </>
        );
      },
    },
    {
      title: "Booking Date",
      dataIndex: "BookingDate",
      key: "address",
      render: (text) => {
        const formattedDate = format(new Date(text), "dd-MM-yyyy");
        return (
          <>
            <div className="client_dashboard_table_appdate_date">
              {formattedDate}
            </div>
          </>
        );
      },
    },
    {
      title: "Package",
      dataIndex: "Purpose",
      key: "Purpose",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "address",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (text, record) => (
        <>
          {text === 0 ? (
            <button className="client_dashboard_table_pending_btn">
              Pending Approval
            </button>
          ) : text === -1 ? (
            <button
              className="client_dashboard_table_reject_btn"
              onClick={() => {
                navigate("/booking");
              }}
            >
              Reschedule
            </button>
          ) : text === 1 ? (
            <button className="client_dashboard_table_confirm_btn">
              Accepted
            </button>
          ) : text === 2 ? (
            <button className="client_dashboard_table_confirm_btn">
              Accepted
            </button>
          ) : null}
        </>
      ),
    },
    {
      title: "Bill",
      key: "Status",
      dataIndex: "Status",
      render: (text, record) => (
        <>
          {text === -1 ? (
            <button className="client_dashboard_table_reject_btn" disabled>
              N/A
            </button>
          ) : text === 0 ? (
            <button className="client_dashboard_table_pending_btn" disabled>
              N/A
            </button>
          ) : text === 1 ? (
            <button
              className="client_dashboard_table_pending_btn"
              onClick={() => {
                var Url = window.API_PREFIX + "booking/payfees";
                fetch(Url, {
                  method: "POST",
                  headers: {
                    "Content-type": "application/json",
                  },
                  body: JSON.stringify({
                    Token: localStorage.getItem("DToken"),
                    BookingId: record.BookingId,
                    Amount: record.Amount,
                    clinicId: record?.ClinicDetails?.ClinicId,
                  }),
                })
                  .then((resp) => resp.json())
                  .then((data) => {
                    console.log(data);
                    if (data["status"] === "1") {
                      const options = {
                        key: data["key"],
                        amount: Number(record.Amount) * 100,
                        order_id: data["Payment_id"],
                        name: "KodeNauts",
                        description: "Thank you for payment",
                        handler: function (response) {
                          var Url =
                            window.API_PREFIX + "booking/verify_payment";

                          fetch(Url, {
                            method: "POST",
                            headers: {
                              "Content-type": "application/json",
                            },
                            body: JSON.stringify({
                              Token: localStorage.getItem("DToken"),
                              razorpay_order_id: response.razorpay_order_id,
                              razorpay_payment_id: response.razorpay_payment_id,
                              razorpay_signature: response.razorpay_signature,
                              BookingId: record.BookingId,
                              clinicId: record?.ClinicDetails?.ClinicId,
                            }),
                          })
                            .then((resp) => resp.json())
                            .then((Revdata) => {
                              console.log(Revdata);
                              if (data["status"] === "Block") {
                                toast.error(data["message"]);
                                localStorage.clear();
                                navigate("/");
                                window.location.reload();
                              } else if (Revdata["status"] == "1") {
                                getbilling();
                                setDashboardOption([false, false, false, true]);
                              }
                            });
                        },
                        prefill: {
                          name: "",
                          email: "",
                          phone_number: "",
                        },
                      };
                      const paymentObject = new window.Razorpay(options);
                      paymentObject.open();
                    }
                  });
              }}
            >
              Pay Amount
            </button>
          ) : text === 2 ? (
            <button className="client_dashboard_table_confirm_btn" disabled>
              Paid
            </button>
          ) : null}
        </>
      ),
    },
  ];

  const [activePopoverIndex, setActivePopoverIndex] = useState(null);
  const [attendedEvents, setAttendedEvents] = useState({});
  const [payButtonIndex, setPayButtonIndex] = useState(null);

  const handlepopOpenChange = (i, open) => {
    if (open) {
      setActivePopoverIndex(i);
    } else {
      setActivePopoverIndex(null);
    }
  };

  const pophide = () => {
    setActivePopoverIndex(null);
  };

  const modalRef = useRef(null);
  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setIsModalOpen1(null);
    }
  };

  useEffect(() => {
    if (isModalOpen1 !== null) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isModalOpen1]);

  const handleAttendClick = async (index, item) => {
    var Url = window.API_PREFIX + "patient/addeventattandees";
    var uploadData = new FormData();
    uploadData.append("Token", localStorage.getItem("DToken"));
    uploadData.append("eventId", item?.eventId);
    uploadData.append("clinicId", item?.ClinicDetails?.ClinicId);
    fetch(Url, {
      method: "POST",
      // headers: {
      //     'Content-type': 'application/json',
      // },
      body: uploadData,
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/");
          window.location.reload();
        } else if (data["status"] === "1") {
          setAttendedEvents((prev) => ({ ...prev, [index]: true }));
          setActivePopoverIndex(null);
          toast.success("Event registered successfully");
          getEventList();
        }
      });
  };

  const handlePayClick = async (index, item) => {
    var Url = window.API_PREFIX + "patient/eventpayfees";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        eventId: item.eventId,
        Amount: item.fees,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          const options = {
            key: data["key"],
            amount: Number(item.fees) * 100,
            order_id: data["Payment_id"],
            name: "KodeNauts",
            description: "Thank you for payment",
            handler: function (response) {
              var Url = window.API_PREFIX + "patient/verifyeventpayment";

              fetch(Url, {
                method: "POST",
                headers: {
                  "Content-type": "application/json",
                },
                body: JSON.stringify({
                  Token: localStorage.getItem("DToken"),
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_signature: response.razorpay_signature,
                  eventId: item.eventId,
                }),
              })
                .then((resp) => resp.json())
                .then((Revdata) => {
                  console.log(Revdata);
                  if (data["status"] === "Block") {
                    toast.error(data["message"]);
                    localStorage.clear();
                    navigate("/");
                    window.location.reload();
                  } else if (Revdata["status"] == "1") {
                    setDashboardOption([false, false, false, false, true]);
                  }
                });
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        }
      });
  };
  return (
    <>
      <main className="container-fuild mx-2">
        <div className="client_dashboard_main_division">
          <div class="client_bashboard_tab_division">
            <div
              className={
                DashboardOption[0]
                  ? "col client_bashboard_tabs_title_name_division_active"
                  : "col client_bashboard_tabs_title_name_division"
              }
              onClick={() => {
                var Url = window.API_PREFIX + "patient/userwiseappointment";
                fetch(Url, {
                  method: "POST",
                  headers: {
                    "Content-type": "application/json",
                  },
                  body: JSON.stringify({
                    Token: localStorage.getItem("DToken"),
                  }),
                })
                  .then((resp) => resp.json())
                  .then((data) => {
                    console.log(data);
                    if (data["status"] === "Block") {
                      toast.error(data["message"]);
                      localStorage.clear();
                      navigate("/");
                      window.location.reload();
                    } else if (data["status"] === "1") {
                      setuserAppointmentData([...data["appointment"]]);
                    }
                  });
                setDashboardOption([true, false, false, false, false]);
              }}
            >
              <div class="client_bashboard_tabs_title_name">Appointments</div>
            </div>
            <div
              className={
                DashboardOption[1]
                  ? "col client_bashboard_tabs_title_name_division_active"
                  : "col client_bashboard_tabs_title_name_division"
              }
              onClick={() => {
                getOfferList();
                setDashboardOption([false, true, false, false, false]);
              }}
            >
              <div class="client_bashboard_tabs_title_name">Offers</div>
            </div>
            <div
              className={
                DashboardOption[2]
                  ? "col client_bashboard_tabs_title_name_division_active"
                  : "col client_bashboard_tabs_title_name_division"
              }
              onClick={() => {
                getmedicalrecord();
                setDashboardOption([false, false, true, false, false]);
              }}
            >
              <div class="client_bashboard_tabs_title_name">Client's share</div>
            </div>
            <div
              className={
                DashboardOption[3]
                  ? "col client_bashboard_tabs_title_name_division_active"
                  : "col client_bashboard_tabs_title_name_division"
              }
              onClick={() => {
                getbilling();
                setDashboardOption([false, false, false, true, false]);
              }}
            >
              <div class="client_bashboard_tabs_title_name">Billing</div>
            </div>
            <div
              className={
                DashboardOption[4]
                  ? "col client_bashboard_tabs_title_name_division_active"
                  : "col client_bashboard_tabs_title_name_division"
              }
              onClick={() => {
                getEventList();
                setDashboardOption([false, false, false, false, true]);
              }}
            >
              <div class="client_bashboard_tabs_title_name">
                {" "}
                HF events by VDo
              </div>
            </div>
          </div>

          {DashboardOption[0] ? (
            <div className="client_dashboard_table_division">
              <Table
                columns={Appointmentcolumns}
                dataSource={userAppointmentData}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </div>
          ) : DashboardOption[1] ? (
            <div className="client_dashboard_table_division">
              <div className="row mt-4 container ">
                {offerList?.map((item, i) => (
                  <div
                    className="col col-12 col-sm-6 col-md-6 col-lg-4 gx-2 gy-2"
                    key={i}
                  >
                    <div
                      key={i}
                      style={{
                        borderRadius: "10px",
                        overflow: "hidden",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                      }}
                    >
                      <div
                        style={{
                          backgroundImage: `url(${image})`,
                          backgroundSize: "cover",
                          height: "200px",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            display: "flex",
                            gap: "5px",
                          }}
                        ></div>
                      </div>
                      <div style={{ padding: "20px" }}>
                        <div className="d-flex justify-content-between align-item-center">
                          <h3
                            style={{
                              margin: "0 0 10px 0",
                              fontSize: "18px",
                              textAlign: "start",
                            }}
                          >
                            {item?.title}
                          </h3>
                          <h3
                            style={{
                              margin: "0 0 10px 0",
                              fontSize: "18px",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setIsModalOpen1(i);
                              setSelectedRow(item);
                            }}
                          >
                            {item?.ClinicDetails?.ClinicName}
                          </h3>
                        </div>

                        {isModalOpen1 === i && (
                          <div
                            style={{
                              position: "fixed",
                              top: "0",
                              left: "0",
                              width: "100%",
                              height: "100%",
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              ref={modalRef}
                              style={{
                                backgroundColor: "#fff",
                                padding: "20px",
                                borderRadius: "10px",
                                width: "300px",
                                textAlign: "center",
                                position: "relative",
                              }}
                            >
                              <div
                                className="d-flex align-items-center justify-content-center me-2 "
                                style={{ color: "black" }}
                              >
                                <img
                                  className="image_div"
                                  style={{ justifyItems: "center" }}
                                  src={
                                    selectedRow?.ClinicDetails?.clinicProfilePic
                                  }
                                ></img>
                              </div>
                              <div
                                className="new_card_client_name"
                                style={{ color: "black", fontSize: "20px" }}
                              >
                                {selectedRow?.ClinicDetails?.ClinicName}
                              </div>
                              <div
                                className="other_info mb-2"
                                style={{ color: "black" }}
                              >
                                <div>
                                  <i className="fa-solid fa-phone me-2"></i>
                                  {selectedRow?.ClinicDetails?.ClinicContact}
                                </div>
                                <div>
                                  <i className="fa-solid fa-location-dot me-2"></i>
                                  {selectedRow?.ClinicDetails?.ClinicAddress}
                                </div>
                                <div>
                                  <i class="fa-solid fa-at me-2"></i>
                                  {selectedRow?.ClinicDetails?.ClinicEmail}
                                </div>
                              </div>
                              {/* Close button inside modal */}
                              <button
                                onClick={() => setIsModalOpen1(null)}
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "10px",
                                  background: "none",
                                  color: "black",
                                  border: "none",
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                              >
                                &times;
                              </button>
                            </div>
                          </div>
                        )}
                        <p style={{ color: "#A0A0A0" }}>{item?.Date}</p>

                        <div
                          style={{
                            margin: "0 0 10px 0",
                            fontSize: "14px",
                            color: "black",
                            textAlign: "start",
                            whiteSpace:
                              isReadMore.open && isReadMore.id === i
                                ? "normal"
                                : "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {isReadMore.open && isReadMore.id === i
                            ? item?.description
                            : item?.description}
                        </div>
                        <div
                          onClick={() => {
                            setIsReadMore({
                              id: i,
                              open:
                                i === isReadMore.id ? !isReadMore.open : true,
                            });
                          }}
                          style={{
                            cursor: "pointer",
                            color: "blue",
                            textDecoration: "underline",
                            textAlign: "start",
                            margin: "0 0 10px 0",
                          }}
                        >
                          {isReadMore.open && isReadMore.id === i
                            ? "Show Less"
                            : "Read More"}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : DashboardOption[2] ? (
            <>
              <div className="mt-2 text-end">
                <button
                  onClick={() => {
                    setAddMedicalRecord(true);
                  }}
                  type="button"
                  className=" mitwank_patient_profile_add_prescription_btn btn"
                >
                  <i class="fa-solid fa-plus me-2"></i>Add Files
                </button>
              </div>
              <div className="client_dashboard_table_division">
                <Table
                  columns={Medicalcolumn}
                  dataSource={userMedicalData}
                  pagination={pagination}
                  onChange={handleTableChange}
                />
              </div>
            </>
          ) : DashboardOption[3] ? (
            <div className="client_dashboard_table_division">
              <Table
                columns={BillingColumn}
                dataSource={userBillingData}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </div>
          ) : (
            <div className="row mt-4 container ">
              {eventList?.map((item, i) => (
                <div
                  className="col col-12 col-sm-6 col-md-6 col-lg-4 gx-2 gy-2"
                  key={i}
                >
                  <div
                    style={{
                      filter:
                        timers[i] === "Time is over"
                          ? "grayscale(100%)"
                          : "grayscale(0%)",
                      borderRadius: "10px",
                      overflow: "hidden",
                      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                    }}
                    key={i}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${image})`,
                        backgroundSize: "cover",
                        height: "200px",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "10px",
                          left: "10px",
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        <span
                          style={{
                            backgroundColor: "#4361EE",
                            color: "white",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            fontSize: "12px",
                          }}
                        >
                          EVENT
                        </span>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          bottom: "10px",
                          left: "10px",
                          backgroundColor: "rgba(0,0,0,0.7)",
                          color: "white",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          fontSize: "14px",
                        }}
                      >
                        ₹ {item.fees}
                      </div>
                    </div>
                    <div style={{ padding: "20px", color: "white" }}>
                      <div className="d-flex justify-content-between align-item-center">
                        <h3
                          style={{
                            margin: "0 0 10px 0",
                            fontSize: "18px",
                            textAlign: "start",
                          }}
                        >
                          {item.title}
                        </h3>
                        <h3
                          style={{
                            margin: "0 0 10px 0",
                            fontSize: "18px",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setIsModalOpen1(i);
                            setSelectedRow(item);
                          }}
                        >
                          {item?.ClinicDetails?.ClinicName}
                        </h3>
                      </div>
                      {isModalOpen1 === i && (
                        <div
                          style={{
                            position: "fixed",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            ref={modalRef}
                            style={{
                              backgroundColor: "#fff",
                              padding: "20px",
                              borderRadius: "10px",
                              width: "300px",
                              textAlign: "center",
                              position: "relative",
                            }}
                          >
                            <div
                              className="d-flex align-items-center justify-content-center me-2 "
                              style={{ color: "black" }}
                            >
                              <img
                                className="image_div"
                                style={{ justifyItems: "center" }}
                                src={
                                  selectedRow?.ClinicDetails?.clinicProfilePic
                                }
                              ></img>
                            </div>
                            <div
                              className="new_card_client_name"
                              style={{ color: "black", fontSize: "20px" }}
                            >
                              {selectedRow?.ClinicDetails?.ClinicName}
                            </div>
                            <div
                              className="other_info mb-2"
                              style={{ color: "black" }}
                            >
                              <div>
                                <i className="fa-solid fa-phone me-2"></i>
                                {selectedRow?.ClinicDetails?.ClinicContact}
                              </div>
                              <div>
                                <i className="fa-solid fa-location-dot me-2"></i>
                                {selectedRow?.ClinicDetails?.ClinicAddress}
                              </div>
                              <div>
                                <i class="fa-solid fa-at me-2"></i>
                                {selectedRow?.ClinicDetails?.ClinicEmail}
                              </div>
                            </div>
                            {/* Close button inside modal */}
                            <button
                              onClick={() => setIsModalOpen1(null)}
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                background: "none",
                                color: "black",
                                border: "none",
                                fontSize: "20px",
                                cursor: "pointer",
                              }}
                            >
                              &times;
                            </button>
                          </div>
                        </div>
                      )}
                      <p style={{ color: "#A0A0A0" }}>{item?.Date}</p>

                      <div
                        style={{
                          margin: "0 0 10px 0",
                          fontSize: "14px",
                          color: "black",
                          textAlign: "start",
                          whiteSpace:
                            isReadMore.open && isReadMore.id === i
                              ? "normal"
                              : "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {isReadMore.open && isReadMore.id === i
                          ? item?.description
                          : item?.description}
                      </div>

                      <div
                        onClick={() => {
                          setIsReadMore({
                            id: i,
                            open: i === isReadMore.id ? !isReadMore.open : true,
                          });
                        }}
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          textDecoration: "underline",
                          textAlign: "start",
                          margin: "0 0 10px 0",
                        }}
                      >
                        {isReadMore.open && isReadMore.id === i
                          ? "Show Less"
                          : "Read More"}
                      </div>
                      <div>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              textAlign: "center",
                              gap: "3px",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ fontSize: "18px", color: "#333" }}>
                              {timers[i]?.days || 0}
                            </div>
                            <span style={{ fontSize: "14px", color: "#555" }}>
                              Days
                            </span>
                          </div>
                          <span
                            style={{
                              fontSize: "18px",
                              color: "#333",
                              margin: "0 3px",
                              fontWeight: "bold",
                            }}
                          >
                            :
                          </span>
                          <div
                            style={{
                              textAlign: "center",
                              gap: "3px",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "18px",
                                color: "#333",
                                margin: 0,
                              }}
                            >
                              {timers[i]?.hours || 0}
                            </div>
                            <span style={{ fontSize: "14px", color: "#555" }}>
                              Hours
                            </span>
                          </div>
                          <span
                            style={{
                              fontSize: "18px",
                              color: "#333",
                              margin: "0 3px",
                              fontWeight: "bold",
                            }}
                          >
                            :
                          </span>
                          <div
                            style={{
                              textAlign: "center",
                              gap: "3px",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "18px",
                                color: "#333",
                                margin: 0,
                              }}
                            >
                              {timers[i]?.minutes || 0}
                            </div>
                            <span style={{ fontSize: "14px", color: "#555" }}>
                              Minutes
                            </span>
                          </div>
                          <span
                            style={{
                              fontSize: "18px",
                              color: "#333",
                              margin: "0 3px",
                              fontWeight: "bold",
                            }}
                          >
                            :
                          </span>
                          <div
                            style={{
                              textAlign: "center",
                              gap: "3px",
                              alignItems: "center",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "18px",
                                color: "#333",
                                margin: 0,
                              }}
                            >
                              {timers[i]?.seconds || 0}
                            </p>
                            <span style={{ fontSize: "14px", color: "#555" }}>
                              Seconds
                            </span>
                          </div>
                        </div>

                        {/* {timers[i] === 'Time is over' ?
                         (
                          <div>Time is over</div>
                        ) : 
                        timers[i] ? (
                          <div>
                            {timers[i].days}d {timers[i].hours}h {timers[i].minutes}m {timers[i].seconds}s
                          </div>
                        ) : (
                          <div>Loading...</div> 
                        )} */}

                        <div>
                          {item?.eventRegister === "Yes" ? (
                            item?.eventPay === "Paid" ? (
                              <button
                                className="mt-2"
                                style={{
                                  backgroundColor: "transparent",
                                  border: "2px solid #00A676",
                                  color: "#00A676",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  fontSize: "14px",
                                }}
                                disabled
                              >
                                Already Registered & Paid Successfully
                              </button>
                            ) : (
                              <button
                                className="mt-2"
                                style={{
                                  backgroundColor: "transparent",
                                  border: "2px solid #00A676",
                                  color: "#00A676",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  fontSize: "14px",
                                }}
                                onClick={() => handlePayClick(i, item)}
                              >
                                PAY
                              </button>
                            )
                          ) : (
                            <Popover
                              content={
                                <div className="d-flex justify-content-between">
                                  <a onClick={pophide}>No</a>
                                  <a onClick={() => handleAttendClick(i, item)}>
                                    Yes
                                  </a>
                                </div>
                              }
                              title="Are you sure you want to attend?"
                              trigger="click"
                              open={activePopoverIndex === i}
                              onOpenChange={(open) =>
                                open ? setActivePopoverIndex(i) : pophide()
                              }
                            >
                              <button
                                className="mt-2"
                                style={{
                                  backgroundColor: "transparent",
                                  border: "2px solid #4361EE",
                                  color: "#4361EE",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  fontSize: "14px",
                                }}
                              >
                                ATTEND →
                              </button>
                            </Popover>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <Popup
          contentStyle={addmedicalrecordpopup}
          modal
          open={addMedicalRecord}
          // closeOnDocumentClick={false}
          onClose={() => {
            setAddMedicalRecord(false);
          }}
        >
          <div className="Add_medical_records_popup_div">
            <div className="add_medical_records_popuop_header">
              <h5 className="add_medical_records_records_title">Add Files</h5>
              <i
                onClick={() => {
                  setAddMedicalRecord(false);
                }}
                class=" add_medical_records_popuop_colse_button fa-solid fa-xmark"
              ></i>
            </div>
            <div className="add_medical_records_popup_body">
              <div className="popup_body_div_two">
                <div className="popup_body_name_client_section row">
                  <div className="col-12 col-md-6">
                    <select
                      className="form-select mb-3"
                      aria-label="Select Clinic"
                      value={selectedBranch}
                      onChange={handleSelectChange}
                    >
                      <option value="" disabled>
                        Select Clinic
                      </option>
                      {branches.map((branch) => (
                        <option key={branch.id} value={branch.id}>
                          {branch.clinicName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="popup_body_name_client_section row">
                  <div className="col-12 col-md-6">
                    <p className="popup_body_name_title d-flex">
                      Title Name
                      <div className="required_feild ms-2">(* required) </div>
                    </p>
                    <input
                      type="text"
                      name="description"
                      class="addmedical_pop_text_feild form-control"
                      placeholder="Enter Title Name"
                      value={TitleName}
                      onChange={handleTextareaChange}
                      required
                    />
                  </div>
                </div>
                <br></br>
                <div>
                  <p className="popup_body_upload_title d-flex">
                    Upload
                    <div className="required_feild ms-2">(* required) </div>
                  </p>
                  <div class="  input-group">
                    <input
                      class=" form-control"
                      id="inputGroupFile02"
                      style={{ cursor: "pointer" }}
                      type="file"
                      name="myImage"
                      onChange={(event) => {
                        const file = event.target.files[0];

                        if (file) {
                          if (file.size < 20 * 1024 * 1024) {
                            setIsRequiredMedicalRecord(false);
                            setMedicalRecord(file);
                          } else {
                            toast.error("File size must be less than 20MB");
                            event.target.value = null;
                          }
                        } else {
                          setIsRequiredMedicalRecord(true);
                        }
                      }}
                      required
                    />
                    <label class="input-group-text" for="inputGroupFile02">
                      Upload
                    </label>
                  </div>
                </div>
                <br></br>
                <div>
                  <p className="popup_body_symptoms_title d-flex">
                    Remarks
                    <div className="required_feild ms-2">(* required) </div>
                  </p>
                  <input
                    type="text"
                    name="description"
                    class=" addmedical_pop_text_feild form-control"
                    placeholder=""
                    onChange={(e) => {
                      e.target.value !== ""
                        ? setIsRequiredSymptoms(false)
                        : setIsRequiredSymptoms(true);
                      setSymptoms(e.target.value);
                    }}
                    required
                  />
                </div>
                <br></br>
                <div className="text-center">
                  {loaderon ? (
                    <ClipLoader />
                  ) : (
                    <button
                      type="button"
                      className="popup_submit_button btn"
                      disabled={checkSubmitBtnDisable()}
                      onClick={() => {
                        var Url =
                          window.API_PREFIX + "patient/add_medicalrecords";
                        setloaderon(true);

                        var uploadData = new FormData();
                        uploadData.append(
                          "Token",
                          localStorage.getItem("DToken")
                        );
                        uploadData.append("TitleName", TitleName);
                        uploadData.append("Symptoms", Symptoms);
                        uploadData.append("RecordDate", RecordDate);
                        uploadData.append("Attachment", MedicalRecord);
                        uploadData.append("clinicId", selectedBranch);
                        fetch(Url, {
                          method: "POST",
                          body: uploadData,
                        })
                          .then((resp) => resp.json())
                          .then((data) => {
                            console.log(data);
                            if (data["status"] === "Block") {
                              toast.error(data["message"]);
                              localStorage.clear();
                              navigate("/");
                              window.location.reload();
                            } else if (data["status"] === "1") {
                              toast.success("Files Added");
                              getmedicalrecord();
                              setTitleName("");
                              setloaderon(false);
                              setAddMedicalRecord(false);
                            }
                          });
                      }}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Popup>
      </main>
    </>
  );
}
export default ClientDashboard;
