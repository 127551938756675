import "../../App.css";
import "antd/dist/antd.css";
import { Table } from "antd";
import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "../Mitwank/Sidebar.css";
import { Popover } from "antd";
import { format } from "date-fns";
import dayjs from "dayjs";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../Loader";
import { ClipLoader } from "react-spinners";

// billing table end

function DoctorPatientProfile() {
  const [loaderon, setloaderon] = useState(false);
  const [loaderonpay, setloaderonpay] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    pageSizeOptions: ["5", "10", "20", "50", "100"],
    showSizeChanger: true,
  });

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = (page) => {
    setCurrentPage(page - 1);
    console.log("Current page:", page);
  };

  const [frameworkData, setFrameworkData] = useState({
    bookingId: "",
    patientId: "",
    clinicId: "",
  });
  console.log("frameworkData: ", frameworkData);
  const [fileData, setFileData] = useState({
    bookingId: "",
    patientId: "",
    clinicId: "",
  });

  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const handleEntriesPerPageChange = (current, size) => {
    setEntriesPerPage(size);
    console.log("Entries per page:", size);
  };

  const addmedicalrecordpopup = {
    borderRadius: "10px",
    backgroundColor: "white",
    // boxshadow: "1 1 20px rgba(255, 255, 255, 0.4)",
    borderradius: "5px",
    padding: "0px",
    width: "100%",
    height: "max-content",
    margin: "60px 450px",
  };
  const addprescribtionpopup = {
    borderRadius: "10px",
    backgroundColor: "white",
    // boxshadow: "1 1 20px rgba(255, 255, 255, 0.4)",
    borderradius: "5px",
    padding: "0px",
    width: "100%",
    height: "max-content",
    margin: "60px 450px",
  };
  const editpricepopup = {
    borderRadius: "10px",
    backgroundColor: "white",
    // boxshadow: "1 1 20px rgba(255, 255, 255, 0.4)",
    borderradius: "5px",
    padding: "0px",
    width: "100%",
    height: "max-content",
    margin: "60px 450px",
  };

  const params = useParams();
  let navigate = useNavigate();
  const { PID } = params;
  const { state } = useLocation();

  const [addMedicalRecord, setAddMedicalRecord] = useState(false);
  const [addInvoice, setaddInvoice] = useState(false);
  const [editpricePop, seteditpricePop] = useState(false);

  const [docAppointmentData, setdocAppointmentData] = useState([]);
  const [docPrescribeData, setdocPrescribeData] = useState([]);
  const [docMedicalData, setdocMedicalData] = useState([]);
  const [docBillingData, setdocBillingData] = useState([]);
  const [editPriceIndex, seteditPriceIndex] = useState("");
  const [eacheditprice, seteacheditprice] = useState("");

  const [TitleName, setTitleName] = useState();
  const [isRequiredTitleName, setIsRequiredTitleName] = useState(true);
  const [HospitalName, setHospitalName] = useState();
  const [isRequiredHospitalName, setIsRequiredHospitalName] = useState(true);
  const [Symptoms, setSymptoms] = useState();
  const [isRequiredSymptoms, setIsRequiredSymptoms] = useState(true);
  const [RecordDate, setRecordDate] = useState("");
  const [MedicalRecord, setMedicalRecord] = useState();
  const [isRequiredMedicalRecord, setIsRequiredMedicalRecord] = useState(true);

  const [addprescribtion, setaddprescribtion] = useState(false);
  const [PrescName, setPrescName] = useState("");
  const [isRequiredPrescName, setIsRequiredPrescName] = useState(true);
  const [PrescRecordDate, setPrescRecordDate] = useState("");
  const [PrescRecord, setPrescRecord] = useState();
  const [isRequiredPrescRecord, setIsRequiredPrescRecord] = useState(true);

  const [Amount, setAmount] = useState();
  const [isRequiredAmount, setIsRequiredAmount] = useState(true);
  const [InvoiceDate, setInvoiceDate] = useState();
  const [isRequiredInvoiceDate, setIsRequiredInvoiceDate] = useState(true);
  const [InvoiceRecord, setInvoiceRecord] = useState();
  const [isRequiredInvoiceRecord, setIsRequiredInvoiceRecord] = useState(true);
  const [DashboardOption, setDashboardOption] = useState([
    true,
    false,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    console.log(state);
    getappointment();
  }, []);

  function getappointment() {
    var Url = window.API_PREFIX + "admin/userwiseappointment";
    let clinic_id = localStorage.getItem("clinicId");
    if (clinic_id) {
      Url += `?clinicId=${clinic_id}`;
    }
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: PID,
        loginAs: localStorage.getItem("LoginDetails"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } else if (data["status"] === "1") {
          const APdata = [...data["appointment"]];
          const dataWithAPSr = APdata.map((item, index) => ({
            ...item,
            srNo: APdata.length - index,
          }));
          console.log("P");
          setdocAppointmentData(dataWithAPSr);
        }
      });
  }

  function TrashDelete(Requestindex, BookingId) {
    var Url = window.API_PREFIX + "booking/trash_booking";
    const index = docAppointmentData.findIndex(
      (appointment) => appointment.BookingId === BookingId
    );
    console.log(docAppointmentData[index]);
    // dispatch({
    //     type: 'StartLoad'
    // })
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docAppointmentData[index]["BookingId"],
        loginAs: localStorage.getItem("LoginDetails"),
        clinicId: localStorage.getItem("clinicId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        // dispatch({
        //     type: 'StopLoad'
        // })
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } else if (data["status"] === "1") {
          getappointment();
        }
      });
  }

  function delete_medicalrecord(Requestindex, id) {
    var Url = window.API_PREFIX + "admin/trash_delete_files";
    const index = docMedicalData.findIndex((records) => records.id === id);

    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docMedicalData[index]["id"],
        loginAs: localStorage.getItem("LoginDetails"),
        clinicId: localStorage.getItem("clinicId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } else if (data["status"] === "1") {
          getmedicalrecord();
        }
      });
  }

  function delete_prescription(Requestindex, id) {
    var Url = window.API_PREFIX + "admin/trash_delete_framework";
    const index = docPrescribeData.findIndex((data) => data.id === id);
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docPrescribeData[index]["id"],
        loginAs: localStorage.getItem("LoginDetails"),
        clinicId: localStorage.getItem("clinicId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } else if (data["status"] === "1") {
          getprescription();
        }
      });
  }

  function delete_billing(Requestindex, id) {
    var Url = window.API_PREFIX + "admin/trash_delete_invoice";
    const index = docBillingData.findIndex((Bill) => Bill.id === id);
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docBillingData[index]["id"],
        loginAs: localStorage.getItem("LoginDetails"),
        clinicId: localStorage.getItem("clinicId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } else if (data["status"] === "1") {
          getbilling();
        }
      });
  }

  function addprescription() {
    var Url = window.API_PREFIX + "admin/add_prescription";
    setloaderon(true);
    var uploadData = new FormData();
    uploadData.append("Token", localStorage.getItem("DToken"));
    uploadData.append("Name", PrescName);
    uploadData.append("PrescRecordDate", PrescRecordDate);
    uploadData.append("Attachment", PrescRecord);
    uploadData.append("PatientId", PID);
    uploadData.append("clinicId", localStorage.getItem("clinicId"));
    uploadData.append("loginAs", localStorage.getItem("LoginDetails"));

    // uploadData.append("bookingId", frameworkData?.bookingId);

    fetch(Url, {
      method: "POST",
      // headers: {
      //     'Content-type': 'application/json',
      // },
      body: uploadData,
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } else if (data["status"] === "1") {
          setloaderon(false);
          getprescription();
          toast.success("Offers & Events Added");
          setaddprescribtion(false);
        }
      });
  }

  function getmedicalrecord() {
    var Url = window.API_PREFIX + "admin/view_medicalrecords";
    let clinic_id = localStorage.getItem("clinicId");
    if (clinic_id) {
      Url += `?clinicId=${clinic_id}`;
    }
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        PatientId: PID,
        loginAs: localStorage.getItem("LoginDetails"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } else if (data["status"] === "1") {
          setdocMedicalData([...data["records"]]);
        }
      });
  }

  function getprescription() {
    var Url = window.API_PREFIX + "admin/view_prescription";
    let clinic_id = localStorage.getItem("clinicId");
    if (clinic_id) {
      Url += `?clinicId=${clinic_id}`;
    }
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        PatientId: PID,
        loginAs: localStorage.getItem("LoginDetails"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } else if (data["status"] === "1") {
          const PrescribeData = [...data["data"]];
          const dataWithSrNo = PrescribeData.map((item, index) => ({
            ...item,
            srNo: PrescribeData.length - index,
          }));
          setdocPrescribeData(dataWithSrNo);
        }
      });
  }
  function getbilling() {
    var Url = window.API_PREFIX + "admin/billingpatientwise";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        PatientId: PID,
        loginAs: localStorage.getItem("LoginDetails"),
        clinicId: localStorage.getItem("clinicId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } else if (data["status"] === "1") {
          setdocBillingData([...data["Bill"]]);
        }
      });
  }

  function Pending_Payment(Paymentindex, BookingId) {
    var Url = window.API_PREFIX + "admin/Paybydoctor";
    const index = docAppointmentData.findIndex(
      (appointment) => appointment.BookingId === BookingId
    );
    setloaderonpay({ ...loaderonpay, [Paymentindex]: true });
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docAppointmentData[index]["BookingId"],
        PatientId: PID,
        clinicId: localStorage.getItem("clinicId"),
        loginAs: localStorage.getItem("LoginDetails"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } else if (data["status"] === "1") {
          setloaderonpay({ ...loaderonpay, [Paymentindex]: false });
          var tmp = docAppointmentData;
          tmp[index]["Status"] = 2;
          setdocAppointmentData([...tmp]);
        }
      });
  }

  function AcceptRequest(Requestindex, BookingId) {
    var Url = window.API_PREFIX + "booking/approve_booking";
    const index = docAppointmentData.findIndex(
      (appointment) => appointment.BookingId === BookingId
    );
    setloaderon({ ...loaderon, [Requestindex]: true });
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docAppointmentData[index]["BookingId"],
        clinicId: localStorage.getItem("clinicId"),
        loginAs: localStorage.getItem("LoginDetails"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } else if (data["status"] === "1") {
          setloaderon({ ...loaderon, [Requestindex]: false });
          var tmp = docAppointmentData;
          tmp[index]["Status"] = 1;
          setdocAppointmentData([...tmp]);
        }
      });
  }

  function RejectRequest(Requestindex, BookingId) {
    var Url = window.API_PREFIX + "booking/reject_booking";
    const index = docAppointmentData.findIndex(
      (appointment) => appointment.BookingId === BookingId
    );
    setloaderon({ ...loaderon, [Requestindex]: true });
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: docAppointmentData[index]["BookingId"],
        clinicId: localStorage.getItem("clinicId"),
        loginAs: localStorage.getItem("LoginDetails"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } else if (data["status"] === "1") {
          setloaderon({ ...loaderon, [Requestindex]: false });
          var tmp = docAppointmentData;
          tmp[index]["Status"] = -1;
          setdocAppointmentData([...tmp]);
        }
      });
  }

  const Prescribecolumns = [
    {
      title: "Sr. No",
      dataIndex: "srNo",
      render(text, record, index) {
        return {
          props: {
            style: { fontSize: "16px", textAlign: "center", color: "#483a15" },
          },
          children: <div style={{ fontWeight: "600" }}>{record.srNo}</div>,
        };
      },
    },
    // {
    //   title: "Appt Date",
    //   key: "nameClinic",
    //   render: (text, record, index) => (
    //     <div>{record?.BookingDetails?.bookingApptDate}</div>
    //   ),
    // },
    {
      title: " Name",
      dataIndex: "Name",
      key: "name",
      render: (text, record, index) => <div>{text}</div>,
    },

    {
      title: "Date",
      dataIndex: "Date",
      key: "date",
      render: (text) => {
        const formattedDate = format(new Date(text), "dd-MM-yyyy");
        return (
          <>
            <div className="client_dashboard_table_appdate_date">
              {formattedDate}
            </div>
          </>
        );
      },
    },

    {
      title: "Attachment",
      dataIndex: "Attachment",
      key: "Attachment",
      render: (text) => (
        <>
          <a href={text} target="_blank">
            {" "}
            <button className="patient_dashboard_table_view_btn">
              <i class="fa-regular fa-eye"></i> View
            </button>
          </a>
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (text, record, index) => (
        <>
          <i
            class="fa-solid fa-trash-can"
            style={{ color: "#e11f28" }}
            onClick={() => {
              delete_prescription(index, record.id);
            }}
          ></i>
        </>
      ),
    },
  ];

  const Medicalcolumn = [
    {
      title: "Record ID",
      dataIndex: "id",
      key: "ID",
    },
    // {
    //   title: "Appt Date",
    //   key: "nameClinic",
    //   render: (text, record, index) => (
    //     <div>{record?.BookingDetails?.bookingApptDate}</div>
    //   ),
    // },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
      render: (text) => {
        const formattedDate = format(new Date(text), "dd-MM-yyyy");
        return (
          <>
            <div className="client_dashboard_table_appdate_date">
              {formattedDate}
            </div>
          </>
        );
      },
    },
    {
      title: "Title Name",
      dataIndex: "TitleName",
      key: "TitleName",
    },
    {
      title: "Remarks",
      dataIndex: "Symptoms",
      key: "Description",
    },
    {
      title: "Attachment",
      dataIndex: "Attachment",
      key: "Attachment",
      render: (text) => (
        <>
          <a href={text} target="_blank">
            {" "}
            <button className="patient_dashboard_table_view_btn">
              <i class="fa-regular fa-eye"></i> View
            </button>
          </a>
        </>
      ),
    },
    {
      title: "Created",
      dataIndex: "CreateBy",
      key: "Created",
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (text, record, index) => (
        <>
          <i
            class="fa-solid fa-trash-can"
            style={{ color: "#e11f28" }}
            onClick={() => {
              delete_medicalrecord(index, record.id);
            }}
          ></i>
        </>
      ),
    },
  ];

  const BillingColumn = [
    {
      title: "Invoice No",
      dataIndex: "Invoice",
      key: "InvoiceNo",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
    {
      title: "Paid On Date",
      dataIndex: "Paidon",
      key: "PaidOn",
      render: (text) => {
        const formattedDate = format(new Date(text), "dd-MM-yyyy");
        return (
          <>
            <div className="client_dashboard_table_appdate_date">
              {formattedDate}
            </div>
          </>
        );
      },
    },
    {
      title: "",
      key: "Attachment",
      dataIndex: "Attachment",
      render: (text) => {
        return (
          <>
            <button className="client_dashboard_table_print_btn me-2">
              <i class="fa-solid fa-print"></i> Print
            </button>
            <a href={text} target="_blank">
              {" "}
              <button className="client_dashboard_table_view_btn">
                <i class="fa-regular fa-eye"></i> View
              </button>
            </a>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (text, record, index) => (
        <>
          <i
            class="fa-solid fa-trash-can"
            style={{ color: "#e11f28" }}
            onClick={() => {
              delete_billing(index, record.id);
            }}
          ></i>
        </>
      ),
    },
  ];

  const doctor_patient_profile_columns = [
    {
      title: "Sr. No",
      dataIndex: "srNo",
      render(text, record, index) {
        return {
          props: {
            style: { fontSize: "16px", textAlign: "center", color: "#483a15" },
          },
          children: <div style={{ fontWeight: "600" }}>{record.srNo}</div>,
        };
      },
    },
    {
      title: "Appt Date",
      dataIndex: "Date",
      key: "age",
      render: (text, record) => {
        const formattedDate = format(new Date(text), "dd-MM-yyyy");
        return (
          <>
            <div className="mitwank_patient_profile_table_datetime_div">
              <div className="mitwank_patient_profile_table_appdate_date">
                {formattedDate}
              </div>
              <div className="mitwank_patient_profile_table_appdate_time">
                {record["Slot"]}
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "Booking Date",
      dataIndex: "BookingDate",
      key: "address",
      render: (text) => {
        const formattedDate = format(new Date(text), "dd-MM-yyyy");
        return (
          <>
            <div className="client_dashboard_table_appdate_date">
              {formattedDate}
            </div>
          </>
        );
      },
    },
    {
      title: "Package",
      dataIndex: "Purpose",
      key: "Purpose",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "address",
    },

    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (text, record, index) => (
        <>
          {text === 0 ? (
            <>
              <div className="d-flex">
                {loaderon[index] ? (
                  <Loader />
                ) : (
                  <button
                    className="btn mitwank_patient_profile_table_confirm_btn"
                    onClick={() => {
                      // var INDEX = currentPage * entriesPerPage + index
                      AcceptRequest(index, record.BookingId);
                    }}
                  >
                    Accept
                  </button>
                )}

                {loaderon[index] ? (
                  <Loader />
                ) : (
                  <button
                    className="btn patient_dashboard_table_reject_btn"
                    onClick={() => {
                      // var INDEX = currentPage * entriesPerPage + index
                      RejectRequest(index, record.BookingId);
                    }}
                  >
                    Reject
                  </button>
                )}
              </div>
            </>
          ) : text === 1 || text === 2 ? (
            <button
              className="btn patient_dashboard_table_confirm_btn"
              disabled
            >
              Accepted
            </button>
          ) : text === -1 ? (
            <button className="btn patient_dashboard_table_reject_btn" disabled>
              Rejected
            </button>
          ) : null}
        </>
      ),
    },
    {
      title: "Payment",
      key: "tags",
      dataIndex: "tags",
      render: (text, record, index) => (
        <>
          {record["Status"] === 1 ? (
            <>
              {loaderonpay[index] ? (
                <Loader />
              ) : (
                <button
                  className="mitwank_patient_profile_table_edit_btn me-2"
                  onClick={() => {
                    // var INDEX = currentPage * entriesPerPage + index
                    const INDEX = docAppointmentData.findIndex(
                      (appointment) =>
                        appointment.BookingId === record.BookingId
                    );
                    seteditpricePop(true);
                    seteditPriceIndex(INDEX);
                    seteacheditprice(record["Amount"]);
                  }}
                >
                  <i class="fa-solid fa-pen-to-square"></i> Edit Price
                </button>
              )}

              {loaderonpay[index] ? (
                <Loader />
              ) : (
                <Popover
                  content={
                    <div className="d-flex justify-content-between">
                      <a onClick={pophide}>No</a>
                      <a
                        onClick={() => {
                          Pending_Payment(index, record.BookingId);
                          pophide();
                        }}
                      >
                        Yes
                      </a>
                    </div>
                  }
                  title="Are you want to sure ?"
                  trigger="click"
                  open={activePopoverIndex === index}
                  onOpenChange={(open) => handlepopOpenChange(index, open)}
                >
                  <button className=" btn admin_dashboard_tabel_view_btn">
                    {" "}
                    Pending Payment
                  </button>
                </Popover>
              )}
            </>
          ) : record["Status"] === 2 ? (
            <button
              className="btn patient_dashboard_table_confirm_btn"
              disabled
            >
              Paid
            </button>
          ) : record["Status"] === 0 ? (
            <>
              <button
                className="mitwank_patient_profile_table_edit_btn"
                onClick={() => {
                  // var INDEX = currentPage * entriesPerPage + index
                  seteditpricePop(true);
                  const INDEX = docAppointmentData.findIndex(
                    (appointment) => appointment.BookingId === record.BookingId
                  );
                  seteditPriceIndex(INDEX);
                  seteacheditprice(record["Amount"]);
                }}
              >
                <i class="fa-solid fa-pen-to-square"></i> Edit Price
              </button>
              <button
                className="btn mitwank_patient_profile_table_Pending_btn mt-1"
                disabled
              >
                Pending Request
              </button>
            </>
          ) : record["Status"] === -1 ? (
            <button className="btn patient_dashboard_table_reject_btn" disabled>
              N/A
            </button>
          ) : null}
        </>
      ),
    },
    {
      title: "Actions",
      // dataIndex: "Actions",
      key: "Actions",
      render: (text, record, index) => (
        <div>
          {/* {record?.Status === 2 && (
            <i
              class="fa-solid fa-upload"
              style={{ color: "#743A87" }}
              onClick={() => {
                setaddprescribtion(true);
                setFrameworkData({
                  bookingId: record?.BookingId,
                  patientId: record?.patientDetails?.patientId,
                  clinicId: record?.ClinicDetails?.ClinicId,
                });
              }}
            ></i>
          )}
          {record?.Status === 2 && (
            <i
              class="fa-solid fa-folder mx-2"
              style={{ color: "#743A87" }}
              onClick={() => {
                setAddMedicalRecord(true);
                setFileData({
                  bookingId: record?.BookingId,
                  patientId: record?.patientDetails?.patientId,
                  clinicId: record?.ClinicDetails?.ClinicId,
                });
              }}
            ></i>
          )} */}

          <i
            class="fa-solid fa-trash-can mx-2"
            style={{ color: "#e11f28" }}
            onClick={() => {
              TrashDelete(index, record.BookingId);
            }}
          ></i>
        </div>
      ),
    },
  ];

  // responsive table end

  const [name, setname] = useState("");
  const [Email, setEmail] = useState("");
  const [Phnumber, setPhnumber] = useState("");
  const [Img, setImg] = useState("");
  const [gender, setgender] = useState("male");
  const [Weight, setWeight] = useState("");
  const [height, setheight] = useState("");
  const [profession, setprofession] = useState("");
  const [age, setage] = useState("");

  const [Address, setAddress] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [Country, setCountry] = useState("");
  const [OtherInfo, setOtherInfo] = useState("");
  const [file, setFile] = useState();
  const [Pincode, setPincode] = useState("");
  const [showImg, setshowImg] = useState();
  const [Birthdate, setBirthdate] = useState();
  const [allergiesToFoodMedication, setAllergiesToFoodMedication] =
    useState("");
  console.log("allergiesToFoodMedication: ", allergiesToFoodMedication);
  const [skinSencitivity, setSkinSencitivity] = useState(false);
  console.log("skinSencitivity: ", skinSencitivity);
  const [tedencyKeloidFormation, setTedencyKeloidFormation] = useState(false);
  console.log("tedencyKeloidFormation: ", tedencyKeloidFormation);
  const [pregnancyStatus, setPregnancyStatus] = useState("");
  console.log("pregnancyStatus: ", pregnancyStatus);
  const [menstrualStatus, setMenstrualStatus] = useState("");
  console.log("menstrualStatus: ", menstrualStatus);
  const [smokingStatus, setSmokingStatus] = useState("");
  console.log("smokingStatus: ", smokingStatus);
  const [previousSurgeryDesc, setPreviousSurgeryDesc] = useState("");
  console.log("previousSurgeryDesc: ", previousSurgeryDesc);
  const [skinCareRoutine, setSkinCareRoutine] = useState("");
  console.log("skinCareRoutine: ", skinCareRoutine);
  const [retinolStatus, setRetinolStatus] = useState("");
  console.log("retinolStatus: ", retinolStatus);
  const [cosmeticTreatmentDesc, setCosmeticTreatmentDesc] = useState("");
  console.log("cosmeticTreatmentDesc: ", cosmeticTreatmentDesc);
  const [treatmentType, setTreatmentType] = useState("");
  console.log("treatmentType: ", treatmentType);
  const [treatmentDate, setTreatmentDate] = useState("");
  console.log("treatmentDate: ", treatmentDate);
  const [otherProblemDesc, setOtherProblemDesc] = useState("");
  console.log("otherProblemDesc: ", otherProblemDesc);

  const checkSubmitBtnDisable = () => {
    if (!isRequiredPrescName && !isRequiredPrescRecord) {
      return false;
    } else {
      return true;
    }
  };

  const submitbtndisable = () => {
    if (
      !isRequiredTitleName &&
      !isRequiredMedicalRecord &&
      !isRequiredSymptoms
    ) {
      return false;
    } else {
      return true;
    }
  };

  const invoicesubmitbtndisable = () => {
    if (
      !isRequiredAmount &&
      !isRequiredInvoiceDate &&
      !isRequiredInvoiceRecord
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    var Url = window.API_PREFIX + "patient/view_profile";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        PatientId: PID,
        clinicId: localStorage.getItem("clinicId"),
        loginAs: localStorage.getItem("LoginDetails"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } else if (data["status"] === "1") {
          const birthDate = dayjs(data["Birthdate"]);
          const today = dayjs();

          setname(data["Name"]);
          setPhnumber(data["Mobile"]);
          setImg(data["Image"]);
          setgender(data["Gender"]);
          setWeight(data["Weight"]);
          setheight(data["Height"]);
          setprofession(data["profession"]);
          setage(today.diff(birthDate, "year"));

          setAddress(data["Address"]);
          setCity(data["City"]);
          setState(data["State"]);
          setCountry(data["Country"]);
          setOtherInfo(data["OtherInformation"]);
          setEmail(data["Email"]);
          setshowImg(data["Image"]);
          // setFile(data['Name'])
          setPincode(data["Pincode"]);
          setBirthdate(data["Birthdate"]);
          setAllergiesToFoodMedication(data["allergy_food_medications"]);
          setSkinSencitivity(data["skinSencitive"]);
          setTedencyKeloidFormation(data["tedencyKeloidFormation"]);
          setPregnancyStatus(data["pregnancyStatus"]);
          setMenstrualStatus(data["menstrualStatus"]);
          setSmokingStatus(data["smokingStatus"]);
          setPreviousSurgeryDesc(data["previousSurgeryDescription"]);
          setSkinCareRoutine(data["skinCareRoutine"]);
          setRetinolStatus(data["retinolStatus"]);
          setCosmeticTreatmentDesc(data["cosmeticTreatmentDescription"]);
          setTreatmentType(data["treatmentType"]);
          setTreatmentDate(data["treatmentDate"]);
          setOtherProblemDesc(data["anyProblemDescription"]);
        }
      });
  }, []);

  const [activePopoverIndex, setActivePopoverIndex] = useState(null);

  const handlepopOpenChange = (index, open) => {
    if (open) {
      setActivePopoverIndex(index);
    } else {
      setActivePopoverIndex(null);
    }
  };

  const pophide = () => {
    setActivePopoverIndex(null);
  };

  return (
    <>
      <div className="container-fuild mx-2">
        <div className="component_division_mitwank_patient_profile">
          <div
            onClick={() => {
              navigate("/clinic/clinic-clients");
            }}
          >
            <button className="btn client_back_button">
              <i class="fa-solid fa-arrow-left back_icon"></i>Back
            </button>
          </div>

          <div className="mitwank_patient_profile_main_division">
            <div class="client_bashboard_tab_division">
              <div
                className={
                  DashboardOption[0]
                    ? "col client_bashboard_tabs_title_name_division_active"
                    : "col client_bashboard_tabs_title_name_division"
                }
                onClick={() => {
                  setDashboardOption([true, false, false, false, false, false]);
                }}
              >
                <div class="client_bashboard_tabs_title_name">Profile</div>
              </div>
              <div
                className={
                  DashboardOption[1]
                    ? "col client_bashboard_tabs_title_name_division_active"
                    : "col client_bashboard_tabs_title_name_division"
                }
                onClick={() => {
                  setDashboardOption([false, true, false, false, false, false]);
                }}
              >
                <div class="client_bashboard_tabs_title_name">
                  Other Details
                </div>
              </div>

              <div
                className={
                  DashboardOption[2]
                    ? "col client_bashboard_tabs_title_name_division_active"
                    : "col client_bashboard_tabs_title_name_division"
                }
                onClick={() => {
                  getappointment();
                  setDashboardOption([false, false, true, false, false, false]);
                }}
              >
                <div class="client_bashboard_tabs_title_name">Appointments</div>
              </div>

              {/* <div
                className={
                  DashboardOption[3]
                    ? "col client_bashboard_tabs_title_name_division_active"
                    : "col client_bashboard_tabs_title_name_division"
                }
                onClick={() => {
                  getprescription();
                  setDashboardOption([false, false, false, true, false, false]);
                }}
              >
                <div class="client_bashboard_tabs_title_name">
                  Offers & Events
                </div>
              </div> */}
              <div
                className={
                  DashboardOption[4]
                    ? "col client_bashboard_tabs_title_name_division_active"
                    : "col client_bashboard_tabs_title_name_division"
                }
                onClick={() => {
                  getmedicalrecord();
                  setDashboardOption([false, false, false, false, true, false]);
                }}
              >
                <div class="client_bashboard_tabs_title_name">
                  Client's Share
                </div>
              </div>
              {/* <div
                  className={
                    DashboardOption[5]
                      ? "col client_bashboard_tabs_title_name_division_active"
                      : "col client_bashboard_tabs_title_name_division"
                  }
                  onClick={() => {
                    getbilling();
                    setDashboardOption([
                      false,
                      false,
                      false,
                      false,
                      false,
                      true,
                    ]);
                  }}
                >
                  <div class="client_bashboard_tabs_title_name">Billing</div>
                </div> */}
            </div>

            {DashboardOption[0] ? (
              <div>
                <div className="Profilesettings_main_diviison">
                  <div className="profilesettings_Upload_image">
                    <div className="profilesettings_profile_image">
                      <img
                        className="profilesettings_profile_image_sizes"
                        src={showImg}
                      />
                    </div>
                    <div className="profilesettings_upload_button_main">
                      <button
                        type="button"
                        className="profilesettings_upload_photo_button btn"
                      >
                        <i className="fa fa-upload profilesettings_upload_icon"></i>{" "}
                        Upload Photo
                        <input
                          type="file"
                          accept="image/gif, image/jpeg, image/png"
                          onChange={(e) => {
                            setImg(e.target.files[0]);

                            setshowImg(URL.createObjectURL(e.target.files[0]));
                          }}
                        />
                      </button>
                      <br />
                      {/* ... */}
                      <div className="profilesetting_upload_p">
                        <p>Allowed JPG, GIF or PNG. Max size of 20MB</p>
                      </div>
                      {/* .. */}
                    </div>
                  </div>
                  <div className="profilesetting_input_form">
                    <div className="row g-3 profilesetting_form">
                      <div className="col-md-6 profilesettings_first_name">
                        <label
                          for="inputfirstaname"
                          className="form-label profilesettings_label"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control profilesettings_input"
                          placeholder="Enter Your Name"
                          id="inputfirstname"
                          value={name}
                          onChange={(e) => {
                            setname(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-md-6 profilesettings_mobile">
                        <label
                          for="inputmobile"
                          className="form-label profilesettings_label"
                        >
                          Mobile Number
                        </label>
                        <input
                          type="textfield"
                          className="form-control profilesettings_input"
                          id="inputmobile"
                          placeholder="Enter Your Mobile Number"
                          value={Phnumber}
                          onChange={(e) => {
                            setPhnumber(e.target.value);
                          }}
                        />
                      </div>

                      <div className="col-md-6 profilesettings_email">
                        <label
                          for="inputemail"
                          className="form-label profilesettings_label"
                        >
                          Email ID
                        </label>
                        <input
                          type="email"
                          className="form-control profilesettings_input"
                          placeholder="Enter Your Email Address"
                          id="inputemail"
                          disabled
                          value={Email}
                        />
                      </div>
                      <div className="col-md-6 profilesettings_Date_of_birth">
                        <label
                          for="inputdateofbirth"
                          className="form-label profilesettings_label"
                        >
                          Date Of Birth
                        </label>
                        <input
                          type="date"
                          className="form-control profilesettings_input"
                          id="inputdateofbirth"
                          value={Birthdate}
                          onChange={(e) => {
                            setBirthdate(e.target.value);

                            var today = new Date();
                            var birthDate = new Date(e.target.value);
                            var age_now =
                              today.getFullYear() - birthDate.getFullYear();
                            var m = today.getMonth() - birthDate.getMonth();
                            if (
                              m < 0 ||
                              (m === 0 && today.getDate() < birthDate.getDate())
                            ) {
                              age_now--;
                            }

                            setage(age_now);
                          }}
                        />
                      </div>
                      <div className="col-md-6 profilesettings_Age">
                        <label
                          for="inputage"
                          className="form-label profilesettings_label"
                        >
                          Age
                        </label>
                        <input
                          type="text"
                          className="form-control profilesettings_input"
                          disabled
                          id="inputage"
                          value={age}
                          onChange={(e) => {
                            setage(e.target.value);
                          }}
                        />
                      </div>

                      <div className="col-md-6 profilesettings_Age">
                        <label
                          for="inputage"
                          className="form-label profilesettings_label"
                        >
                          Proffesion
                        </label>
                        <input
                          type="text"
                          className="form-control profilesettings_input"
                          disabled
                          id="inputage"
                          value={profession}
                          onChange={(e) => {
                            setprofession(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-12 profilesettings_address">
                        <label
                          for="inputAddress"
                          className="form-label profilesettings_label"
                        >
                          Address
                        </label>
                        <textarea
                          className="form-control profilesettings_address_textarea"
                          placeholder="Enter Your Address"
                          id="floatingTextarea"
                          value={Address}
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                        ></textarea>
                      </div>
                      <div className="col-md-6 profilesettings_city">
                        <label
                          for="inputCity"
                          className="form-label profilesettings_label"
                        >
                          City
                        </label>
                        <input
                          type="text"
                          className="form-control profilesettings_input"
                          placeholder="Enter City"
                          id="inputCity"
                          value={City}
                          onChange={(e) => {
                            setCity(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-md-6 profilesettings_state">
                        <label
                          for="inputstate"
                          className="form-label profilesettings_state_label"
                        >
                          State
                        </label>
                        <input
                          type="text"
                          className="form-control profilesettings_input"
                          placeholder="Enter State"
                          id="inputstate"
                          value={State}
                          onChange={(e) => {
                            setState(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-md-6 profilesettings_zip_code">
                        <label
                          for="inputzipcode"
                          className="form-label profilesettings_label"
                        >
                          Zip Code
                        </label>
                        <input
                          type="text"
                          className="form-control profilesettings_input"
                          placeholder="Enter Zip Code"
                          id="inputzipcode"
                          value={Pincode}
                          onChange={(e) => {
                            setPincode(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-md-6 profilesettings_country">
                        <label
                          for="inputcountry"
                          className="form-label profilesettings_label"
                        >
                          Country
                        </label>
                        <input
                          type="text"
                          className="form-control profilesettings_input"
                          placeholder="Enter Country"
                          id="inputcountry"
                          value={Country}
                          onChange={(e) => {
                            setCountry(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-12 profilesettings_other_information">
                        <label
                          for="inputotherinformation"
                          className="form-label profilesettings_label"
                        >
                          Other Information
                        </label>
                        <textarea
                          className="form-control profilesettings_address_textarea"
                          id="floatingTextarea"
                          placeholder="Other Information"
                          value={OtherInfo}
                          onChange={(e) => {
                            setOtherInfo(e.target.value);
                          }}
                        ></textarea>
                      </div>
                      <div className="col-12 profilesettings_savechanges_button_main">
                        <button
                          // type="submit"
                          className="btn profilesettings_savechanges_button"
                          onClick={() => {
                            var Url =
                              window.API_PREFIX +
                              "patient/add_registration_data";

                            var uploadData = new FormData();
                            uploadData.append(
                              "Token",
                              localStorage.getItem("DToken")
                            );
                            uploadData.append("PatientId", PID);
                            uploadData.append("Name", name);
                            uploadData.append("Pic", Img);
                            uploadData.append("Gender", gender);
                            uploadData.append("Weight", Weight);
                            uploadData.append("Height", height);
                            uploadData.append("Birthdate", Birthdate);
                            uploadData.append("profession", profession);

                            uploadData.append("Address", Address);
                            uploadData.append("City", City);
                            uploadData.append("State", State);
                            uploadData.append("Country", Country);
                            uploadData.append("Pincode", Pincode);
                            uploadData.append("OtherInformation", OtherInfo);
                            uploadData.append("Mobile", Phnumber);
                            uploadData.append(
                              "clinicId",
                              localStorage.getItem("clinicId")
                            );
                            uploadData.append(
                              "loginAs",
                              localStorage.getItem("LoginDetails")
                            );
                            fetch(Url, {
                              method: "POST",
                              // headers: {
                              //     'Content-type': 'application/json',
                              // },
                              body: uploadData,
                            })
                              .then((resp) => resp.json())
                              .then((data) => {
                                console.log(data);
                                if (data["status"] === "Block") {
                                  toast.error(data["message"]);
                                  localStorage.clear();
                                  navigate("/Clinic");
                                  window.location.reload();
                                } else if (data["status"] === "1") {
                                  toast.success(
                                    "Profile detail change successfully"
                                  );
                                  if (data["FileUpdated"]) {
                                    window.location.reload();
                                  }
                                }
                              });
                          }}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : DashboardOption[1] ? (
              <div>
                <form className="medical-form">
                  {/* Allergies Section */}
                  <div className="form-section">
                    <h2 className="form-heading">Allergies:</h2>
                    <div className="form-group">
                      <label className="form-label">
                        Do you have any allergies to food medications?
                      </label>
                      <input
                        className="form-input"
                        type="text"
                        name="allergies"
                        value={allergiesToFoodMedication}
                        onChange={(e) => {
                          setAllergiesToFoodMedication(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        Is your skin very sensitive?
                      </label>
                      <div className="form-radio-group">
                        <input
                          className="form-radio"
                          type="radio"
                          id="skinSensitiveYes"
                          name="skinSensitive"
                          checked={skinSencitivity === "yes"}
                          onChange={(e) => {
                            setSkinSencitivity("yes");
                          }}
                        />
                        <label
                          className="form-radio-label"
                          htmlFor="skinSensitiveYes"
                        >
                          Yes
                        </label>

                        <input
                          className="form-radio"
                          type="radio"
                          id="skinSensitiveNo"
                          name="skinSensitive"
                          checked={skinSencitivity === "no"}
                          onChange={(e) => {
                            setSkinSencitivity("no");
                          }}
                        />
                        <label
                          className="form-radio-label"
                          htmlFor="skinSensitiveNo"
                        >
                          No
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        Do you have a tendency to keloid formation?
                      </label>
                      <div className="form-radio-group">
                        <input
                          className="form-radio"
                          type="radio"
                          id="keloidYes"
                          name="keloid"
                          checked={tedencyKeloidFormation === "yes"}
                          onChange={(e) => {
                            setTedencyKeloidFormation("yes");
                          }}
                        />
                        <label className="form-radio-label" htmlFor="keloidYes">
                          Yes
                        </label>

                        <input
                          className="form-radio"
                          type="radio"
                          id="keloidNo"
                          name="keloid"
                          checked={tedencyKeloidFormation === "no"}
                          onChange={(e) => {
                            setTedencyKeloidFormation("no");
                          }}
                        />
                        <label className="form-radio-label" htmlFor="keloidNo">
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  {gender === "female" && (
                    <>
                      {/* Pregnancy Section */}
                      <div className="form-section">
                        <h2 className="form-heading">Pregnancy:</h2>
                        <div className="form-group">
                          <label className="form-label">
                            Are you currently pregnant?
                          </label>
                          <div className="form-radio-group">
                            <input
                              className="form-radio"
                              type="radio"
                              id="pregnancyYes"
                              name="pregnancy"
                              checked={pregnancyStatus === "yes"}
                              onChange={(e) => {
                                setPregnancyStatus("yes");
                              }}
                            />
                            <label
                              className="form-radio-label"
                              htmlFor="pregnancyYes"
                            >
                              Yes
                            </label>

                            <input
                              className="form-radio"
                              type="radio"
                              id="pregnancyNo"
                              name="pregnancy"
                              checked={pregnancyStatus === "no"}
                              onChange={(e) => {
                                setPregnancyStatus("no");
                              }}
                            />
                            <label
                              className="form-radio-label"
                              htmlFor="pregnancyNo"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>

                      {/* Menstrual History Section */}
                      <div className="form-section">
                        <h2 className="form-heading">Menstrual history:</h2>
                        <div className="form-group">
                          <label className="form-label">
                            Is your menstrual history Regular/Irregular?
                          </label>
                          <div className="form-radio-group">
                            <input
                              className="form-radio"
                              type="radio"
                              id="menstrualRegular"
                              name="menstrual"
                              checked={menstrualStatus === "yes"}
                              onChange={(e) => {
                                setMenstrualStatus("yes");
                              }}
                            />
                            <label
                              className="form-radio-label"
                              htmlFor="menstrualRegular"
                            >
                              Regular
                            </label>

                            <input
                              className="form-radio"
                              type="radio"
                              id="menstrualIrregular"
                              name="menstrual"
                              checked={menstrualStatus === "no"}
                              onChange={(e) => {
                                setMenstrualStatus("no");
                              }}
                            />
                            <label
                              className="form-radio-label"
                              htmlFor="menstrualIrregular"
                            >
                              Irregular
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* Smoking Section */}
                  <div className="form-section">
                    <h2 className="form-heading">Smoking:</h2>
                    <div className="form-group">
                      <label className="form-label">Do you smoke?</label>
                      <div className="form-radio-group">
                        <input
                          className="form-radio"
                          type="radio"
                          id="smokeYes"
                          name="smoke"
                          checked={smokingStatus === "yes"}
                          onChange={(e) => {
                            setSmokingStatus("yes");
                          }}
                        />
                        <label className="form-radio-label" htmlFor="smokeYes">
                          Yes
                        </label>

                        <input
                          className="form-radio"
                          type="radio"
                          id="smokeNo"
                          name="smoke"
                          checked={smokingStatus === "no"}
                          onChange={(e) => {
                            setSmokingStatus("no");
                          }}
                        />
                        <label className="form-radio-label" htmlFor="smokeNo">
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* Previous Surgeries Section */}
                  <div className="form-section">
                    <h2 className="form-heading">
                      Have you had any previous surgeries?
                    </h2>
                    <div className="form-group">
                      <label className="form-label">Please describe:</label>
                      <textarea
                        className="form-textarea"
                        name="surgeries"
                        value={previousSurgeryDesc}
                        onChange={(e) => {
                          setPreviousSurgeryDesc(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>

                  {/* Current Skin Care Routine Section */}
                  <div className="form-section">
                    <h2 className="form-heading">Current skin care routine:</h2>
                    <div className="form-group">
                      <label className="form-label">
                        Describe your routine (cleansers, moisturizers,
                        sunscreen, etc.):
                      </label>
                      <textarea
                        className="form-textarea"
                        name="skinRoutine"
                        value={skinCareRoutine}
                        onChange={(e) => {
                          setSkinCareRoutine(e.target.value);
                        }}
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <label className="form-label">Use of Retinol:</label>
                      <div className="form-radio-group">
                        <input
                          className="form-radio"
                          type="radio"
                          id="retinolYes"
                          name="retinolUse"
                          checked={retinolStatus === "yes"}
                          onChange={(e) => {
                            setRetinolStatus("yes");
                          }}
                        />
                        <label
                          className="form-radio-label"
                          htmlFor="retinolYes"
                        >
                          Yes
                        </label>

                        <input
                          className="form-radio"
                          type="radio"
                          id="retinolNo"
                          name="retinolUse"
                          checked={retinolStatus === "no"}
                          onChange={(e) => {
                            setRetinolStatus("no");
                          }}
                        />
                        <label className="form-radio-label" htmlFor="retinolNo">
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* Cosmetic Treatment History Section */}
                  <div className="form-section">
                    <h2 className="form-heading">
                      Cosmetic treatment history:
                    </h2>
                    <div className="form-group">
                      <label className="form-label">
                        Previous laser or any treatment?
                      </label>
                      <textarea
                        className="form-textarea"
                        name="cosmeticTreatment"
                        value={cosmeticTreatmentDesc}
                        onChange={(e) => {
                          setCosmeticTreatmentDesc(e.target.value);
                        }}
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <label className="form-label">Type:</label>
                      <input
                        className="form-input"
                        type="text"
                        name="treatmentType"
                        value={treatmentType}
                        onChange={(e) => {
                          setTreatmentType(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Date:</label>
                      <input
                        className="form-date"
                        type="date"
                        name="treatmentDate"
                        value={treatmentDate}
                        onChange={(e) => {
                          setTreatmentDate(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Any problems?</label>
                      <textarea
                        className="form-textarea"
                        name="treatmentProblems"
                        value={otherProblemDesc}
                        onChange={(e) => {
                          setOtherProblemDesc(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                </form>
                <div className="col-12 profilesettings_savechanges_button_main">
                  <button
                    // type="submit"
                    className="btn profilesettings_savechanges_button"
                    onClick={() => {
                      var Url =
                        window.API_PREFIX + "patient/add_patient_other_info";

                      var uploadData = new FormData();
                      uploadData.append(
                        "Token",
                        localStorage.getItem("DToken")
                      );
                      uploadData.append("PatientId", PID);
                      uploadData.append(
                        "allergy_food_medications",
                        allergiesToFoodMedication
                      );
                      uploadData.append("skinSencitive", skinSencitivity);
                      uploadData.append(
                        "tedencyKeloidFormation",
                        tedencyKeloidFormation
                      );
                      uploadData.append("pregnancyStatus", pregnancyStatus);
                      uploadData.append("menstrualStatus", menstrualStatus);
                      uploadData.append("smokingStatus", smokingStatus);
                      uploadData.append(
                        "previousSurgeryDescription",
                        previousSurgeryDesc
                      );
                      uploadData.append("skinCareRoutine", skinCareRoutine);
                      uploadData.append("retinolStatus", retinolStatus);
                      uploadData.append(
                        "cosmeticTreatmentDescription",
                        cosmeticTreatmentDesc
                      );
                      uploadData.append("treatmentType", treatmentType);
                      uploadData.append("treatmentDate", treatmentDate);
                      uploadData.append(
                        "anyProblemDescription",
                        otherProblemDesc
                      );
                      uploadData.append(
                        "clinicId",
                        localStorage.getItem("clinicId")
                      );
                      uploadData.append(
                        "loginAs",
                        localStorage.getItem("LoginDetails")
                      );
                      fetch(Url, {
                        method: "POST",
                        // headers: {
                        //     'Content-type': 'application/json',
                        // },
                        body: uploadData,
                      })
                        .then((resp) => resp.json())
                        .then((data) => {
                          console.log(data);
                          if (data["status"] === "Block") {
                            toast.error(data["message"]);
                            localStorage.clear();
                            navigate("/Clinic");
                            window.location.reload();
                          } else if (data["status"] === "1") {
                            toast.success("Profile detail change successfully");
                            if (data["FileUpdated"]) {
                              window.location.reload();
                            }
                          }
                        });
                    }}
                  >
                    Save Other Information
                  </button>
                </div>
              </div>
            ) : DashboardOption[2] ? (
              <div className="mitwank_patient_profile_table_division">
                <Table
                  columns={doctor_patient_profile_columns}
                  dataSource={docAppointmentData}
                  pagination={pagination}
                  onChange={handleTableChange}
                />
              </div>
            ) : DashboardOption[3] ? (
              <>
                {" "}
                <div
                  className="mitwank_patient_profile_add_prescription_division"
                  onClick={() => {
                    // navigate("/mitwank/addprescription", {
                    //     state: {
                    //         PID: PID,
                    //         ADDType: true

                    //     }
                    // })
                    setaddprescribtion(true);
                  }}
                >
                  <button className="mitwank_patient_profile_add_prescription_btn">
                    + Add Offers & Events
                  </button>
                </div>
                <div className="mitwank_patient_profile_table_division">
                  <Table
                    columns={Prescribecolumns}
                    dataSource={docPrescribeData}
                    pagination={pagination}
                    onChange={handleTableChange}
                  />
                </div>
              </>
            ) : DashboardOption[4] ? (
              <>
                <div className="mitwank_patient_profile_add_prescription_division">
                  <button
                    onClick={() => {
                      setAddMedicalRecord(true);
                      console.log("Popup open");
                    }}
                    className="mitwank_patient_profile_add_prescription_btn btn"
                  >
                    + Add Files
                  </button>
                </div>

                <div className="mitwank_patient_profile_table_division">
                  <Table
                    columns={Medicalcolumn}
                    dataSource={docMedicalData}
                    pagination={pagination}
                    onChange={handleTableChange}
                  />
                </div>
              </>
            ) : // :
            // DashboardOption[5] ? (
            //   <>
            //     <div className="mitwank_patient_profile_add_prescription_division">
            //       <button
            //         onClick={() => {
            //           setaddInvoice(true);
            //           console.log("Popup open");
            //         }}
            //         className="mitwank_patient_profile_add_prescription_btn btn"
            //       >
            //         + Add Invoice
            //       </button>
            //     </div>

            //     <div className="mitwank_patient_profile_table_division">
            //       <Table
            //         columns={BillingColumn}
            //         dataSource={docBillingData}
            //         pagination={pagination}
            //         onChange={handleTableChange}
            //       />
            //     </div>
            //   </>
            // )
            null}

            <Popup
              contentStyle={addmedicalrecordpopup}
              modal
               // closeOnDocumentClick={false}
              open={addMedicalRecord}
              onClose={() => {
                setAddMedicalRecord(false);
                setloaderon(false);
              }}
            >
              <div className="Add_medical_records_popup_div">
                <div className="add_medical_records_popuop_header">
                  <h5 className="add_medical_records_records_title">
                    Add Files
                  </h5>
                  <i
                    onClick={() => {
                      setAddMedicalRecord(false);
                    }}
                    class=" add_medical_records_popuop_colse_button fa-solid fa-xmark"
                  ></i>
                </div>
                <div className="add_medical_records_popup_body">
                  <div className="popup_body_div_two">
                    <div className="popup_body_name_patient_section row">
                      <div className="col-12 col-md-6">
                        <p className="popup_body_name_title d-flex">
                          Title Name
                          <div className="required_feild ms-2">
                            (* required){" "}
                          </div>
                        </p>
                        <input
                          type="text"
                          name="description"
                          class=" popup_input_tags form-control"
                          placeholder="Enter Title Name"
                          onChange={(e) => {
                            e.target.value !== ""
                              ? setIsRequiredTitleName(false)
                              : setIsRequiredTitleName(true);
                            setTitleName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <br></br>
                    <div>
                      <p className="popup_body_upload_title d-flex">
                        Upload
                        <div className="required_feild ms-2">(* required) </div>
                      </p>
                      <div class="  input-group">
                        <input
                          class=" form-control"
                          id="inputGroupFile02"
                          style={{ cursor: "pointer" }}
                          type="file"
                          name="myImage"
                          // onChange={(event) => {
                          //     event.target.files.length > 0 ? setIsRequiredMedicalRecord(false) : setIsRequiredMedicalRecord(true)
                          //     setMedicalRecord(event.target.files[0]);

                          // }}
                          onChange={(event) => {
                            const file = event.target.files[0];
                            if (file) {
                              if (file.size < 20 * 1024 * 1024) {
                                setIsRequiredMedicalRecord(false);
                                setMedicalRecord(file);
                              } else {
                                toast.error("File size must be less than 20MB");
                                event.target.value = null;
                                setIsRequiredMedicalRecord(true);
                              }
                            } else {
                              setIsRequiredMedicalRecord(true);
                            }
                          }}
                        />
                        <label class="input-group-text" for="inputGroupFile02">
                          Upload
                        </label>
                      </div>
                    </div>
                    <br></br>
                    <div>
                      <p className="popup_body_symptoms_title d-flex">
                        Remarks
                        <div className="required_feild ms-2">(* required) </div>
                      </p>
                      <input
                        type="text"
                        name="description"
                        class=" popup_input_tags form-control"
                        placeholder=""
                        onChange={(e) => {
                          e.target.value !== ""
                            ? setIsRequiredSymptoms(false)
                            : setIsRequiredSymptoms(true);
                          setSymptoms(e.target.value);
                        }}
                      />
                    </div>
                    <br></br>
                    <br></br>
                    <div className="text-center">
                      {loaderon ? (
                        <ClipLoader />
                      ) : (
                        <button
                          type="button"
                          className="popup_submit_button btn"
                          onClick={() => {
                            var Url =
                              window.API_PREFIX + "admin/add_medicalrecords";
                            setloaderon(true);
                            var uploadData = new FormData();
                            uploadData.append(
                              "Token",
                              localStorage.getItem("DToken")
                            );
                            uploadData.append("TitleName", TitleName);
                            uploadData.append("Symptoms", Symptoms);
                            uploadData.append("RecordDate", RecordDate);
                            uploadData.append("Attachment", MedicalRecord);
                            uploadData.append("PatientId", PID);
                            uploadData.append(
                              "clinicId",
                              localStorage.getItem("clinicId")
                            );
                            uploadData.append(
                              "loginAs",
                              localStorage.getItem("LoginDetails")
                            );
                            // uploadData.append(
                            //   "bookingId",
                            //   fileData?.bookingId
                            // );
                            uploadData.append("CreatedBy", "Clinic");

                            fetch(Url, {
                              method: "POST",
                              // headers: {
                              //     'Content-type': 'application/json',
                              // },
                              body: uploadData,
                            })
                              .then((resp) => resp.json())
                              .then((data) => {
                                console.log(data);
                                if (data["status"] === "Block") {
                                  toast.error(data["message"]);
                                  localStorage.clear();
                                  navigate("/Clinic");
                                  window.location.reload();
                                } else if (data["status"] === "1") {
                                  setloaderon(false);
                                  setAddMedicalRecord(false);
                                  getmedicalrecord();
                                  toast.success("File Added Successfully");
                                }
                              });
                          }}
                          disabled={submitbtndisable()}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Popup>

            <Popup
              contentStyle={addprescribtionpopup}
              modal
               // closeOnDocumentClick={false}
              open={addprescribtion}
              onClose={() => {
                setaddprescribtion(false);
              }}
            >
              <div className="Add_medical_records_popup_div">
                <div className="add_medical_records_popuop_header">
                  <h5 className="add_medical_records_records_title">
                    Add Offers & Events
                  </h5>
                  <i
                    onClick={() => {
                      setaddprescribtion(false);
                    }}
                    class=" add_medical_records_popuop_colse_button fa-solid fa-xmark"
                  ></i>
                </div>
                <div className="add_medical_records_popup_body">
                  <div className="popup_body_div_two">
                    <div className="popup_body_name_patient_section row">
                      <div className="col-12 col-md-6">
                        <p className="popup_body_name_title d-flex">
                          Name
                          <div className="required_feild ms-2">
                            (* required){" "}
                          </div>
                        </p>
                        <input
                          type="text"
                          name="description"
                          class=" popup_input_tags form-control"
                          placeholder="Enter Title Name"
                          onChange={(e) => {
                            e.target.value !== ""
                              ? setIsRequiredPrescName(false)
                              : setIsRequiredPrescName(true);
                            setPrescName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <br></br>

                    <div>
                      <p className="popup_body_upload_title d-flex">
                        Upload
                        <div className="required_feild ms-2">(* required) </div>
                      </p>
                      <div class="  input-group">
                        <input
                          class=" form-control"
                          id="inputGroupFile02"
                          style={{ cursor: "pointer" }}
                          type="file"
                          // onChange={(event) => {
                          //     event.target.files.length > 0 ? setIsRequiredPrescRecord(false) : setIsRequiredPrescRecord(true)
                          //     setPrescRecord(event.target.files[0]);
                          // }}
                          onChange={(event) => {
                            const file = event.target.files[0];

                            if (file) {
                              if (file.size < 20 * 1024 * 1024) {
                                setIsRequiredPrescRecord(false);
                                setPrescRecord(file);
                              } else {
                                toast.error("File size must be less than 20MB");
                                event.target.value = null;
                                setIsRequiredPrescRecord(true);
                              }
                            } else {
                              setIsRequiredPrescRecord(true);
                            }
                          }}
                        />
                        <label class="input-group-text" for="inputGroupFile02">
                          Upload
                        </label>
                      </div>
                    </div>
                    <br></br>
                    <div className="d-flex justify-content-center">
                      {loaderon ? (
                        <ClipLoader />
                      ) : (
                        <button
                          type="button"
                          className="popup_submit_button btn"
                          onClick={() => {
                            addprescription();
                          }}
                          disabled={checkSubmitBtnDisable()}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Popup>

            <Popup
              contentStyle={addInvoice}
              modal
               // closeOnDocumentClick={false}
              open={addInvoice}
              onClose={() => {
                setaddInvoice(false);
              }}
            >
              <div className="Add_medical_records_popup_div">
                <div className="add_medical_records_popuop_header">
                  <h5 className="add_medical_records_records_title">
                    Add Invoice
                  </h5>
                  <i
                    onClick={() => {
                      setaddInvoice(false);
                    }}
                    class=" add_medical_records_popuop_colse_button fa-solid fa-xmark"
                  ></i>
                </div>
                <div className="add_medical_records_popup_body">
                  <div className="popup_body_div_two">
                    <div className="popup_body_name_patient_section row">
                      <div className="col-12 col-md-6">
                        <p className="popup_body_name_title d-flex">
                          Amount
                          <div className="required_feild ms-2">
                            (* required){" "}
                          </div>
                        </p>
                        <input
                          type="number"
                          name="amount"
                          class=" popup_input_tags form-control"
                          placeholder="Amount"
                          onChange={(e) => {
                            e.target.value !== ""
                              ? setIsRequiredAmount(false)
                              : setIsRequiredAmount(true);
                            setAmount(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <p className="popup_body_name_title d-flex">
                          Invoice Date
                          <div className="required_feild ms-2">
                            (* required){" "}
                          </div>
                        </p>
                        <input
                          type="date"
                          name="date"
                          class=" popup_input_tags form-control"
                          placeholder="Invoice-date"
                          onChange={(e) => {
                            e.target.value !== ""
                              ? setIsRequiredInvoiceDate(false)
                              : setIsRequiredInvoiceDate(true);
                            setInvoiceDate(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <br></br>

                    <div>
                      <p className="popup_body_upload_title d-flex">
                        Upload
                        <div className="required_feild ms-2">(* required) </div>
                      </p>
                      <div class="  input-group">
                        <input
                          class=" form-control"
                          id="inputGroupFile02"
                          style={{ cursor: "pointer" }}
                          type="file"
                          name="myImage"
                          // onChange={(event) => {
                          //     event.target.files.length > 0 ? setIsRequiredInvoiceRecord(false) : setIsRequiredInvoiceRecord(true)
                          //     setInvoiceRecord(event.target.files[0]);

                          // }}
                          onChange={(event) => {
                            const file = event.target.files[0];
                            if (file) {
                              if (file.size < 20 * 1024 * 1024) {
                                setIsRequiredInvoiceRecord(false);
                                setInvoiceRecord(file);
                              } else {
                                toast.error("File size must be less than 20MB");

                                event.target.value = null;
                                setIsRequiredInvoiceRecord(true);
                              }
                            } else {
                              setIsRequiredInvoiceRecord(true);
                            }
                          }}
                        />
                        <label class="input-group-text" for="inputGroupFile02">
                          Upload
                        </label>
                      </div>
                    </div>
                    <br></br>
                    <div className="text-center">
                      {loaderon ? (
                        <ClipLoader />
                      ) : (
                        <button
                          type="button"
                          className="popup_submit_button btn"
                          onClick={() => {
                            var Url = window.API_PREFIX + "admin/AddInvoice";
                            setloaderon(true);
                            var uploadData = new FormData();
                            uploadData.append(
                              "Token",
                              localStorage.getItem("DToken")
                            );
                            uploadData.append("Amount", Amount);
                            uploadData.append("InvoiceDate", InvoiceDate);
                            uploadData.append("Attachment", InvoiceRecord);
                            uploadData.append("PatientId", PID);
                            uploadData.append(
                              "clinicId",
                              localStorage.getItem("clinicId")
                            );
                            uploadData.append(
                              "loginAs",
                              localStorage.getItem("LoginDetails")
                            );

                            fetch(Url, {
                              method: "POST",
                              body: uploadData,
                            })
                              .then((resp) => resp.json())
                              .then((data) => {
                                console.log(data);
                                if (data["status"] === "Block") {
                                  toast.error(data["message"]);
                                  localStorage.clear();
                                  navigate("/Clinic");
                                  window.location.reload();
                                }
                                if (data["status"] === "1") {
                                  setloaderon(false);
                                  getbilling();
                                  toast.success("Invoice Added");
                                  setaddInvoice(false);
                                }
                              });
                          }}
                          disabled={invoicesubmitbtndisable()}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Popup>

            <Popup
              contentStyle={editpricepopup}
              modal
               // closeOnDocumentClick={false}
              open={editpricePop}
              onClose={() => {
                seteditpricePop(false);
              }}
            >
              <div className="Add_medical_records_popup_div">
                <div className="add_medical_records_popuop_header">
                  <h5 className="add_medical_records_records_title">
                    Edit Price
                  </h5>
                  <i
                    onClick={() => {
                      seteditpricePop(false);
                    }}
                    class=" add_medical_records_popuop_colse_button fa-solid fa-xmark"
                  ></i>
                </div>
                <div className="add_medical_records_popup_body">
                  <div className="popup_body_div_two">
                    <div className="popup_body_name_patient_section row">
                      <div className="col-12 col-md-6">
                        <p className="popup_body_name_title">Enter new price</p>
                        <input
                          type="text"
                          name="description"
                          class=" popup_input_tags form-control"
                          placeholder="Enter Price Name"
                          value={eacheditprice}
                          onChange={(e) => {
                            seteacheditprice(e.target.value);
                          }}
                        ></input>
                      </div>
                    </div>

                    <div className=" mt-2">
                      {loaderon ? (
                        <ClipLoader />
                      ) : (
                        <button
                          type="button"
                          className="popup_submit_button btn"
                          onClick={() => {
                            var Url =
                              window.API_PREFIX + "admin/changebookingprice";
                            setloaderon(true);
                            fetch(Url, {
                              method: "POST",
                              headers: {
                                "Content-type": "application/json",
                              },
                              body: JSON.stringify({
                                Token: localStorage.getItem("DToken"),
                                Amount: eacheditprice,
                                Id: docAppointmentData[editPriceIndex][
                                  "BookingId"
                                ],
                                PID: PID,
                                loginAs: localStorage.getItem("LoginDetails"),
                                clinicId: localStorage.getItem("clinicId"),
                              }),
                            })
                              .then((resp) => resp.json())
                              .then((data) => {
                                console.log(data);
                                if (data["status"] === "Block") {
                                  toast.error(data["message"]);
                                  localStorage.clear();
                                  navigate("/Clinic");
                                  window.location.reload();
                                } else if (data["status"] === "1") {
                                  setloaderon(false);
                                  toast.success("Price Updated Successfully");
                                  setdocAppointmentData([
                                    ...data["appointment"],
                                  ]);
                                  seteditpricePop(false);
                                }
                              });
                          }}
                        >
                          Confirm
                          <i class="fa-regular fa-circle-check ms-2"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Popup>
          </div>
        </div>

        <Toaster />
      </div>
    </>
  );
}
export default DoctorPatientProfile;
