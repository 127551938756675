import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom"
import Footer from "../Footer";
import Mitwankheader from '../Mitwank/Mitwankheader';
import { useStateValue } from "../StateProviders";
import MitwankLogin from "./MitwankLogin";
import Topbar from "../Topbar";
import Breadcrumb from "../Breadcrumb";
import Mitwanksidebar from '../Mitwank/MitwankSidebar';

export default function Adminlayout() {

  const [{ DIsLogin }, dispatch] = useStateValue();
  let navigate = useNavigate();

  useEffect(() => {
    if (DIsLogin) {
      // navigate("/mitwank/dashboard");
    }
  }, [DIsLogin]);

  useEffect(() => {
    if (localStorage.getItem("DToken") !== null && localStorage.getItem("LoginDetails") === 'Admin') {
      dispatch({
        type: "DLogin",
      });
    } else {
      dispatch({
        type: "DLogout",
      });
    }
  }, []);
  return (<>
    <Topbar />
    <Mitwankheader />
    {DIsLogin ?
      <>
        <Breadcrumb />
        <div className="slidebar_and_component">
          <Mitwanksidebar />
          <div className="outlet_main_div">
            <Outlet />
          </div>
        </div>
      </>

      : <MitwankLogin />}
    <Footer />
  </>)
}