import React, { useState, useEffect } from "react";
import ClinicSidebar from "./ClinicSidebar";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
function ClinicProfilesettings() {
  const [showImg, setshowImg] = useState();
  const [Img, setImg] = useState("");
  const [name, setname] = useState("");
  const [Phnumber, setPhnumber] = useState("");
  const [Email, setEmail] = useState("");
  const [OtherInfo, setOtherInfo] = useState("");
  const [Address, setAddress] = useState("");
  const [timing, settiming] = useState();
  let navigate = useNavigate();
  useEffect(() => {
    var Url = window.API_PREFIX + "admin/viewclinicprofile";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        clinicId: localStorage.getItem("clinicId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } else if (data["status"] === "1") {
          setshowImg(data["clinicProfilePic"]);
          setImg(data["clinicProfilePic"]);
          setname(data["clinicName"]);
          setAddress(data["clinicAddress"]);
          settiming(data["clinicTiming"]);
          //   setpassword(data["clinicPassword"]);
          setPhnumber(data["clinicMobile"]);
          setEmail(data["clinicEmail"]);
          setOtherInfo(data["clinicDescription"]);
        }
      });
  }, []);

  return (
    <>
      <div className="container-fuild mx-2">
        <div className="Profilesettings_main_diviison">
          <div className="profilesettings_Upload_image">
            <div className="profilesettings_profile_image">
              <img
                className="profilesettings_profile_image_sizes"
                src={showImg}
              />
            </div>
            <div className="profilesettings_upload_button_main">
              <button
                type="button"
                className="profilesettings_upload_photo_button btn"
              >
                <i className="fa fa-upload profilesettings_upload_icon"></i>{" "}
                Upload Photo
                <input
                  type="file"
                  accept="image/gif, image/jpeg, image/png"
                  onChange={(e) => {
                    setImg(e.target.files[0]);

                    setshowImg(URL.createObjectURL(e.target.files[0]));
                  }}
                />
              </button>
              <br />
              <div className="profilesetting_upload_p">
                <p>Allowed JPG, GIF or PNG. Max size of 20MB</p>
              </div>
            </div>
          </div>

          <div className="profilesetting_input_form">
            <div className="row g-3 profilesetting_form">
              <div className="col-md-6 profilesettings_first_name">
                <label
                  for="inputfirstaname"
                  className="form-label profilesettings_label"
                >
                  Clinic Name
                </label>
                <input
                  type="text"
                  className="form-control profilesettings_input"
                  placeholder="Clinic name"
                  id="inputfirstname"
                  disabled
                  value={name}
                  onChange={(e) => {
                    setname(e.target.value);
                  }}
                />
              </div>

              <div className="col-md-6 profilesettings_mobile">
                <label
                  for="inputmobile"
                  className="form-label profilesettings_label"
                >
                  Mobile Number
                </label>
                <input
                  type="textfield"
                  className="form-control profilesettings_input"
                  id="inputmobile"
                  placeholder="Mobile Number"
                  disabled
                  value={Phnumber}
                  onChange={(e) => {
                    setPhnumber(e.target.value);
                  }}
                />
              </div>

              <div className="col-md-6 profilesettings_mobile">
                <label
                  for="inputmobile"
                  className="form-label profilesettings_label"
                >
                  Timing
                </label>
                <input
                  type="textfield"
                  className="form-control profilesettings_input"
                  id="inputmobile"
                  placeholder="Timing"
                  disabled
                  value={timing}
                  onChange={(e) => {
                    settiming(e.target.value);
                  }}
                />
              </div>

              <div className="col-md-6 profilesettings_email">
                <label
                  for="inputemail"
                  className="form-label profilesettings_label"
                >
                  Email ID
                </label>
                <input
                  type="email"
                  className="form-control profilesettings_input"
                  placeholder="Email Address"
                  id="inputemail"
                  disabled
                  value={Email}
                />
              </div>
              <div className="col-12 profilesettings_address">
                <label
                  for="inputAddress"
                  className="form-label profilesettings_label"
                >
                  Address
                </label>
                <textarea
                  className="form-control profilesettings_address_textarea"
                  placeholder="Enter Your Address"
                  id="floatingTextarea"
                  value={Address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                ></textarea>
              </div>

              <div className="col-12 profilesettings_other_information">
                <label
                  for="inputotherinformation"
                  className="form-label profilesettings_label"
                >
                  Add Discription
                </label>
                <textarea
                  className="form-control profilesettings_address_textarea"
                  id="floatingTextarea"
                  placeholder="Other Information"
                  value={OtherInfo}
                  onChange={(e) => {
                    setOtherInfo(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className="col-12 profilesettings_savechanges_button_main">
                <button
                  className="btn profilesettings_savechanges_button"
                  onClick={() => {
                    var Url = window.API_PREFIX + "admin/editclinic";

                    var uploadData = new FormData();
                    uploadData.append("Token", localStorage.getItem("DToken"));
                    uploadData.append(
                      "clinicId",
                      localStorage.getItem("clinicId")
                    );
                    uploadData.append("clinicProfilePic", Img);
                    uploadData.append("clinicName", name);
                    uploadData.append("clinicAddress", Address);
                    uploadData.append("clinicTiming", timing);
                    uploadData.append("clinicMobile", Phnumber);
                    uploadData.append("clinicDescription", OtherInfo);
                    uploadData.append(
                      "loginAs",
                      localStorage.getItem("LoginDetails")
                    );
                    fetch(Url, {
                      method: "POST",
                      // headers: {
                      //     'Content-type': 'application/json',
                      // },
                      body: uploadData,
                    })
                      .then((resp) => resp.json())
                      .then((data) => {
                        console.log(data);
                        if (data["status"] === "Block") {
                          toast.error(data["message"]);
                          localStorage.clear();
                          navigate("/Receptionist");
                          window.location.reload();
                        } else if (data["status"] === "1") {
                          toast.success("Profile detail change successfully");
                          if (data["FileUpdated"]) {
                            window.location.reload();
                          }
                        }
                      });
                  }}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClinicProfilesettings;
