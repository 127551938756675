import "../../App.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Mitwank/Sidebar.css";
import { Pagination, Tooltip } from "antd";
import { AppstoreOutlined, UnorderedListOutlined } from "@ant-design/icons";
import ClinicSidebar from "./ClinicSidebar";
import toast from "react-hot-toast";
function DoctorMyPatient() {
  const [patientData, setpatientData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [viewMode, setViewMode] = useState("grid"); // 'grid' or 'list'
  let navigate = useNavigate();

  // Set items per page based on view mode
  const patientsPerPage = viewMode === "grid" ? 6 : 4;

  useEffect(() => {
    let clinic_id = localStorage.getItem("clinicId");
    const Url =
      window.API_PREFIX + `admin/clinicpatients?clinicId=${clinic_id}`;
    // if (clinic_id) {
    //   Url += `?clinicId=${clinic_id}`;
    // }
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        loginAs: localStorage.getItem("LoginDetails"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } else if (data["status"] === "1") {
          const patients = data["allpatient"];
          setpatientData([...patients]);
          // Initialize checkedState based on blockedStatus
          const initialCheckedState = {};
          patients.forEach((patient) => {
            initialCheckedState[patient.id] = patient.IsBlocked === 1;
          });
          setCheckedState(initialCheckedState);
        }
      });
  }, []);

  const filteredPatients = patientData.filter((patient) => {
    return (
      patient.patientName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      patient.displayid.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Calculate patients to display based on current page and view mode
  const startIndex = (currentPage - 1) * patientsPerPage;
  const currentPatients = filteredPatients.slice(
    startIndex,
    startIndex + patientsPerPage
  );

  // Handle view mode toggle
  const toggleViewMode = (mode) => {
    setViewMode(mode);
    setCurrentPage(1); // Reset to first page when view mode changes
  };

  const [checkedState, setCheckedState] = useState({});
  const handleToggle = async (id) => {
    setCheckedState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));

    // Check the new state of the toggle
    const newCheckedState = !checkedState[id];

    try {
      if (newCheckedState) {
        // Call blocking API if the toggle is set to true
        await blockPatient(id);
        console.log(`Patient with id ${id} has been blocked.`);
      } else {
        // Call unblocking API if the toggle is set to false
        await unblockPatient(id);
        console.log(`Patient with id ${id} has been unblocked.`);
      }
    } catch (error) {
      console.error(
        "An error occurred while updating the patient status:",
        error
      );
    }
  };

  // Blocking API call
  const blockPatient = async (id) => {
    var Url = window.API_PREFIX + "patient/blockpatient";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Id: id,
        clinicId: localStorage.getItem("clinicId"),
        loginAs: localStorage.getItem("LoginDetails"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        // dispatch({
        //     type: 'StopLoad'
        // })
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } else if (data["status"] === "1") {
          toast.success("Client is Block Successfully");
        }
      });
  };

  // Unblocking API call
  const unblockPatient = async (id) => {
    var Url = window.API_PREFIX + "patient/unblockpatient";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Id: id,
        clinicId: localStorage.getItem("clinicId"),
        loginAs: localStorage.getItem("LoginDetails"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        // dispatch({
        //     type: 'StopLoad'
        // })
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } else if (data["status"] === "1") {
          toast.success("Client is Un Block Successfully");
        }
      });
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024 && viewMode === "list") {
        setViewMode("grid");
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [viewMode]);


  const [isImageOpen, setIsImageOpen] = useState(false);
  const [isImageClosing, setIsImageClosing] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsImageOpen(true);
    setIsImageClosing(false);
  };

  const closeImagePopup = () => {
    setIsImageClosing(true);
    setTimeout(() => {
      setIsImageOpen(false);
    });
  };

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      closeImagePopup();
    }
  };



  return (
    <>
      <div className="container-fuild mx-2">
        <div className="d-flex justify-content-between align-items-center mt-2">
          <div className="view-toggle-buttons">
            <Tooltip title="Grid View">
              <button
                className={`view-toggle-button ${viewMode === "grid" ? "active" : ""
                  }`}
                onClick={() => toggleViewMode("grid")}
              >
                <AppstoreOutlined />
              </button>
            </Tooltip>
            <Tooltip title="List View">
              <button
                className={`view-toggle-button ${viewMode === "list" ? "active" : ""
                  }`}
                onClick={() => toggleViewMode("list")}
              >
                <UnorderedListOutlined />
              </button>
            </Tooltip>
          </div>
          {/* <div className="search-field">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Clients"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <span className="search-icon"><i className="fa-solid fa-magnifying-glass"></i></span>
                            </div> */}

          <div className="search-field">
            <input
              type="text"
              className="form-control"
              placeholder="Search Clients"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <span className="search-icon">
              <i className="fa-solid fa-magnifying-glass"></i>
            </span>
          </div>
        </div>

        <div className={`row mt-2 ${viewMode}-view`}>
          {currentPatients.map((eachPatient) => {
            const [age, gender] = eachPatient["Age-Gender"].split(",");
            return (
              <div
                className={`${viewMode === "grid"
                    ? "col col-12 col-md-6 col-lg-6 col-xl-4"
                    : "col-12"
                  }`}
                key={eachPatient.id}
              >
                <div
                  className="new_card_main"
                  style={{
                    display: viewMode === "list" ? "flex" : null,
                    justifyContent:
                      viewMode === "list" ? "space-between" : null,
                    filter: checkedState[eachPatient.id]
                      ? "grayscale(100%)"
                      : "none",
                  }}
                >
                  <div
                    style={{
                      display: viewMode === "list" ? "flex" : null,
                    }}
                  >
                    <div className="d-flex mb-3 me-2 align-items-center">
                      <img
                        className="image_div"
                        src={eachPatient["Img"]}
                        alt={eachPatient["patientName"]}
                        onClick={() => handleImageClick(eachPatient["Img"])}
                      ></img>
                      <div>
                        <div className="new_card_client_id">
                          {eachPatient["displayid"]}
                        </div>
                        <div className="new_card_client_name">
                          {eachPatient["patientName"]}
                        </div>
                        <div className="d-flex">
                          <div className="age_title">Age: {age}</div> |
                          <div className="gender_title">{gender}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="other_info">
                    <div>
                      <i className="fa-solid fa-phone me-2"></i>
                      {eachPatient["Phone"]}
                    </div>
                    <div>
                      <i className="fa-solid fa-location-dot me-2"></i>
                      {eachPatient["Address"]}
                    </div>
                  </div>


                  <div className="d-flex align-items-center mt-2 justify-content-between">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={`flexSwitchCheckChecked-${eachPatient.id}`}
                        checked={checkedState[eachPatient.id] || false}
                        onChange={() => handleToggle(eachPatient.id)}
                      />
                    </div>
                    <button
                      className="btn mt-2 more_details_btn"
                      onClick={() => {
                        navigate("/clinic/clientprofile/" + eachPatient["id"], {
                          state: {
                            displayId: eachPatient["displayid"],
                            name: eachPatient["patientName"],
                            Img: eachPatient["Img"],
                            Address: eachPatient["Address"],
                            Bloodgroup: eachPatient["Bloodgroup"],
                            Age_Gender: eachPatient["Age-Gender"],
                            Phone: eachPatient["Phone"],
                          },
                        });
                      }}
                    >
                      More Details
                    </button>
                  </div>

                  {isImageOpen && (
                    <div
                      className={`modal-overlay ${isImageClosing ? "closing" : "opening"}`}
                      style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        transition: "opacity 0.3s ease-in-out",
                      }}
                      onClick={handleOutsideClick}
                    >
                      <div
                        className={`${isImageClosing ? "closing" : "opening"}`}
                        style={{
                          position: "relative",
                          transform: isImageClosing ? "scale(0.9)" : "scale(1)",
                          transition: "transform 0.3s ease-in-out",
                        }}
                      >

                        <img
                          src={selectedImage}
                          alt="Enlarged"
                          style={{
                            height: '300px',
                            width: '300px',
                            objectFit: 'contain',
                            borderRadius: "50%",
                            background:'white'
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {/* Ant Design Pagination controls */}
        <div className="pagination-controls d-flex justify-content-center mt-4">
          <Pagination
            current={currentPage}
            pageSize={patientsPerPage}
            total={filteredPatients.length}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
      </div>
    </>
  );
}

export default DoctorMyPatient;
