import "./MitwankClients.css";
import "../../App.css";
import MitwankSidebar from "./MitwankSidebar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Mitwank/Sidebar.css";
import { Pagination, Tooltip } from "antd";
import { AppstoreOutlined, UnorderedListOutlined } from "@ant-design/icons";
import TextField from "@mui/material/TextField";
import toast from "react-hot-toast";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

function Myclinic() {
  const [ClinicData, setClinicData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [viewMode, setViewMode] = useState("grid");
  let navigate = useNavigate();

  const ClinicsPerPage = viewMode === "grid" ? 6 : 4;

  useEffect(() => {
    const Url = window.API_PREFIX + "admin/allclinics";
    fetch(Url, {
      method: "GET",
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          const clinics = data["allClinicList"];
          setClinicData([...clinics]);
          // Initialize checkedState based on blockedStatus
          const initialCheckedState = {};
          clinics.forEach((clinic) => {
            initialCheckedState[clinic.id] = clinic.IsBlocked === 1;
          });
          setCheckedState(initialCheckedState);
        }
      });
  }, []);

  const filteredClinics = ClinicData.filter((patient) => {
    return (
      patient.clinicName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      patient.clinicMobile.toLowerCase().includes(searchQuery.toLowerCase()) ||
      patient.clinicEmail.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Calculate patients to display based on current page and view mode
  const startIndex = (currentPage - 1) * ClinicsPerPage;
  const currentClinics = filteredClinics.slice(
    startIndex,
    startIndex + ClinicsPerPage
  );

  // Handle view mode toggle
  const toggleViewMode = (mode) => {
    setViewMode(mode);
    setCurrentPage(1); // Reset to first page when view mode changes
  };

  // Add Clinic Start

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (event.target.id === "myModal") {
      closeModal();
    }
  };

  const [clinicname, setclinicname] = useState();
  const [address, setaddress] = useState();
  const [timing, settiming] = useState();
  const [discription, setdiscription] = useState();
  const [number, setnumber] = useState();
  const [password, setpassword] = useState();
  const [profile, setprofile] = useState();
  const [mail, setmail] = useState();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [checkedState, setCheckedState] = useState({});
  // const handleToggle = (id) => {
  //   setCheckedState((prevState) => ({
  //     ...prevState,
  //     [id]: !prevState[id], // Toggle the checked state for the specific patient
  //   }));
  // };

  const handleToggle = async (id) => {
    setCheckedState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));

    // Check the new state of the toggle
    const newCheckedState = !checkedState[id];

    try {
      if (newCheckedState) {
        // Call blocking API if the toggle is set to true
        await blockClinic(id);
        console.log(`Clinic with id ${id} has been blocked.`);
      } else {
        // Call unblocking API if the toggle is set to false
        await unblockClinic(id);
        console.log(`Clinic with id ${id} has been unblocked.`);
      }
    } catch (error) {
      console.error(
        "An error occurred while updating the Clinic status:",
        error
      );
    }
  };

  // Blocking API call
  const blockClinic = async (id) => {
    var Url = window.API_PREFIX + "admin/blockclinic";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Id: id,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        // dispatch({
        //     type: 'StopLoad'
        // })
        if (data["status"] === "1") {
          toast.success("Clinic is Block Successfully");
        }
      });
  };

  // Unblocking API call
  const unblockClinic = async (id) => {
    var Url = window.API_PREFIX + "admin/unblockclinic";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Id: id,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        // dispatch({
        //     type: 'StopLoad'
        // })
        if (data["status"] === "1") {
          toast.success("Clinic is Un Block Successfully");
        }
      });
  };

  const callCinicList = () => {
    const Url = window.API_PREFIX + "admin/allclinics";
    fetch(Url, {
      method: "GET",
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          const clinics = data["allClinicList"];
          setClinicData([...clinics]);
          // Initialize checkedState based on blockedStatus
          const initialCheckedState = {};
          clinics.forEach((clinic) => {
            initialCheckedState[clinic.id] = clinic.IsBlocked === 1;
          });
          setCheckedState(initialCheckedState);
        }
      });
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024 && viewMode === "list") {
        setViewMode("grid");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [viewMode]);

  return (
    <>
      <div className="container-fuild mx-2 mt-2">
        <div className="d-flex justify-content-between align-items-center mt-2">
          <div className="view-toggle-buttons">
            <Tooltip title="Grid View">
              <button
                className={`view-toggle-button ${
                  viewMode === "grid" ? "active" : ""
                }`}
                onClick={() => toggleViewMode("grid")}
              >
                <AppstoreOutlined />
              </button>
            </Tooltip>
            <Tooltip title="List View">
              <button
                className={`view-toggle-button ${
                  viewMode === "list" ? "active" : ""
                }`}
                onClick={() => toggleViewMode("list")}
              >
                <UnorderedListOutlined />
              </button>
            </Tooltip>
          </div>

          <div className="d-flex align-items-center">
            <div className="search-field me-2">
              <input
                type="text"
                className="form-control"
                placeholder="Search Clinics"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <span className="search-icon">
                <i className="fa-solid fa-magnifying-glass"></i>
              </span>
            </div>

            <div class="text-end">
              <button
                class="mitwank_patient_profile_add_prescription_btn"
                onClick={openModal}
              >
                + Add Clinic
              </button>
            </div>

            {isModalOpen && (
              <div id="myModal" className="modal" onClick={handleOutsideClick}>
                <div className="modal-content">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Add Clinic</h5>
                    <i
                      onClick={closeModal}
                      class="fa-solid fa-xmark fa-x close"
                    ></i>
                  </div>
                  <div className="mt-3">
                    <div>
                      <label for="formFile" class="form-label">
                        Profile Picture
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        accept="image/gif, image/jpeg, image/png"
                        id="formFile"
                        onChange={(e) => {
                          setprofile(e.target.files[0]);
                        }}
                        required
                      />
                    </div>

                    <TextField
                      id="outlined-basic"
                      label="Clinic Name"
                      variant="outlined"
                      size="small"
                      className="w-100 mt-3"
                      onChange={(e) => {
                        setclinicname(e.target.value);
                      }}
                      required
                    />

                    <TextField
                      id="outlined-basic"
                      label="Address"
                      variant="outlined"
                      size="small"
                      className="w-100 mt-3"
                      onChange={(e) => {
                        setaddress(e.target.value);
                      }}
                      required
                    />

                    <TextField
                      id="outlined-basic"
                      label="Timing"
                      variant="outlined"
                      size="small"
                      className="w-100 mt-3"
                      onChange={(e) => {
                        settiming(e.target.value);
                      }}
                      required
                    />

                    <TextField
                      type="number"
                      id="outlined-basic"
                      label="Mobile No."
                      variant="outlined"
                      size="small"
                      className="w-100 mt-3"
                      onChange={(e) => {
                        setnumber(e.target.value);
                      }}
                      required
                    />

                    <TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      size="small"
                      className="w-100 mt-3"
                      onChange={(e) => {
                        setmail(e.target.value);
                      }}
                      required
                    />

                    <FormControl
                      sx={{ width: "100%" }}
                      variant="outlined"
                      className="mt-3"
                    >
                      <InputLabel htmlFor="outlined-adornment-password">
                        Creat Password
                      </InputLabel>
                      <OutlinedInput
                        size="small"
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                        onChange={(e) => {
                          setpassword(e.target.value);
                        }}
                        required
                      />
                    </FormControl>

                    <textarea
                      class="form-control mt-3"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Add Discription"
                      onChange={(e) => {
                        setdiscription(e.target.value);
                      }}
                    ></textarea>

                    <div class="d-flex justify-content-center mt-3">
                      <button
                        type="button"
                        class="popup_submit_button btn"
                        onClick={() => {
                          var Url = window.API_PREFIX + "admin/addclinic";
                          var uploadData = new FormData();
                          // uploadData.append("Token", localStorage.getItem("DToken"));
                          uploadData.append("clinicProfilePic", profile);
                          uploadData.append("clinicName", clinicname);
                          uploadData.append("clinicAddress", address);
                          uploadData.append("clinicTiming", timing);
                          uploadData.append("clinicMobile", number);
                          uploadData.append("clinicEmail", mail);
                          uploadData.append("clinicPassword", password);
                          uploadData.append("clinicDescription", discription);

                          fetch(Url, {
                            method: "POST",
                            body: uploadData,
                          })
                            .then((resp) => resp.json())
                            .then((data) => {
                              console.log(data);
                              if (data["status"] === "1") {
                                closeModal();
                                toast.success("Added Successfully");
                                callCinicList();
                              }
                            });
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={`row mt-2 ${viewMode}-view`}>
          {currentClinics.map((eachClinic) => {
            return (
              <div
                className={`${
                  viewMode === "grid"
                    ? "col col-12 col-md-6 col-lg-6 col-xl-4"
                    : "col-12"
                }`}
                key={eachClinic.id}
              >
                <div
                  className="new_card_main"
                  style={{
                    display: viewMode === "list" ? "flex" : null,
                    justifyContent:
                      viewMode === "list" ? "space-between" : null,
                    filter: checkedState[eachClinic.id]
                      ? "grayscale(100%)"
                      : "none",
                  }}
                >
                  <div
                    style={{
                      display: viewMode === "list" ? "flex" : null,
                    }}
                  >
                    <div className="d-flex mb-3 me-2 align-items-center">
                      <img
                        className="image_div"
                        src={eachClinic["clinicProfilePic"]}
                        alt={eachClinic["clinicName"]}
                      ></img>
                      <div>
                        <div className="new_card_client_name">
                          {eachClinic["clinicName"]}
                        </div>
                        <div className="new_card_client_name">
                          {eachClinic["clinicEmail"]}
                        </div>
                      </div>
                    </div>

                    <div
                      className="other_info"
                      style={{
                        width: viewMode === "list" ? "40%" : null,
                      }}
                    >
                      <div>
                        <i className="fa-solid fa-phone me-2"></i>
                        {eachClinic["clinicMobile"]}
                      </div>
                      <div>
                        <i className="fa-solid fa-location-dot me-2"></i>
                        {eachClinic["clinicAddress"]}
                      </div>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mt-2 justify-content-between">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={`flexSwitchCheckChecked-${eachClinic.id}`}
                        checked={checkedState[eachClinic.id] || false}
                        onChange={() => handleToggle(eachClinic.id)}
                      />
                    </div>
                    <button
                      className="btn more_details_btn"
                      onClick={() => {
                        navigate("/mitwank/clinicprofile/" + eachClinic["id"], {
                          state: {
                            Img: eachClinic["clinicProfilePic"],
                            name: eachClinic["clinicName"],
                            mail: eachClinic["clinicEmail"],
                            Phone: eachClinic["clinicMobile"],
                            Address: eachClinic["clinicAddress"],
                            discription: eachClinic["clinicDescription"],
                            timing: eachClinic["clinicTiming"],
                          },
                        });
                      }}
                    >
                      More Details
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* Ant Design Pagination controls */}
        <div className="pagination-controls d-flex justify-content-end mt-4">
          <Pagination
            current={currentPage}
            pageSize={ClinicsPerPage}
            total={filteredClinics.length}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
      </div>
    </>
  );
}

export default Myclinic;
