import React from "react";
import "../../App.css";
import { useEffect, useState } from "react";
import { Table } from "antd";
import "antd/dist/antd.css";
import { Popover } from "antd";
import Loader from "../Loader";
import Popup from "reactjs-popup";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
function Receptionist_dashboard() {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    pageSizeOptions: ["5", "10", "20", "50", "100"],
    showSizeChanger: true,
  });
  let navigate = useNavigate();

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };
  //     ######################### Notification Section ###########################

  const [anchorEl, setAnchorEl] = useState(null);

  // const [AllMsg,setAllMsg] = useState([])
  const [GroupName, setGroupName] = useState("");
  // const [unreadcount,setunreadcount] = useState("")

  const [chatSocket, setChatSocket] = useState(null);
  const [webSocketInitialized, setWebSocketInitialized] = useState(false);
  const [userdata, setuserdata] = useState("");
  console.log("userdata1209: ", userdata);

  useEffect(() => {
    if (!webSocketInitialized) {
      const socket = new WebSocket(window.WEBSOC + "ws/ReceptionistDashboard/");
      socket.onopen = () => {
        console.log("WebSocket connection opened");
        setWebSocketInitialized(true);
      };
      socket.onmessage = function (e) {
        let received_data_as_object = e["data"];
        // console.log(received_data_as_object)

        if (received_data_as_object.split("_")[0] === "Reload") {
          if (AppointmentOption[0]) {
            upcoming_booking();
          } else if (AppointmentOption[1]) {
            today_booking();
          } else if (AppointmentOption[2]) {
            previous_booking();
          } else if (AppointmentOption[3]) {
            all_trash_booking();
          }
        }
        //   const data = JSON.parse(e.data);
        // setChatLog((prevChatLog) => prevChatLog + e.data + '\n');
      };

      socket.onclose = function (e) {
        console.error("Chat socket closed unexpectedly");
        setWebSocketInitialized(false);
      };
      setChatSocket(socket);
      // return () => {
      //     socket.close();
      // };
    }
  }, [GroupName]);

  useEffect(() => {
    var Url = window.API_PREFIX + "admin/receptionistdetail";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        } else if (data["status"] === "1") {
          setuserdata({ ...data["data"] });
        }
      });
  }, []);

  const [loaderon, setloaderon] = useState({});
  const [loaderonpayment, setloaderonpayment] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = (page) => {
    setCurrentPage(page - 1);
    console.log("Current page:", page);
  };

  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const handleEntriesPerPageChange = (current, size) => {
    setEntriesPerPage(size);
    console.log("Entries per page:", size);
  };

  const findBookingIndex = (id) => {
    const index = AppointmentData.findIndex(
      (booking) => booking.BookingId === id
    );
    return index;
  };

  function Pending_Payment(Paymentindex, BookingId) {
    var Url = window.API_PREFIX + "admin/Paybydoctor";
    var BookingIndex = findBookingIndex(BookingId);
    setloaderonpayment({ ...loaderonpayment, [Paymentindex]: true });
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: AppointmentData[BookingIndex]["BookingId"],
        PatientId: AppointmentData[BookingIndex]["PatientId"],
        loginAs: localStorage.getItem("LoginDetails"),
        receptionistId: localStorage.getItem("receptionistId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        setloaderonpayment({ ...loaderonpayment, [Paymentindex]: false });
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        } else if (data["status"] === "1") {
          var tmp = AppointmentData;
          tmp[BookingIndex]["BookingStatus"] = 2;
          setAppointmentData([...tmp]);
        }
      });
  }

  function Delete_Appointment(Requestindex, BookingId) {
    var Url = window.API_PREFIX + "booking/delete_booking";
    // dispatch({
    //     type: 'StartLoad'
    // })
    var BookingIndex = findBookingIndex(BookingId);

    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: AppointmentData[BookingIndex]["BookingId"],
        loginAs: localStorage.getItem("LoginDetails"),
        receptionistId: localStorage.getItem("receptionistId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        // dispatch({
        //     type: 'StopLoad'
        // })
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        } else if (data["status"] === "1") {
          var tmp = AppointmentData;
          tmp.splice(BookingIndex, 1);
          setAppointmentData([...tmp]);
        }
      });
  }

  function RestoreRequest(Requestindex, BookingId) {
    var Url = window.API_PREFIX + "booking/restore_trash_booking";
    // dispatch({
    //     type: 'StartLoad'
    // })
    var BookingIndex = findBookingIndex(BookingId);

    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: AppointmentData[BookingIndex]["BookingId"],
        loginAs: localStorage.getItem("LoginDetails"),
        receptionistId: localStorage.getItem("receptionistId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        // dispatch({
        //     type: 'StopLoad'
        // })
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        } else if (data["status"] === "1") {
          var tmp = AppointmentData;
          tmp.splice(BookingIndex, 1);
          setAppointmentData([...tmp]);
        }
      });
  }

  function TrashDelete(Requestindex, BookingId) {
    var Url = window.API_PREFIX + "booking/trash_booking";
    var BookingIndex = findBookingIndex(BookingId);

    // dispatch({
    //     type: 'StartLoad'
    // })
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: AppointmentData[BookingIndex]["BookingId"],
        loginAs: localStorage.getItem("LoginDetails"),
        receptionistId: localStorage.getItem("receptionistId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        // dispatch({
        //     type: 'StopLoad'
        // })
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        } else if (data["status"] === "1") {
          var tmp = AppointmentData;
          tmp.splice(BookingIndex, 1);
          setAppointmentData([...tmp]);
        }
      });
  }

  function AcceptRequest(Requestindex, BookingId) {
    var Url = window.API_PREFIX + "booking/approve_booking";
    var BookingIndex = findBookingIndex(BookingId);
    setloaderon({ ...loaderon, [Requestindex]: true });
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: AppointmentData[BookingIndex]["BookingId"],
        receptionist: userdata?.Name,
        clinicId: localStorage.getItem("clinicId"),
        loginAs: localStorage.getItem("LoginDetails"),
        receptionistId: localStorage.getItem("receptionistId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        setloaderon({ ...loaderon, [Requestindex]: false });
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        } else if (data["status"] === "1") {
          var tmp = AppointmentData;
          tmp[BookingIndex]["BookingStatus"] = 1;
          setAppointmentData([...tmp]);
        }
      });
  }

  function RejectRequest(Requestindex, BookingId) {
    var Url = window.API_PREFIX + "booking/reject_booking";
    setloaderon({ ...loaderon, [Requestindex]: true });
    var BookingIndex = findBookingIndex(BookingId);

    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: AppointmentData[BookingIndex]["BookingId"],
        receptionist: userdata?.Name,
        clinicId: localStorage.getItem("clinicId"),
        loginAs: localStorage.getItem("LoginDetails"),
        receptionistId: localStorage.getItem("receptionistId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        setloaderon({ ...loaderon, [Requestindex]: false });
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        } else if (data["status"] === "1") {
          var tmp = AppointmentData;
          tmp[BookingIndex]["BookingStatus"] = -1;
          setAppointmentData([...tmp]);
        }
      });
  }

  function DeleteRequest(Requestindex, BookingId) {
    var BookingIndex = findBookingIndex(BookingId);

    var Url = window.API_PREFIX + "admin/delete_booking";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: AppointmentData[BookingIndex]["BookingId"],
        loginAs: localStorage.getItem("LoginDetails"),
        receptionistId: localStorage.getItem("receptionistId"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        } else if (data["status"] === "1") {
          var tmp = AppointmentData;
          tmp[BookingIndex]["BookingStatus"] = -1;
          setAppointmentData([...tmp]);
        }
      });
  }
  // function AcceptRequest(Requestindex) {
  //     var tmp = AppointmentData
  //     tmp[Requestindex]['BookingStatus'] = 1
  //     setAppointmentData([...tmp])
  // }

  // function RejectRequest(Requestindex) {
  //     var tmp = AppointmentData
  //     tmp[Requestindex]['BookingStatus'] = -1
  //     setAppointmentData([...tmp])
  // }

  const columns = [
    {
      title: "Client Name",

      dataIndex: "PatientName",
      key: "name",
      render: (text, record) => (
        <>
          <div className="admin_dashboard_table_image_name_division">
            <img
              className="admin_dashboard_table_image"
              src={record["ProfilePic"]}
              style={{ height: "2.5rem", width: "2.5rem" }}
            />
            <div className="admin_dashboard_table_name_id_division">
              <div className="admin_dashboard_table_patient_name">{text}</div>
              <div className="admin_dashboard_table_patient_id">
                #SH{record["PatientId"]}
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Booking Date",
      dataIndex: "Date",
      key: "Bookingdate",
    },
    {
      title: "Appt Date",
      dataIndex: "Date",
      key: "date",
      render: (text, record) => (
        <>
          <div className="admin_dashboard_time_date_div">
            <div className="admin_dashboard_table_appt_date">{text}</div>
            <div className="admin_dashboard_table_appt_time">
              {record["Time"]}
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Package",
      dataIndex: "Purpose",
      key: "purpose",
      render: (text, record, index) => (
        <>
          <div className="admin_dashboard_table_appt_date">{text}</div>
        </>
      ),
    },
    // {
    //   title: 'Purpose',
    //   dataIndex: 'Purpose',
    //   key: 'purpose',
    //   render: (text, record, index) => <>
    //     <div className='admin_dashboard_table_appt_date'>{text}</div>

    //   </>
    // },
    {
      title: " Amount",
      dataIndex: "Amount",
      key: "paidamount",
    },
    {
      title: "Status",
      key: "tags",
      dataIndex: "BookingStatus",
      render: (text, record, index) => (
        <>
          {text === 0 ? (
            <div className="admin_dashboard_tabel_view_accept_cancel">
              {loaderon[index] ? (
                <Loader />
              ) : (
                <button
                  className="btn admin_dashboard_tabel_accept_btn"
                  onClick={() => {
                    AcceptRequest(index, record.BookingId);
                  }}
                >
                  Accept
                </button>
              )}

              {loaderon[index] ? (
                <Loader />
              ) : (
                <button
                  className="btn admin_dashboard_tabel_cancel_btn"
                  onClick={() => {
                    RejectRequest(index, record.BookingId);
                  }}
                >
                  Reject
                </button>
              )}
            </div>
          ) : text === 1 ? (
            <div className="admin_dashboard_tabel_view_accept_cancel">
              <div>
                <button
                  className="btn admin_dashboard_tabel_accept_btn"
                  disabled
                >
                  Accepted
                </button>
              </div>
            </div>
          ) : text === 2 ? (
            <div className="admin_dashboard_tabel_view_accept_cancel">
              <div>
                <button
                  className="btn admin_dashboard_tabel_accept_btn"
                  disabled
                >
                  Accepted
                </button>
              </div>
            </div>
          ) : (
            <div className="admin_dashboard_tabel_view_accept_cancel">
              <div>
                <button
                  className="btn admin_dashboard_tabel_cancel_btn"
                  disabled
                >
                  Rejected
                </button>
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Payment Status",
      key: "tags",
      dataIndex: "Ispaid",
      render: (text, record, index) => (
        <>
          <div className="admin_dashboard_tabel_view_accept_cancel">
            {record["BookingStatus"] === 1 ? (
              <div>
                {loaderonpayment[index] ? (
                  <Loader />
                ) : (
                  <Popover
                    content={
                      <div className="d-flex justify-content-between">
                        <a onClick={pophide}>No</a>
                        <a
                          onClick={() => {
                            Pending_Payment(index, record.BookingId);
                            pophide();
                          }}
                        >
                          Yes
                        </a>
                      </div>
                    }
                    title="Are you want to sure ?"
                    trigger="click"
                    open={activePopoverIndex === index}
                    onOpenChange={(open) => handlepopOpenChange(index, open)}
                  >
                    <button className=" btn admin_dashboard_tabel_view_btn">
                      {" "}
                      Pending Payment
                    </button>
                  </Popover>
                )}
              </div>
            ) : record["BookingStatus"] === 2 ? (
              <div>
                <button
                  className="btn admin_dashboard_tabel_accept_btn"
                  disabled
                >
                  Paid
                </button>
              </div>
            ) : record["BookingStatus"] === 0 ? (
              <div>
                <button className="btn admin_dashboard_tabel_view_btn" disabled>
                  Pending Request
                </button>
              </div>
            ) : (
              <div>
                <button
                  className="btn admin_dashboard_tabel_cancel_btn"
                  disabled
                >
                  N/A
                </button>
              </div>
            )}
          </div>
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (text, record, index) => (
        <div className="d-flex justify-content-center align-ietms-center">
          <i
            class="fa-solid fa-trash-can me-2"
            style={{ color: "#e11f28" }}
            onClick={() => {
              // var INDEX = currentPage * entriesPerPage + index

              TrashDelete(index, record.BookingId);
            }}
          ></i>

          <i
            class="fa-solid fa-pen-to-square"
            style={{ color: "#26af48" }}
            onClick={() => {
              seteditpricePop(true);
              seteacheditprice(record.Amount);
              setSelectedRecord(record);
            }}
          ></i>
        </div>
      ),
    },
  ];

  const previouscolumns = [
    {
      title: "Client Name",

      dataIndex: "PatientName",
      key: "name",
      render: (text, record) => (
        <>
          <div className="admin_dashboard_table_image_name_division">
            <img
              className="admin_dashboard_table_image"
              src={record["ProfilePic"]}
              style={{ height: "2.5rem", width: "2.5rem" }}
            />
            <div className="admin_dashboard_table_name_id_division">
              <div className="admin_dashboard_table_patient_name">{text}</div>
              <div className="admin_dashboard_table_patient_id">
                #SH{record["PatientId"]}
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Booking Date",
      dataIndex: "Date",
      key: "Bookingdate",
    },
    {
      title: "Appt Date",
      dataIndex: "Date",
      key: "date",
      render: (text, record) => (
        <>
          <div className="admin_dashboard_time_date_div">
            <div className="admin_dashboard_table_appt_date">{text}</div>
            <div className="admin_dashboard_table_appt_time">
              {record["Time"]}
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Package",
      dataIndex: "Purpose",
      key: "purpose",
      render: (text, record, index) => (
        <>
          <div className="admin_dashboard_table_appt_date">{text}</div>
        </>
      ),
    },
    {
      title: " Amount",
      dataIndex: "Amount",
      key: "paidamount",
    },
    {
      title: "Status",
      key: "tags",
      dataIndex: "BookingStatus",
      render: (text, record, index) => (
        <>
          {text === 0 ? (
            <div className="admin_dashboard_tabel_view_accept_cancel">
              {loaderon[index] ? (
                <Loader />
              ) : (
                <button
                  className="btn admin_dashboard_tabel_accept_btn"
                  onClick={() => {
                    AcceptRequest(
                      index,
                      record.BookingId,
                      record.ClinicDetails.ClinicId
                    );
                  }}
                >
                  Accept
                </button>
              )}

              {loaderon[index] ? (
                <Loader />
              ) : (
                <button
                  className="btn admin_dashboard_tabel_cancel_btn"
                  onClick={() => {
                    RejectRequest(
                      index,
                      record.BookingId,
                      record.ClinicDetails.ClinicId
                    );
                  }}
                >
                  Reject
                </button>
              )}
            </div>
          ) : text === 1 ? (
            <div className="admin_dashboard_tabel_view_accept_cancel">
              <div>
                <button
                  className="btn admin_dashboard_tabel_accept_btn"
                  disabled
                >
                  Accepted
                </button>
              </div>
            </div>
          ) : text === 2 ? (
            <div className="admin_dashboard_tabel_view_accept_cancel">
              <div>
                <button
                  className="btn admin_dashboard_tabel_accept_btn"
                  disabled
                >
                  Accepted
                </button>
              </div>
            </div>
          ) : (
            <div className="admin_dashboard_tabel_view_accept_cancel">
              <div>
                <button
                  className="btn admin_dashboard_tabel_cancel_btn"
                  disabled
                >
                  Rejected
                </button>
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Payment Status",
      key: "tags",
      dataIndex: "Ispaid",
      render: (text, record, index) => (
        <>
          <div className="admin_dashboard_tabel_view_accept_cancel">
            {record["BookingStatus"] === 1 ? (
              <div>
                {loaderonpayment[index] ? (
                  <Loader />
                ) : (
                  <Popover
                    content={
                      <div className="d-flex justify-content-between">
                        <a onClick={pophide}>No</a>
                        <a
                          onClick={() => {
                            Pending_Payment(index, record.BookingId);
                            pophide();
                          }}
                        >
                          Yes
                        </a>
                      </div>
                    }
                    title="Are you want to sure ?"
                    trigger="click"
                    open={activePopoverIndex === index}
                    onOpenChange={(open) => handlepopOpenChange(index, open)}
                  >
                    <button className=" btn admin_dashboard_tabel_view_btn">
                      {" "}
                      Pending Payment
                    </button>
                  </Popover>
                )}
              </div>
            ) : record["BookingStatus"] === 2 ? (
              <div>
                <button
                  className="btn admin_dashboard_tabel_accept_btn"
                  disabled
                >
                  Paid
                </button>
              </div>
            ) : record["BookingStatus"] === 0 ? (
              <div>
                <button className="btn admin_dashboard_tabel_view_btn" disabled>
                  Pending Request
                </button>
              </div>
            ) : (
              <div>
                <button
                  className="btn admin_dashboard_tabel_cancel_btn"
                  disabled
                >
                  N/A
                </button>
              </div>
            )}
          </div>
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (text, record, index) => (
        <div className="d-flex justify-content-center align-ietms-center">
          <i
            class="fa-solid fa-trash-can me-2"
            style={{ color: "#e11f28" }}
            onClick={() => {
              // var INDEX = currentPage * entriesPerPage + index

              TrashDelete(index, record.BookingId);
            }}
          ></i>
          {/* 
          <i
            class="fa-solid fa-pen-to-square"
            style={{ color: "#26af48" }}
            onClick={() => {
              seteditpricePop(true);
            }}
          ></i> */}
        </div>
      ),
    },
  ];

  const trashColumns = [
    {
      title: "Client Name",

      dataIndex: "PatientName",
      key: "name",
      render: (text, record) => (
        <>
          <div className="admin_dashboard_table_image_name_division">
            <img
              className="admin_dashboard_table_image"
              src={record["ProfilePic"]}
              style={{ height: "2.5rem", width: "2.5rem" }}
            />
            <div className="admin_dashboard_table_name_id_division">
              <div className="admin_dashboard_table_patient_name">{text}</div>
              <div className="admin_dashboard_table_patient_id">
                #SH{record["PatientId"]}
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Appt Date",
      dataIndex: "Date",
      key: "date",
      render: (text, record) => (
        <>
          <div className="admin_dashboard_time_date_div">
            <div className="admin_dashboard_table_appt_date">{text}</div>
            <div className="admin_dashboard_table_appt_time">
              {record["Time"]}
            </div>
          </div>
        </>
      ),
    },
    // {
    //   title: 'Purpose',
    //   dataIndex: 'Purpose',
    //   key: 'purpose',
    //   render: (text, record) => <>
    //     <div className='admin_dashboard_table_appt_date'>{text}</div>

    //   </>
    // },
    {
      title: " Amount",
      dataIndex: "Amount",
      key: "paidamount",
    },
    {
      title: "Option",
      key: "tags",
      dataIndex: "BookingStatus",
      render: (text, record, index) => (
        <>
          <div>
            <button
              className="btn admin_dashboard_tabel_cancel_btn"
              onClick={() => {
                var INDEX = currentPage * entriesPerPage + index;

                RestoreRequest(INDEX, record.BookingId);
              }}
            >
              Restore
            </button>
          </div>
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (text, record, index) => (
        <>
          <i
            class="fa-solid fa-trash-can"
            style={{ color: "#e11f28" }}
            onClick={() => {
              var INDEX = currentPage * entriesPerPage + index;
              Delete_Appointment(INDEX, record.BookingId);
            }}
          ></i>
        </>
      ),
    },
  ];

  function upcoming_booking(action = null) {
    var Url = window.API_PREFIX + "admin/upcoming_booking";
    let clinic_id = localStorage.getItem("clinicId");
    let loginAs = localStorage.getItem("LoginDetails");
    let receptionistId = localStorage.getItem("receptionistId");
    let queryParams = [];
    if (clinic_id) queryParams.push(`clinicId=${clinic_id}`);
    if (loginAs) queryParams.push(`loginAs=${loginAs}`);
    if (receptionistId) queryParams.push(`receptionistId=${receptionistId}`);
    if (queryParams.length > 0) {
      Url += `?${queryParams.join("&")}`;
    }
    console.log('Url: ', Url);
    fetch(Url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        } else if (data["status"] === "1") {
          if (action === null) {
            setAppointmentOption([true, false, false, false]);
            setAppointmentData([...data["Booking"]]);
          }
        }
      });
  }

  function today_booking() {
    var Url = window.API_PREFIX + "admin/Today_booking";
    let clinic_id = localStorage.getItem("clinicId");
    let loginAs = localStorage.getItem("LoginDetails");
    let receptionistId = localStorage.getItem("receptionistId");
    let queryParams = [];
    if (clinic_id) queryParams.push(`clinicId=${clinic_id}`);
    if (loginAs) queryParams.push(`loginAs=${loginAs}`);
    if (receptionistId) queryParams.push(`receptionistId=${receptionistId}`);
    if (queryParams.length > 0) {
      Url += `?${queryParams.join("&")}`;
    }
    fetch(Url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
      // body: JSON.stringify({'Token':localStorage.getItem('DToken')}),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        }
        else if (data["status"] === "1") {
          setAppointmentOption([false, true, false, false]);
          setAppointmentData([...data["Booking"]]);
        }
      });
  }

  function previous_booking() {
    var Url = window.API_PREFIX + "admin/Previous_booking";
    let clinic_id = localStorage.getItem("clinicId");
    let loginAs = localStorage.getItem("LoginDetails");
    let receptionistId = localStorage.getItem("receptionistId");
    let queryParams = [];
    if (clinic_id) queryParams.push(`clinicId=${clinic_id}`);
    if (loginAs) queryParams.push(`loginAs=${loginAs}`);
    if (receptionistId) queryParams.push(`receptionistId=${receptionistId}`);
    if (queryParams.length > 0) {
      Url += `?${queryParams.join("&")}`;
    }
    fetch(Url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
      // body: JSON.stringify({'Token':localStorage.getItem('DToken')}),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        }
        else if (data["status"] === "1") {
          setAppointmentOption([false, false, true, false]);

          setAppointmentData([...data["Booking"]]);
        }
      });
  }

  function all_trash_booking() {
    var Url = window.API_PREFIX + "admin/all_trash_bookings";
    let clinic_id = localStorage.getItem("clinicId");
    let loginAs = localStorage.getItem("LoginDetails");
    let receptionistId = localStorage.getItem("receptionistId");
    let queryParams = [];
    if (clinic_id) queryParams.push(`clinicId=${clinic_id}`);
    if (loginAs) queryParams.push(`loginAs=${loginAs}`);
    if (receptionistId) queryParams.push(`receptionistId=${receptionistId}`);
    if (queryParams.length > 0) {
      Url += `?${queryParams.join("&")}`;
    }
    fetch(Url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
      // body: JSON.stringify({'Token':localStorage.getItem('DToken')}),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Receptionist");
          window.location.reload();
        }
        else if (data["status"] === "1") {
          setAppointmentOption([false, false, false, true]);

          setAppointmentData([...data["Booking"]]);
        }
      });
  }
  const [AppointmentData, setAppointmentData] = useState([]);
  const [AppointmentOption, setAppointmentOption] = useState([
    true,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    upcoming_booking();
  }, []);

  const [activePopoverIndex, setActivePopoverIndex] = useState(null);

  const handlepopOpenChange = (index, open) => {
    if (open) {
      setActivePopoverIndex(index);
    } else {
      setActivePopoverIndex(null);
    }
  };

  const pophide = () => {
    setActivePopoverIndex(null);
  };

  const [editpricePop, seteditpricePop] = useState(false);
  const [eacheditprice, seteacheditprice] = useState("");
  const [selectedRecord, setSelectedRecord] = useState("");
  const editpricepopup = {
    borderRadius: "10px",
    backgroundColor: "white",
    // boxshadow: "1 1 20px rgba(255, 255, 255, 0.4)",
    borderradius: "5px",
    padding: "0px",
    width: "100%",
    height: "max-content",
    margin: "60px 450px",
  };

  return (
    <>
      <div className="container-fuild mx-2">
        <div className="admin_dashboard_main_diviison">
          <div className="admin_dashboard_patient_appointment_table_main_division">
            {/* <p className='admin_dashboard_patientappoinment_name'>Client Appoinments</p> */}
            <div className="admin_dashboard_upcoming_today_btn_divsion">
              <div className="admin_dashboard_today_btn_divsion">
                <button
                  type="button"
                  class={
                    AppointmentOption[0]
                      ? "btn admin_dashboard_upcoming_btn_active"
                      : "btn admin_dashboard_upcoming_btn"
                  }
                  onClick={() => {
                    upcoming_booking();
                  }}
                >
                  Upcoming
                </button>
              </div>
              <div className="admin_dashboard_today_btn_divsion">
                <button
                  type="button"
                  class={
                    AppointmentOption[1]
                      ? "btn admin_dashboard_upcoming_btn_active"
                      : "btn admin_dashboard_upcoming_btn"
                  }
                  onClick={() => {
                    today_booking();
                  }}
                >
                  Today
                </button>
              </div>
              <div className="admin_dashboard_today_btn_divsion">
                <button
                  type="button"
                  class={
                    AppointmentOption[2]
                      ? "btn admin_dashboard_upcoming_btn_active"
                      : "btn admin_dashboard_upcoming_btn"
                  }
                  onClick={() => {
                    previous_booking();
                  }}
                >
                  Previous
                </button>
              </div>

              <div className="admin_dashboard_today_btn_divsion">
                <button
                  type="button"
                  class={
                    AppointmentOption[3]
                      ? "btn admin_dashboard_upcoming_btn_active"
                      : "btn admin_dashboard_upcoming_btn"
                  }
                  onClick={() => {
                    all_trash_booking();
                  }}
                >
                  Trash
                </button>
              </div>
            </div>
            <div className="admin_dashboard_patient_appointment_table_division">
              <Table
                columns={
                  AppointmentOption[3]
                    ? trashColumns
                    : AppointmentOption[2]
                    ? previouscolumns
                    : columns
                }
                dataSource={AppointmentData}
                // pagination={{
                //     current: currentPage + 1,
                //     onChange: handlePageChange,
                //     pageSize: entriesPerPage,
                //     onShowSizeChange: handleEntriesPerPageChange,
                // }}
                pagination={pagination}
                onChange={handleTableChange}
              />

              <Popup
                contentStyle={editpricepopup}
                modal
                 // closeOnDocumentClick={false}
                open={editpricePop}
                onClose={() => {
                  seteditpricePop(false);
                  seteacheditprice("");
                }}
              >
                <div className="Add_medical_records_popup_div">
                  <div className="add_medical_records_popuop_header">
                    <h5 className="add_medical_records_records_title">
                      Edit Price
                    </h5>
                    <i
                      onClick={() => {
                        seteditpricePop(false);
                        seteacheditprice("");
                      }}
                      class=" add_medical_records_popuop_colse_button fa-solid fa-xmark"
                    ></i>
                  </div>
                  <div className="add_medical_records_popup_body">
                    <div className="popup_body_div_two">
                      <div className="popup_body_name_patient_section row">
                        <div className="col-12 col-md-6">
                          <p className="popup_body_name_title">
                            Enter new price
                          </p>
                          <input
                            type="text"
                            name="description"
                            class=" popup_input_tags form-control"
                            placeholder="Enter Price"
                            value={eacheditprice}
                            onChange={(e) => {
                              seteacheditprice(e.target.value);
                            }}
                          ></input>
                        </div>
                      </div>

                      <div className=" mt-2">
                        <button
                          type="button"
                          className="popup_submit_button btn"
                          onClick={() => {
                            var Url =
                              window.API_PREFIX + "admin/changebookingprice";
                            setloaderon(true);
                            fetch(Url, {
                              method: "POST",
                              headers: {
                                "Content-type": "application/json",
                              },
                              body: JSON.stringify({
                                Token: localStorage.getItem("DToken"),
                                Amount: eacheditprice,
                                Id: selectedRecord?.BookingId,
                                PID: selectedRecord?.PatientId,
                                loginAs: localStorage.getItem("LoginDetails"),
                                receptionistId: localStorage.getItem("receptionistId"),
                                clinicId: localStorage.getItem("clinicId"),
                              }),
                            })
                              .then((resp) => resp.json())
                              .then((data) => {
                                console.log(data);
                                if (data["status"] === "Block") {
                                  toast.error(data["message"]);
                                  localStorage.clear();
                                  navigate("/Receptionist");
                                  window.location.reload();
                                }
                                else if (data["status"] === "1") {
                                  setloaderon(false);
                                  toast.success("Price Updated Successfully");
                                  setSelectedRecord("");
                                  seteditpricePop(false);
                                  seteacheditprice("");
                                  upcoming_booking("Change");
                                  today_booking();
                                }
                              });
                          }}
                        >
                          Confirm
                          <i class="fa-regular fa-circle-check ms-2"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Popup>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Receptionist_dashboard;
