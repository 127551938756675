import patientdashboardimg from '../../media/image.jpg';
import { useNavigate } from 'react-router-dom';
import './MitwankSidebar.css';
import '../Mitwank/Sidebar.css'
import '../../App.css';
import { useEffect, useState } from 'react';
import { useStateValue } from '../StateProviders';
export default function DoctorSidebar() {

  const [{ Loading }, dispatch] = useStateValue();

  let navigate = useNavigate();
  const [DocData, setDocData] = useState({});
  useEffect(() => {

    var Url = window.API_PREFIX + 'admin/doctordetail'
    fetch(Url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(
        {
          Token: localStorage.getItem('DToken'),


        }
      ),
    }).then(resp => resp.json()).then(data => {
      console.log(data)
      if (data['status'] === "1") {

        setDocData({ ...data['data'] })

      }
    })



  }, [])


  return (
    <div className='sidebar_main_division'>

      <div className={window.location.pathname.includes('/mitwank/dashboard') ? 'sidebar_dashboard_title_division_active' : "sidebar_dashboard_title_division"}
        onClick={() => {

          navigate('/mitwank/dashboard')
        }}
      ><i class="fa-solid fa-shapes me-2"></i> Dashboard</div>

      <div className={window.location.pathname.includes('/mitwank/mitwankappointmentbydate') ? 'sidebar_dashboard_title_division_active' : "sidebar_dashboard_title_division"}
        onClick={() => {

          navigate('/mitwank/mitwankappointmentbydate')
        }}
      ><i class="fa-solid fa-calendar-days me-2"></i> Appointment By Date</div>

      <div className={window.location.pathname.includes('/mitwank/my-clients') ? 'sidebar_dashboard_title_division_active' : 'sidebar_dashboard_title_division'}
        onClick={() => {

          navigate('/mitwank/my-clients')

        }}
      ><i class="fa-solid fa-user me-2"></i> My Clients</div>
      <div className={window.location.pathname.includes('/mitwank/schedule-timing') ? 'sidebar_dashboard_title_division_active' : 'sidebar_dashboard_title_division'}
        onClick={() => {
          navigate('/mitwank/schedule-timing')

        }}
      ><i class="fa-solid fa-calendar-day me-2"></i>Schedule Timing</div>

      <div className={window.location.pathname.includes('/mitwank/myclinic') ? 'sidebar_dashboard_title_division_active' : 'sidebar_dashboard_title_division'}
        onClick={() => {
          navigate('/mitwank/myclinic')

        }}
      ><i class="fa-solid fa-house-chimney-medical me-2"></i>My Clinics </div>
      <div className={window.location.pathname.includes('/mitwank/add-package') ? 'sidebar_dashboard_title_division_active' : 'sidebar_dashboard_title_division'}
        onClick={() => {
          navigate('/mitwank/add-package')

        }}
      ><i class="fa-solid fa-plus me-2"></i>Add Package </div>
      <div className={window.location.pathname.includes('/mitwank/mitwankchangepassword') ? 'sidebar_dashboard_title_division_active' : 'sidebar_dashboard_title_division'}
        onClick={() => {
          navigate('/mitwank/mitwankchangepassword')

        }}
      ><i class="fa-solid fa-key me-2"></i> Change Password </div>
      <div className={window.location.pathname.includes('/mitwank/mitwanktrasactions') ? 'sidebar_dashboard_title_division_active' : 'sidebar_dashboard_title_division'}
        onClick={() => {
          navigate('/mitwank/mitwanktrasactions')

        }}
      ><i class="fa-solid fa-indian-rupee-sign me-2"></i> Transactions </div>
      <div className={window.location.pathname.includes('/mitwank/mitwankoffers') ? 'sidebar_dashboard_title_division_active' : 'sidebar_dashboard_title_division'}
        onClick={() => {
          navigate('/mitwank/mitwankoffers')

        }}
      ><i class="fa-solid fa-percent me-2"></i> Offers </div>
      <div className={window.location.pathname.includes('/mitwank/mitwankevents') ? 'sidebar_dashboard_title_division_active' : 'sidebar_dashboard_title_division'}
        onClick={() => {
          navigate('/mitwank/mitwankevents')

        }}
      ><i class="fa-solid fa-calendar-days me-2"></i>Events </div>
      <div className={window.location.pathname.includes('/mitwank/clientmessages') ? 'sidebar_dashboard_title_division_active' : 'sidebar_dashboard_title_division'}
        onClick={() => {
          navigate('/mitwank/clientmessages')

        }}
      ><i class="fa-solid fa-message me-2"></i> Messages</div>

      <div className={window.location.pathname.includes('/mitwank/newsletter') ? 'sidebar_dashboard_title_division_active' : 'sidebar_dashboard_title_division'}
        onClick={() => {
          navigate('/mitwank/newsletter')

        }}
      ><i class="fa-solid fa-envelope me-2"></i> Newsletter</div>

      <div className={window.location.pathname.includes('/mitwank/trash') ? 'sidebar_dashboard_title_division_active' : 'sidebar_dashboard_title_division'}
        onClick={() => {
          navigate('/mitwank/trash')

        }}
      ><i class="fa-solid fa-trash me-2"></i> Trash</div>

      <div className='sidebar_dashboard_title_division'
        onClick={() => {
          localStorage.clear()
          dispatch({ type: 'DLogout' })
          navigate('/mitwank')

        }}
      ><i class="fa-solid fa-right-from-bracket me-2"></i> Logout </div>

    </div>
  )
}