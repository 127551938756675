export const initialState = {

    IsLogin: false,
    DIsLogin: false,
    CIsLogin: false,
    RIsLogin: false,
    IsRegister: false,
    Loading: false,
    NewNotification: false,

};

const reducer = (state, action) => {

    switch (action.type) {
        case 'Login':
            return {
                ...state, IsLogin: true,
            };
        case 'Logout':
            return {
                ...state, IsLogin: false,
            };
        case 'Register':
            return {
                ...state, IsRegister: true,
            };
        case 'NotRegister':
            return {
                ...state, IsRegister: false,
            };
        case 'DLogin':
            return {
                ...state, DIsLogin: true,
            };
        case 'DLogout':
            return {
                ...state, DIsLogin: false,
            };
        case 'CLogin':
            return {
                ...state, CIsLogin: true,
            };
        case 'CLogout':
            return {
                ...state, CIsLogin: false,
            };
        case 'RLogin':
            return {
                ...state, RIsLogin: true,
            };
        case 'RLogout':
            return {
                ...state, RIsLogin: false,
            };
        case 'StartLoad':
            return {
                ...state, Loading: true,
            };
        case 'StopLoad':
            return {
                ...state, Loading: false,
            };
        case 'NewNotification':
            return {
                ...state, NewNotification: true,
            };
        default: return state;
    }
}
export default reducer;