import { useEffect, useState } from "react";
import React from "react";
import image from "../../media/event6.jpg";
import { Table } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { Popover } from "antd";
import toast from "react-hot-toast";

function Registeruser() {
  const location = useLocation();
  console.log("location: ", location.state);
  const eventDetails = location.state?.eventDetails;
  console.log("eventDetails1234: ", eventDetails);
  const [activePopoverIndex, setActivePopoverIndex] = useState(null);
  const [eventList, setEventList] = useState([]);
  const [timers, setTimers] = useState({});
  const eventDays = location?.state?.days;
  const eventHours = location?.state?.eventHours;
  const minutes = location?.state?.minutes;
  const seconds = location?.state?.seconds;
  const [eventAttandee, setEventAttandeeList] = useState([]);
  console.log("eventAttandee: ", eventAttandee);

  useEffect(() => {
    const Url = window.API_PREFIX + "admin/eventattandeesbyevent";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        eventId: eventDetails?.eventId,
        ClinicId: eventDetails?.ClinicDetails?.ClinicId,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setEventAttandeeList([...data["eventAttandees"]]);
        }
      });
  }, []);

  //   useEffect(() => {
  //     const timerIntervals = {};

  //     eventList.forEach((event, index) => {
  //       const updateTimer = () => {
  //         const targetDate = new Date(`${eventDetails.Date} ${eventDetails.time}`).getTime();
  //         const currentTime = new Date().getTime();
  //         const difference = targetDate - currentTime;

  //         if (difference > 0) {
  //           setTimers((prev) => ({
  //             ...prev,
  //             [index]: {
  //               days: Math.floor(difference / (1000 * 60 * 60 * 24)),
  //               hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
  //               minutes: Math.floor((difference / 1000 / 60) % 60),
  //               seconds: Math.floor((difference / 1000) % 60),
  //             },
  //           }));
  //         } else {
  //           clearInterval(timerIntervals[index]);
  //           setTimers((prev) => ({
  //             ...prev,
  //             [index]: "Time is over",
  //           }));
  //         }
  //       };
  //       timerIntervals[index] = setInterval(updateTimer, 1000);
  //       updateTimer();
  //     });

  //     return () => {
  //       Object.values(timerIntervals).forEach(clearInterval);
  //     };
  //   }, [eventList]);

  const handlepopOpenChange = (index, open) => {
    if (open) {
      setActivePopoverIndex(index);
    } else {
      setActivePopoverIndex(null);
    }
  };
  const pophide = () => {
    setActivePopoverIndex(null);
  };

  const eventDate = new Date("2024-09-25T00:00:00"); // Set the event date

  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = eventDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  let navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Cleanup the interval on unmount
  }, []);

  const handlePayClick = async (item) => {
    console.log("itempay: ", item);
    var Url = window.API_PREFIX + "admin/eventCashPayment";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        eventId: item.eventDetails.eventId,
        clinicId: item.clinicDetails.ClinicId,
        Patient: item.patientDetails.patientId,
        amount: item.eventDetails.eventFees,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          toast.success("Event Payment Successfully");
          window.location.reload();
        }
      });
  };

  //   const dataSource = [
  //     {
  //       key: "1",
  //       number: "1",
  //       name: "Mike",
  //       address: "paid unpaid",
  //     },
  //     {
  //       key: "2",
  //       number: "1",
  //       name: "John",
  //       address: "paid unpaid",
  //     },
  //   ];

  const columns = [
    {
      title: "Sr No",
      key: "id",
      render: (text, record, index) => <div>{index + 1}</div>,
    },
    {
      title: "Name",
      key: "name",
      render: (text, record, index) => (
        <div>{record?.patientDetails?.patientName}</div>
      ),
    },
    {
      title: "Mobile",
      key: "mobile",
      render: (text, record, index) => (
        <div>{record?.patientDetails?.patientMobile}</div>
      ),
    },
    {
      title: "Email",
      key: "email",
      render: (text, record, index) => (
        <div>{record?.patientDetails?.patientEmail}</div>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (text, record, index) => (
        <>
          {record?.paymentStatus === "Pending" ? (
            <Popover
              content={
                <div className="d-flex justify-content-between">
                  <a onClick={pophide}>No</a>
                  <a onClick={() => handlePayClick(record)}>Yes</a>
                </div>
              }
              title="Are you sure?"
              trigger="click"
              open={activePopoverIndex === index}
              onOpenChange={(open) => handlepopOpenChange(index, open)}
            >
              <button className="btn admin_dashboard_tabel_view_btn">
                {record?.paymentStatus}
              </button>
            </Popover>
          ) : (
            <button className="btn admin_dashboard_tabel_view_btn">
              {record?.paymentStatus}
            </button>
          )}
        </>
      ),
    },
  ];
  return (
    <div className="container-fuild mx-2 mt-2">
      <div
        onClick={() => {
          navigate("/mitwank/mitwankevents");
        }}
      >
        <button className="btn client_back_button">
          <i class="fa-solid fa-arrow-left back_icon"></i>Back
        </button>
      </div>
      <div className="mitwank_newsletter_main_diviison">
        <div className="col d-flex justify-content-end align-items-center">
          <button type="button" class="btn export_button mb-3 me-4">
            Export
          </button>
        </div>
      </div>

      <div
        style={{
          border: "1px solid #ccc",
          borderRadius: "8px",
          padding: "16px",
          maxWidth: "600px",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <div style={{ width: "30%" }}>
            <img
              src={eventDetails?.ClinicDetails?.clinicProfilePic}
              alt="Event"
              style={{
                width: "100%",
                height: "160px",
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
          </div>
          <div style={{ width: "100%", paddingLeft: "16px" }}>
            <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>
              {eventDetails?.title}
            </h2>
            <p style={{ color: "#666", margin: "4px 0" }}>
              {eventDetails?.ClinicDetails?.ClinicName}
            </p>
            <p style={{ color: "#666", margin: "4px 0" }}>
              {eventDetails?.ClinicDetails?.ClinicAddress}
            </p>
            <p style={{ color: "#666", margin: "4px 0" }}>
              {eventDetails?.ClinicDetails?.ClinicContact}
            </p>
            <div
              style={{ display: "flex", alignItems: "center", color: "#888" }}
            >
              <i class="fa-regular fa-clock"></i>
              <span style={{ marginLeft: "8px" }}>{eventDetails?.Date}</span>
            </div>
            {/* <div
              style={{
                marginTop: "16px",
                display: "flex",
                justifyContent: "space-between",
                color: "#333",
              }}
            >
              <div>
                <span style={{ fontWeight: "600", fontSize: "18px" }}>
                  {eventDays}
                </span>{" "}
                Days
              </div>
              <div>
                <span style={{ fontWeight: "600", fontSize: "18px" }}>
                  {eventHours}
                </span>{" "}
                Hours
              </div>
              <div>
                <span style={{ fontWeight: "600", fontSize: "18px" }}>
                  {minutes}
                </span>{" "}
                Minutes
              </div>
              <div>
                <span style={{ fontWeight: "600", fontSize: "18px" }}>
                  {seconds}
                </span>{" "}
                Seconds
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="mitwank_newsletter_table_main_division mt-3">
        <div className="mitwank_newsletter_table_division">
          <Table dataSource={eventAttandee} columns={columns} />
        </div>
      </div>
    </div>
  );
}

export default Registeruser;
