import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { useEffect,useState } from 'react';
import { useStateValue } from './StateProviders';
import { useNavigate } from 'react-router-dom';
import ClientLogin from './Client/ClientLogin'
import ClientDetailsRegister from './Client/ClientDetailsRegister';
import Homepage from './Homepage/Homepage';
import Topbar from './Topbar';
import './Headertwo.css';
import Breadcrumb from './Breadcrumb';
import Clientsidebar from '../Component/Client/ClientSidebar'
import Downloadapp from './Downloadapp';


export default function HomeLayout() {
  let navigate = useNavigate()
  const [{ IsLogin, IsRegister }, dispatch] = useStateValue();
  useEffect(() => {

    console.log(IsRegister)
    if (localStorage.getItem('DToken') !== null && localStorage.getItem("LoginDetails") === 'User') {
      dispatch({ type: 'Login' });
    }
    else {
      dispatch({ type: 'Logout' });
    }


  }, [])



  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);
  // Update state on window resize
  useEffect(() => {
    const handleResize = () => {
      const mobileView = window.innerWidth < 576;
      setIsMobile(mobileView);

      // Force redirect to download-app if screen is less than 576px
      if (mobileView) {
        navigate('/download-app');
      }
    };

    window.addEventListener('resize', handleResize);

    // Trigger navigation on component mount if needed
    if (window.innerWidth < 576) {
      navigate('/download-app');
    }

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [navigate]);



  return (<>
    {
      isMobile ? <Downloadapp /> :
        <>

          <Topbar />
          <Header />

          {
            window.location.pathname === '/' ||
              window.location.pathname === '/contact' ||
              window.location.pathname === '/aboutus'


              ?
              <Outlet />
              :
              !IsLogin ?
                <ClientLogin /> :
                !IsRegister ?
                  <ClientDetailsRegister /> :
                  <>
                    <Breadcrumb />
                    <div className='slidebar_and_component'>
                      <Clientsidebar />
                      <div className='outlet_main_div'>
                        <Outlet />
                      </div>
                    </div>
                  </>


          }

          <Footer />
        </>
    }
  </>
  )
} 