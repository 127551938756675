import React from "react";
import toast from "react-hot-toast";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import image from "../../media/event6.jpg";
import { useState, useEffect, useRef } from "react";
function Receptionistevents() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loaderon, setloaderon] = useState(false);
  const [PrescRecord, setPrescRecord] = useState();
  const [isRequiredPrescRecord, setIsRequiredPrescRecord] = useState(true);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [addprescribtion, setaddprescribtion] = useState(false);
  const [branches, setBranches] = useState([]);
  const [eventTime, setEventTime] = useState("");
  const [eventplace, seteventPlace] = useState("");
  const [eventtitle, setevntTitle] = useState("");
  const [FilterDate, setFilterDate] = useState([]);
  const [eventFees, seteventFees] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventList, setEventList] = useState([]);
  console.log("eventList: ", eventList);
  const [searchQuery, setSearchQuery] = useState("");
  const [timers, setTimers] = useState({});
  let navigate = useNavigate();
  console.log('timers: ', timers);
  const [frameworkData, setFrameworkData] = useState({
    bookingId: "",
    patientId: "",
    clinicId: "",
  });

  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});






  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSelectChange = (event) => {
    setSelectedBranch(event.target.value);
  };

  const addprescribtionpopup = {
    borderRadius: "10px",
    backgroundColor: "white",
    // boxshadow: "1 1 20px rgba(255, 255, 255, 0.4)",
    padding: "0px",
    width: "100%",
    height: "max-content",
    margin: "60px 450px",
  };

  useEffect(() => {
    const Url = window.API_PREFIX + "admin/allclinics";
    fetch(Url, {
      method: "GET",
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setBranches([...data["allClinicList"]]);
        }
      });
  }, []);

  useEffect(() => {
    const clinic_id = localStorage.getItem("clinicId");
    const loginAs = localStorage.getItem("LoginDetails");
    const queryParams = [];

    if (clinic_id) queryParams.push(`clinicId=${clinic_id}`);
    if (loginAs) queryParams.push(`loginAs=${loginAs}`);

    const Url = window.API_PREFIX + "admin/allevents" + 
        (queryParams.length > 0 ? `?${queryParams.join("&")}` : "");

    fetch(Url, {
      method: "GET",
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setEventList([...data["Events"]]);
        }
      });
  }, []);

  useEffect(() => {
    const timerIntervals = {};

    eventList.forEach((event, index) => {
      const updateTimer = () => {
        const targetDate = new Date(`${event.Date} ${event.time}`).getTime();
        const currentTime = new Date().getTime();
        const difference = targetDate - currentTime;

        if (difference > 0) {
          setTimers((prev) => ({
            ...prev,
            [index]: {
              days: Math.floor(difference / (1000 * 60 * 60 * 24)),
              hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
              minutes: Math.floor((difference / 1000 / 60) % 60),
              seconds: Math.floor((difference / 1000) % 60),
            },
          }));
        } else {
          clearInterval(timerIntervals[index]);
          setTimers((prev) => ({
            ...prev,
            [index]: 'Time is over',
          }));
        }
      };
      timerIntervals[index] = setInterval(updateTimer, 1000);
      updateTimer();
    });

    return () => {
      Object.values(timerIntervals).forEach(clearInterval);
    };
  }, [eventList]);

  function addEvent() {
    var Url = window.API_PREFIX + "admin/addevents";
    var uploadData = new FormData();
    uploadData.append("Token", localStorage.getItem("DToken"));
    uploadData.append("clinicId", selectedBranch);
    uploadData.append("title", eventtitle);
    uploadData.append("description", eventDescription);
    uploadData.append("time", eventTime);
    uploadData.append("fees", eventFees);
    uploadData.append("Attachment", PrescRecord);
    uploadData.append("Date", FilterDate);

    fetch(Url, {
      method: "POST",
      body: uploadData,
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          closeModal();
          // getPackages();
          toast.success("Added Successfully");
        }
      });
  }



  const [isReadMore, setIsReadMore] = useState({ id: "", open: false });
  // console.log("isReadMore: ", isReadMore);




  const modalRef = useRef(null);
  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setIsModalOpen1(null);
    }
  };

  useEffect(() => {
    if (isModalOpen1 !== null) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isModalOpen1]);



  return (
    <div className="container-fuild mx-2 mt-2">
      {/* <div class=" mt-2 d-flex align-items-center justify-content-end">
        <div className="search-field ">
          <input
            type="text"
            className="form-control"
            placeholder="Search Clients"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <span className="search-icon">
            <i className="fa-solid fa-magnifying-glass"></i>
          </span>
        </div>
        <button
          class="mitwank_patient_profile_add_prescription_btn ms-3"
          onClick={() => {
            setaddprescribtion(true);
          }}
        >
          + Add Events
        </button>



        <Popup
          contentStyle={addprescribtionpopup}
          modal
          open={addprescribtion}
          closeOnDocumentClick={false}
          onClose={() => {
            setaddprescribtion(false);
            setFrameworkData({
              bookingId: "",
              patientId: "",
              clinicId: "",
            });
          }}
        >
          <div className="Add_medical_records_popup_div">
            <div className="add_medical_records_popuop_header">
              <h5 className="add_medical_records_records_title">Add Events</h5>
              <i
                onClick={() => {
                  setaddprescribtion(false);
                  setFrameworkData({
                    bookingId: "",
                    patientId: "",
                    clinicId: "",
                  });
                }}
                class=" add_medical_records_popuop_colse_button fa-solid fa-xmark"
              ></i>
            </div>
            <div className="add_medical_records_popup_body">
              <div className="popup_body_div_two">
                <div className="popup_body_name_patient_section row">
                  <div className="col-12 col-md-6">
                    <p className="popup_body_name_title d-flex">
                      clinic
                      <div className="required_feild ms-2">(* required) </div>
                    </p>

                    <select
                      className="form-select mb-3"
                      aria-label="Select Clinic"
                      value={selectedBranch}
                      onChange={handleSelectChange}
                    >
                      <option value="" disabled>
                        Select Clinic
                      </option>

                      {branches.map((branch) => (
                        <option key={branch.id} value={branch.id}>
                          {branch.clinicName}
                        </option>
                      ))}
                    </select>

                  </div>
                  <div className="col-12 col-md-6">
                    <p className="popup_body_name_title d-flex">
                      Title Name
                      <div className="required_feild ms-2">(* required) </div>
                    </p>
                    <input
                      type="text"
                      name="description"
                      class=" popup_input_tags form-control"
                      placeholder="Enter Title Name"
                      value={eventtitle}
                      onChange={(e) => {
                        setevntTitle(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                    <p className="popup_body_name_title d-flex">
                      Enter Event Date
                      <div className="required_feild ms-2">(* required) </div>
                    </p>
                    <input
                      label="dob"
                      type="date"
                      class="popup_input_tags form-control"
                      id="inputdateofbirth"
                      onChange={(e) => {
                        const date = new Date(e.target.value);
                        const formattedDate = date.toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        });
                        console.log(formattedDate);
                        setFilterDate(formattedDate);
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                    <p className="popup_body_name_title d-flex">
                      Event Fees
                      <div className="required_feild ms-2">(* required) </div>
                    </p>
                    <input
                      label="text"
                      type="text"
                      class="popup_input_tags form-control"
                      id="inputdateofbirth"
                      value={eventFees}
                      onChange={(e) => {
                        seteventFees(e.target.value);
                      }}
                    />
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <p className="popup_body_name_title d-flex">
                      Time
                      <div className="required_feild ms-2">(* required) </div>
                    </p>
                    <input
                      type="time"
                      name="time"
                      class=" popup_input_tags form-control"
                      placeholder="Enter time"
                      value={eventTime}
                      onChange={(e) => {
                        setEventTime(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                    <p className="popup_body_name_title d-flex">
                      Description
                      <div className="required_feild ms-2">(* required) </div>
                    </p>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="2"
                      value={eventDescription}
                      onChange={(e) => {
                        setEventDescription(e.target.value);
                      }}
                    />


                  </div>

                  <div className="mt-2 w-50">
                    <p className="popup_body_upload_title d-flex">
                      Upload
                      <div className="required_feild ms-2">(* required) </div>
                    </p>
                    <div class="  input-group">
                      <input
                        class=" form-control"
                        id="inputGroupFile02"
                        style={{ cursor: "pointer" }}
                        type="file"

                        onChange={(event) => {
                          const file = event.target.files[0];

                          if (file) {
                            if (file.size < 20 * 1024 * 1024) {
                              setIsRequiredPrescRecord(false);
                              setPrescRecord(file);
                            } else {
                              toast.error("File size must be less than 20MB");
                              event.target.value = null;
                              setIsRequiredPrescRecord(true);
                            }
                          } else {
                            setIsRequiredPrescRecord(true);
                          }
                        }}
                      />
                      <label class="input-group-text" for="inputGroupFile02">
                        Upload
                      </label>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-center mt-3">
                  {loaderon ? (
                    <ClipLoader />
                  ) : (
                    <button
                      type="button"
                      className="popup_submit_button btn"
                      onClick={() => {
                        addEvent();
                        setaddprescribtion(false);
                      }}
                      disabled={
                        eventtitle === "" ||
                        eventTime === "" ||
                        FilterDate === "" ||
                        eventFees === "" ||
                        eventDescription === ""
                      }
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Popup>
      </div> */}

      <div class="text-end mt-4 d-flex gap-2">
        <div className="row mt-4 container ">

          {eventList?.map((item, i) => (
            <div className="col col-12 col-sm-6 col-md-6 col-lg-4 gx-2 gy-2 ">
              <div
                style={{
                  filter: timers[i] === 'Time is over' ? "grayscale(100%)" : "grayscale(0%)",
                  borderRadius: "10px",
                  overflow: "hidden",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                }}
                key={i}
              >
                <div
                  style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    height: "200px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      left: "10px",
                      display: "flex",
                      gap: "5px",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: "#4361EE",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        fontSize: "12px",
                      }}
                    >
                      EVENT
                    </span>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      left: "10px",
                      backgroundColor: "rgba(0,0,0,0.7)",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      fontSize: "14px",
                    }}
                  >
                    ₹ {item.fees}
                  </div>
                </div>
                <div style={{ padding: "20px", color: "white" }}>
                  <div className="d-flex justify-content-between align-item-center">
                    <h3
                      style={{
                        margin: "0 0 10px 0",
                        fontSize: "18px",
                        textAlign: "start",
                      }}
                    >
                      {item.title}
                    </h3>
                    <h3
                      style={{
                        margin: "0 0 10px 0",
                        fontSize: "18px",
                        textDecoration: "underline",
                        cursor: "pointer",
                        textAlign: 'start'
                      }}
                      onClick={() => {
                        setIsModalOpen1(i);
                        setSelectedRow(item)
                      }}
                    >
                      {item?.ClinicDetails?.ClinicName}
                    </h3>
                  </div>
                  {isModalOpen1 === i && (
                    <div
                      style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        ref={modalRef}
                        style={{
                          backgroundColor: "#fff",
                          padding: "20px",
                          borderRadius: "10px",
                          width: "300px",
                          textAlign: "center",
                          position: "relative",
                        }}
                      >
                        <div
                          className="d-flex align-items-center justify-content-center me-2 "
                          style={{ color: "black" }}
                        >
                          <img
                            className="image_div"
                            style={{ justifyItems: "center" }}
                            src={selectedRow?.ClinicDetails?.clinicProfilePic}
                          ></img>
                        </div>
                        <div
                          className="new_card_client_name"
                          style={{ color: "black", fontSize: "20px" }}
                        >
                          {selectedRow?.ClinicDetails?.ClinicName}
                        </div>
                        <div className="other_info mb-2" style={{ color: "black" }}>
                          <div>
                            <i className="fa-solid fa-phone me-2"></i>
                            {selectedRow?.ClinicDetails?.ClinicContact}
                          </div>
                          <div>
                            <i className="fa-solid fa-location-dot me-2"></i>
                            {selectedRow?.ClinicDetails?.ClinicAddress}
                          </div>
                          <div>
                            <i class="fa-solid fa-at me-2"></i>
                            {selectedRow?.ClinicDetails?.ClinicEmail}
                          </div>
                        </div>
                        {/* Close button inside modal */}
                        <button
                          onClick={() => setIsModalOpen1(null)}
                          style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            background: "none",
                            color: "black",
                            border: "none",
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                        >
                          &times;
                        </button>
                      </div>
                    </div>
                  )}
                  <p
                    style={{
                      margin: "0 0 20px 0",
                      fontSize: "14px",
                      color: "#A0A0A0",
                      textAlign: "start",
                    }}
                  >
                    {item.Date}
                  </p>
                  <div
                    style={{
                      margin: "0 0 10px 0",
                      fontSize: "14px",
                      color: "black",
                      textAlign: "start",
                      whiteSpace: isReadMore.open && isReadMore.id === i ? "normal" : "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {isReadMore.open && isReadMore.id === i
                      ? item?.description
                      : item?.description}
                  </div>
                  <div
                    onClick={() => {
                      setIsReadMore({
                        id: i,
                        open: i === isReadMore.id ? !isReadMore.open : true,
                      });
                    }}
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      textDecoration: "underline",
                      textAlign: 'start',
                      margin: "0 0 10px 0"
                    }}
                  >
                    {isReadMore.open && isReadMore.id === i ? "Show Less" : "Read More"}
                  </div>
                  {/* <p
                        style={{
                          margin: "0 0 20px 0",
                          fontSize: "14px",
                          color: "black",
                          textAlign: "start",
                        }}
                      >
                        <div>
                          {isReadMore.open && isReadMore.id === i
                            ? item?.description
                            : `${item?.description.slice(0, 100)}...`}
                        </div>
                        <div
                          onClick={() => {
                            setIsReadMore({
                              id: i,
                              open:
                                i === isReadMore.id
                                  ? !isReadMore.open
                                  : true,
                            });
                          }}
                          style={{
                            cursor: "pointer",
                            color: "blue",
                            textDecoration: "underline",
                          }}
                        >
                          {isReadMore.open && isReadMore.id === i
                            ? "Show Less"
                            : "Read More"}
                        </div>
                      </p> */}
                  <div style={{ textAlign: "start" }}>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          textAlign: "center",
                          gap: "3px",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ fontSize: "18px", color: "#333" }}>
                          {timers[i]?.days || 0}
                        </div>
                        <span style={{ fontSize: "14px", color: "#555" }}>
                          Days
                        </span>
                      </div>
                      <span
                        style={{
                          fontSize: "18px",
                          color: "#333",
                          margin: "0 3px",
                          fontWeight: "bold",
                        }}
                      >
                        :
                      </span>
                      <div
                        style={{
                          textAlign: "center",
                          gap: "3px",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ fontSize: "18px", color: "#333", margin: 0 }}>
                          {timers[i]?.hours || 0}
                        </div>
                        <span style={{ fontSize: "14px", color: "#555" }}>
                          Hours
                        </span>
                      </div>
                      <span
                        style={{
                          fontSize: "18px",
                          color: "#333",
                          margin: "0 3px",
                          fontWeight: "bold",
                        }}
                      >
                        :
                      </span>
                      <div
                        style={{
                          textAlign: "center",
                          gap: "3px",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ fontSize: "18px", color: "#333", margin: 0 }}>
                          {timers[i]?.minutes || 0}
                        </div>
                        <span style={{ fontSize: "14px", color: "#555" }}>
                          Minutes
                        </span>
                      </div>
                      <span
                        style={{
                          fontSize: "18px",
                          color: "#333",
                          margin: "0 3px",
                          fontWeight: "bold",
                        }}
                      >
                        :
                      </span>
                      <div
                        style={{
                          textAlign: "center",
                          gap: "3px",
                          alignItems: "center",
                        }}
                      >
                        <p style={{ fontSize: "18px", color: "#333", margin: 0 }}>
                          {timers[i]?.seconds || 0}
                        </p>
                        <span style={{ fontSize: "14px", color: "#555" }}>
                          Seconds
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <button
                        className="mt-2"
                        onClick={() => {
                          navigate(
                            "/mitwank/eventattandes")
                        }}
                        style={{
                          backgroundColor: "transparent",
                          border: "2px solid #4361EE",
                          color: "#4361EE",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          cursor: "pointer",
                          fontSize: "14px",
                        }}
                      >
                        List of registered user
                      </button>
                      <div style={{
                        color: "#4361EE",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}>
                        <div className="text-center" style={{ fontSize: '18px' }}>
                          5
                        </div>
                        Registered users
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

        </div>
      </div>
    </div>
  );
}

export default Receptionistevents;
