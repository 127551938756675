import 'antd/dist/antd.min.css';
import { useStateValue } from './Component/StateProviders';
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { Toaster } from 'react-hot-toast';

// client side import
import HomeLayout from './Component/HomeLayout';
import ClientLogin from './Component/Client/ClientLogin';
import Client_booking from './Component/Client/Client_Booking';
import ClientDashboard from './Component/Client/ClientDashboard';
import Clientchangepassword from './Component/Client/Clientchangepassword';
import ClientProfilesettings from './Component/Client/ClientProfilesettings';
import ContactUs from './Component/ContactUs/ContactUs';
import AboutUs from './Component/AboutUs/AboutUs';
import Appointmentbookingsuccessfullypopup from './Component/Client/Appointmentbookingsuccessfullypopup';
import ClientDetailsRegister from './Component/Client/ClientDetailsRegister';
// import Reviewdetails from './Component/Client/Reviewdetails';
import NewclientRegister from './Component/Client/NewclientRegister';
import ClientForgotPassword from './Component/Client/ClientForgotPassword';
import Invoice from './Component/Invoice/Invoice';

// header footer import
import Footer from './Component/Footer';
import Header from './Component/Header';

// Mitwank side import
import Adminlayout from './Component/Mitwank/Adminlayout';
import Schedule_Timing from './Component/Mitwank/Schedule_Timings';
import Mitwank_Dashboard from './Component/Mitwank/Mitwank_Dashboard';
import MitwankClients from './Component/Mitwank/MitwankClients';
import MitwankclientProfile from './Component/Mitwank/MitwankclientProfile';
import MitwankChangePassword from './Component/Mitwank/MitwankChangePassword';
import Events from './Component/Mitwank/Events';
import Offers from './Component/Mitwank/Offers';
import Mitwankmessages from './Component/Mitwank/Mitwankmessages';
import AddPrescription from './Component/Mitwank/AddPrescription';
import Newsletter from './Component/Mitwank/Newsletter';
import MitwankSidebar from './Component/Mitwank/MitwankSidebar';
import MitwankAppointmentByDate from './Component/Mitwank/MitwankAppointmentByDate';
import Mitwankforgetpassword from './Component/Mitwank/Mitwankforgotpassword';
import Topbar from './Component/Topbar';
import DoctorAppointmentByDate from './Component/Mitwank/MitwankAppointmentByDate';
import Notfound from './Component/Notfound';
import Termsandconditions from './Component/Termsandconditions';
import Privacypolicy from './Component/Privacypolicy';
import Shippingpolicy from './Component/Shippingpolicy';
import Transaction from './Component/Mitwank/Transaction';
import Returnrefund from './Component/Returnrefund';
import Mitwanktrash from './Component/Mitwank/Mitwanktrash';


// Clinic side import
import Cliniclayout from './Component/Clinic/Cliniclayout';
import Clinic_dashboard from './Component/Clinic/Clinic_dashboard';
import ClinicAppointmentByDate from './Component/Clinic/ClinicAppointmentByDate';
import Client_Schedule_Timings from './Component/Clinic/Client_Schedule_Timings';
import ClinicChangePassword from './Component/Clinic/ClinicChangePassword';
import Clinictrash from './Component/Clinic/Clinictrash';
import Clinictransaction from './Component/Clinic/clinicTransaction';
import ClinicSidebar from './Component/Clinic/ClinicSidebar';
import Clinicforgetpassword from './Component/Clinic/Clinicforgetpassword';
import Clinic_add_receptionist from './Component/Clinic/Clinic_add_receptionist';
import ClinicClients from './Component/Clinic/ClinicClient';
import Clinicclientprofile from './Component/Clinic/Clinicclientprofile';





// Receptionist side import
import Receptionist_dashboard from './Component/Receptionist/Receptionist_dashboard';
import ReceptionistAppointmentByDate from './Component/Receptionist/ReceptionistAppointmentByDate';
import Receptionist_Schedule_Timings from './Component/Receptionist/Receptionist_Schedule_Timings';
import ReceptionistChangePassword from './Component/Receptionist/ReceptionistChangePassword';
import ReceptionistSidebar from './Component/Receptionist/ReceptionistSidebar';
import Receptionistforgetpassword from './Component/Receptionist/Receptionistforgetpassword';
import Receptionistlayout from './Component/Receptionist/Receptionistlayout';
import Myclinic from './Component/Mitwank/Myclinic';
import Clinicsprofile from './Component/Mitwank/Clinicsprofile';
import Addpackage from './Component/Mitwank/Addpackage';
import ClinicProfilesettings from './Component/Clinic/ClinicProfilesettings';
import Receptionistprofilesetting from './Component/Receptionist/Receptionistprofilesetting';
import Receptionistevents from './Component/Receptionist/Receptionistvents';
import Receptionistoffers from './Component/Receptionist/Receptionistoffers';
import Clinicoffers from './Component/Clinic/Clinicoffers';
import Clinicevents from './Component/Clinic/Clinicevents';
import Registeruser from './Component/Mitwank/Registeruser';
import Downloadapp from './Component/Downloadapp';





// window.API_PREFIX = 'https://apibooking.kodenauts.com/'
window.API_PREFIX = 'https://api.shhydrafacial.in/'

window.WEBSOC = "wss://" + String(window.API_PREFIX).split("//")[1]

// window.API_PREFIX = 'http://localhost:8000/'

function App() {


  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   setLoading(true)
  //   setTimeout(() => {
  //     setLoading(false)
  //   }, 1000)
  // }, [])


  let navigate = useNavigate()
  const [{ IsRegister, Loading }, dispatch] = useStateValue();

  return (
    <>

      <Routes path="">


        {/* user route start */}

        <Route path="" element={<HomeLayout />} >
          <Route path="login" element={<ClientLogin />} />
          <Route path="booking" element={<Client_booking />} />
          <Route path="dashboard" element={<ClientDashboard />} />
          <Route path="change-password" element={<Clientchangepassword />} />
          <Route path='profile-setting' element={<ClientProfilesettings />} />
          {/* <Route path='reviewdetails' element={<Reviewdetails />} /> */}
          <Route path='client-detail-register' element={<ClientDetailsRegister />} />
          <Route path='contact' element={<ContactUs />} />
          <Route path='about' element={<AboutUs />} />
          <Route path='' element={<ClientLogin />} />
          <Route path='bookingsuccessfully' element={<Appointmentbookingsuccessfullypopup />} />
        </Route>
        <Route path="/forget-password" element={<><Header /><ClientForgotPassword /><Footer /></>} />
        <Route path="/client-detail-register" element={<><Header />{!IsRegister ? <ClientDetailsRegister /> : null}<Footer /></>} />
        <Route path="/register" element={<><Header /><NewclientRegister /><Footer /></>} />
        <Route path="invoice" element={<Invoice />} />
        <Route path="download-app" element={<Downloadapp />} />

        {/* user route end */}



        {/* admin route start */}

        <Route path="/mitwank" element={<Adminlayout />} >
          <Route path="schedule-timing" element={<Schedule_Timing />} />
          <Route path="myclinic" element={<Myclinic />} />
          <Route path="clinicprofile/:PID" element={<Clinicsprofile />} />
          <Route path="dashboard" element={<Mitwank_Dashboard />} />
          <Route path="trash" element={<Mitwanktrash />} />
          <Route path="my-clients" element={< MitwankClients />} />
          <Route path="clientprofile/:PID" element={<MitwankclientProfile />} />
          <Route path="mitwankchangepassword" element={<MitwankChangePassword />} />
          <Route path='mitwanktrasactions' element={<Transaction />} />
          <Route path='mitwankoffers' element={<Offers />} />
          <Route path='mitwankevents' element={<Events/>} />
          <Route path='eventattandes' element={<Registeruser/>} />
          <Route path='add-package' element={<Addpackage />} />
          <Route path='clientmessages' element={<Mitwankmessages />} />
          <Route path="addprescription" element={<AddPrescription />} />
          <Route path="newsletter" element={<Newsletter />} />
          <Route path="doctorsidebar" element={<MitwankSidebar />} />
          <Route path="mitwankappointmentbydate" element={<DoctorAppointmentByDate />} />
        </Route>
        <Route path="/adminforgetpassword" element={<><Topbar /><Header /><Mitwankforgetpassword /><Footer /></>} />
        <Route path='/*' element={<Notfound />} />
        <Route path='terms-and-conditions' element={<><Header /><Termsandconditions /><Footer /></>} />
        <Route path='privacy-policy' element={<><Header /><Privacypolicy /><Footer /></>} />
        <Route path='return-and-refund-policy' element={<><Header /><Returnrefund /><Footer /></>} />
        <Route path='shipping-policy' element={<><Header /><Shippingpolicy /><Footer /></>} />



        {/* admin route end */}



        {/* clinic route start */}

        <Route path="/clinic" element={<Cliniclayout />} >
          <Route path="schedule-timing" element={<Client_Schedule_Timings />} />
          <Route path='clinic-add-receptionist' element={<Clinic_add_receptionist />} />
          <Route path='clinictransactions' element={<Clinictransaction />} />
          <Route path="dashboard" element={<Clinic_dashboard />} />
          <Route path="trash" element={<Clinictrash />} />
          <Route path='profile-setting' element={<ClinicProfilesettings />} />
          <Route path="clinicchangepassword" element={<ClinicChangePassword />} />
          <Route path='clinictransaction' element={<Clinictransaction />} />
          <Route path='clinicoffers' element={<Clinicoffers/>} />
          <Route path='clinicevents' element={<Clinicevents/>} />
          <Route path="doctorsidebar" element={<ClinicSidebar />} />
          <Route path="clinicappointmentbydate" element={<ClinicAppointmentByDate />} />
          <Route path="clinic-clients" element={< ClinicClients />} />
          <Route path="clientprofile/:PID" element={<Clinicclientprofile />} />
        </Route>
        <Route path="/clinicforgetpassword" element={<><Header /><Clinicforgetpassword /><Footer /></>} />

        {/* clinic route end*/}



        {/* receptionist route start */}

        <Route path="/receptionist" element={<Receptionistlayout />} >
          <Route path="schedule-timing" element={<Receptionist_Schedule_Timings />} />
          <Route path="dashboard" element={<Receptionist_dashboard />} />
          <Route path='receptionistprofilesettings' element={<Receptionistprofilesetting />} />
          <Route path="receptionistchangepassword" element={<ReceptionistChangePassword />} />
          <Route path='receptionistoffers' element={<Receptionistoffers />} />
          <Route path='receptionistevents' element={<Receptionistevents/>} />
          <Route path="doctorsidebar" element={<ReceptionistSidebar />} />
          <Route path="receptionistappointmentbydate" element={<ReceptionistAppointmentByDate />} />
        </Route>
        <Route path="/receptionistforgetpassword" element={<><Header /><Receptionistforgetpassword /><Footer /></>} />

        {/* receptionist route end */}


      </Routes>



      <Toaster />
    </>
  );
}

export default App;
