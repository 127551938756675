import React from "react";
import "../../App.css";
import "./Clientchangepassword.css";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import ClientSidebar from "./ClientSidebar";
export default function Clientchangepassword() {
  const [oldpassword, setoldpassword] = useState("");
  const [newpassword, setnewpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");

  const [Inputtype, setInputtype] = useState("password");
  const [Inputtypen, setInputtypen] = useState("password");
  const [Inputtypeco, setInputtypeco] = useState("password");
  const [ButtonName, setButtonName] = useState(<i class="fa-solid fa-eye"></i>);
  const [ButtonNamen, setButtonNamen] = useState(
    <i class="fa-solid fa-eye"></i>
  );
  const [ButtonNameco, setButtonNameco] = useState(
    <i class="fa-solid fa-eye"></i>
  );
  let navigate = useNavigate();
  const savechangesbutton = () => {
    if (newpassword === confirmpassword) {
      var Url = window.API_PREFIX + "patient/change_password";

      fetch(Url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          Token: localStorage.getItem("DToken"),
          Old_Password: oldpassword,
          New_Password: newpassword,
        }),
      })
        .then((resp) => resp.json())
        .then((data) => {
          console.log(data);
          if (data["status"] === "Block") {
            toast.error(data["message"]);
            localStorage.clear();
            navigate("/");
            window.location.reload();
          } else if (data["status"] === "1") {
            toast.success("Password Successfully Changed");
          } else {
            toast.error(data["message"]);
          }
        });
    } else {
      toast.error("Password Do Not Match");
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleClickShowPassword3 = () => setShowPassword3((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <main className="container-fluid mx-2">
        <div className="client_change_passwords_main_diviison">
          <div className="client_change_password_section">
            <FormControl
              sx={{ width: "100%" }}
              variant="outlined"
              className="mt-3"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Old Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                onChange={(e) => {
                  setoldpassword(e.target.value);
                }}
              />
            </FormControl>

            <FormControl
              sx={{ width: "100%" }}
              variant="outlined"
              className="mt-3"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                New Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword2 ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword2}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword2 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                onChange={(e) => {
                  setnewpassword(e.target.value);
                }}
              />
            </FormControl>

            <FormControl
              sx={{ width: "100%" }}
              variant="outlined"
              className="mt-3"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword3 ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword3}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword3 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                onChange={(e) => {
                  setconfirmpassword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    savechangesbutton();
                  }
                }}
              />
            </FormControl>

            <div className="client_change_password_section_save_button_section mt-3">
              <button
                type="button"
                class=" client_change_password_section_save_button btn"
                onClick={savechangesbutton}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </main>
      {/* Maintag-end */}
    </>
  );
}
