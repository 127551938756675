import React, { useState, useEffect, useRef } from "react";
import Popup from "reactjs-popup";
import image from "../../media/event6.jpg";
import toast from "react-hot-toast";
import { ClipLoader } from "react-spinners";
import TextField from "@mui/material/TextField";

function Offers() {
  const [addprescribtion1, setaddprescribtion1] = useState(false);
  const [PrescRecord, setPrescRecord] = useState();
  const [isRequiredPrescRecord, setIsRequiredPrescRecord] = useState(true);
  const [loaderon, setloaderon] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [eventtitle, setevntTitle] = useState("");
  const [FilterDate, setFilterDate] = useState([]);
  const [branches, setBranches] = useState([]);
  const [eventDescription, setEventDescription] = useState("");
  const [offerList, setOfferList] = useState([]);
  console.log('offerList: ', offerList);

  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});


  const handleSelectChange = (event) => {
    setSelectedBranch(event.target.value);
  };

  useEffect(() => {
    const Url = window.API_PREFIX + "admin/allclinics";
    fetch(Url, {
      method: "GET",
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setBranches([...data["allClinicList"]]);
        }
      });
  }, []);

  useEffect(() => {
    const Url = window.API_PREFIX + "admin/alloffers";
    fetch(Url, {
      method: "GET",
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setOfferList([...data["Offers"]]);
        }
      });
  }, []);

  const [isReadMore, setIsReadMore] = useState({ id: "", open: false });

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const addprescribtionpopup1 = {
    borderRadius: "10px",
    backgroundColor: "white",
    // boxshadow: "1 1 20px rgba(255, 255, 255, 0.4)",
    padding: "0px",
    width: "100%",
    height: "max-content",
    margin: "60px 450px",
  };

  const [frameworkData, setFrameworkData] = useState({
    bookingId: "",
    patientId: "",
    clinicId: "",
  });

  function addOffer() {
    var Url = window.API_PREFIX + "admin/addoffers";
    var uploadData = new FormData();
    uploadData.append("Token", localStorage.getItem("DToken"));
    uploadData.append("clinicId", selectedBranch);
    uploadData.append("title", eventtitle);
    uploadData.append("description", eventDescription);
    uploadData.append("Attachment", PrescRecord);
    uploadData.append("Date", FilterDate);

    fetch(Url, {
      method: "POST",
      body: uploadData,
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setaddprescribtion1(false);
          // getPackages();
          toast.success("Added Successfully");
        }
      });
  }


  const modalRef = useRef(null);
  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setIsModalOpen1(null);
    }
  };

  useEffect(() => {
    if (isModalOpen1 !== null) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isModalOpen1]);


  const [isEditing, setIsEditing] = useState(false);

  const closeUpdateModal = () => {
    setIsEditing(false);
  };


  const handleOutsideClick2 = (event) => {
    if (event.target.id === "myModal") {
      closeUpdateModal();
    }
  };

  function getOffers() {
    const Url = window.API_PREFIX + "admin/alloffers";
    fetch(Url, {
      method: "GET",
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setOfferList([...data["Offers"]]);
        }
      });
  }

  // Delete Offer API call
  const deleteOffer = async (id) => {
    var Url = window.API_PREFIX + "admin/deleteoffers";
    var uploadData = new FormData();
    uploadData.append("id", id);
    fetch(Url, {
      method: "POST",
      body: uploadData,
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          toast.success("Offer Deleted Successfully");
          getOffers();
        }
      });
  };

  return (
    <div className="container-fuild mx-2 mt-2">
      <div class="text-end mt-2">
        <div
          className="mitwank_patient_profile_add_prescription_division"
          onClick={() => {
            setaddprescribtion1(true);
          }}
        >
          <button className="mitwank_patient_profile_add_prescription_btn">
            + Add Offers
          </button>
        </div>

        <Popup
          contentStyle={addprescribtionpopup1}
          modal
          open={addprescribtion1}
          // closeOnDocumentClick={false}
          onClose={() => {
            setaddprescribtion1(false);
          }}
        >
          <div className="Add_medical_records_popup_div">
            <div className="add_medical_records_popuop_header">
              <h5 className="add_medical_records_records_title">Add Offers</h5>
              <i
                onClick={() => {
                  setaddprescribtion1(false);
                  setFrameworkData({
                    bookingId: "",
                    patientId: "",
                    clinicId: "",
                  });
                }}
                class=" add_medical_records_popuop_colse_button fa-solid fa-xmark"
              ></i>
            </div>
            <div className="add_medical_records_popup_body">
              <div className="popup_body_div_two">
                <div className="popup_body_name_patient_section row">
                  <div className="col-12 col-md-6 mt-2">
                    <p className="popup_body_name_title d-flex">
                      clinic
                      <div className="required_feild ms-2">(* required) </div>
                    </p>
                    <select
                      className="form-select mb-3"
                      aria-label="Select Clinic"
                      value={selectedBranch}
                      onChange={handleSelectChange}
                    >
                      <option value="" disabled>
                        Select Clinic
                      </option>
                      {/* <option value={"All"}>All Clinic</option> */}
                      {branches.map((branch) => (
                        <option key={branch.id} value={branch.id}>
                          {branch.clinicName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                    <p className="popup_body_name_title d-flex">
                      Title Name
                      <div className="required_feild ms-2">(* required) </div>
                    </p>
                    <input
                      type="text"
                      name="description"
                      class=" popup_input_tags form-control"
                      placeholder="Enter offer Name"
                      value={eventtitle}
                      onChange={(e) => {
                        setevntTitle(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                    <p className="popup_body_name_title d-flex">
                      Enter validity Date
                      <div className="required_feild ms-2">(* required) </div>
                    </p>
                    <input
                      label="dob"
                      type="date"
                      class="popup_input_tags form-control"
                      id="inputdateofbirth"
                      onChange={(e) => {
                        const date = new Date(e.target.value);
                        const formattedDate = date.toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        });
                        console.log(formattedDate);
                        setFilterDate(formattedDate);
                      }}
                    />
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <p className="popup_body_upload_title d-flex">
                      Upload
                      <div className="required_feild ms-2">(* required) </div>
                    </p>
                    <div class="  input-group">
                      <input
                        class=" form-control"
                        id="inputGroupFile02"
                        style={{ cursor: "pointer" }}
                        type="file"
                        // onChange={(event) => {
                        //     event.target.files.length > 0 ? setIsRequiredPrescRecord(false) : setIsRequiredPrescRecord(true)
                        //     setPrescRecord(event.target.files[0]);
                        // }}
                        onChange={(event) => {
                          const file = event.target.files[0];

                          if (file) {
                            if (file.size < 20 * 1024 * 1024) {
                              setIsRequiredPrescRecord(false);
                              setPrescRecord(file);
                            } else {
                              toast.error("File size must be less than 20MB");
                              event.target.value = null;
                              setIsRequiredPrescRecord(true);
                            }
                          } else {
                            setIsRequiredPrescRecord(true);
                          }
                        }}
                      />
                      <label class="input-group-text" for="inputGroupFile02">
                        Upload
                      </label>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 mt-2">
                    <p className="popup_body_name_title d-flex">
                      Description
                      <div className="required_feild ms-2">(* required) </div>
                    </p>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="2"
                      value={eventDescription}
                      onChange={(e) => {
                        setEventDescription(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-center mt-3">
                  {loaderon ? (
                    <ClipLoader />
                  ) : (
                    <button
                      type="button"
                      className="popup_submit_button btn"
                      onClick={() => {
                        setaddprescribtion1(false);
                        addOffer();
                        setFrameworkData({
                          bookingId: "",
                          patientId: "",
                          clinicId: "",
                        });
                      }}
                      disabled={
                        !PrescRecord ||
                        !eventDescription ||
                        !FilterDate ||
                        !eventtitle ||
                        !selectedBranch
                      }
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Popup>
      </div>

      <div className="row mt-4 container ">

        {offerList?.map((item, i) => (
          <div className="col col-12 col-sm-6 col-md-6 col-lg-4 gx-2 gy-2" key={i} >
            <div
              key={i}
              style={{
                borderRadius: "10px",
                overflow: "hidden",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundSize: "cover",
                  height: "200px",
                }}
              >

              </div>
              <div style={{ padding: "20px" }}>
                <div className="d-flex align-items-center justify-content-between">
                  <h3
                    style={{
                      margin: "0 0 10px 0",
                      fontSize: "18px",
                      textAlign: "start",
                    }}
                  >
                    {item?.title}
                  </h3>
                  <h3
                    style={{
                      margin: "0 0 10px 0",
                      fontSize: "18px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setIsModalOpen1(i);
                      setSelectedRow(item)
                    }}
                  >
                    {item?.ClinicDetails?.ClinicName}
                  </h3>
                </div>

                {isModalOpen1 === i && (
                  <div
                    style={{
                      position: "fixed",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      ref={modalRef}
                      style={{
                        backgroundColor: "#fff",
                        padding: "20px",
                        borderRadius: "10px",
                        width: "300px",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      <div
                        className="d-flex align-items-center justify-content-center me-2 "
                        style={{ color: "black" }}
                      >
                        <img
                          className="image_div"
                          style={{ justifyItems: "center" }}
                          src={selectedRow?.ClinicDetails?.clinicProfilePic}
                        ></img>
                      </div>
                      <div
                        className="new_card_client_name"
                        style={{ color: "black", fontSize: "20px" }}
                      >
                        {selectedRow?.ClinicDetails?.ClinicName}
                      </div>
                      <div
                        className="other_info mb-2"
                        style={{ color: "black" }}
                      >
                        <div>
                          <i className="fa-solid fa-phone me-2"></i>
                          {selectedRow?.ClinicDetails?.ClinicContact}
                        </div>
                        <div>
                          <i className="fa-solid fa-location-dot me-2"></i>
                          {selectedRow?.ClinicDetails?.ClinicAddress}
                        </div>
                        <div>
                          <i class="fa-solid fa-at me-2"></i>
                          {selectedRow?.ClinicDetails?.ClinicEmail}
                        </div>
                      </div>
                      <button
                        onClick={() => setIsModalOpen1(null)}
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          background: "none",
                          color: "black",
                          border: "none",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                      >
                        &times;
                      </button>
                    </div>
                  </div>
                )}
                <p style={{ color: "#A0A0A0" }}>{item?.Date}</p>

                <div
                  style={{
                    margin: "0 0 10px 0",
                    fontSize: "14px",
                    color: "black",
                    textAlign: "start",
                    whiteSpace: isReadMore.open && isReadMore.id === i ? "normal" : "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {isReadMore.open && isReadMore.id === i
                    ? item?.description
                    : item?.description}
                </div>
                <div
                  onClick={() => {
                    setIsReadMore({
                      id: i,
                      open: i === isReadMore.id ? !isReadMore.open : true,
                    });
                  }}
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    textDecoration: "underline",
                    textAlign: 'start',
                    margin: "0 0 10px 0"
                  }}
                >
                  {isReadMore.open && isReadMore.id === i ? "Show Less" : "Read More"}
                </div>

                <div className="d-flex">
                  <div className="small_icon me-2">
                    <i
                      class="fa-solid fa-pen"
                      style={{ color: "green" }}
                      onClick={() => {
                        setIsEditing(true);

                      }}
                    ></i>
                  </div>
                  <div className="small_icon">
                    <i
                      class="fa-solid fa-trash"
                      style={{ color: "red" }}
                      onClick={() => {

                        deleteOffer(item?.offerId)

                      }}
                    ></i>
                  </div>
                </div>

                {isEditing && (
                  <div id="myModal" className="modal" onClick={handleOutsideClick2}>
                    <div className="modal-content">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Edit Offer</h5>
                        <i
                          onClick={closeUpdateModal}
                          class="fa-solid fa-xmark fa-x close"
                        ></i>
                      </div>
                      <div>

                        <div>

                          <div className="popup_body_name_patient_section row">
                            <div className="col-12 col-md-6 mt-2">
                              <p className="popup_body_name_title d-flex">
                                clinic
                                <div className="required_feild ms-2">(* required) </div>
                              </p>
                              <select
                                className="form-select mb-3"
                                aria-label="Select Clinic"
                                value={selectedBranch}
                                onChange={handleSelectChange}
                              >
                                <option value="" disabled>
                                  Select Clinic
                                </option>
                                {/* <option value={"All"}>All Clinic</option> */}
                                {branches.map((branch) => (
                                  <option key={branch.id} value={branch.id}>
                                    {branch.clinicName}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-12 col-md-6 mt-2">
                              <p className="popup_body_name_title d-flex">
                                Title Name
                                <div className="required_feild ms-2">(* required) </div>
                              </p>
                              <input
                                type="text"
                                name="description"
                                class=" popup_input_tags form-control"
                                placeholder="Enter offer Name"
                                value={eventtitle}
                                onChange={(e) => {
                                  setevntTitle(e.target.value);
                                }}
                              />
                            </div>
                            <div className="col-12 col-md-6 mt-2">
                              <p className="popup_body_name_title d-flex">
                                Enter validity Date
                                <div className="required_feild ms-2">(* required) </div>
                              </p>
                              <input
                                label="dob"
                                type="date"
                                class="popup_input_tags form-control"
                                id="inputdateofbirth"
                                onChange={(e) => {
                                  const date = new Date(e.target.value);
                                  const formattedDate = date.toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  });
                                  console.log(formattedDate);
                                  setFilterDate(formattedDate);
                                }}
                              />
                            </div>

                            <div className="col-12 col-md-6 mt-2">
                              <p className="popup_body_upload_title d-flex">
                                Upload
                                <div className="required_feild ms-2">(* required) </div>
                              </p>
                              <div class="  input-group">
                                <input
                                  class=" form-control"
                                  id="inputGroupFile02"
                                  style={{ cursor: "pointer" }}
                                  type="file"

                                  onChange={(event) => {
                                    const file = event.target.files[0];

                                    if (file) {
                                      if (file.size < 20 * 1024 * 1024) {
                                        setIsRequiredPrescRecord(false);
                                        setPrescRecord(file);
                                      } else {
                                        toast.error("File size must be less than 20MB");
                                        event.target.value = null;
                                        setIsRequiredPrescRecord(true);
                                      }
                                    } else {
                                      setIsRequiredPrescRecord(true);
                                    }
                                  }}
                                />
                                <label class="input-group-text" for="inputGroupFile02">
                                  Upload
                                </label>
                              </div>
                            </div>

                            <div className="col-12 col-md-6 mt-2">
                              <p className="popup_body_name_title d-flex">
                                Description
                                <div className="required_feild ms-2">(* required) </div>
                              </p>
                              <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="2"
                                value={eventDescription}
                                onChange={(e) => {
                                  setEventDescription(e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="d-flex justify-content-center mt-3">
                            {loaderon ? (
                              <ClipLoader />
                            ) : (
                              <button
                                type="button"
                                className="popup_submit_button btn"
                                onClick={() => {
                                  closeUpdateModal();
                                }}
                              >
                                Update
                              </button>
                            )}
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                )}


              </div>
            </div>
          </div>
        ))}


      </div>
    </div>
  );
}

export default Offers;
