import "./Client_Booking.css";
import "../../App.css";
import Carousel from "react-elastic-carousel";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "../Client/Sidebar.css";
import { format, parseISO } from "date-fns";
import toast from "react-hot-toast";
import { SyncLoader } from "react-spinners";
import Tooltip from '@mui/material/Tooltip';

function Client_Booking() {
  let navigate = useNavigate();

  const [loaderon, setloaderon] = useState(false);
  const [AllDate, setAllDate] = useState([]);

  const [activeDate, setActiveDate] = useState(null);
  const [activeDay, setActiveDay] = useState(null);
  const [BookingPage, setBookingPage] = useState(1);
  const [disablebutton, setdisablebutton] = useState(false);
  const [Price, setPrice] = useState([]);


  const [SelectedSlot, setSelectedSlot] = useState(null);
  console.log('SelectedSlot: ', SelectedSlot);
  const [TimeSlot, setTimeSlot] = useState([]);
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 4, itemsToScroll: 2, pagination: false },
    { width: 768, itemsToShow: 5 },
    { width: 1150, itemsToShow: 5, itemsToScroll: 2 },
    { width: 1440, itemsToShow: 6 },
    { width: 1750, itemsToShow: 6 },
  ];
  function showAdvanceSlot(SelectedDate) {
    var Url = window.API_PREFIX + "timing/showslot_advance";
    console.log(SelectedDate);
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Date: SelectedDate,
        clinicId: selectedCard,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setTimeSlot([...data["TotalSlot"]]);
        }
      });
  }


  function showSlot(id) {
    var Url = window.API_PREFIX + "timing/show_slot";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        clinicId: id,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setTimeSlot([...data["TotalSlot"]]);
          setAllDate([...data["date_List"]]);
          setActiveDate(data["date_List"][0]["Date"]);
          setActiveDay(data["date_List"][0]["Day"]);
          setPrice(data["Price"]);
        }
      });

  }

  const [selectedCard, setSelectedCard] = useState(null);
  console.log("selectedCard: ", selectedCard);
  const [selectedCardData, setSelectedCardData] = useState(null);
  console.log('selectedCardData: ', selectedCardData);
  const handleCardClick = (clinic) => {
    console.log('id1354: ', clinic?.id);
    setSelectedCard(clinic?.id);
    setSelectedCardData(clinic)
    showSlot(clinic?.id)
  };


  const [clinics, setClinics] = useState([]);

  useEffect(() => {
    const Url = window.API_PREFIX + "admin/allclinics";
    fetch(Url, {
      method: "GET",
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setClinics([...data["allClinicList"]]);
        }
      });
  }, []);


  const [Package, setpackage] = useState(false)
  const [open, setopen] = useState()
  const [timeCard, setTimeCard] = useState("");
  const [timeOptions, setTimeOptions] = useState(null);
  const [selectedTimeOptions, setSelectedTimeOptions] = useState("");
  console.log('selectedTimeOptions: ', selectedTimeOptions);

  const handleCardClick2 = (selectedCard) => {
    setTimeCard(selectedCard?.id);
    setSelectedTimeOptions(selectedCard)
  };
  useEffect(() => {
    const Url = window.API_PREFIX + "patient/packagelist";
    fetch(Url, {
      method: "GET",
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "1") {
          setTimeOptions([...data["packageList"]]);
        }
      });
  }, []);





  return (

    <main className="container-fuild mx-2">
      {


        BookingPage === 1 ?
          <>

            <div className=" purpose_of_visit_division_container mt-2">
              <div className="d-flex justify-content-end">
                <div className="search-field w-25">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Clinics"

                  />
                  <span className="search-icon">
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </span>
                </div>

              </div>

              <div className="row gx-3 gy-3 mt-2">
                {clinics.map((clinic) => (
                  <div
                    className="col col-12 col-sm-4 col-md-3"
                    key={clinic.id}
                    onClick={() => handleCardClick(clinic)}
                  >
                    <div
                      className="app_card_main"
                      style={{
                        border:
                          selectedCard === clinic.id
                            ? "2px solid #743A87"
                            : "2px solid #E2E8F0",
                      }}
                    >
                      <div className="d-flex justify-content-center"
                        style={{
                          borderBottom: '1px solid #E2E8F0'
                        }}>
                        <img
                          className="pt-2 pb-2"
                          style={{
                            width: "70%",
                            height:'150px',
                            objectFit: "contain",

                          }}
                          src={clinic.clinicProfilePic}
                        ></img>
                      </div>
                      <div style={{ padding: '5px 20px 20px 20px' }}>
                        <h5 className="text-center mt-2">
                          {clinic.clinicName}
                          <i class="fas fa-check-circle verified"
                            style={{ color: '#28a745', marginLeft: '3px' }}></i>
                        </h5>
                        <div class="app_clinic_address" style={{ textAlign: 'justify' }}><i class="fa-solid fa-location-dot me-3 mt-1"></i>
                          <div>
                            Address: {clinic.clinicAddress}
                          </div></div>
                        <div class="app_clinic_address" style={{ textAlign: 'justify' }}><i class="fa-regular fa-clock me-3 mt-1"></i>
                          <div>
                            Timing: {clinic.clinicTiming}
                          </div></div>

                        <div className="row mt-2">
                          <div className="col">
                            <button className="btn mail_btn"
                              key={clinic.id}
                              onClick={() => handleCardClick(clinic)}
                              style={{
                                background: selectedCard === clinic.id ? '#743A87' : 'white',
                                color: selectedCard === clinic.id ? 'white' : '#743A87'
                              }}>Book</button>
                          </div>
                          <div className="d-flex col justify-content-evenly">
                            <Tooltip title={clinic.clinicMobile} arrow>
                              <div className="">
                                <a href={`tel:${clinic.clinicMobile}`} class=" star_box"><i class="fa-solid fa-phone fa-1x"></i></a>
                              </div>
                            </Tooltip>
                            <Tooltip title={clinic.clinicEmail} arrow>
                              <div className="">
                                <a href={`mailto:${clinic.clinicEmail}`} class="star_box"><i class="fa-solid fa-envelope fa-1x"></i></a>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="client_booking_proceedpay_button_division">
              <button
                type="button"
                class="btn client_booking_proceedpay_button mt-2"
                disabled={!selectedCard}
                onClick={() => {

                  setBookingPage(2);


                }}
              >
                Next
              </button>
            </div>


          </>
          :
          BookingPage === 2 ?

            <div>

              <div>
                <button className='btn Reviewdetails_back_button' onClick={() => {
                  setBookingPage(1)
                }}
                ><i class="fa-solid fa-arrow-left back_icon"></i>Back</button>
              </div>
              {
                selectedCard ? (
                  <div>
                    <div class="d-flex justify-content-center mt-3">
                      <div>
                        <i class="fa-solid fa-square me-2 indication_1"></i>
                        Availble
                      </div>
                      <div class="ms-2">
                        <i class="fa-solid fa-square me-2 indication_2"></i>
                        Not Availble
                      </div>
                      <div class="ms-2">
                        <i class="fa-solid fa-square me-2 indication_3"></i>
                        Selected
                      </div>
                    </div>

                    <div className="client_booking_main_diviison">
                      <div className="client_booking_diviison">
                        <div className="client_booking_week_division">
                          <Carousel breakPoints={breakPoints}>
                            {AllDate.map((eachDay, DayIndex) => {
                              return (
                                <div
                                  onClick={() => {
                                    setActiveDate(eachDay["Date"]);
                                    setActiveDay(eachDay["Day"]);
                                    showAdvanceSlot(eachDay["Date"]);
                                  }}
                                  className={
                                    activeDate == eachDay["Date"]
                                      ? "client_booking_week_name_active"
                                      : "client_booking_week_name"
                                  }
                                >
                                  <div>{eachDay["Day"]}</div>
                                  <div
                                    className={
                                      eachDay["Date"]
                                        ? "client_booking_day_name_active"
                                        : "client_booking_day_name"
                                    }
                                  >
                                    {eachDay["Date"]
                                      ? format(
                                        parseISO(eachDay["Date"]),
                                        "dd-MM-yyyy"
                                      )
                                      : ""}
                                  </div>
                                </div>
                              );
                            })}
                          </Carousel>
                        </div>

                        {TimeSlot.length > 0 ? (
                          <div className="client_booking_time_slot_table_division">
                            {TimeSlot.map((eachTimeSlot, TimeSlotindex) => {
                              return (
                                <div
                                  className={
                                    !eachTimeSlot["Flag"]
                                      ? "client_booking_time_slots_active"
                                      : eachTimeSlot["Slot"] === SelectedSlot
                                        ? "client_booking_time_slots_active_selected"
                                        : "client_booking_time_slots"
                                  }
                                  onClick={() => {
                                    if (eachTimeSlot["Flag"] === 1) {
                                      setSelectedSlot(eachTimeSlot["Slot"]);
                                    }
                                  }}
                                >
                                  {eachTimeSlot["Slot"]}
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="text-center mt-5 mb-5">
                            There is no slots Availble
                          </div>
                        )}
                      </div>
                      <div className="client_booking_proceedpay_button_division">
                        <button
                          type="button"
                          class="btn client_booking_proceedpay_button mt-2"
                          disabled={!SelectedSlot}
                          onClick={() => {

                            setBookingPage(3);


                          }}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div >
                ) : null
              }

            </div>



            :

            BookingPage === 3 ?
              <>
                <div className=' mt-2 '>
                  <div>
                    <div>
                      <button className='btn Reviewdetails_back_button' onClick={() => {
                        setBookingPage(2)
                      }}
                      ><i class="fa-solid fa-arrow-left back_icon"></i>Back</button>
                    </div>
                    <h3 className="mt-3">Select Package</h3>
                    <div className="row">

                      {timeOptions.map((option) => (
                        <div className="col col-12 col-md-4 gy-1">
                          <div
                            key={option.id}
                            onClick={() => handleCardClick2(option)}
                            style={{
                              padding: "12px",
                              border: timeCard === option.id ? "1px solid #743A87" : "1px solid #E5E7EB",
                              borderRadius: "25px",
                              cursor: "pointer",
                              textAlign: "center",
                              backgroundColor: timeCard === option.id ? "#FFFFFF" : " #F2F6F6",
                            }}
                          >
                            <div className="d-flex">
                              <div className="star_box me-2">
                                <i class="fa-solid fa-star fa-xl" style={{ color: '#743A87' }}></i>
                              </div>
                              <div>
                                <p className="package_name">{option.packageName}</p>
                              </div>
                            </div>
                            {/* <p className="package_disc mt-2">{option.packageDescription}</p> */}
                            <div className="d-flex align-items-center">
                              <div className="package_price me-2">
                                ₹{option.packagePrice}
                              </div>
                              <div className="package_duration">
                                /{option.packageDuration}
                              </div>
                            </div>
                            <div className="text-start mt-2">
                              <h6>What’s included</h6>
                              <div>
                                <i class="fas fa-check-circle verified"
                                  style={{ color: '#28a745', marginRight: '3px' }}></i>
                                {option.packageDescription}
                              </div>
                              {/* <div className="mt-1">
                                <i class="fas fa-check-circle verified"
                                  style={{ color: '#28a745', marginRight: '3px' }}></i>
                                Lorem ipsum dolor amet, consectetur
                              </div> */}
                            </div>


                          </div>
                        </div>
                      ))}


                    </div>

                    <div className='client_booking_proceedpay_button_division mt-2 mb-2'>
                      <button type="button" class="btn client_booking_proceedpay_button"

                        onClick={(() => {
                          if (timeCard) {
                            setBookingPage(4)
                          }
                          else {
                            toast.error("please select package")
                          }

                        })}
                      >Next</button>
                    </div>
                  </div>
                </div>
              </>
              :

              <>
                <div className="mt-2 ">
                  <div>
                    <button
                      className="btn Reviewdetails_back_button mt-2 mb-2"
                      onClick={() => {
                        setBookingPage(3)
                      }}
                    >
                      <i class="fa-solid fa-arrow-left back_icon"></i>Back
                    </button>
                    <div className="Reviewdetails_main_division mt-2">
                      <div className="Reviewdetails_division col-12 col-md-6">
                        <p className="Reviewdetails_summary_name">Booking summary</p>
                        <div className="d-flex mt-2">
                          <div className="Reviewdetails_image_division">
                            <img
                              className="Reviewdetails_image"
                              src={selectedCardData.clinicProfilePic}
                            ></img>
                          </div>
                          <div>
                            <div className="Reviewdetails_mitwank_name">
                              {selectedCardData.clinicName}
                            </div>
                            <div className="Reviewdetails_mitwank_name">
                              {selectedCardData.clinicAddress}
                            </div>
                          </div>
                        </div>
                        <div className="reviewdetails_date_time_fee_division mt-2">
                          <div className="reviewdetails_leftside"> Package Name</div>
                          <div className="reviewdetails_rightside">{selectedTimeOptions?.packageName}</div>
                        </div>
                        <div className="reviewdetails_date_time_fee_division">
                          <div className="reviewdetails_leftside"> Package Duration</div>
                          <div className="reviewdetails_rightside">{selectedTimeOptions?.packageDuration}</div>
                        </div>

                        <div className="reviewdetails_date_time_fee_division">
                          <div className="reviewdetails_leftside"> date</div>
                          <div className="reviewdetails_rightside">
                            {activeDate ? format(parseISO(activeDate), "dd-MM-yyyy, EEEE") : ""}
                          </div>
                        </div>
                        <div className="reviewdetails_date_time_fee_division">
                          <div className="reviewdetails_leftside"> time</div>
                          <div className="reviewdetails_rightside">{SelectedSlot}</div>
                        </div>
                        <div className="reviewdetails_date_total_division">
                          <div className="reviewdetails_total">total</div>
                          <div className="reviewdetails_rightside">₹{selectedTimeOptions?.packagePrice}</div>
                        </div>

                        <div className="client_booking_proceedpay_button_division">
                          <button
                            type="button"
                            class="btn client_booking_proceedpay_button"
                            disabled={disablebutton}
                            onClick={() => {
                              setdisablebutton(true);
                              // const getData = setTimeout(() => {
                              if (!disablebutton) {
                                var Url = window.API_PREFIX + "booking/booking";
                                setloaderon(true);
                                fetch(Url, {
                                  method: "POST",
                                  headers: {
                                    "Content-type": "application/json",
                                  },
                                  body: JSON.stringify({
                                    Token: localStorage.getItem("DToken"),
                                    Slot: SelectedSlot,
                                    Date: activeDate,
                                    Purpose: selectedTimeOptions?.packageName,
                                    Price: selectedTimeOptions?.packagePrice,
                                    clinicId: selectedCard,
                                  }),
                                })
                                  .then((resp) => resp.json())
                                  .then((data) => {
                                    console.log(data);
                                    if (data["status"] === "1") {
                                      toast.success("Sent For Approval");
                                      setloaderon(false);
                                      navigate("/dashboard");
                                      setTimeCard("");
                                      setSelectedTimeOptions("");
                                    }
                                  });
                              }
                              // })

                              // return () => clearTimeout(getData)
                            }}
                          >
                            {" "}
                            {loaderon ? (
                              <SyncLoader color="white" />
                            ) : (
                              "Request Appoinment"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>

      }

    </main >

  );
}
export default Client_Booking;
