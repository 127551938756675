import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import toast from "react-hot-toast";
import { Switch } from "antd";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Table } from "antd";
import { SyncLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

function Clinic_add_receptionist() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const handleOutsideClick = (event) => {
    if (event.target.id === "myModal") {
      closeModal();
    }
  };
  let navigate = useNavigate();
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  //   const openModal2 = () => {
  //     setIsModalOpen2(true);
  //   };

  const closeModal2 = () => {
    setIsModalOpen2(false);
  };

  const handleOutsideClick2 = (event) => {
    if (event.target.id === "myModal") {
      closeModal2();
    }
  };
  const [selectedId, setSelectedId] = useState();
  const [recename, setrecename] = useState();
  const [mail, setmail] = useState("");
  const [password, setpassword] = useState();
  const [number, setnumber] = useState();
  const [birthDate, setBirthDate] = useState("");
  const [age, setage] = useState("");
  const [gender, setgender] = useState("");
  const [Img, setImg] = useState("");
  const [showImg, setshowImg] = useState();
  const [OTPbuttonFlag, setOTPbuttonFlag] = useState(false);
  const [systemOTP, setsystemOTP] = useState(false);
  const [buttonFlag, setbuttonFlag] = useState(false);
  const [loaderon, setloaderon] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [Activegender, setActivegender] = useState([false, false]);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    pageSizeOptions: ["5", "10", "20", "50", "100"],
    showSizeChanger: true,
  });

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const [receptionistdata, setReceptionistdata] = useState([]);

  function getReceptionist() {
    var Url = window.API_PREFIX + "admin/receptionistlist";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        clinicId: localStorage.getItem("clinicId"),
        loginAs: localStorage.getItem("LoginDetails"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        }
        if (data["status"] === "1") {
          const receptionistData = [...data["receptionistList"]];
          const dataWithSrNo = receptionistData.map((item, index) => ({
            ...item,
            srNo: receptionistData.length - index,
          }));
          setReceptionistdata(dataWithSrNo);
        }
      });
  }

  function delete_receptionist(Requestindex, id) {
    var Url = window.API_PREFIX + "admin/trashdeletereceptionist";
    const index = receptionistdata.findIndex((records) => records.id === id);

    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Token: localStorage.getItem("DToken"),
        Id: receptionistdata[index]["id"],
        ClinicId: localStorage.getItem("clinicId"),
        loginAs: localStorage.getItem("LoginDetails"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        }
        if (data["status"] === "1") {
          getReceptionist();
        }
      });
  }

  useEffect(() => {
    getReceptionist();
  }, []);

  const receptionistcolumn = [
    {
      title: "Sr No",
      dataIndex: "srNo",
      render(text, record, index) {
        return {
          props: {
            style: { fontSize: "16px", textAlign: "center", color: "#483a15" },
          },
          children: <div style={{ fontWeight: "600" }}>{record.srNo}</div>,
        };
      },
    },
    {
      title: "Receptionist Name",
      dataIndex: "Name",
      key: "name",
      render: (text, record, index) => <div>{record?.receptionistName}</div>,
    },
    {
      title: "Receptionist Email",
      dataIndex: "Name",
      key: "name",
      render: (text, record, index) => <div>{record?.receptionistEmail}</div>,
    },
    {
      title: "Action",
      dataIndex: "Actions",
      key: "Actions",
      render: (text, record, index) => (
        <div className="d-flex justify-content-center align-items-center">
          <i
            class="fa-solid fa-pen me-2"
            style={{ color: "green" }}
            // onClick={openModal2}
            onClick={() => {
              setIsModalOpen2(true);
              setSelectedId(record?.id);
              setrecename(record?.receptionistName);
              setmail(record?.receptionistEmail);
              setpassword(record?.receptionistPassword);
              setImg(record?.receptionistProfilePic);
              setshowImg(record?.receptionistProfilePic);
              setnumber(record?.receptionistNumber)
              setBirthDate(record?.receptionistBirthDate)
            }}
          ></i>
          <i
            class="fa-solid fa-trash-can me-2"
            style={{ color: "#e11f28" }}
            onClick={() => {
              delete_receptionist(index, record.id);
            }}
          ></i>

          <div class="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              checked={record.IsBlocked}
              onChange={(e) => handleToggleUser(record.id, e.target.checked)}
            />
          </div>
        </div>
      ),
    },
  ];

  const handleToggleUser = async (id, isActive) => {
    console.log("isActiveRec: ", isActive);
    console.log("idRec: ", id);
    if (isActive === true) {
      await blockReceptionist(id);
    } else {
      await unblockReceptionist(id);
    }
  };

  // Blocking API call
  const blockReceptionist = async (id) => {
    var Url = window.API_PREFIX + "admin/blockreceptionist";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Id: id,
        clinicId: localStorage.getItem("clinicId"),
        loginAs: localStorage.getItem("LoginDetails"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        // dispatch({
        //     type: 'StopLoad'
        // })
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } else if (data["status"] === "1") {
          toast.success("Receptionist is Block Successfully");
          getReceptionist();
        }
      });
  };

  // Unblocking API call
  const unblockReceptionist = async (id) => {
    var Url = window.API_PREFIX + "admin/unblockreceptionist";
    fetch(Url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        Id: id,
        clinicId: localStorage.getItem("clinicId"),
        loginAs: localStorage.getItem("LoginDetails"),
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        // dispatch({
        //     type: 'StopLoad'
        // })
        if (data["status"] === "Block") {
          toast.error(data["message"]);
          localStorage.clear();
          navigate("/Clinic");
          window.location.reload();
        } else if (data["status"] === "1") {
          toast.success("Receptionist is Un Block Successfully");
          getReceptionist();
        }
      });
  };

  const handleDateChangedate = (e) => {
    const selectedDate = e.target.value;
    setBirthDate(selectedDate);
    validateAge(selectedDate);
  };

  // Calculate the maximum allowed date (18 years ago from today)
  const getMaxDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18);
    return today.toISOString().split("T")[0];
  };

  const validateAge = (selectedDate) => {
    const today = new Date();
    const birthDate = new Date(selectedDate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    if (age < 18) {
      toast.error("You must be at least 18 years old.");
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <div className="container-fuild mx-2">
        <div class="text-end mt-2">
          <button
            class="mitwank_patient_profile_add_prescription_btn"
            onClick={openModal}
          >
            + Add Receptionist
          </button>
        </div>

        {isModalOpen && (
          <div id="myModal" className="modal" onClick={handleOutsideClick}>
            <div className="modal-content">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Add Receptionist</h5>
                <i
                  onClick={closeModal}
                  class="fa-solid fa-xmark fa-x close"
                ></i>
              </div>
              <div className="mt-3">
                <div>
                  <label for="formFile" class="form-label">
                    Profile Picture
                  </label>
                  <input
                    class="form-control"
                    type="file"
                    accept="image/gif, image/jpeg, image/png"
                    id="formFile"
                    onChange={(e) => {
                      setImg(e.target.files[0]);
                    }}
                    required
                  />
                </div>
                <TextField
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  size="small"
                  className="w-100 mt-3"
                  onChange={(e) => {
                    setrecename(e.target.value);
                    const newValue = e.target.value;
                    const nonNumericRegex = /^[A-Za-z\s]*$/;
                    if (nonNumericRegex.test(newValue)) {
                      setrecename(newValue);
                    } else {
                      toast.error("Please enter alphabets only.");
                    }
                  }}
                  required
                />

                <TextField
                  id="outlined-basic"
                  label="Number"
                  variant="outlined"
                  size="small"
                  type="number"
                  className="w-100 mt-3"
                  value={number}
                  onChange={(e) => {
                    setnumber(e.target.value);
                  }}
                  required
                />

                <div className="client_register_date_feild_division">
                  <lable>Enter Your Birthdate*</lable>
                  <input
                    label="dob"
                    type="date"
                    class="client_register_email_field form-control h-50"
                    id="inputdateofbirth"
                    value={birthDate}
                    onChange={handleDateChangedate}
                    max={getMaxDate()}
                  />
                </div>

                <div
                  className="client_register_personal_gender_main_division"
                  style={{ height: "40px" }}
                >
                  <button
                    className={
                      Activegender[0]
                        ? "client_register_personal_male_btn_active"
                        : "client_register_personal_male_btn"
                    }
                    onClick={() => {
                      setgender("male");
                      setActivegender([true, false]);
                    }}
                  >
                    <i class="fa-solid fa-mars-stroke"></i> Male
                  </button>
                  <button
                    className={
                      Activegender[1]
                        ? "client_register_personal_female_btn_active"
                        : "client_register_personal_female_btn"
                    }
                    onClick={() => {
                      setgender("female");
                      setActivegender([false, true]);
                    }}
                  >
                    <i class="fa-solid fa-mars-stroke-up"></i> Female
                  </button>
                </div>

                <TextField
                  id="outlined-basic"
                  label="Age"
                  variant="outlined"
                  size="small"
                  type="number"
                  className="w-100 mt-3"
                  onChange={(e) => {
                    setage(e.target.value);
                  }}
                  required
                />

                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  size="small"
                  className="w-100 mt-3"
                  onChange={(e) => {
                    setmail(e.target.value);
                    setOTPbuttonFlag(false);
                  }}
                  required
                />

                <button
                  className={
                    mail.length !== 0 && !OTPbuttonFlag
                      ? "client_register_btn_login_division_active"
                      : "client_register_btn_login_division"
                  }
                  disabled={!(mail.length !== 0 && !OTPbuttonFlag)}
                  onClick={() => {
                    if (mail.length !== 0) {
                      var Url =
                        window.API_PREFIX + "admin/receptionistgenerateotp";
                      setloaderon(true);
                      fetch(Url, {
                        method: "POST",
                        headers: {
                          "Content-type": "application/json",
                        },
                        body: JSON.stringify({
                          Email: mail,
                          ClinicId: localStorage.getItem("clinicId"),
                          loginAs: localStorage.getItem("LoginDetails"),
                        }),
                      })
                        .then((resp) => resp.json())
                        .then((data) => {
                          console.log(data);
                          if (data["status"] === "Block") {
                            toast.error(data["message"]);
                            localStorage.clear();
                            navigate("/Clinic");
                            window.location.reload();
                          } else if (data["status"] === "1") {
                            setloaderon(false);
                            setOTPbuttonFlag(true);
                          } else if (data["status"] === "0") {
                            toast.error("Email already register");
                            setloaderon(false);
                          }
                        });
                    }
                  }}
                >
                  {loaderon ? <SyncLoader color="white" /> : "Generate OTP"}
                </button>

                <TextField
                  id="outlined-basic"
                  size="small"
                  label="Otp"
                  variant="outlined"
                  className="w-100 mt-3"
                  onChange={(e) => {
                    if (e.target.value.length !== 0) {
                      var Url =
                        window.API_PREFIX + "admin/receptionistcheckotp";
                      fetch(Url, {
                        method: "POST",
                        headers: {
                          "Content-type": "application/json",
                        },
                        body: JSON.stringify({
                          Email: mail,
                          OTP: e.target.value,
                          clinicId: localStorage.getItem("clinicId"),
                          loginAs: localStorage.getItem("LoginDetails"),
                        }),
                      })
                        .then((resp) => resp.json())
                        .then((data) => {
                          console.log(data);
                          if (data["status"] === "Block") {
                            toast.error(data["message"]);
                            localStorage.clear();
                            navigate("/Clinic");
                            window.location.reload();
                          } else if (data["status"] === "1") {
                            setbuttonFlag(true);
                            setsystemOTP(true);
                          } else if (data["status"] === "-1") {
                            toast.error("Email not found");
                            setbuttonFlag(false);
                          }
                        });
                    }
                  }}
                />

                <FormControl
                  sx={{ width: "100%" }}
                  variant="outlined"
                  className="mt-3"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Create Password
                  </InputLabel>
                  <OutlinedInput
                    size="small"
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    onChange={(e) => {
                      setpassword(e.target.value);
                    }}
                    required
                  />
                </FormControl>

                <div class="d-flex justify-content-center mt-3">
                  <button
                    type="button"
                    class="popup_submit_button btn"
                    disabled={!buttonFlag}
                    onClick={() => {
                      if (recename === "") {
                        toast.error("Please Enter Receptionist Name");
                      } else if (mail === "") {
                        toast.error("Please Enter Receptionist Email");
                      } else if (!Img) {
                        toast.error("Please Upload Profile Picture");
                      } else if (password === "") {
                        toast.error("Please Enter Receptionist Password");
                      } else {
                        var Url = window.API_PREFIX + "admin/addreceptionist";
                        var uploadData = new FormData();
                        // uploadData.append("Token", localStorage.getItem("DToken"));
                        uploadData.append(
                          "clinicId",
                          localStorage.getItem("clinicId")
                        );
                        uploadData.append("receptionistname", recename);
                        uploadData.append("receptionistProfilePic", Img);
                        uploadData.append("receptionistmail", mail);
                        uploadData.append("receptionistpassword", password);

                        fetch(Url, {
                          method: "POST",
                          body: uploadData,
                        })
                          .then((resp) => resp.json())
                          .then((data) => {
                            console.log(data);
                            if (data["status"] === "Block") {
                              toast.error(data["message"]);
                              localStorage.clear();
                              navigate("/Clinic");
                              window.location.reload();
                            } else if (data["status"] === "1") {
                              closeModal();
                              getReceptionist();
                              toast.success("Added Successfully");
                            }
                          });
                      }
                    }}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isModalOpen2 && (
          <div id="myModal" className="modal" onClick={handleOutsideClick2}>
            <div className="modal-content">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Edit Receptionist</h5>
                <i
                  onClick={closeModal2}
                  class="fa-solid fa-xmark fa-x close"
                ></i>
              </div>
              <div className="profilesettings_Upload_image">
                <div className="profilesettings_profile_image">
                  <img
                    className="profilesettings_profile_image_sizes"
                    src={showImg}
                  />
                </div>
                <div className="profilesettings_upload_button_main">
                  <button
                    type="button"
                    className="profilesettings_upload_photo_button btn"
                  >
                    <i className="fa fa-upload profilesettings_upload_icon"></i>{" "}
                    Upload Photo
                    <input
                      type="file"
                      accept="image/gif, image/jpeg, image/png"
                      onChange={(e) => {
                        setImg(e.target.files[0]);
                        setshowImg(URL.createObjectURL(e.target.files[0]));
                      }}
                    />
                  </button>
                  <br />
                  <div className="profilesetting_upload_p">
                    <p>Allowed JPG, GIF or PNG. Max size of 20MB</p>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <TextField
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  size="small"
                  className="w-100 mt-3"
                  value={recename}
                  onChange={(e) => {
                    setrecename(e.target.value);
                  }}
                  required
                />

                <TextField
                  id="outlined-basic"
                  label="Mail"
                  variant="outlined"
                  size="small"
                  className="w-100 mt-3"
                  value={mail}
                  onChange={(e) => {
                    setmail(e.target.value);
                  }}
                  required
                  disabled
                />
                <div className="client_register_date_feild_division">
                  <lable>Edit Your Birthdate*</lable>
                  <input
                    label="dob"
                    type="date"
                    class="client_register_email_field form-control h-50"
                    id="inputdateofbirth"
                    value={birthDate}
                    onChange={handleDateChangedate}
                  />
                </div>

                <TextField
                  id="outlined-basic"
                  label="Number"
                  variant="outlined"
                  size="small"
                  type="number"
                  className="w-100 mt-3"
                  value={number}
                  onChange={(e) => {
                    setnumber(e.target.value);
                  }}
                  required
                />

                <FormControl
                  sx={{ width: "100%" }}
                  variant="outlined"
                  className="mt-3"
                >
                  {password === "" && (
                    <InputLabel htmlFor="outlined-adornment-password">
                      Creat Password
                    </InputLabel>
                  )}
                  <OutlinedInput
                    label="password"
                    size="small"
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={password}
                    onChange={(e) => {
                      setpassword(e.target.value);
                    }}
                    required
                  />
                </FormControl>
                <div
                  className="client_register_personal_gender_main_division"
                  style={{ height: "40px" }}
                >
                  <button
                    className={
                      Activegender[0]
                        ? "client_register_personal_male_btn_active"
                        : "client_register_personal_male_btn"
                    }
                    onClick={() => {
                      setgender("male");
                      setActivegender([true, false]);
                    }}
                  >
                    <i class="fa-solid fa-mars-stroke"></i> Male
                  </button>
                  <button
                    className={
                      Activegender[1]
                        ? "client_register_personal_female_btn_active"
                        : "client_register_personal_female_btn"
                    }
                    onClick={() => {
                      setgender("female");
                      setActivegender([false, true]);
                    }}
                  >
                    <i class="fa-solid fa-mars-stroke-up"></i> Female
                  </button>
                </div>

                <div class="d-flex justify-content-center mt-3">
                  <button
                    type="button"
                    class="popup_submit_button btn"
                    onClick={() => {
                      var Url = window.API_PREFIX + "admin/editreceptionist";
                      var uploadData = new FormData();
                      uploadData.append("id", selectedId);
                      uploadData.append("receptionistname", recename);
                      uploadData.append("receptionistProfilePic", Img);
                      uploadData.append("receptionistpassword", password);
                      uploadData.append("receptionistBirthDate", birthDate);
                      uploadData.append("receptionistNumber", number);
                      uploadData.append("clinicId", localStorage.getItem("clinicId"));
                      uploadData.append("loginAs", localStorage.getItem("LoginDetails"));


                      fetch(Url, {
                        method: "POST",
                        body: uploadData,
                      })
                        .then((resp) => resp.json())
                        .then((data) => {
                          console.log(data);
                          if (data["status"] === "Block") {
                            toast.error(data["message"]);
                            localStorage.clear();
                            navigate("/Clinic");
                            window.location.reload();
                          }
                          else if (data["status"] === "1") {
                            closeModal2();
                            setSelectedId("");
                            getReceptionist();
                            toast.success("Added Successfully");
                          }
                        });
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="mitwank_patient_profile_table_division">
          <Table
            columns={receptionistcolumn}
            pagination={pagination}
            dataSource={receptionistdata}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </>
  );
}

export default Clinic_add_receptionist;
