import './Mitwankforgotpassword.css';
import '../../App.css';
import doccureforgotpassworfimg from '../../media/login_img.png';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import toast, { Toaster } from 'react-hot-toast';


function Mitwankforgotpassword() {

    let navigate = useNavigate()

    const [OTPbutton, setOTPbutton] = useState(false)
    const [userOTP, setuserOTP] = useState("-1")
    const [sysOTP, setsysOTP] = useState(false)
    const [Email, setEmail] = useState("")
    const [Password, setPassword] = useState('')

    const [Inputtype, setInputtype] = useState("password");
    const [ButtonName, setButtonName] = useState(<i class="fa-solid fa-eye"></i>);


    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    return (
        <div className='container'>
            <div className='client_forgot_password_main_division'>
                <div className='client_forgot_password_main_first_division'>
                    <div className='d-flex justify-content-center'><div className='client_forgot_password_img_division'>
                        <img className='client_forgot_password_img' src={doccureforgotpassworfimg} />
                    </div></div>
                </div>

                <div className='client_forgot_password_main_second_division'>
                    <div className='client_forgot_password_title_name'>Forgot Password?</div>
                    <div className='client_forgot_password_sub_title_name'>Enter your email to get a password reset link</div>


                    <TextField id="outlined-basic" label="Email" variant="outlined" className="w-100 mt-2" onChange={(e) => {
                        setEmail(e.target.value)
                        setOTPbutton(false)
                    }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {

                                if (Email.length !== 0) {
                                    setOTPbutton(true)

                                    var Url = window.API_PREFIX + 'admin/generate_otp_forget_password'
                                    fetch(Url, {
                                        method: 'POST',
                                        headers: {
                                            'Content-type': 'application/json',
                                        },
                                        body: JSON.stringify(
                                            {

                                                Email: Email,

                                            }
                                        ),
                                    }).then(resp => resp.json()).then(data => {
                                        console.log(data)
                                        if (data['status'] === "1") {

                                            // setsysOTP(data['OTP'])
                                        }
                                        else if
                                            (data['status'] === "-1") {

                                            toast.error('Email not found')
                                            setOTPbutton(false)
                                        }

                                    })

                                }
                            }
                        }} />


                    <button className={Email.length !== 0 && !OTPbutton ? "client_forgot_password_btn_login_division" : 'client_forgot_password_btn_login_division_inactive'} disabled={!(Email.length !== 0 && !OTPbutton)}
                        onClick={() => {
                            if (Email.length !== 0) {
                                setOTPbutton(true)

                                var Url = window.API_PREFIX + 'admin/generate_otp_forget_password'
                                fetch(Url, {
                                    method: 'POST',
                                    headers: {
                                        'Content-type': 'application/json',
                                    },
                                    body: JSON.stringify(
                                        {

                                            Email: Email,

                                        }
                                    ),
                                }).then(resp => resp.json()).then(data => {
                                    console.log(data)
                                    if (data['status'] === "1") {

                                        // setsysOTP(data['OTP'])
                                    }
                                    else if
                                        (data['status'] === "-1") {

                                        toast.error('Email not found')
                                        setOTPbutton(false)
                                    }

                                })

                            }

                        }}

                    >Send OTP on email</button>

                    {OTPbutton ? <> <div class="client_forgot_password_email_field_division form-floating mt-4">
                        <TextField id="outlined-basic" label="Otp" type='number' variant="outlined" className="w-100 mt-2" onChange={(e) => {
                           
                                if (e.target.value.length!==0)
                                {

                                    var Url = window.API_PREFIX + 'admin/checkOTP'
                                    fetch(Url, {
                                        method: 'POST',
                                        headers: {
                                            'Content-type': 'application/json',
                                        },
                                        body: JSON.stringify(
                                            {
                                                Email: Email,
        
                                                OTP:  e.target.value,
        
                                            }
                                        ),
                                    }).then(resp => resp.json()).then(data => {
                                        console.log(data)
                                        if (data['status'] === "1") {
        
                                            setsysOTP(true)
                                        }
                                        else if
                                            (data['status'] === "-1") {
        
                                            toast.error('Email not found')
                                            setOTPbutton(false)
                                        }
        
                                    })
                                }

                        }} />

                    </div>


                        { sysOTP ?

                            <div>
                            
                                <FormControl sx={{ width: '100%' }} variant="outlined" className="mt-3">
                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                        onChange={(e) => {
                                            setPassword(e.target.value)

                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {

                                                if (sysOTP) {

                                                    var Url = window.API_PREFIX + 'admin/forget_password'
                                                    fetch(Url, {
                                                        method: 'POST',
                                                        headers: {
                                                            'Content-type': 'application/json',
                                                        },
                                                        body: JSON.stringify(
                                                            {

                                                                Email: Email,
                                                                Password: Password

                                                            }
                                                        ),
                                                    }).then(resp => resp.json()).then(data => {
                                                        console.log(data)
                                                        if (data['status'] === "1") {
                                                            toast.success('New password set successfully')
                                                            navigate("/login")

                                                        }
                                                        else if
                                                            (data['status'] === "-1") {

                                                            toast.error('New password failed')

                                                        }

                                                    })
                                                }
                                                else {
                                                    toast.error("OTP incorrect")
                                                }
                                            }
                                        }}
                                    />
                                </FormControl>

                            </div>
                            : null}

                    </> : null}

                    <div className='client_forgot_password_forgot_password_division'
                        onClick={() => {
                            navigate('/mitwank')
                        }}
                    >Remember your password?</div>
                    <button className={Password.length !== 0 && sysOTP ? "client_forgot_password_btn_login_division" : 'client_forgot_password_btn_login_division_inactive'} disabled={!(Password.length !== 0)}
                        onClick={() => {

                            if (userOTP === sysOTP) {

                                var Url = window.API_PREFIX + 'admin/forget_password'
                                fetch(Url, {
                                    method: 'POST',
                                    headers: {
                                        'Content-type': 'application/json',
                                    },
                                    body: JSON.stringify(
                                        {

                                            Email: Email,
                                            Password: Password

                                        }
                                    ),
                                }).then(resp => resp.json()).then(data => {
                                    console.log(data)
                                    if (data['status'] === "1") {
                                        toast.success('New password set successfully')
                                        navigate("/login")

                                    }
                                    else if
                                        (data['status'] === "-1") {

                                        toast.error('New password failed')

                                    }

                                })
                            }
                            else {
                                toast.error("OTP incorrect")
                            }


                        }}
                    >Reset Password</button>
                </div>
            </div>
        </div>
    )
}
export default Mitwankforgotpassword;