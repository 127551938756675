import React from "react";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./Footer.css";
import Footerlogo from "../media/about/vralogo.png";
import googleplay from '../media/about/googleplay.png'
import appstore from '../media/about/appstore.png';
import snh_logo from '../media/SH-Hydrafacial.png'


function Footer() {


  const validateEmail = (input) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailPattern.test(input));
  };

  let navigate = useNavigate()
  const [Email, setEmail] = useState("")
  const [IsValidEmail, setIsValidEmail] = useState("")

  const [one, setone] = useState(false)
  const [two, settwo] = useState(false)
  const [three, setthree] = useState(false)
  return (
    <>
      <footer className=" footer_main_div">


        <div className="ff_first_main">
          <div className="container first_main_div">
            <div className="nfooter_first_div row align-items-center">
              <div className="col col-12 col-md-6 text-center steller_div">
                <div>"Stay Connected"</div>
                <div>Subscribe To Our Newsletter</div>
              </div>
              <div className="col col-12 col-md-6">
                <div className="sub_div_main">
                  <input type="email" class="form-control input_div" id="exampleFormControlInput1" placeholder="Email"

                    onChange={(e) => {
                      setEmail(e.target.value);
                      validateEmail(e.target.value);
                    }}
                    onKeyDown={(e) => {

                      if (e.key === "Enter") {
                        if (IsValidEmail) {

                          var Url = window.API_PREFIX + 'admin/newsletter'
                          fetch(Url, {
                            method: 'POST',
                            headers: {
                              'Content-type': 'application/json',
                            },
                            body: JSON.stringify(
                              {
                                "mail": Email,

                              }),

                          }).then(resp => resp.json()).then(data => {
                            console.log(data)
                            if (data['status'] === "1") {
                              // alert("Thank you for subscribing our Newsletter.")
                              setone(true)
                              settwo(false)
                              setthree(false)
                            }
                            else {
                              // alert("Email Id is already subscribe for Newsletter")
                              settwo(true)
                              setone(false)
                              setthree(false)
                            }


                          }
                          )
                        }
                        else {
                          // alert("Please enter valid email")
                          setthree(true)
                          settwo(false)
                          setone(false)
                        }
                      }
                    }}
                  />
                  < div className="d-flex justify-content-center">
                    <button type="button" class="btn sub_btn"
                      onClick={() => {
                        if (IsValidEmail) {
                          var Url = window.API_PREFIX + 'admin/newsletter'
                          fetch(Url, {
                            method: 'POST',
                            headers: {
                              'Content-type': 'application/json',
                            },
                            body: JSON.stringify(
                              {
                                "mail": Email,

                              }),

                          }).then(resp => resp.json()).then(data => {
                            console.log(data)
                            if (data['status'] === "1") {
                              // alert("Thank you for subscribing our Newsletter.")
                              setone(true)
                              settwo(false)
                              setthree(false)
                            }
                            else {
                              // alert("Email Id is already subscribe for Newsletter")
                              settwo(true)
                              setone(false)
                              setthree(false)

                            }



                          }
                          )
                        }
                        else {
                          // alert("Please enter valid email")
                          setthree(true)
                          setone(false)
                          settwo(false)
                        }
                      }}




                    >Subscribe</button>
                  </div>
                </div>

                <div>
                  {
                    one ? 'Thank you for subscribing our Newsletter.' : ''
                  }
                  {
                    two ? 'Email Id is already subscribe for Newsletter.' : ''
                  }
                  {
                    three ? 'Please enter valid email.' : ''
                  }
                </div>

              </div>

            </div>
          </div>
        </div>



        <div className="nfooter_second_div pt-5 pb-5">

          <div className="row align-items-start m-0">

            <div className="col col-12 col-md-4">
              <ul className="nfooter_ul_list" >
                <li onClick={() => {
                  navigate('/terms-and-conditions')
                }}> <i class="fa-solid fa-angles-right me-2"></i> Terms of use</li>
                <li onClick={() => {
                  navigate('/privacy-policy')
                }}> <i class="fa-solid fa-angles-right me-2"></i> Privacy policy</li>
                <li onClick={() => {
                  navigate('/return-and-refund-policy')
                }}> <i class="fa-solid fa-angles-right me-2"></i> Cancellation and refund policy</li>
                <li onClick={() => {
                  navigate('/shipping-policy')
                }}> <i class="fa-solid fa-angles-right me-2"></i> Shipping policy</li>
                <li onClick={() => {
                  navigate('/contact')
                }}> <i class="fa-solid fa-angles-right me-2" ></i> Contact Us</li>
              </ul>
            </div>

            <div className="col col-12 col-md-4">
              <img className="nfooter_logo" src={snh_logo}></img>
              <div className="application_link">
                <a href="https://play.google.com/store/apps/details?id=com.kodenauts.shhydrafacial.android">
                  <img src={googleplay} className="google_play" ></img>
                </a>
                <a href="https://apps.apple.com/in/app/sh-hydrafacial/id6683282817">
                  <img src={appstore} className="app_store"></img>
                </a>
              </div>
            </div>

            <div className="col col-12 col-md-4">
              <ul className="nfooter_ul_list" >
                <li > <i class="fa-solid fa-angles-right me-2"></i> <a className="tell" href="tel:+917984794957">Mobile No : (+91)-7984794957</a></li>
                <li> <i class="fa-solid fa-angles-right me-2"></i> <a className="tell" href="mailto:Support@S&HAestheticClinic.com">Email : support@shhydrafacial.in</a></li>
                <li> <i class="fa-solid fa-angles-right me-2 "></i> Address : OPP. BHAVANS SCHOOL, FF/102, PRATAP TOWER,
                  MAKARPURA ROAD, Vadodara,Gujarat, 390004</li>
                <li> <i class="fa-solid fa-angles-right me-2"></i> GSTIN : 24ADEPC8664R1Z0</li>
              </ul>
            </div>
          </div>

        </div>

        <div className="nfooter_third_div">
          <div>Copyright © 2024 S&H Aesthetic Clinic</div>
        </div>
      </footer>
    </>

  );
}

export default Footer;
